import { LeadsList } from 'src/app/model/enums/leads-list-type.enum';

export const enum ESortOptions {
  CLICKS = 'clicks',
  CONTENT = 'content',
  EMAIL = 'email',
  FIRST_NAME = 'firstname',
  GROUP_TITLE = 'title',
  IS_OWA_ACTIVATED = 'isOwaSignatureActivated',
  LAST_NAME = 'lastname',
  PROSPECT = 'isProspect',
  TG_TITLE = 'TargetGroupList.title',
  VIEWS = 'views'
}

export interface IFullQueryObj extends IQueryObj {
  action: string;
  sortList: string;
}

export interface ISortList {
  [index: string]: string[];
  employees: string[];
  groups: string[];
  targetListEntries: string[];
  //TODO reenable later, after multi-timezone support
  // latestClickAt: string[];
}

export interface IQueryObj {
  [index: number]: string;
  page: number;
  pagesize: number;
  search: string;
  sort: string;
  sortDir: 'ASC' | 'DESC';
}

// Target group entries queryObj
export interface TargetGroupEntriesQueryObj {
  doNotUseProspects?: boolean;
  type?: LeadsList;
}
