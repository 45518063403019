<div ngbDropdown class="btn-group sort leftbutton" container="body">
  <mt-button
    text="sort"
    [dropdown]="true"
    id="dropdownSort"
    ngbDropdownToggle
  ></mt-button>
  <ul ngbDropdownMenu aria-labelledby="dropdownSort">
    <ng-content></ng-content>
  </ul>
</div>
