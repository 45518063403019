// Angular
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

// Rxjs
import { BehaviorSubject, map, ReplaySubject } from 'rxjs';

// Services
import { AlertService } from 'src/app/services/alert/alert.service';
import { InstallationService } from '@services/installation/installation.service';
import { SignatureDataService } from 'src/app/services/signature-data/signature-data.service';
import { UtilService } from 'src/app/services/util/util.service';

// Interfaces
import { IEmployeeGet } from 'src/app/services/employee/employee-service.interface';
import { InstallationStep } from 'src/app/model/interfaces/integrations.interface';
import {
  IntegrationsSignature,
  SignatureInstallationMailClientType
} from 'src/app/model/interfaces/signature.interface';
import { IWizardTabComponent, IWizardTabsContainerComponent } from 'src/app/model/interfaces/wizard.interface';

// Other
import { INSTALLATION_GUIDE } from 'src/app/shared/components/organisms/installation/model/installation-guide-modal.const';
import { MtCheckboxComponent } from '@atoms/inputs/checkbox/checkbox.component';
import { InstallationGuideStepsComponent } from '@organisms/installation/components/installation-guide-steps/installation-guide-steps.component';
import { SignatureRolloutPreviewComponent } from '@organisms/cogsig-signatures/signature-rollout-preview/signature-rollout-preview.component';
import { WizardTabComponent } from '@organisms/wizard/wizard-tab/wizard-tab.component';
import { WizardTabsContainerComponent } from '@organisms/wizard/wizard-tabs-container/wizard-tabs-container.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';

@Component({
  selector: 'mt-installation-guide-modal',
  templateUrl: './installation-guide-modal.component.html',
  styleUrls: ['./installation-guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CustomModalComponent,
    NgClass,
    NgIf,
    TranslocoModule,
    WizardTabsContainerComponent,
    NgFor,
    WizardTabComponent,
    SignatureRolloutPreviewComponent,
    InstallationGuideStepsComponent,
    MtCheckboxComponent,
    AsyncPipe
  ]
})
export class InstallationGuideModalComponent implements OnInit {
  /**
   * Input data to holds the `signatures`, `title` and `user`
   * @defaultValue {
   * signatures: [],
   * title: '',
   * user: '',
   *}
   */
  @Input()
  inputData = {
    signatures: [] as IntegrationsSignature[],
    title: '',
    user: {} as IEmployeeGet
  };

  stepIntroDescription!: string;
  installationData!: InstallationStep[];
  showSignaturePreview = false;

  _isCogSigSignature$ = new ReplaySubject<string>();

  /**
   * Information for wizard container
   * @defaultValue translate: 'available_signature_instructions'
   */
  signaturePreviewListComponent: IWizardTabsContainerComponent = { translate: 'available_signature_instructions' };

  companyData$ = this.signatureDataService.loadCompanyData();

  /**
   * Information selected mail client type
   * @defaultValue type: 'other'
   */
  private _mailClientType$ = new BehaviorSubject<SignatureInstallationMailClientType>('other');
  mailClientType$ = this._mailClientType$.asObservable();

  /**
   * The list of signature as a wizard steps and status as completed or incomplete
   */
  signatureStepsStatus$ = this.installationService.stepStatus$.pipe(
    map(stepsData => {
      return stepsData.map(data => {
        data.open = false;
        return data;
      });
    })
  );

  constructor(
    private alert: AlertService,
    private installationService: InstallationService,
    private signatureDataService: SignatureDataService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    switch (this.inputData.title) {
      case 'outlook_mac_easysync_header_rec':
        this.stepIntroDescription = 'html.outlookmac_easysync_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_mac_easysync_1_1;
        break;

      case 'outlook_mac_manual_header':
        this.stepIntroDescription = 'html.outlook_mac_manual_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_mac_desktop_manual_1_2;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'outlook_windows_easysync_header_rec':
        this.stepIntroDescription = 'html.outlook_windows_easysync_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_win_easysync_2_1;
        break;

      case 'outlook_windows_manual_header':
        this.stepIntroDescription = 'html.outlook_windows_manual_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_win_desktop_manual_2_2;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_outlook_1_3_title':
        this.stepIntroDescription = 'html.outlook_web_manual_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_on_the_web_3;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_outlook_1_4_title':
        this.stepIntroDescription = 'html.outlook_mobile_android_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_mobile_app_android_4;
        this.updateSelectedMailClient('android');
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_outlook_1_5_title':
        this.stepIntroDescription = 'html.outlook_mobile_ios_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_mobile_app_ios_5;
        this.updateSelectedMailClient('android');
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_gmail_2_2_title':
        this.stepIntroDescription = 'html.gmail_manual_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.gmail_manual_6;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_gmail_2_3_title':
        this.stepIntroDescription = 'html.gmail_android_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.gmail_mobile_app_android_7;
        this.updateSelectedMailClient('android');
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_gmail_2_4_title':
        this.stepIntroDescription = 'html.gmail_ios_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.gmail_mobile_app_ios_8;
        this.updateSelectedMailClient('android');
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'mail_easysync_header_rec':
        this.stepIntroDescription = 'html.applemail_easysync_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.apple_mail_desktop_easysync_9_1;
        break;

      case 'mail_manual_header':
        this.stepIntroDescription = 'html.applemail_manual_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.apple_mail_desktop_manual_9_2;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_apple_mail_3_2_title':
        this.stepIntroDescription = 'html.applemail_ios_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.apple_mail_desktop_app_ios_10;
        this.showSignaturePreview = true;
        this.updateSelectedMailClient('ios');
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_android_4_1_title': {
        this.stepIntroDescription = 'html.android_samsung_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.android_samsung_mobile_email_app_11;
        this.showSignaturePreview = true;
        this.updateSelectedMailClient('android');
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;
      }

      case 'integration_other_5_1_title':
        this.stepIntroDescription = 'html.html_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.html_copy_and_paste_12_1;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'integration_other_5_2_title':
        this.stepIntroDescription = 'html.signature_template_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.signature_tempalte_copy_and_paste_12_2;
        this.showSignaturePreview = true;
        this.installationService.updateSignaturePreviewStepStatus(
          this.getStepsFromSignatures(this.inputData.signatures)
        );
        break;

      case 'outlook_addin_header_hl':
        this.stepIntroDescription = 'html.outlook_outlook_addin_1';
        this.installationData = INSTALLATION_GUIDE.outlook_addin;
        break;

      case 'integration_gmail_2_1_title':
        this.stepIntroDescription = 'html.int_gmail_easysync_hl';
        this.installationData = INSTALLATION_GUIDE.chromExtenstionGSuite;
        break;

      default:
        this.stepIntroDescription = 'html.outlookmac_easysync_direction_0_0';
        this.installationData = INSTALLATION_GUIDE.outlook_mac_easysync_1_1;
        break;
    }
  }

  /**
   * Updates the step status as completed or incomplete
   * @param step - The step info
   * @param steps - The steps of signature list
   */
  changeStepStatus(step: IWizardTabComponent, steps: IWizardTabComponent[]): void {
    step.open = !step.open;
    step.completed = !step.completed;
    this.utilService.setWizardCompleted(step, step.subtitle, step.completed);
    this.installationService.updateSignaturePreviewStepStatus(steps);
  }

  /**
   * Scroll to specific signature preview
   * @param signatureId - The signature id
   * @param step - The signature step
   * @param mailClientType - The trigger to check the integration type for mobile devices
   * @param signatureTxt - The signature plaintext
   */
  scrollToPreview(
    signatureId: string,
    step: IWizardTabComponent,
    mailClientType: SignatureInstallationMailClientType,
    signatureTxt: string
  ): void {
    if (mailClientType === 'android' && !signatureTxt) {
      step.open = false;
      void this.alert.defaultInfoMessage(this.alert.translate('html.plaintext_no_plaintext'));
    } else {
      // If step is open then only signature preview should be load
      if (step.open === true) {
        this._isCogSigSignature$.next(signatureId);
        setTimeout(() => {
          if (document.getElementById(signatureId)) document.getElementById(signatureId)?.scrollIntoView();
        }, 0);
      }
    }
  }

  /**
   * Updates the slected mail client type
   * @param type - The mail client type
   */
  updateSelectedMailClient(type: SignatureInstallationMailClientType): void {
    this._mailClientType$.next(type);
  }

  /**
   * Given an array of signatures, generates an array of steps to display each signature
   *
   * @remarks Should always be used when a guide displays signature previews
   * @param signatures - Signatures to generate steps for
   * @returns Array of steps
   */
  private getStepsFromSignatures(signatures: IntegrationsSignature[]): IWizardTabComponent[] {
    return signatures.map((data, index) => {
      return {
        stepNo: index + 1,
        tabTitle: data.signatureTitle,
        subtitle: data.groupTitle,
        open: false
      };
    });
  }
}
