<ng-container *transloco="let translate">
  <div class="product-container-header">
    <div class="image">
      <img
        src="/assets/images/planOverview/icon_basic.png"
        width="125"
        height="125"
      />
    </div>
    <div class="title">{{ translate('plan_short_sig_management') }}</div>
    <div class="title-content">
      <span
        class="content"
        [innerHTML]="translate('html.info_basic_new')"
      ></span>
    </div>
    <div class="seperation-line martop30"></div>
  </div>

  <ng-container *ngIf="currentProduct === 'Basic'; else notBasic">
    <div class="current-product fontsize20 padtop140">
      {{ translate('your_current_plan') }}
    </div>
  </ng-container>

  <ng-template #notBasic>
    <div *ngIf="priceData.totalUsers < maxUsers">
      <mt-product-container-price
        [priceBig]="priceData.basicBig"
        [priceSmall]="priceData.basicSmall"
        [totalUsers]="priceData.totalUsers >= 90 ? priceData.totalUsers : 90"
        [costPerUser]="priceData.basePrices.Basic.yearly.costPerUser"
        [billingInterval]="billingInterval"
      ></mt-product-container-price>
      <div class="martop20 booking-buy-term-box"></div>
      <a
        (click)="setProduct.emit('Basic')"
        class="btn firstbutton btn-lg mt_blau lineheight15 font13"
      >
        {{ translate('buy_plan_sig_management') }}</a
      >
    </div>
    <div *ngIf="priceData.totalUsers >= maxUsers" class="martop40">
      <div class="product-container-demo-content">
        <div class="image">
          <img
            src="/assets/images/planOverview/avatar_sales_phillip.png"
            alt=""
            width="145"
            height="145"
          />
        </div>
        <div>
          <span class="content">{{ translate('out_team_adivces_you') }}</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="priceData.totalUsers >= 250"
      [ngClass]="{ martop20: priceData.totalUsers >= maxUsers }"
    >
      <a
        class="btn firstbutton btn-lg btn-quote lineheight15 martop10"
        href="mailto:t.bauer@mailtastic.com"
        >{{ translate('get_proposal') }}
      </a>
    </div>
  </ng-template>
</ng-container>
