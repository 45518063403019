import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISignaturePlaceholderImg } from 'src/app/model/interfaces/signature.interface';
import { ISignaturePlaceholderTypeModalOptions } from 'src/app/services/alert/alert-service.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SignatureHelperService } from 'src/app/services/signature-helper/signature-helper.service';
import { ImageCropperModalComponent } from '@organisms/modals/signature-placeholder-type/image-cropper/image-cropper.component';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { take } from 'rxjs/operators';
import { TStatus } from 'src/app/model/types/status.type';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { UploadImageButtonComponent } from '@molecules/buttons/upload-image-button/upload-image-button.component';
import { MtColorPickerComponent } from '@atoms/inputs/color-picker/color-picker.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgStyle } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-signature-placeholder-type-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    CustomModalComponent,
    DefaultBoxComponent,
    FormsModule,
    MtColorPickerComponent,
    NgIf,
    NgStyle,
    ReactiveFormsModule,
    TranslocoModule,
    UploadImageButtonComponent
  ]
})
export class SignaturePlaceholderTypeImageModalComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'https://app.mailtastic.de/api/images/default/User.png';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('modalDeleteImage') modalDeleteImage!: TemplateRef<any>;
  modalDeleteImageRef!: NgbModalRef;

  @Input() datasetId?: string;
  @Input() employeeId?: string;
  @Input() modalOptions: ISignaturePlaceholderTypeModalOptions = {
    mode: 'create',
    showSignatureRelevantData: false
  };

  imageDimensionsAvailable = true;
  imageDimensionData = { mode: '' };

  imageForm!: UntypedFormGroup;

  infoMessages = { isCompany: false };

  saveImageData!: ISignaturePlaceholderImg;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal,
    public signatureHelper: SignatureHelperService,
    private modalService: NgbModal,
    private alert: AlertService,
    private employeeService: EmployeeService
  ) {}

  //#region LIFE CYCLE
  ngOnInit(): void {
    this.imageForm = this.createImageForm();
  }
  //#endregion

  //#region PUBLIC
  /**
   * Opens the modal to choose if the image should be deleted.
   */
  deleteImage(): void {
    this.modalDeleteImageRef = this.modalService.open(this.modalDeleteImage, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal mt_modal_900'
    });

    this.modalDeleteImageRef.result.then(
      (res: TStatus) => {
        if (res === 'refresh') {
          const emptyImage: ISignaturePlaceholderImg = {
            defaultImage: this.DEFAULT_IMAGE,
            disabled: false,
            imgdimension: { height: 0, mode: 'default', width: 0 },
            label: 'Foto',
            locked: false,
            styles: { linkcolor: '#000000' },
            tag: 'ma_foto',
            type: 'image',
            value: {
              altText: '',
              etag: null,
              image: '',
              initialdimension: { height: 0, width: 0 },
              isCropped: false,
              linkText: '',
              showAs: 'text',
              type: 'image',
              url: '',
              whichImage: 'default'
            }
          };

          // changes the image form data to have default values
          this.imageForm.get('url')?.setValue('');
          this.imageForm.get('altText')?.setValue('');
          this.imageForm.get('linkText')?.setValue('');
          this.imageForm.get('image')?.setValue('');
          this.imageForm.get('imgradio')?.setValue('default');
          this.imageForm.get('globalradio')?.setValue('false');
          this.imageForm.get('textorgraphic')?.setValue('image');
          this.imageForm.get('underline')?.setValue('true');
          this.imageForm.get('linkcolor')?.setValue('');

          // changes the modal options form data to have default values
          if (
            this.signatureHelper.isImg(this.modalOptions.formData) &&
            this.signatureHelper.isImgValue(this.modalOptions.formData?.value)
          ) {
            this.modalOptions.formData.value.isCropped = false;
          }

          this.readyImageDataDetails(emptyImage);

          // closes image component modal
          this.modal.close(this.saveImageData);
        }
      },
      (rej: TStatus) => {
        this.modal.close(rej);
        void this.alert.defaultErrorMessage(this.alert.translateDataNotLoaded());
      }
    );
  }

  /**
   * Gets either the image src or the default image.
   * @returns image src
   */
  getNoImageCropSrc(): string {
    const isSrc = this.getImageFormImageAsString();
    if (isSrc !== '') {
      return isSrc;
    } else {
      return this.DEFAULT_IMAGE;
    }
  }

  /**
   * Sets the image or is redirected to crop image if `ma_foto`.
   * @param image
   */
  setImage(image: HTMLImageElement): void {
    if (this.signatureHelper.isImg(this.modalOptions.formData)) {
      if (this.modalOptions.formData.tag === 'ma_foto') {
        const modalRef = this.modalService.open(ImageCropperModalComponent, {
          backdrop: 'static',
          backdropClass: 'mt_modal_default_backdrop',
          windowClass: 'mt_modal employeeModal mt_modal_930'
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        modalRef.componentInstance['displayImage'] = image;

        // applies all the data of the form onto saveImageData
        this.readyImageDataDetails();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        modalRef.componentInstance['imageData'] = this.saveImageData;

        modalRef.result.then(
          (res: ISignaturePlaceholderImg) => {
            if (this.signatureHelper.isImg(this.modalOptions.formData)) {
              if (this.signatureHelper.isImgValue(this.modalOptions.formData?.value) && res.value?.image) {
                this.modalOptions.formData.value.image = ''; // forces to be clean save

                // for the view
                if (typeof res.value.image !== 'string') {
                  this.imageForm.get('image')?.setValue(res.value?.image.$ngfDataUrl);
                } else {
                  this.imageForm.get('image')?.setValue(res.value?.image);
                }

                // for the view
                this.modalOptions.formData.value.isCropped = res.value.isCropped;
                this.modalOptions.formData.value.cropType = res.value.cropType;

                // ready's the save
                this.readyImageDataDetails(res);
              }
            }
          },
          (failure: string) => {
            this.alert.debugFailedModal(failure);
          }
        );
      } else {
        if (this.signatureHelper.isImgValue(this.modalOptions.formData?.value)) {
          this.modalOptions.formData.value.image = ''; // forces to be clean save

          this.imageForm.get('image')?.setValue(image.src); // for viewing;
        }
      }
    }
  }

  /**
   * Sets the link color.
   * @param color
   */
  setLinkColor(color: string): void {
    this.imageForm.get('linkcolor')?.setValue(color);
  }

  /**
   * Saves the data.
   */
  saveValue(): void {
    this.readyImageDataDetails();

    this.modal.close(this.saveImageData);
  }
  //#endregion

  //#region TEMPLATE DELETE IMAGE
  /**
   * Deletes the user image for either all or just selected profile.
   * @param whoToDelete datasetId | 'all'
   */
  deleteUserImage(whoToDelete?: string): void {
    if (!this.employeeId || !whoToDelete) {
      return;
    }
    this.employeeService
      .deleteUserImage(this.employeeId, whoToDelete)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.modalDeleteImageRef.close('refresh');
        } else {
          this.modalDeleteImageRef.close('errored');
          void this.alert.defaultErrorMessage(this.alert.translateDataNotLoaded());
        }
      });
  }
  //#endregion

  //#region PRIVATE
  /**
   * Creates the form group.
   * @returns
   */
  private createImageForm(): UntypedFormGroup {
    const formData = this.modalOptions.formData as ISignaturePlaceholderImg;

    // REMARK-JS currently ISignaturePlaceholder.global value doesn't do anything

    return this.formBuilder.group({
      tag: this.formBuilder.control({ value: formData?.tag, disabled: true }),
      url: formData?.value?.url,
      altText: formData?.value?.altText,
      linkText: formData?.value?.linkText,
      image: formData?.value?.image,
      imgradio: formData?.value?.whichImage || 'default',
      textorgraphic: formData?.value?.showAs || 'image',
      globalradio: String(this.modalOptions?.global || false),
      underline: formData.styles?.underline || 'true',
      imgdimradio: formData.imgdimension.mode,
      linkcolor: formData.styles?.linkcolor || '',
      allDataProfiles: true
    });
  }

  /**
   * Gets the string value of the saved value for the image.
   * @returns image src
   */
  private getImageFormImageAsString(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isString = this.imageForm.get('image')?.value;
    if (typeof isString === 'string') {
      return isString;
    } else {
      return '';
    }
  }

  /**
   * Prepares the data to be saved for the backend.
   * @param appendData e.g. cropped image
   */
  private readyImageDataDetails(appendData?: ISignaturePlaceholderImg): void {
    const formData = {
      // global: this.imageForm.get('globalradio')?.value as boolean,
      style: {
        linkcolor: this.imageForm.get('linkcolor')?.value as string,
        underline: this.imageForm.get('underline')?.value as string
      },
      value: {
        altText: this.imageForm.get('altText')?.value as string,
        image: '',
        linkText: this.imageForm.get('linkText')?.value as string,
        showAs: this.imageForm.get('textorgraphic')?.value as string,
        type: 'image',
        url: this.imageForm.get('url')?.value as string,
        whichImage: this.imageForm.get('imgradio')?.value as string
      }
    };

    let uploadFileData = {};

    if (this.signatureHelper.isImg(this.modalOptions.formData)) {
      let backendSaveFile;
      if (this.modalOptions.formData.value?.image !== this.getImageFormImageAsString()) {
        backendSaveFile = {
          value: {
            image: {
              $ngfDataUrl: this.getImageFormImageAsString(),
              $ngfHeight: this.modalOptions.formData.imgdimension.height,
              $ngfWidth: this.modalOptions.formData.imgdimension.width
            },
            showAs: 'image',
            whichImage: 'own'
          }
        } as ISignaturePlaceholderImg;
      }
      if (this.modalOptions.formData.value && backendSaveFile?.value) {
        this.modalOptions.formData.value = { ...this.modalOptions.formData.value, ...backendSaveFile.value };
      }
      uploadFileData = this.modalOptions.formData;
    }

    // to prevent cyclic object value
    this.saveImageData = Object.assign({}, this.saveImageData, uploadFileData, formData);

    if (appendData) {
      this.saveImageData = Object.assign({}, this.saveImageData, appendData);
    }
  }
  //#endregion
}
