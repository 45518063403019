import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SignatureCampaignStatusService } from 'src/app/services/signature-campaign-status/signature-campaign-status.service';
import { SignatureDataService } from 'src/app/services/signature-data/signature-data.service';
import { ISignatureGetAll } from 'src/app/services/signature/signature-service.interface';
import { SignatureService } from 'src/app/services/signature/signature.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'mt-guide-signature-list-modal',
  templateUrl: './guide-signature-list-modal.component.html',
  styleUrls: ['./guide-signature-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideSignatureListModalComponent {
  @Input() list!: ISignatureGetAll[];

  constructor(
    private alert: AlertService,
    private router: Router,
    private signatureDataService: SignatureDataService,
    private signatureService: SignatureService,
    private utilService: UtilService,
    public modal: NgbActiveModal,
    public signatureCampaignStatusService: SignatureCampaignStatusService
  ) {}

  /**
   * Rolls out signature to all groups it is assigned to
   * @param signature - Signature to rollout
   */
  rolloutSignatures(signature: ISignatureGetAll): void {
    if (signature.activegroups.length === 0 || signature.activegroups[0].amountOfMembers === 0) {
      this.signatureDataService.setOpenSignatureEditStep('rollout');
      void this.router.navigate(['/signatures/edit/', signature.id]);

      this.modal.close(true);
    } else {
      this.signatureService.rolloutSignature(signature.id).subscribe(() => {
        void this.alert.defaultSuccessMessage(this.alert.translate('CHANGES_SAVED_SUCCESSFULLY'));
        this.modal.close(true);
      });
    }
  }

  /**
   * Redirect selected signature to editor page
   * Scrolls to add group step directly
   * @param signature - Signature object
   */
  openAddGroupStep(signature: ISignatureGetAll): void {
    this.signatureDataService.setOpenSignatureEditStep('assignDepartment');
    void this.router.navigate(['/signatures/edit/', signature.id]);
    this.modal.close(true);
  }
}
