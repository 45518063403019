import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MtIconsModule } from 'projects/mailtastic-icons-library/src/lib/mt-icons/mt-icons.module';
import { NgbDropdownModule, NgbModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

// Components
import { CogsigTopInfoBannerComponent } from './cogsig-top-info-banner/cogsig-top-info-banner.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { NavigationSidebarComponent } from './navigation-sidebar/navigation-sidebar.component';
import { NavigationSidebarEmployeeSubmenuComponent } from './submenu-employee/navigation-sidebar-employee-submenu.component';
import { NavigationSidebarIntegrationSubmenuComponent } from './submenu-integrations/navigation-sidebar-integration-submenu.component';
import { NavigationSidebarMainMenusComponent } from './menu/navigation-sidebar-main-menus.component';
import { NavigationSidebarMarketingSubmenuComponent } from './submenu-marketing/navigation-sidebar-marketing-submenu.component';
import { NavigationSidebarMenuHeaderComponent } from './menu-header/navigation-sidebar-menu-header.component';
import { NavigationSidebarSettingsSubmenuComponent } from './submenu-settings/navigation-sidebar-settings-submenu.component';
import { NavigationSidebarSettingsTooltipComponent } from './settings/navigation-sidebar-settings-tooltip.component';
import { NavigationSidebarSignatureSubmenuComponent } from './submenu-signature/navigation-sidebar-signature-submenu.component';
import { NavigationSidebarSubmenuHeaderComponent } from './submenu-header/navigation-sidebar-submenu-header.component';
import { TopInfoBannerComponent } from './top-info-banner/top-info-banner.component';
import { TopInfoBannerSuperAdminComponent } from './top-info-banner-super-admin/top-info-banner-super-admin.component';

// Modules
import { DashboardRoutingModule } from '@modules/dashboard/dashboard-routing.module';

// Directives
import { ClickOutsideDirective } from '@modules/root/directives/click-outside/click-outside.directive';

// Pipes
import { DisplayMenuByPlanPipe } from './pipes/display-menu-by-plan/display-menu-by-plan.pipe';
import { HeaderAdjustmentPipe } from 'src/app/modules/root/pipes/header-adjustment/header-adjustment.pipe';
import { IsMenuShownPipe } from 'src/app/modules/root/pipes/is-menu-shown/is-menu-shown.pipe';
import { ShortNamePipe } from './pipes/short-name/short-name.pipe';
import { SubmenuInactivePipe } from 'src/app/modules/root/pipes/submenu-inactive/submenu-inactive.pipe';
import { TextTruncatePipe } from '@shared/pipes/text-truncate/text-truncate.pipe';
import { TopAreaShownPipe } from './pipes/top-area-shown/top-area-shown.pipe';

// Services
import { IntegrationsService } from '@modules/integrations/services/integrations.service';
import { SimpleAuthService } from '@modules/auth/services/simple-auth.service';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    CogsigTopInfoBannerComponent,
    DisplayMenuByPlanPipe,
    HeaderAdjustmentPipe,
    IsMenuShownPipe,
    NavigationSidebarComponent,
    NavigationSidebarEmployeeSubmenuComponent,
    NavigationSidebarIntegrationSubmenuComponent,
    NavigationSidebarMainMenusComponent,
    NavigationSidebarMarketingSubmenuComponent,
    NavigationSidebarMenuHeaderComponent,
    NavigationSidebarSettingsSubmenuComponent,
    NavigationSidebarSettingsTooltipComponent,
    NavigationSidebarSignatureSubmenuComponent,
    NavigationSidebarSubmenuHeaderComponent,
    ShortNamePipe,
    SubmenuInactivePipe,
    TopAreaShownPipe,
    TopInfoBannerComponent,
    TopInfoBannerSuperAdminComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    MtIconsModule,
    NgbModule,
    ReactiveFormsModule,
    NgbTooltip,
    NgbDropdownModule,
    RouterModule,
    TranslocoModule,
    TextTruncatePipe,
    MtSvgComponent,
    ButtonComponent
  ],
  exports: [NavigationSidebarComponent],
  providers: [IntegrationsService, SimpleAuthService]
})
export class MtNavigationSidebarModule {}
