<div class="martop35">
  <div class="bigprice flex">
    <div class="number">{{ priceBig }}</div>
    <div class="unit">
      ,{{ priceSmall | currency:'EUR':'symbol':'2.0-0':'de-DE' }}
    </div>
    <div class="fontsize20">*</div>
  </div>
  <div class="flex-center-all priceinfotext includedusers martop25">
    <span class="begin" transloco="per_month_for"></span>
    <input
      [value]="totalUsers"
      readonly="true"
      type="text"
      class="form-control numberofempsinput"
    />
    <span class="end" transloco="Users"></span>
  </div>
  <div class="hint">
    <span
      [innerHtml]="
        'param.AdjustProfessionalFlexible'
          | transloco
            : {
                amount: (costPerUser | currency: 'EUR':'':'1.2-2':'de-DE')
              }
      "
    ></span>
    <span>
      / {{ 'accounting' | transloco }} {{ billingInterval | transloco }}
    </span>
  </div>
</div>
