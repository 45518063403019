import { Injectable } from '@angular/core';
import { IAccountData } from '@model/interfaces/account-admin.interface';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, take } from 'rxjs';
import { UpgradeSubscriptionComponent } from 'src/app/modules/dashboard/components/modals/upgrade-subscription/upgrade-subscription.component';

@Injectable()
export class DasboardModalService {
  /**
   * Modal options
   */
  modalOptions: NgbModalOptions = {
    animation: true,
    backdrop: 'static',
    backdropClass: 'mt_modal_default_backdrop'
  };

  constructor(private modal: NgbModal) {}

  /**
   * Open upgrade subscription modal
   * @param accountdata - Object of account data
   * @returns Observable of boolean
   */
  openExpireToUpgradeSubscriptionModal(accountData: IAccountData): Observable<boolean> {
    const modalInstance = this.modal.open(UpgradeSubscriptionComponent, {
      ...this.modalOptions,
      keyboard: false,
      size: 'lg',
      windowClass: 'mt_modal_990'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.accountData = accountData;

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }
}
