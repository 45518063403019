import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { IGroup, GroupSmall, SyncGroup } from 'src/app/model/interfaces/group.interface';
import {
  IResponse,
  IResponseCount,
  IResponseData,
  IResponseMessage,
  Response,
  ResponseMessageOrCode
} from 'src/app/model/interfaces/response.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { INTERCOM_DATA } from '../intercom/intercom.module';
import { IntercomService } from '../intercom/intercom.service';
import { IQueryObj } from '../query-helper/query-helper-service.interface';
import { QueryHelperService } from '../query-helper/query-helper.service';
import { IGroupAdd, IGroupGet, IGroupGetMembers, GroupGetOne, IGroupGetStatistics } from './group-service.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators,
    private queryHelperService: QueryHelperService
  ) {}

  /**
   * Gets all groups based off the `queryObj`
   * @param queryObj - Object containing the query parameters
   * @returns Observable containing groups
   */
  get(queryObj?: IQueryObj): Observable<IGroupGet[]> {
    return this.http
      .get<IResponseData<IGroupGet[]>>('/groups' + this.queryHelperService.createQuery(queryObj))
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the small details of all groups
   * @param queryObj - Object containing the query parameters
   * @returns Observable of groups
   */
  getSmallDetails(queryObj?: IQueryObj): Observable<GroupSmall[]> {
    return this.http
      .get<IResponseData<GroupSmall[]>>(`/groups/smalldetails${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the count of groups
   * @param queryObj - Object containing query parameters
   * @returns Observable of total number of groups
   */
  count(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<IResponseCount>(`/groups/count${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseCount());
  }

  /**
   * Gets one group
   * @param id - The id of the group to return
   * @returns Observable containing group information
   */
  getOne(id: number): Observable<GroupGetOne> {
    return this.http.get<[GroupGetOne]>(`/groups/${id.toString()}`).pipe(
      map(value => {
        if (!value[0]) throw new Error(this.alert.translateTechnicalError());
        return value[0];
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Search for the group with the `query`
   * @param query
   * @returns
   */
  search(query: string): unknown {
    return this.http.get('/groups/search/withq?search=' + query).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Adds the group to be able to be synced
   * @param groupName - The group name
   * @returns - Observable of group id
   */
  addSyncGroup(groupName: string): Observable<number> {
    return this.http.post<Response & SyncGroup>('/groups/syncGroup', { title: groupName }).pipe(
      map(value => {
        if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
        return value.groupId;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Duplicates the group
   * @param groupId - Group identifier to duplicate
   * @param newTitle - New group's title
   * @param copyMembers - Determines if group members should be copied
   * @param copySignature - Determines if group signature should be copied
   * @param copyCampaign - Determines if group campaign should be copied
   * @returns Observable containing success message
   */
  duplicateGroup(
    groupId: number,
    newTitle: string,
    copyMembers: boolean,
    copySignature: boolean,
    copyCampaign: boolean
  ): Observable<true> {
    return this.http
      .put<ResponseMessageOrCode>('/groups/duplicategroup', {
        groupId,
        newTitle,
        copyMembers,
        copySignature,
        copyCampaign
      })
      .pipe(
        map(value => {
          if (!value.success) {
            if (value.message && value.message === 'department_already_exists') {
              throw new Error(value.message);
            }
            throw new Error(this.alert.translateDataNotLoaded());
          }
          return value.success;
        }),
        tap(() => {
          this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'groups');
        })
      );
  }

  /**
   * Sets the active campaign for one or more groups
   * @param groupIds - Group(s) to assign the campaing for
   * @param campaignId - Campaign id to be assigned
   * @remarks When no campaign id provided removes the campaign assignment from the group(s)
   * @returns Observable of true
   */
  setCampaign(groupIds: number[], campaignId?: number): Observable<true> {
    return this.http
      .put<IResponseMessage>('/groups/modify/campaign', {
        campaignId: campaignId !== undefined ? campaignId : null,
        groups: groupIds
      })
      .pipe(
        this.operator.extractResponse(),
        tap(() => {
          if (groupIds.length === 1 && environment.isCogSig)
            campaignId
              ? this.intercomService.trackEvent(INTERCOM_DATA.campaign_changed)
              : this.intercomService.trackEvent(INTERCOM_DATA.assigned_campaign_to_group);
        })
      );
  }

  /**
   * Sets the default signature setting and priority. Priority is determined by the array order.
   * @param newmail - New mails groups ids
   * @param reply - Reply mails groups ids
   * @param internalMail - Internal mails groups ids
   * @returns Observable of true
   */
  setDefaultSignatureSettings(newmail: number[], reply: number[], internalMail: number[]): Observable<true> {
    return this.http
      .put<IResponseMessage>('/groups/defaultsignatures', { newmail, reply, internalMail })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Sets the active signature to one or many groups
   * @param groupIds
   * @param signatureId
   * @returns
   */
  setSignature(groupIds: number[], signatureId?: string): Observable<true> {
    return this.http.put<IResponseMessage>('/groups/modify/signature', { signatureId, groups: groupIds }).pipe(
      this.operator.extractResponse(),
      tap(() => {
        if (groupIds.length === 1 && environment.isCogSig) {
          signatureId
            ? this.intercomService.trackEvent(INTERCOM_DATA.signature_modified)
            : this.intercomService.trackEvent(INTERCOM_DATA.assigned_signature_to_group);
        }
      })
    );
  }

  /**
   * Gets the members of the specific group
   * @param id - Group identifier
   * @param queryObj - Object containing query params
   * @returns Observable of group members
   */
  getMembers(id: number, queryObj?: IQueryObj): Observable<IGroupGetMembers[]> {
    return this.http
      .get<IResponseData<IGroupGetMembers[]>>(
        `/groups/${id.toString()}/members${this.queryHelperService.createQuery(queryObj)}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Counts the amount of employees for a specific group
   * @param id - Group identifier
   * @param queryObj - Object containing query params
   * @returns Observable of group members count
   */
  countMembersById(id: number, queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<IResponseData<number>>(
        `/groups/${id.toString()}/members/count${this.queryHelperService.createQuerySearch(queryObj)}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the group
   * @param group - Updated group object
   * @returns Observable containing the success of the operation
   */
  update(group: Partial<IGroup>): Observable<true> {
    return this.http.put<IResponse>('/groups', group).pipe(
      map(value => {
        if (!value.success) {
          if (value.status && value.status === 400) {
            throw new Error('department_already_exists');
          }
          throw new Error(this.alert.translateDataNotLoaded());
        }
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.group_modified);
        return value.success;
      })
    );
  }

  /**
   * Creates a new group
   * @param group - Group object to create, holding title and members to add
   * @returns Observable of group id created
   */
  add(group: IGroupAdd | IGroup): Observable<number> {
    return this.http.post<IResponseMessage & { groupId: number; status?: number }>('/groups', group).pipe(
      map(value => {
        if (!value.success) {
          if (value.status && value.status === 400) {
            throw new Error('department_already_exists');
          }
          throw new Error(this.alert.translateDataNotLoaded());
        }
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.group_created);
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'groups');
        return value.groupId;
      })
    );
  }

  /**
   * Deletes one group
   * @param id - The group id to delete
   * @returns - Observable of true
   */
  delete(id: number): Observable<true> {
    return this.http.delete<IResponse>(`/groups/${id.toString()}`).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-1, 'groups');
      })
    );
  }

  /**
   * Deletes multiple groups
   * @param ids - Array of ids to delete
   * @returns Observable of true
   */
  deleteMany(ids: number[]): Observable<true> {
    return this.http.post<IResponseMessage>('/groups/del/many', { groupids: ids }).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-ids.length, 'groups');
      })
    );
  }

  /**
   * Gets the statistics for all groups
   * @returns
   */
  getStatstics(): Observable<IGroupGetStatistics> {
    return this.http.get<IGroupGetStatistics>('/groups/data/statistics').pipe(
      map(value => {
        if (!value) throw new Error(this.alert.translateDataNotLoaded());
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets list with employee ids that can be unassigned from the group
   * @param id - Group identifier
   * @returns Observable containing array of employee ids
   */
  getUnassignableMembers(id: number): Observable<string[]> {
    return this.http.get<{ success: boolean; data: string[] }>(`/groups/${id}/members/unassignable`).pipe(
      map(value => {
        if (!value.success) throw new Error(this.alert.translateTechnicalError());
        return value.data;
      }),
      catchError(() => {
        throw new Error(this.alert.translateTechnicalError());
      })
    );
  }
}
