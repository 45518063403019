<div class="statuscol">
  <div class="title italic" *ngIf="signaturestatus.signatures.length > 1">
    <div class="innertitle" style="overflow: hidden">
      <mt-svg
        [ngClass]="iconColor"
        class="tiny-svg marright4 mt-darkgrey"
        src="assets/images/siteelements/signature_list_svg.svg"
      ></mt-svg>
      <span>{{ signaturestatus.signatures.length }} </span>
      <span
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        [ngbTooltip]="signaturestatus.signatures.length <= 5 ? less5 : more5"
        class="upper cursor-pointer"
        transloco="signaturesAssignedShortened"
      ></span>
    </div>
  </div>
  <div class="title" *ngIf="signaturestatus.signatures.length === 1">
    <div class="innertitle" style="overflow: hidden">
      <mt-svg
        [ngClass]="iconColor"
        class="tiny-svg marright4 mt-darkgrey"
        src="assets/images/siteelements/signature_list_svg.svg"
      ></mt-svg>
      <span
        class="link"
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ signatures[0].title }}"
        [routerLink]="['/signatures/details/', signatures[0].id]"
        (click)="redirectToSignatureDetail.emit()"
        >{{ signatures[0].title }}</span
      >
    </div>
  </div>

  <div class="title schrift_rot italic" *ngIf="signatures.length === 0">
    <div class="innertitle" style="overflow: hidden">
      <span
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ 'nosigassignedtodep' | transloco }}"
        transloco="Unassigned"
      ></span>
    </div>
  </div>

  <div class="subline"><span transloco="Signature"></span></div>
  <!-- Temporary Disable -->
  <!-- <div class="subline">
    <img
      *ngIf="
        signaturestatus.signatures.length > 0 &&
        signaturestatus.atLeastOneNotLatest === false &&
        signaturestatus.hidestatus !== true
      "
      src="assets/images/common/Tick.png"
      alt="OK"
      class="statuspicto"
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ successmo }}"
    />

    <img
      *ngIf="
        signaturestatus.signatures.length > 0 &&
        signaturestatus.atLeastOneNotLatest === true &&
        signaturestatus.hidestatus !== true
      "
      src="assets/images/common/Ausrufezeichen.png"
      alt="Attention"
      class="statuspicto"
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ alertmo | transloco }}"
      tooltip-append-to-body="true"
    />

    <span
      *ngIf="
        signaturestatus.signatures.length > 0 &&
        signaturestatus.atLeastOneNotLatest === false
      "
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ successmo | transloco }}"
      transloco="Signature"
    ></span>
    <span
      *ngIf="
        signaturestatus.signatures.length > 0 &&
        signaturestatus.atLeastOneNotLatest === true
      "
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ alertmo | transloco }}"
      transloco="Signature"
    ></span>
    <span
      *ngIf="signaturestatus.signatures.length === 0"
      transloco="Signature"
    ></span>
  </div> -->
</div>

<!-- Template used when multiple signatures but less than 5 -->
<ng-template #less5>
  <ng-container *ngFor="let signature of signaturestatus.signatures">
    <span class="grouptitle">{{ signature.title }} <br /></span>
  </ng-container>
</ng-template>

<!-- Template used when more than 5 signatures -->
<ng-template #more5>
  <ng-container
    *ngFor="let signature of signaturestatus.signatures; let index = index"
  >
    <span *ngIf="index < 5">
      <span class="grouptitle">{{ signature.title }} <br /></span>
    </span>
  </ng-container>
  <span class="grouptitle"
    >...{{ signaturestatus.signatures.length - 5 }} {{ 'more' | transloco
    }}</span
  >
</ng-template>
