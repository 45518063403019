import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mt-product-container-price',
  templateUrl: './product-container-price.component.html',
  styleUrls: ['./product-container-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductContainerPriceComponent {
  /**
   * Input of large price value to show
   */
  @Input() priceBig!: string;

  /**
   * Input of small/fractional price value to show
   */
  @Input() priceSmall!: string;

  /**
   * Input of total users
   */
  @Input() totalUsers!: number;

  /**
   * Input of cost per user
   */
  @Input() costPerUser!: number;

  /**
   * Input of billing interval
   */
  @Input() billingInterval!: string;
}
