import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntegrationsSignature } from 'src/app/model/interfaces/signature.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GeneratorStoreService } from 'src/app/services/generator-store/generator-store.service';
import { tap } from 'rxjs';
import { PatternService } from 'src/app/shared/services/pattern.service';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'mt-signature-rollout-preview',
  templateUrl: './signature-rollout-preview.component.html',
  styleUrls: ['./signature-rollout-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe]
})
export class SignatureRolloutPreviewComponent {
  /**
   * Hold signature html preview string
   */
  data!: SafeHtml;

  /**
   * Hold signature object
   */
  private _signature!: IntegrationsSignature;

  /**
   * Input integration signature object
   */
  @Input() set signature(val: IntegrationsSignature) {
    val.signatureHtml = val.signatureHtml
      .replaceAll('?m=o', '')
      .replaceAll('&m=o', '')
      .replaceAll('?track=n', '')
      .replaceAll('&track=n', '')
      .replaceAll('moz-do-not-send="true"', '');
    this._signature = val;
    this.data = this.sanitized.bypassSecurityTrustHtml(this._signature.signatureHtml);
  }
  get signature(): IntegrationsSignature {
    return this._signature;
  }

  /**
   * Observable is used to copy rollout signature template
   */
  copyRolloutSignatureContent$ = this.generatorStoreService.copyRolloutSignatureContent$.pipe(
    tap(copySignatureHTML => {
      if (!copySignatureHTML.isCopy) {
        this.generatorStoreService.signatureHTML.next(this._signature.signatureHtml);
      } else {
        const listener = (e: ClipboardEvent) => {
          if (!copySignatureHTML.isHtmlCopy) {
            e.clipboardData?.setData(
              'text/html',
              this._signature.signatureHtml.replace(this.pattern.sanitizeSignatureHTMLPattern, '')
            );
          }
          e.clipboardData?.setData(
            'text/plain',
            this._signature.signatureHtml.replace(this.pattern.sanitizeSignatureHTMLPattern, '')
          );
          e.preventDefault();
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
      }
    })
  );

  constructor(
    private generatorStoreService: GeneratorStoreService,
    private pattern: PatternService,
    private sanitized: DomSanitizer
  ) {}
}
