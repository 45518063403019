<div
  class="target-groups-col cursor-normal"
  [ngClass]="{ first: isFirst === true }"
>
  <div *ngIf="targetGroups[0]">
    <div
      *ngIf="targetGroups.length > 1 && displayedTargetGroups[0].title"
      class="title italic"
    >
      <div
        style="overflow: hidden; display: inline-block"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip-template="'tooltipTemplate1.html'"
      >
        <span class="mt-mediumgrey">
          <mt-svg
            class="tiny-svg calicon"
            src="assets/images/siteelements/targetgroup_list_svg.svg"
          ></mt-svg>
          <span> {{ targetGroups.length }} </span>
          <span transloco="target_group_s"></span>
        </span>
      </div>
    </div>

    <div
      *ngIf="targetGroups.length === 1 && displayedTargetGroups[0].title"
      class="title flex"
      [ngClass]="{
        'mt-blau': isFirst === true,
        'mt-mediumgrey': isFirst === false
      }"
    >
      <div class="innertitle" style="overflow: hidden">
        <mt-svg
          class="tiny-svg calicon"
          [ngClass]="iconColor"
          src="assets/images/siteelements/targetgroup_list_svg.svg"
        >
        </mt-svg>
        <a
          *ngIf="disableLinks === false"
          class="link"
          style="line-height: 1; vertical-align: top"
          (click)="$event.stopPropagation()"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ displayedTargetGroups[0].title }}"
          [routerLink]="['/targetgroups/details/', displayedTargetGroups[0].id]"
          >{{ displayedTargetGroups[0].title }}</a
        >

        <span
          *ngIf="disableLinks === true"
          style="line-height: 1; vertical-align: top"
          (click)="$event.stopPropagation()"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ displayedTargetGroups[0].title }}"
          >{{ displayedTargetGroups[0].title }}</span
        >
      </div>
      <div class="marleft5">
        <span
          class="status_badge hubspot"
          *ngIf="(displayedTargetGroups[0].source | lowercase) === 'hubspot'"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ 'hubspot_mo' | transloco }}"
        >
          <span transloco="hubspot"></span>
        </span>
        <span
          class="status_badge marketo"
          *ngIf="(displayedTargetGroups[0].source | lowercase) === 'marketo'"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ 'marketo_mo' | transloco }}"
        >
          <span transloco="marketo"></span>
        </span>
        <span
          class="status_badge pardot"
          *ngIf="(displayedTargetGroups[0].source | lowercase) === 'pardot'"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ 'pardot_mo' | transloco }}"
        >
          <span transloco="pardot"></span>
        </span>
      </div>
    </div>

    <div
      *ngIf="!displayedTargetGroups[0].title"
      class="title schrift_rot italic"
    >
      <span
        transloco="Unassigned"
        style="cursor: pointer"
        tooltipClass="status-info-tooltip"
        ngbTooltip-template="'tooltipTemplateUnassigned.html'"
        placement="top"
      ></span>
      <ng-template #tooltipTemplateUnassigned.html>
        <span class="grouptitle" transloco="no_targetgroup_found"></span>
      </ng-template>
    </div>

    <div class="subline">
      <span
        transloco="{{
          displayedTargetGroups[0].type !== 'contact'
            ? 'target_group_accounts'
            : 'target_group_contacts'
        }}"
      ></span>
    </div>

    <ng-template #tooltipTemplate1.html>
      <div
        *ngFor="let targetGroup of displayedTargetGroups"
        class="mt-white title"
      >
        <mt-svg
          class="medium-svg"
          [ngClass]="iconColor"
          src="assets/images/siteelements/targetgroup_list_svg.svg"
        ></mt-svg>
        <span class="grouptitle">{{ targetGroup.title }}</span>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="!targetGroups[0]">
    <div class="title schrift_rot">
      <span
        class="italic"
        transloco="Unassigned"
        style="cursor: pointer"
        tooltipClass="status-info-tooltip"
        ngbTooltip="{{ 'no_targetgroup_found' | transloco }}"
        placement="top"
      ></span>
      <div class="subline">
        <span transloco="target_group_accounts"></span>
      </div>

      <ng-template #tooltipTemplate1>
        <div
          *ngFor="let targetGroup of displayedTargetGroups"
          class="mt-white title"
        >
          <mt-svg
            class="medium-svg mt-darkgrey"
            [ngClass]="iconColor"
            src="assets/images/siteelements/targetgroup_list_svg.svg"
          ></mt-svg>

          <span class="grouptitle">{{ targetGroup.title }}</span>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
