<div
  *ngIf="parent && parent.isStepDisplay"
  class="detailpanel {{ parent.tabThemeClass }}"
>
  <div
    class="header"
    (click)="onHeaderClickHandler($event)"
    [ngClass]="[
      parent.open ? 'active' : 'header_only cursor-pointer',
      parent.completed ? 'completed' : ''
    ]"
    (mouseenter)="_headerHovered = true"
    (mouseleave)="_headerHovered = false"
  >
    <div
      class="start"
      style="
        display: inline-block;
        width: calc(100% - 100px);
        overflow: hidden;
        white-space: nowrap;
      "
      [ngStyle]="{
        width:
          'calc(100% - ' +
          ((parent.open || parent.resolveBtn ? 100 : 0) +
            (parent.showRemovedBtn && (_headerHovered || parent.open)
              ? 50
              : 0)) +
          'px)',
        color: parent.open ? '#96d2eb' : '#8593a2'
      }"
    >
      <!-- step no. -->

      <span
        [ngClass]="{
          steperrorbg: !parent.formInputValid
        }"
      >
        <mt-svg
          *ngIf="parent.stepNo"
          class="mt-tab-step-no"
          [ngClass]="{
            'htf-regent_grau': !parent.open,
            'htf-white': parent.open,
            'completed-icon':
              !parent.open &&
              (parent.completed || (parent.isLastStep && allTabsFinished())),
            'error-icon': !parent.formInputValid
          }"
          src="{{ stepNoSrc }}"
        ></mt-svg>
        <!-- has NO step no. -->
        <mt-svg
          *ngIf="!parent.stepNo && parent.iconSrc"
          class="mt-tab-step-no steperror"
          [ngClass]="{
            'htf-white': parent.open,
            'htf-regent_grau': !parent.open,
            'completed-icon': !parent.open && parent.completed,
            'error-icon': !parent.open && !parent.formInputValid
          }"
          src="{{ stepNoSrc }}"
        ></mt-svg>
      </span>
      <!-- title -->
      <span class="mt-tab-title" [innerHTML]="tabTitle | transloco"></span>
      <!-- subtitle -->
      <span
        data-cy="tab-subtitle"
        class="mt-tab-subtitle"
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ tabSubtitle | transloco }}"
        [innerHTML]="tabSubtitle | transloco | textTruncate : 45"
      ></span>

      <!-- subtitle strong-->
      <span *ngIf="parent.subtitleStrong" class="mt-tab-subtitle"
        ><b>{{ parent.subtitleStrong }}</b></span
      >
    </div>
    <div class="end">
      <!-- remove btn -->
      <mt-svg
        *ngIf="parent.showRemovedBtn && (_headerHovered || parent.open)"
        class="cursor-pointer mt-tab-close-btn"
        (click)="onRemovedBtnClick($event); $event.stopPropagation()"
        src="assets/images/campaigns/cancel.svg"
      ></mt-svg>

      <!-- completed button -->
      <mt-button
        *ngIf="parent.open"
        type="submit"
        (click)="onCompletedBtnClick($event)"
        color="default"
      >
        {{ parent.completedBtnText }}
      </mt-button>

      <!-- resolve button -->
      <button
        *ngIf="!parent.open && parent.resolveBtn"
        type="button"
        class="btn ownbtn resolve-btn"
        transloco="{{ parent.resolveBtn }}"
        (click)="onHeaderClickHandler($event)"
      ></button>
    </div>
  </div>
  <!-- TAB CONTENT -->
  <div
    class="content {{ parent.classContent }}"
    *ngIf="parent.open && !parent.isLastStep"
  >
    <ng-content></ng-content>
  </div>
  <!-- LAST TAB CONTENT -->
  <div class="content" *ngIf="parent.open && parent.isLastStep">
    <mt-wizard-tab-last
      [parent]="parent"
      [invalidTabs]="invalidTabs"
      (editInvalid)="onInvalidTabClick($event)"
    ></mt-wizard-tab-last>
  </div>
</div>
