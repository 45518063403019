import { Injectable } from '@angular/core';
import { distinctUntilChanged, ReplaySubject, shareReplay, Subject, debounceTime, BehaviorSubject } from 'rxjs';
import { IEmployeeGet } from 'src/app/services/employee/employee-service.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchValue = '';
  public _searchInput: Subject<string> = new Subject<string>();
  searchInput = this._searchInput.asObservable().pipe(shareReplay(1));
  public selectedEmployee?: IEmployeeGet;

  readonly minPreviewSearchLength = 2;

  private _selectedEmployee$ = new ReplaySubject<IEmployeeGet>(1);
  selectedEmployee$ = this._selectedEmployee$
    .asObservable()
    .pipe(distinctUntilChanged((prev, curr) => prev.id === curr.id));

  private _selectedEmployeeInEditSig$ = new ReplaySubject<IEmployeeGet>(1);
  selectedEmployeeInEditSig$ = this._selectedEmployeeInEditSig$.asObservable().pipe(distinctUntilChanged());

  private _selectedEmployeeWithDataset$ = new ReplaySubject<IEmployeeGet>(1);
  selectedEmployeeWithDataset$ = this._selectedEmployeeWithDataset$.asObservable();

  /**
   * Returns the current search value use to filter lists
   *
   * Waits 400ms and emits only distinct values
   */
  private _filterText$ = new BehaviorSubject('');
  filterText$ = this._filterText$.asObservable().pipe(debounceTime(400), distinctUntilChanged());

  /**
   * Sets a new search value
   * @param value - New search value to set
   */
  setSearchInputString(value: IEmployeeGet | string): void {
    if (typeof value === 'string') {
      this.searchValue = value;
    } else {
      this.searchValue = `${value.firstname} ${value.lastname}`;
    }
  }

  /**
   * Returns the current search value
   * @returns Current search value
   */
  getSearchInputString(): string {
    return this.searchValue;
  }

  /**
   * Emitting change to other component waiting for this change
   * This is called when user selects employee from dropdown list
   */
  emitChange(): void {
    this._searchInput.next(this.searchValue);
  }

  /**
   * Sets a new employee as selected
   * @param employee - Employee to set as selected
   */
  setSelectedEmployee(employee: IEmployeeGet): void {
    this.selectedEmployee = employee;
    this.setSearchInputString(employee);
    this._selectedEmployee$.next(employee);
  }

  /**
   * Sets a new employee as selected in edit signature
   * @param employee - Employee to set as selected
   */
  setSelectedEmployeeInEditSig(employee: IEmployeeGet): void {
    this.selectedEmployee = employee;
    this._selectedEmployeeInEditSig$.next(employee);
  }

  /**
   * Sets an employee with selected dataset id in signature generator
   * @param employee - Employee to set as selected
   */
  setEmployeeWithSelectedDataset(employee: IEmployeeGet): void {
    this.selectedEmployee = employee;
    this._selectedEmployeeWithDataset$.next(employee);
  }

  /**
   * Sets the new value for text filtered
   * @param text - String to set as text filtered
   */
  setFilterText(text: string): void {
    this._filterText$.next(text);
  }
}
