<mt-custom-modal headline="{{ 'Selectexistingdepartment' | transloco }}">
  <!-- Modal Body -->
  <ng-container modal-body>
    <div class="content">
      <div class="flex-center-space-between">
        <div class="flex">
          <!-- All Button -->
          <mt-select-all-button
            class="leftbutton"
            [checked]="list.isSelectedAll()"
            (click)="list.updateSelectionAll()"
          ></mt-select-all-button>
        </div>

        <!-- Append Text -->
        <div class="flex">
          <ng-container *ngIf="list.all.length">
            <mt-svg
              class="mt-grey tiny-svg"
              src="assets/images/svgs/info.svg"
            ></mt-svg>
            <div
              class="flex format3 hidden-xs"
              style="max-width: 400px; margin-left: 10px"
              transloco="Noteallocatedarenotdisplayed"
            ></div
          ></ng-container>
        </div>

        <div class="flex">
          <mt-search
            *ngIf="(searchData$ | async) || list.all.length"
            class="leftbutton"
            [disableEnter]="true"
            (search)="list.setFilterText($event)"
          ></mt-search>

          <div
            *ngIf="list.all.length"
            ngbDropdown
            class="btn-group sort leftbutton"
          >
            <mt-button
              text="sort"
              [dropdown]="true"
              id="dropdownSort"
              ngbDropdownToggle
            ></mt-button>
            <ul ngbDropdownMenu aria-labelledby="dropdownSort">
              <li
                ngbDropdownItem
                transloco="sort_titel"
                (click)="list.sortList('title')"
              ></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Group List Container -->
      <div
        class="martop30 main_block_with_scroll"
        style="max-height: 500px; overflow: auto"
      >
        <!-- Group List -->
        <div
          infiniteScroll
          [infiniteScrollDisabled]="infinitescrolloptions.disable"
          [infiniteScrollContainer]="'.main_block_with_scroll'"
          [fromRoot]="true"
          (scrolled)="infinitescrolloptions.scrollAction()"
          *ngIf="loadData$ | async; else loading"
        >
          <ul class="lists">
            <ng-container
              *ngFor="let item of list.all; odd as isOdd; even as isEven"
            >
              <li
                class="listrow"
                (click)="list.updateSelection(item)"
                [ngClass]="{
                  selected: list.isSelected(item),
                  odd: isOdd,
                  even: isEven
                }"
              >
                <div class="tcell first select">
                  <selectcol [list]="list" [item]="item"></selectcol>
                </div>

                <div class="tcell" style="width: 35%">
                  <groupcol
                    [groups]="[item]"
                    [isFirst]="true"
                    [disableLinks]="true"
                    iconColor="mt-blau"
                  ></groupcol>
                </div>

                <div class="tcell" style="width: 35%">
                  <campaignstatuscol
                    [campaigns]="item.Campaign ? [item.Campaign] : []"
                    alertmo="
                    {{ 'atLeastOneEmpHasNotIntegratedCampaignYet' | transloco }}
                  "
                    successmo="{{ 'bannerIsShownInEveryEmpsSig' | transloco }}"
                    (redirectToCampaignDetail)="modal.close()"
                  ></campaignstatuscol>
                </div>

                <div style="width: 20%" class="tcell banner">
                  <img
                    *ngIf="item?.Campaign?.image"
                    style="max-width: 100%; max-height: 50px"
                    src="{{ item?.Campaign?.image }}"
                    alt="Campaign Image"
                  />
                </div>
              </li>
            </ng-container>
          </ul>

          <!-- Empty Group List -->
          <ng-container *ngIf="list.all.length === 0 && !isLoading">
            <mt-box
              type="info"
              icon="info-sign"
              translateTag="Therearenodepartmentsthatarecurrentlyassigned"
            >
              <div class="additionaltext">
                <span transloco="Noteallocatedarenotdisplayed"></span>
              </div>
            </mt-box>
          </ng-container>
        </div>

        <!-- Loading -->
        <ng-template #loading>
          <mt-emptystate [loadingPromise]="true"></mt-emptystate>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <div modal-footer>
    <div class="flex-center-space-between">
      <div class="flex">
        <div *ngIf="list.selected.length">
          <span>{{ list.selected.length }}</span>
          <span class="marleft5" transloco="departments_selected"></span>
        </div>
      </div>

      <div class="flex">
        <mt-button
          color="default"
          text="abort"
          class="leftbutton"
          (click)="modal.close()"
        ></mt-button>
        <mt-button
          [disabled]="!list.selected.length"
          color="primary"
          text="assigndepartment"
          icon="ok"
          (click)="accept()"
        ></mt-button>
      </div>
    </div>
  </div>
</mt-custom-modal>
