import { BookingConfig, BookingContainer } from 'src/app/model/interfaces/payment.interface';

export const BOOKING_CONFIG: BookingConfig = {
  billingInterval: 'yearly',
  defaultPlan: 'Basic',
  maxUsers: 500,
  minAmountForInvoice: 1500, // Min amount for allow invoice
  paymentMethod: 'creditcard'
};

/**
 * Plan features based on products
 */
export const BOOKING_CONTAINER: BookingContainer = {
  openStep1: true,
  openStep2: true,
  openStep3: true,
  signatureManagement: {
    title: 'fah',
    items: ['fa1', 'fa2', 'fa3', 'fa4', 'fa5', 'fa6', 'fa7', 'fa8', 'fa9', 'fa11', 'fa10'],
    free: {
      includes: ['fa1', 'fa2', 'fa3', 'fa4', 'fa5', 'fa6', 'fa7', 'fa8', 'fa9', 'fa11'],
      excludes: ['fa10']
    },
    basic: {
      includes: ['fa1', 'fa2', 'fa3', 'fa4', 'fa5', 'fa6', 'fa7', 'fa8', 'fa9', 'fa11'],
      excludes: ['fa10']
    },
    advanced: {
      includes: ['fa1', 'fa2', 'fa3', 'fa4', 'fa5', 'fa6', 'fa7', 'fa8', 'fa9', 'fa11', 'fa10'],
      excludes: []
    },
    target: {
      includes: ['fa1', 'fa2', 'fa3', 'fa4', 'fa5', 'fa6', 'fa7', 'fa8', 'fa9', 'fa11', 'fa10'],
      excludes: []
    }
  },
  signatureMarketing: {
    title: 'fbh',
    items: ['fb1', 'fb2', 'fb3', 'fb4', 'fb5', 'fb6', 'fb7', 'fb8', 'fb9', 'fb10', 'fb11', 'fb12', 'fb13', 'fb14'],
    free: {
      includes: ['fb1', 'fb2', 'fb3', 'fb4', 'fb5', 'fb6'],
      excludes: ['fb7', 'fb8', 'fb9', 'fb10', 'fb11', 'fb12', 'fb13', 'fb14']
    },
    basic: {
      includes: [],
      excludes: ['fb1', 'fb2', 'fb3', 'fb4', 'fb5', 'fb6', 'fb7', 'fb8', 'fb9', 'fb10', 'fb11', 'fb12', 'fb13', 'fb14']
    },
    advanced: {
      includes: ['fb1', 'fb2', 'fb3', 'fb4', 'fb5', 'fb6'],
      excludes: ['fb7', 'fb8', 'fb9', 'fb10', 'fb11', 'fb12', 'fb13', 'fb14']
    },
    target: {
      includes: ['fb1', 'fb2', 'fb3', 'fb4', 'fb5', 'fb6', 'fb7', 'fb8', 'fb9', 'fb10', 'fb11', 'fb12', 'fb13', 'fb14'],
      excludes: []
    }
  },
  general: {
    title: 'fch',
    items: ['fc1', 'fc2', 'fc3', 'fc4', 'fc5', 'fc6', 'fc7', 'fc8', 'fc9', 'fc10', 'fc11', 'fc12', 'fc13'],
    free: {
      includes: ['fc10', 'fc11', 'fc3'],
      excludes: []
    },
    basic: {
      includes: ['fc1', 'fc2', 'fc3', 'fc4', 'fc9'],
      excludes: ['fc12']
    },
    advanced: {
      includes: ['fc1', 'fc2', 'fc3', 'fc4', 'fc9'],
      excludes: ['fc12']
    },
    target: {
      includes: ['fc1', 'fc2', 'fc3', 'fc4', 'fc9'],
      excludes: ['fc12']
    }
  }
};
