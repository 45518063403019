import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISignature } from 'src/app/model/interfaces/signature.interface';
import { ISignatureStatus } from 'src/app/services/signature-campaign-status/signature-campaign-status.service';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'signaturestatuscol',
  templateUrl: './signature-status-col.component.html',
  styleUrls: ['./signature-status-col.component.scss'],
  standalone: true,
  imports: [NgIf, MtSvgComponent, NgClass, TranslocoModule, NgbTooltip, RouterLink, NgFor]
})
export class SignatureStatusColComponent {
  _signaturestatus!: ISignatureStatus;
  _signatures!: ISignature[];

  /**
   * Message to display when user action is required
   * @defaultValue ''
   */
  @Input() alertmo = '';

  /**
   * Color for svg icon
   * @defaultValue ''
   */
  @Input() iconColor = '';

  /**
   * Message to display when no issues with signature status
   * @defaultValue ''
   */
  @Input() successmo = '';

  /**
   * Array of assigned signatures
   */
  @Input()
  set signatures(arrData: ISignature[]) {
    this._signatures = arrData;
  }
  get signatures(): ISignature[] {
    return this._signatures;
  }

  /**
   * Status of signature when only one assigned
   */
  @Input()
  set signaturestatus(data: ISignatureStatus) {
    this._signatures = data.signatures;
    this._signaturestatus = data;
  }
  get signaturestatus(): ISignatureStatus {
    return this._signaturestatus;
  }

  /**
   * Triggers the signature detail page redirection
   */
  @Output() redirectToSignatureDetail = new EventEmitter<void>();
}
