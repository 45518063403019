import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { IAdmin } from 'src/app/model/interfaces/admin.interface';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { IResponseMessage } from 'src/app/model/interfaces/response.interface';

@Component({
  selector: 'mt-invite-external-admin',
  templateUrl: './invite-external-admin.component.html',
  styleUrls: ['./invite-external-admin.component.scss']
})
export class InviteExternalAdminComponent implements OnInit {
  @Input() public administrators!: [];

  modalTitle = '';
  modalBody = '';
  inputModel = '';
  showMessage = false;
  modalInfoMessage = '';

  constructor(
    public modal: NgbActiveModal,
    private userService: UserService,
    private alert: AlertService,
    public translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.modalTitle = this.translate.translate('createNewExternalAdminstrator');
    this.modalBody = this.translate.translate('send_to_which_mailaddy');
    this.modalInfoMessage = this.translate.translate('admin_already_exists');
  }
  /**
   * Cancels the modal instance
   */
  cancel(): void {
    this.inputModel = '';
    this.showMessage = false;
    this.modal.close();
  }

  /**
   * Sends the invitation for external admin
   */
  submit() {
    if (
      this.administrators.some((admin: IAdmin) => {
        return admin.email === this.inputModel;
      })
    ) {
      this.modalInfoMessage = 'admin_already_existing';
      this.showMessage = true;
      return;
    }
    this.showMessage = false;

    this.userService
      .sendExternalAdminInvitation(this.inputModel)
      .pipe(take(1))
      .subscribe(
        (result: IResponseMessage) => {
          if (result.success) {
            this.modal.close(true);
            void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
          } else {
            if (result.message === 'EXTERNAL_ADMIN_ALREADY_EXISTING') {
              this.modalInfoMessage = 'external_admin_already_exists';
              this.showMessage = true;
            } else if (result.message === 'NON_EXISTING_ADMIN') {
              this.modalInfoMessage = 'external_admin_not_found';
              this.showMessage = true;
            } else if (result.message === 'ADMIN_OF_THIS_ACCOUNT') {
              this.modalInfoMessage = 'admin_of_this_account';
              this.showMessage = true;
            } else {
              void this.alert.defaultErrorMessage(this.alert.translateTechnicalError());
            }
          }
        },
        error => {
          void this.alert.defaultErrorMessage(error);
        }
      );
  }
}
