<!-- Settings submenu section -->
<ul
  class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
  [ngClass]="{ 'cognism-signatures': isCognismSignatures }"
>
  <ng-container *ngFor="let menu of menus">
    <ng-container *ngIf="!menu.submenu; else optionWithSubMenu">
      <!-- Displayed submenu name which has no dropdown options  -->
      <li
        *ngIf="!(hasSubscription && menu.name === 'nav_plan_and_billing')"
        (click)="navigationSidebarService.closeOtherDropDown(menus, menu.name)"
      >
        <a
          [routerLink]="menu.url"
          routerLinkActive="active"
          class="show"
          [ngClass]="{
            link__inactive:
              (menu.name
              | submenuInactive
                : authorizationScope.isUserOnBasicPlan
                : authorizationScope.isUserOnAdvancedPlan)
          }"
        >
          <ng-container
            [ngTemplateOutlet]="renderSideMenuData"
            [ngTemplateOutletContext]="{ menu }"
          ></ng-container>
        </a>
      </li>
    </ng-container>
    <ng-template #optionWithSubMenu>
      <!-- Displayed submenu name(only) which has dropdown options  -->
      <ng-container>
        <li
          *ngIf="menu.name | displayMenuByPlan : hasSubscription || false"
          (click)="
            navigationSidebarService.closeOtherDropDown(menus, menu.name);
            menu.isDropdownOpen = !menu.isDropdownOpen
          "
        >
          <a
            class="show"
            [ngClass]="{
              link__inactive:
                (menu.name
                | submenuInactive
                  : authorizationScope.isUserOnBasicPlan
                  : authorizationScope.isUserOnAdvancedPlan)
            }"
          >
            <ng-container
              [ngTemplateOutlet]="renderSideMenuData"
              [ngTemplateOutletContext]="{ menu }"
            ></ng-container>
          </a></li
      ></ng-container>

      <!-- Displayed submenu options which has dropdown  -->
      <ng-container
        *ngIf="
          menu.isDropdownOpen &&
          (menu.name | displayMenuByPlan : hasSubscription || false)
        "
      >
        <ng-container *ngFor="let subMenu of menu.submenu; let i = index">
          <li
            class="mt-submenu-title_subtitle-wrapper"
            *ngIf="
              subMenu.name
                | displayMenuByPlan : authorizationScope.isUserOnTargetPlan
            "
          >
            <a
              [routerLink]="subMenu.url"
              routerLinkActive="active"
              class="show"
            >
              <div class="mt-submenu-icon marleft5">
                <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                </mt-svg>
              </div>
              <span
                class="mt-submenu-title_subtitle marleft5"
                [transloco]="subMenu.name"
              ></span>
            </a></li
        ></ng-container>
      </ng-container>
    </ng-template>
  </ng-container>

  <!-- Displayed submenu name which has no dropdown options  -->
  <ng-template let-menu="menu" #renderSideMenuData>
    <div class="mt-submenu-icon">
      <mt-svg
        [ngClass]="
          (menu.name
          | submenuInactive
            : authorizationScope.isUserOnBasicPlan
            : authorizationScope.isUserOnAdvancedPlan)
            ? 'mt-grau'
            : 'mt-white'
        "
        src="{{ menu.image }}"
      >
      </mt-svg>
    </div>
    <span
      class="mt-submenu-title inline-block ccccc"
      [transloco]="menu.name"
    ></span>
    <mt-svg
      *ngIf="menu.submenu && menu.submenu.length"
      class="mt-submenu-dropdown-arrow martop4 mt-white"
      src="assets/images/sidebar/down-triangle.svg"
    >
    </mt-svg>
  </ng-template>
</ul>
