import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { SIDEBAR_MENU } from '@modules/root/model/navigation-sidebar.const';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '@services/account/account-service.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'mt-navigation-sidebar-settings-submenu',
  templateUrl: './navigation-sidebar-settings-submenu.component.html',
  styleUrls: ['./navigation-sidebar-settings-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarSettingsSubmenuComponent {
  /**
   * Environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Toggle dropdown of plans
   * @defaultValue true
   */
  @Input() isPlanAndBillingDropdown = true;

  /**
   * Sets user has subscription status
   */
  @Input() hasSubscription!: boolean | null;

  /**
   * Get authorization scope to identify the plan information
   */
  @Input() authorizationScope!: IMAILTASTIC_AUTHORIZATION_SCOPE;

  /**
   * Settings sidebar menus
   */
  menus: NavigationSidebarMenu[] = cloneDeep(SIDEBAR_MENU.advanced);

  constructor(public navigationSidebarService: NavigationSidebarService) {}
}
