<mt-custom-modal
  class="unsaved"
  headerColor="no-color"
  headline="{{ modalHeader | transloco }}"
  theme="video-theme"
>
  <ng-container modal-body
    ><div class="bootbox-body" [innerHTML]="text | transloco"></div>
    <div class="flex-center-right modal-footer mt-4 pe-0">
      <mt-button
        *ngIf="saveBtnText"
        [text]="saveBtnText"
        (click)="modal.close('save_and_exit')"
      ></mt-button>
      <mt-button
        [text]="noSaveBtnText"
        (click)="modal.close('dont_save_and_exit')"
      ></mt-button>
      <mt-button
        text="stay_on_page"
        color="primary"
        (click)="modal.close('abort')"
      ></mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
