<header class="padtop10 {{ className }}" routerLink="/dashboard">
  <div class="sidebar-header" (click)="closeSubmenuSidebar.emit()">
    <div class="mt-menu-logo-icon cursor-pointer">
      <mt-svg
        *ngIf="!isCognismSignatures; else cognismLogo"
        src="assets/images/sidebar/mailtastic.svg"
        class="w-100"
      >
      </mt-svg>
      <ng-template #cognismLogo>
        <mt-svg
          class="cognism-signatures-sidebar-logo"
          *ngIf="isCognismSignatures"
          src="assets/images/sidebar/cognism.svg"
        >
        </mt-svg>
      </ng-template>
    </div>
  </div>
</header>
