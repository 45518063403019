import { Injectable } from '@angular/core';

import { differenceInDays, isBefore, parseISO, isDate } from 'date-fns';
import { environment } from 'src/environments/environment';

import { ICampaign } from 'src/app/model/interfaces/campaign.interface';
import { ISignature } from 'src/app/model/interfaces/signature.interface';
import { IUsers } from 'src/app/model/interfaces/user.interface';
import { IGroupObject, GroupSmall } from 'src/app/model/interfaces/group.interface';

export type TSignatureLatest = 'outdated' | 'never' | 'latest' | true | false;

export interface ISignatureStatus {
  atLeastOneNotLatest: boolean;
  signatures: ISignature[];
  hidestatus?: boolean;
}

export interface ICampaignStatus {
  atLeastOneNotLatest: boolean;
  campaigns: ICampaign[];
  hasCampaignStatus?: boolean;
  hasEventCampaignStatus?: boolean;
  triggerEventCampaignStatus?: ICampaign;
}

@Injectable({
  providedIn: 'root'
})
export class SignatureCampaignStatusService {
  /**
   * Gets the signaturestatus for one specific signature
   * @param userDataUpdatedAt - When was the user info changed
   * @param signatureLastRolledOutAt - When was this signature activated for the user
   * @param signatureActivatedAt - Last rollout datetime of signature itself
   * @returns Whether the signature is up to date or not
   */
  getSignatureStatusIsLatest(
    userDataUpdatedAt?: Date,
    signatureLastRolledOutAt?: Date,
    signatureActivatedAt?: Date
  ): boolean {
    // if not signatureId provided take the one from data manager
    if (!userDataUpdatedAt || !signatureLastRolledOutAt || !signatureActivatedAt) {
      return false;
    }

    return !(
      isBefore(signatureActivatedAt, userDataUpdatedAt) || isBefore(signatureActivatedAt, signatureLastRolledOutAt)
    );
  }

  /**
   * Check if the campaign activation status is in between the threshold
   * @param {Date} campaignActivedAt
   * @returns {boolean}
   */
  getCampaignStatusIsLatest(campaignActivedAt: Date): boolean {
    if (!campaignActivedAt) {
      return false;
    }
    const today = new Date();
    const timediff = differenceInDays(today, campaignActivedAt);

    return timediff < environment.daysTillInactive + 1;
  }

  /**
   * Gets the campaign status for all campaigns which are assigned to the employee
   * @param employeeObject - the employee to update the campaign status
   * @returns the campaign status of the `employeeObject`
   */
  getEmployeeStatusForAllAssignedCampaigns(employeeObject: IUsers): ICampaignStatus {
    const ret: ICampaignStatus = {
      campaigns: [],
      atLeastOneNotLatest: false
    };

    // iterate over all groups in which the employee is member
    for (const group of employeeObject.Groups) {
      if (group.activeEvent) {
        const triggerCampaign = group.TriggerEvents ? group.TriggerEvents.TriggerCampaign : undefined;
        if (triggerCampaign) {
          ret.campaigns.push({
            ...triggerCampaign,
            event: true
          });
        }
      } else {
        const campaign = group.Campaign;
        if (campaign) {
          ret.campaigns.push({
            ...campaign,
            event: false
          });
        }
      }
    }

    ret.hasCampaignStatus =
      (ret.campaigns.length === 1 && ret.campaigns[0].event === false) || ret.campaigns.length === 0;
    ret.hasEventCampaignStatus = ret.campaigns.length === 1 && ret.campaigns[0].event === true;
    ret.triggerEventCampaignStatus =
      employeeObject.Groups[0] && employeeObject.Groups[0].TriggerEvents
        ? employeeObject.Groups[0].TriggerEvents.TriggerCampaign
        : undefined;

    return ret;
  }

  /**
   * Gets the signature status for all signatures which are assigned to the employee
   * @param employeeObject - the employee to update the signature status
   * @returns the signature status of the `employeeObject`
   */
  getEmployeeStatusForAllAssignedSignatures(employeeObject: IUsers): ISignatureStatus {
    const ret: ISignatureStatus = {
      signatures: [],
      atLeastOneNotLatest: false,
      hidestatus: false
    };

    // iterate over all groups in which the employee is member
    for (const group of employeeObject.Groups) {
      let isLatest = this.getEmployeeSignatureStatusForOneGroup(employeeObject, group);

      if (isLatest === 'outdated' || isLatest === 'never') {
        isLatest = false;
        ret.atLeastOneNotLatest = true;
      }

      const signature = group.Signature;
      if (signature) {
        ret.signatures.push({
          ...signature,
          isLatest
        });
      }
    }

    return ret;
  }

  /**
   * Gets the signature status of an employee for one specific group
   * @param employeeObject - Employee to check
   * @param group - Group to check
   * @returns Signature status of the employee for the group
   */
  getEmployeeSignatureStatusForOneGroup(employeeObject: IUsers, group: IGroupObject): TSignatureLatest {
    // Find the matching user activation to userDataSet Definition ID
    const matchingUserActivation = employeeObject.Activations.find(activation => {
      return (
        group.Signature &&
        activation.signatureId === group.Signature.id &&
        activation.groupId === group.id &&
        activation.type === 'signature'
      );
    });

    if (!matchingUserActivation) {
      return 'never';
    }

    // Find the matching user dataset to userDataSet Definition ID
    const matchingUserDataset = employeeObject.Datasets.find(dataset => {
      return group.Signature && dataset.definition === group.Signature.employeeDatasetIdRolledOut;
    });

    if (
      group.Signature &&
      this.getSignatureStatusIsLatest(
        matchingUserDataset?.dataUpdatedAt,
        group.Signature.lastRollout,
        matchingUserActivation.activationTime
      ) === true
    ) {
      return 'latest';
    } else {
      return 'outdated';
    }
  }

  /**
   * Gets the signature status
   * @param item - Group entry
   * @returns Campaign signatures status
   */
  getSignatureStatus(item: GroupSmall): ISignatureStatus {
    if (!item.activeSignature)
      return {
        atLeastOneNotLatest: true,
        hidestatus: false,
        signatures: []
      };
    else
      return {
        atLeastOneNotLatest: false,
        hidestatus: true,
        signatures: [
          {
            createdAt: item.Signature?.createdAt as Date,
            disableHelperStyle: item.Signature?.disableHelperStyle || false,
            id: item.Signature?.id as string,
            owner: item.Signature?.owner as string,
            title: item.Signature?.title || '',
            updatedAt: item.Signature?.updatedAt as Date
          }
        ]
      };
  }
}
