<!-- Submenu section -->
<ul
  class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
  [ngClass]="{ 'cognism-signatures': isCognismSignatures }"
  *ngIf="showNotYetImported$ | async as showNotYetImported"
>
  <ng-container *ngFor="let menu of menus">
    <ng-container *ngIf="!menu.submenu; else optionWithSubMenu">
      <li
        (click)="navigationSidebarService.closeOtherDropDown(menus, menu.name)"
      >
        <a [routerLink]="menu.url" routerLinkActive="active" class="show">
          <ng-container
            [ngTemplateOutlet]="renderSideMenuData"
            [ngTemplateOutletContext]="{ menu }"
          ></ng-container>
        </a>
      </li>
    </ng-container>
    <ng-template #optionWithSubMenu>
      <li
        (click)="
          navigationSidebarService.closeOtherDropDown(menus, menu.name);
          menu.isDropdownOpen = !menu.isDropdownOpen
        "
      >
        <a class="show">
          <ng-container
            [ngTemplateOutlet]="renderSideMenuData"
            [ngTemplateOutletContext]="{ menu }"
          ></ng-container>
        </a>
      </li>
      <ng-container *ngIf="menu.isDropdownOpen">
        <ng-container *ngFor="let subMenu of menu.submenu; let i = index">
          <li
            class="mt-submenu-title_subtitle-wrapper"
            *ngIf="
              subMenu.name !== 'available-from-directory' ||
              (subMenu.name === 'available-from-directory' &&
                showNotYetImported.val)
            "
          >
            <a
              [routerLink]="subMenu.url"
              routerLinkActive="active"
              class="show"
            >
              <div class="mt-submenu-icon marleft20">
                <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                </mt-svg>
              </div>
              <span
                class="mt-submenu-title_subtitle marleft5"
                [transloco]="subMenu.name"
              ></span>
              <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
                {{ subMenu.name === 'all-employees' ? allEmployeeCount :
                outdatedEmployeeCount }}
              </span>
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>

  <!-- Displays sidemenu data -->
  <ng-template let-menu="menu" #renderSideMenuData>
    <div class="mt-submenu-icon">
      <mt-svg class="mt-white" src="{{ menu.image }}"> </mt-svg>
    </div>
    <span class="mt-submenu-title inline-block" [transloco]="menu.name"></span>
    <span *ngIf="menu.hasCounter" class="mt-submenu-counter">
      {{ menu.name === 'EMPLOYEE' ? allEmployeeCount : groupCount }}
    </span>
    <mt-svg
      *ngIf="!menu.hasCounter && menu.submenu.length"
      class="mt-submenu-dropdown-arrow martop4 mt-white"
      src="assets/images/sidebar/down-triangle.svg"
    >
    </mt-svg>
  </ng-template>
</ul>
