import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map, tap, iif, defer, of, switchMap, filter } from 'rxjs';
import { TSignaturePlaceholder, TSignaturePlaceholderValue } from 'src/app/model/interfaces/signature.interface';

import { AlertService } from '../alert/alert.service';
import { IAccount } from 'src/app/services/account/account-service.interface';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import {
  DataOrSuccess,
  IResponse,
  IResponseData,
  ResponseGoogleLogin,
  IResponseMessage,
  IResponseMessageCode,
  ResponseData,
  ResponseMessageOrCode,
  ResponseO365SSO
} from 'src/app/model/interfaces/response.interface';

import {
  GoogleUser,
  IUpdateUser,
  IUser,
  IUserGetAdmin,
  IUserGetAdmins,
  IUserInvitationSignUp,
  UserSignUp,
  M365LoginParams,
  M365ExistingDomainBody,
  GoogleExistingDomainBody
} from 'src/app/model/interfaces/user.interface';

import {
  ActivateExternalAdminResponse,
  ChangeEasySyncSettings,
  CustomerEmails,
  GetOverallStatsResponse,
  isLoginFailed,
  IUserCheckInvitationCode,
  IUserGetAccountData,
  IUserLoginFailed,
  IUserLoginSuccess,
  IUserOptOutDetails,
  IUserSetCompanyInfoSingle,
  IUserUpdateNewAdminByInvitation,
  ResponseEasySync,
  UserChangeOutlookAddinSettings,
  UserGetOutlookAddinSettings,
  UserLoggedInfo
} from './user-service.interface';
import { Router } from '@angular/router';
import { INotificationLanguage, INotificationSetting } from 'src/app/model/interfaces/notification.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AuthService } from 'src/app/services/auth/auth.service';

import { IntercomService, INTERCOM_DATA } from 'src/app/services/intercom/intercom.module';
import { environment } from 'src/environments/environment';
import { AddPaymentCredentials } from 'src/app/model/interfaces/payment.interface';
import { EBoxType } from '@model/enums/box-type.enum';
import { SourcebusterCookieService } from '@services/cookies/sourcebuster-cookie.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private alert: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private operator: CustomOperators,
    private router: Router,
    private sourcebusterCookieService: SourcebusterCookieService
  ) {}

  /**
   * Login to the application using a mailtastic account
   * @param email - Account email
   * @param password - Account password
   * @param isSuperAdmin - The super admin trigger
   * @returns The login data
   */
  login(email: string, password: string, isSuperAdmin = false): Observable<IUserLoginSuccess> {
    let loginApi = this.http.post<IUserLoginSuccess>('/account/authenticate/login', {
      email,
      password
    });

    if (isSuperAdmin) {
      loginApi = this.getCustomerLogin(email);
    }

    return loginApi.pipe(
      switchMap(result => {
        return iif(
          () => result.code === 7 || result.code === 3,
          defer(() => this.resendActivationLink(email)),
          defer(() => of(result))
        );
      }),
      map(res => {
        const result = res as IUserLoginSuccess;

        if (isLoginFailed(result)) {
          // TODO messages for each error code
          if (result.code === 7) throw new Error(this.alert.translate('ACTIVATION_NEEDED'));

          if (result.code === 8) throw new Error('TRY_TO_LOGIN_WITH_SSO');

          if (result.code === 9) throw new Error('login_problem_occured');

          if (result.code === 3) throw new Error(this.alert.translate('ACTIVATION_NEEDED'));

          // If account allows only SSO way of authentication block admin from loging in
          if (result.code === 33) throw new Error(this.alert.translate('login_denied_reason_force_sso'));

          // TODO resetLockedAccount
          // if (typeof result.attempts === 'boolean' && !result.attempts) {
          // } //resetLockedAccount()

          throw new Error(this.alert.translateDataNotLoaded());
        } else {
          if (environment.isCogSig) this.intercomService.userLoggedIn(result);
        }
        return result;
      }),
      catchError((err: HttpErrorResponse) => {
        throw new Error(err.message);
      })
    );
  }

  /**
   * Gets all customer for super admin
   * @param searchValue The text which is search by super admin for customer email
   * @returns Array of customer emails
   */
  getAllCustomers(searchValue: string): Observable<CustomerEmails[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        superadminauthorization: this.authService.getSuperAdminToken()
      })
    };
    return this.http
      .get<ResponseData<CustomerEmails[]>>(`/superAdmin/getAllCustomers?search=${searchValue}`, httpOptions)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the customer login data a super admin account
   * @param email - Customer account email
   * @returns The login success data
   */
  getCustomerLogin(email: string): Observable<IUserLoginSuccess> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        superadminauthorization: this.authService.getSuperAdminToken()
      })
    };
    return this.http
      .post<ResponseData<IUserLoginSuccess>>(
        '/superAdmin/authenticate/customerlogin',
        {
          email
        },
        httpOptions
      )
      .pipe(map(res => res.data));
  }

  /**
   * Resend activation link of new account
   * @param email - The email of registered with our application
   * @returns Observable containing success or code
   */
  resendActivationLink(email: string): Observable<ResponseMessageOrCode> {
    return this.alert
      .defaultConfirmationPrompt(
        this.alert.translate('confirmationmail_error_text'),
        EBoxType.ERROR,
        this.alert.translate('confirmationmail_error_hl'),
        this.alert.translate('confirmationmail_error_button')
      )
      .pipe(
        switchMap(() => this.resendRegistrationConfirmationEmail(email)),
        tap(() => {
          void this.router.navigate(['/signup'], { state: { email, isResendEmail: true } });
        }),
        filter(() => false)
      );
  }

  /**
   * Login to the application using a google account with Google's SSO
   * @param googleUser - Object containing google account's information
   * @returns Observable containing success of operation and login information
   */
  loginWithGoogle(googleUser: GoogleUser): Observable<ResponseGoogleLogin> {
    return this.http
      .post<ResponseGoogleLogin>('/account/authenticate/loginwithgoogle', googleUser)
      .pipe(this.operator.extractResponseGoogleLogin());
  }

  /**
   * Register to the application using a google account with Google's SSO
   * @param googleUser - Object containing google account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithGoogle(googleUser: GoogleUser): Observable<ResponseGoogleLogin> {
    googleUser.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseGoogleLogin>('/account/authenticate/registerwithgoogle', googleUser)
      .pipe(this.operator.extractResponseGoogleLogin());
  }

  /**
   * Login to the application using a O365 account with O365 s SSO
   * @param o365User - Object containing O365 account's information
   * @returns Observable containing success of operation and login information
   */
  loginWithM365(o365User: M365LoginParams): Observable<ResponseO365SSO> {
    return this.http
      .post<ResponseO365SSO>('/account/authenticate/loginwithoffice365', o365User)
      .pipe(this.operator.extractResponseO365SSO());
  }

  /**
   * Register to the application using a O365 account with O365 s SSO
   * @param o365User - Object containing O365 account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithOffice365(o365User: M365LoginParams): Observable<ResponseO365SSO> {
    o365User.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseO365SSO>('/account/authenticate/registerwithoffice365', o365User)
      .pipe(this.operator.extractResponseO365SSO());
  }

  /**
   * Register to the application using a O365 account with O365 s SSO trough Existing domain scenario
   * @param o365User - Object containing O365 account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithOffice365ExistingDomain(reqBody: M365ExistingDomainBody): Observable<ResponseO365SSO> {
    reqBody.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseO365SSO>('/account/authenticate/registerwithoffice365ExistingDomain', reqBody)
      .pipe(this.operator.extractResponseO365SSO());
  }

  /**
   * Register to the application using a Google account with googleSSO trough Existing domain scenario
   * @param googleUser - Object containing Google account's information
   * @returns Observable containing success of operation and login information
   */
  registerWithGoogleExistingDomain(reqBody: GoogleExistingDomainBody): Observable<ResponseGoogleLogin> {
    reqBody.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<ResponseGoogleLogin>('/account/authenticate/registerwithgoogleExistingDomain', reqBody)
      .pipe(this.operator.extractResponseGoogleLogin());
  }

  /**
   * Creates a new mailtastic account
   * @param newAccount - Object containing information used to create a new account
   * @returns Observable containing success of operation
   */
  createNewUser(newAccount: UserSignUp): Observable<IResponseMessageCode> {
    newAccount.userLoggedInfo = { utm: this.sourcebusterCookieService.parseSourcebusterCookies() };
    return this.http
      .post<IResponseMessageCode>('/account/createuser', newAccount, { params: { isFromAngularTs: true } })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Enables multiple signatures
   * @returns
   */
  enableMultiSignature(): unknown {
    return this.http.put('/users/enablemultisignature', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Opt-out from mailtastic application
   * @param params - Object contains information about user email address and unique code
   * @returns
   */
  optOut(params: IUserOptOutDetails): Observable<true> {
    // TODO add authorization: false
    return this.http.post<IResponse>('/account/optout', params).pipe(this.operator.extractResponse());
  }

  /**
   * Opt-out from mailtastic application
   * @param params -
   * @returns
   */
  undoOptOutLink(params: unknown): unknown {
    // TODO add authorization: false
    return this.http.post('/account/undooptoutlink', params).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Opt-out from mailtastic application
   * @param id -
   * @returns
   */
  undoOptOut(id: string): Observable<IResponse> {
    // TODO add authorization: false
    return this.http.post<IResponse>('/account/undooptout', { id: id }).pipe(this.operator.extractResponseOrMessage());
  }

  /**
   * Resends the registration confirmation email
   * @param email - Account email to send registration confirmation
   * @returns Observable containing success or code
   */
  resendRegistrationConfirmationEmail(email: string): Observable<ResponseMessageOrCode> {
    return this.http
      .post<ResponseMessageOrCode>('/account/authenticate/resendregistrationconfirmation', { email })
      .pipe(this.operator.extractResponseSuccessOrMessage());
  }

  /**
   * Gets the HTML snippet
   * @returns
   */
  getHtmlSnippet(): unknown {
    return this.http.post('/employees/snippet', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the overall statistics for the account
   * @returns
   */
  getOverallStats(): Observable<GetOverallStatsResponse[]> {
    return this.http
      .get<IResponseData<GetOverallStatsResponse[]>>('/users/stats/overall')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * User wants to have a custom domain from which the campaign and images have to be loaded to avoid spam scoring
   * @param domain -
   * @returns
   */
  sendCustomDomainRequest(domain: unknown): unknown {
    return this.http.post('/users/customdomain/request', { domain }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the amount of users
   * @returns
   */
  getAmountOfUsers(): unknown {
    return this.http.get('/users/stats/overall').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks the token
   * @param params -
   * @returns
   */
  checkToken(params: unknown): unknown {
    // TODO add authorization: false
    return this.http.post('/account/authenticate/checktoken', params).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Create a beta user
   * @param userobject -
   * @returns
   */
  createBetaUser(userobject: IUser): unknown {
    return this.http.post('/users/createuser/beta', userobject).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Activate a mailtastic account
   * @param activationCode - Activation code
   * @param employeeId - Unique employee ID
   */
  activateAccount(activationCode: string, employeeId: string): Observable<IUserCheckInvitationCode> {
    return this.http
      .post<IResponseData<IUserCheckInvitationCode>>('/account/activate/admin', { activationCode, employeeId })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Activates the new external admin account
   * @param params -
   * @returns
   */
  activateExternalAccount(activationCode: string, adminId: string): Observable<ActivateExternalAdminResponse> {
    return this.http
      .post<DataOrSuccess<ActivateExternalAdminResponse>>('/account/activate/admin/external', {
        activationCode,
        adminId
      })
      .pipe(this.operator.extractResponseDataOrSuccess());
  }

  /**
   * Gets all proven Expert Accounts
   * @returns
   */
  getProvenExpertAccounts(): unknown {
    return this.http.get('/users/provenexpert').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Deletes one proven expert account
   * @param id -
   * @returns
   */
  deleteProvenExpertAccount(id: string): unknown {
    return this.http.delete('/users/delete/peaccount/' + id).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Deletes many proven expert accounts via the list of ids
   * @param ids -
   * @returns
   */
  deleteManyProvenExpertAccounts(ids: string[]): unknown {
    return this.http.post('/users/del/many/peaccounts', { pe_ids: ids }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the proven expert account via the id
   * @param accountID -
   * @returns
   */
  getProvenExpertAccountByID(accountID: string): unknown {
    return this.http.put('/users/provenexpert/getAccount', { accountID }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Creates a proven expert widget
   * @param accountID -
   * @param data -
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  createWidget(accountID: string, data: Object): unknown {
    return this.http
      .post('/users/provenexpert/createwidget', { accountID, data })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Creates a new proven expert account
   * @param accountID -
   * @returns
   */
  createProvenExpertAccount(accountID: string): unknown {
    return this.http.post('/users/provenexpert', { accountID }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the EasySync settings
   * @returns The easysync settings
   */
  getEasySyncSettings(): Observable<ResponseEasySync> {
    return this.http
      .get<IResponseData<ResponseEasySync>>('/users/easysyncsettings')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Changes an EasySync setting
   * @param setting - EasySync setting to change
   * @param value - New setting value
   * @returns Updated easysync settings
   */
  changeEasySyncSettings(
    setting: 'disableEasySync' | 'disableUpdate',
    value: boolean
  ): Observable<ChangeEasySyncSettings> {
    return this.http
      .put<IResponseData<ChangeEasySyncSettings>>('/users/easysyncsettings', { setting, value })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the account data
   * @remarks
   * Here there is no response code or status in return type `IUserGetAccountData`
   * @returns Observable of get user account data
   */
  getAccountData(): Observable<IUserGetAccountData> {
    return this.http.get<IUserGetAccountData>('/users/accountdata').pipe(
      map(result => {
        if (!result) throw new Error(this.alert.translateDataNotLoaded());
        return result;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Sets the email notification settings
   * @param settings - The object of email notification settings
   * @returns Observable of changed email notification settings
   */
  setEmailNotificationSetting(settings: INotificationSetting): Observable<boolean> {
    return this.http
      .put<IResponseMessageCode>('/users/accountdata/emailnotifysetting', settings)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Sets the email notification language
   * @param settings - The object of language settings
   * @returns Observable of changed email notification language
   */
  setEmailNotificationLanguage(settings: INotificationLanguage): Observable<boolean> {
    return this.http
      .put<IResponseMessageCode>('/users/accountdata/emailnotifylanguage', settings)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Sets custom alter text for campaign
   * @param altText -
   * @returns
   */
  setAltText(altText: unknown): Observable<boolean> {
    return this.http.put<IResponse>('/users/accountdata/altText', { altText }).pipe(this.operator.extractResponse());
  }

  /**
   * Finalize the login with setting information and tracking
   * @param auth -
   * @returns
   */
  finalizeLogin(auth: unknown): void {
    // TODO add storage factory
  }

  /**
   * Gets the login data that has been stored
   *
   * @returns \{Object\} as \{'logged_in','accessToken','adminId','accountId','gender','userId','userFirstName','userLastName','userCreatedAt','userEmail','amountOfEmployees','companyName'\}
   */
  getLoginData(): void {
    // TODO get storage factory
  }

  /**
   * Logs out of the app
   */
  logout(): void {
    // Remove storage factory
    this.authService.removeToken();
    if (environment.isCogSig) this.intercomService.shutDown();
    void this.router.navigate(['login']);
  }

  /**
   * Updates the account data
   * First name and Last name, Company name, etc
   * @param data - New account data to update
   * @returns Observable containing success of operation
   */
  setAccountData(data: Partial<IUpdateUser>): Observable<IResponseMessageCode> {
    return this.http.post<IResponseMessageCode>('/users/accountdata', data).pipe(
      this.operator.extractResponseMessageCode(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
      })
    );
  }

  /**
   * Updates the EasySync mac settings for new users
   * @param data - Outlook and apple mail settings
   * @returns Observable containing success of operation
   */
  setEasySyncNewUsers(data: { outlookMacSyncNewUsers: boolean; appleMailSyncNewUsers: boolean }): Observable<true> {
    return this.http.put<IResponse>('/users/accountdata/easysync', data).pipe(this.operator.extractResponse());
  }

  /**
   * Sets the company info
   * @param data -
   * @returns
   */
  setCompanyInfo(data: unknown): unknown {
    return this.http.post('/users/companyinfo', data).pipe(
      this.operator.extractUnknownResponse(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
      })
    );
  }

  /**
   * Resets the lost password
   * @param email - Email to reset the password for
   * @returns Observable containing success of operation
   */
  resetPassword(email: string): Observable<IResponseMessageCode> {
    return this.http
      .post<IResponseMessageCode>('/account/resetpassword', { email })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the new password
   * @param data -
   * @returns
   */
  setNewPassword(data: unknown): Observable<IResponseMessageCode> {
    // TODO add authorization: false
    return this.http
      .post<IResponseMessageCode>('/account/setnewpass', data)
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Adds payment credentials
   * @param data -
   * @returns
   */
  addPaymentCredentials(data: AddPaymentCredentials): Observable<boolean> {
    return this.http
      .post<IResponseData<boolean>>('/users/paymentcreds', data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the company dataset data which is used for signatures
   * @param datasetId - ID of the company dataset
   * @param tagObject - Object containing new info to update with
   * @returns Observable containing the saved tag object
   */
  setCompanyInfoSingle(datasetId: string, tagObject: TSignaturePlaceholder): Observable<TSignaturePlaceholderValue> {
    return this.http.post<IUserSetCompanyInfoSingle>('/employees/companyinfo/single', { datasetId, tagObject }).pipe(
      map(result => {
        if (!result.success || !result?.savedObject) throw new Error(this.alert.translateDataNotLoaded());
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.company_info_changed);
        return result.savedObject;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Remove Company Logo Image from company default dataset
   * @param datasetId - ID of the company dataset
   * @returns Observable of delete company logo
   */
  deleteCompanyLogo(datasetId: string): Observable<true> {
    return this.http.get<IResponse>(`/employees/deleteCompanyLogo/${datasetId}`).pipe(this.operator.extractResponse());
  }

  /**
   * Verifies the password
   * @param password -
   * @returns
   */
  verifyPassword(password: string): unknown {
    return this.http.get('/users/verifyPwd/' + password).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Marks initial introduction tour as seen
   * @returns
   */
  setTourWasSeen(): unknown {
    return this.http.post('/users/marktourasseen', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets track recipient flags
   * @returns
   */
  getRecipientTracking(): Observable<IAccount> {
    return this.http.get<IResponseData<IAccount>>('/users/recipientTracking').pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the ignored internal domains
   * @returns
   */
  getIgnoreInternalDomains(): Observable<IResponseMessageCode> {
    return this.http
      .get<IResponseMessageCode>('/users/ignoreInternalDomains/get')
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the ignored internal domains
   * @param val -
   * @returns
   */
  setIgnoreInternalDomains(val: unknown): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/ignoreInternalDomains/set', { ignoreInternalDomains: val })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Gets the record for the foreign domains
   * @returns
   */
  getRecordForeignDomains(): unknown {
    return this.http.get('/users/recordForeignDomains/get').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the flag for tracking the foreign domains
   * @param val -
   * @param showLeads -
   * @returns
   */
  setRecordForeignDomains(val: boolean, showLeads: boolean): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/recordForeignDomains/set', {
        recordForeignDomains: val,
        showLeads: showLeads
      })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the flag for tracking the foreign contacts
   * @param val -
   * @returns
   */
  setRecordForeignContacts(val: boolean): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/users/recordForeignContacts', { recordForeignContacts: val })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Sets the flag for tracking accounts
   * @param val -
   * @returns
   */
  setAppendRecContent(val: boolean): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/users/appendRecContent', { appendRecContent: val })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Sends the invitation
   * @param val -
   * @returns
   */
  setCampaignTracking(val: boolean): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/disableCampaignTracking', { disableCampaignTracking: val })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Sets the flag for tracking contacts
   * @param val -
   * @returns
   */
  setAppendFullAddress(val: boolean): Observable<IResponseMessageCode> {
    return this.http
      .put<IResponseMessageCode>('/users/appendFullAddress', { appendFullAddress: val })
      .pipe(this.operator.extractResponseMessageCode());
  }

  /**
   * Gets the new seen leads
   * @returns
   */
  getNewLeadsSeen(): unknown {
    return this.http.get('/users/seenNewLeads/get').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the new seen leads
   * @returns
   */
  setNewLeadsSeen(): unknown {
    return this.http.put('/users/seenNewLeads/set', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the list of admins for this account
   * @returns
   */
  getAdmins(): Observable<IUserGetAdmins[]> {
    return this.http
      .get<IResponseData<IUserGetAdmins[]>>('/users/administrators')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the current admin data
   * @returns
   */
  getAdmin(): Observable<IUserGetAdmin> {
    return this.http
      .get<IResponseData<IUserGetAdmin>>('/users/administrator')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Deletes the admin
   * @param adminId -
   * @returns
   */
  deleteAdmin(adminId: string): Observable<IDataset[]> {
    return this.http
      .delete<IResponseData<IDataset[]>>('/users/administrators/' + adminId)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Deletes external admin
   * @param adminId -
   * @returns
   */
  deleteExternalAdmin(adminId: string) {
    return this.http.delete('/users/administrators/external/' + adminId).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks if mail has already expired.
   * @param id -
   * @returns
   */
  checkExpiredMail(id: string) {
    // TODO add authorization: false
    return this.http.get('/account/checkexpiredmail/' + id).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sends the invitation
   * @param email -
   * @returns
   */
  sendInvitation(email: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>('/users/administrators/sendInvitation', { email, isFromNewAngularTs: true })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * @Remarks Operator.extractResponseMessage() not used because we need to display different messages based on backend api response.
   * Sends the external admin invitation
   * @param email - Email of external admin
   * @returns - Invitation status
   */
  sendExternalAdminInvitation(email: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>('/users/administrators/sendExternalAdminInvitation', { email, isFromNewAngularTs: true })
      .pipe(
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Checks the invitation code
   * @param ac - The invitation code to check
   * @returns Observable containing the succes of operation
   */
  checkInvitationCode(ac: string): Observable<IUserCheckInvitationCode> {
    return this.http.post<IUserCheckInvitationCode>('/account/check/invitation/code', { ac }).pipe(
      map(value => {
        if (value.code === 4) {
          throw new Error('INVITATION_LINK_EXPIRE');
        } else if (!value.success) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        return value;
      }),
      catchError(e => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.message === 'INVITATION_LINK_EXPIRE') {
          void this.alert.defaultErrorMessage(this.alert.translate('INVITATION_LINK_EXPIRE'));
        } else {
          void this.alert.defaultErrorMessage(this.alert.translateTechnicalError());
        }
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Adds a new admin to an existing account
   * @param newUser - Object containing new admin information
   * @returns Observable containing success of operation and login information
   */
  updateNewAdminByInvitation(
    newUser: IUserInvitationSignUp
  ): Observable<IUserUpdateNewAdminByInvitation | IUserLoginFailed> {
    return this.http
      .post<IUserUpdateNewAdminByInvitation | IUserLoginFailed>('/account/update/invitation', newUser)
      .pipe(
        map(value => {
          if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
          return value;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Creates a new admin
   * @param gender -
   * @param firstname -
   * @param lastname -
   * @param email -
   * @param password -
   * @returns
   */
  createNewAdmin(gender: string, firstname: string, lastname: string, email: string, password: string) {
    return this.http
      .post('/users/administrators', { gender, firstname, lastname, email, password })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Changes the admin password
   * @param oldpass -
   * @param newpass -
   * @param adminId -
   * @returns
   */
  changeAdminPassword(oldpass: string, newpass: string, adminId: string): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/users/administrators/password', { oldpass, newpass, adminId })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Edits the admin data
   * @param firstname -
   * @param lastname -
   * @param gender -
   * @param email -
   * @param adminId -
   * @returns
   */
  changeAdminData(firstname: string, lastname: string, gender: string, email: string, adminId: number) {
    return this.http
      .put('/users/administrators/contactinfo', { firstname, lastname, gender, email, adminId })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Generates an API secret
   * @param id -
   * @param email -
   * @returns
   */
  resendExternalAdminInvitationEmail(id: string, email: string) {
    return this.http
      .post('/users/administrators/resendExternalAdminInvitation', {
        adminId: id,
        adminEmail: email,
        isFromNewAngularTs: true
      })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Generates an API secret
   * @param id -
   * @param email -
   * @returns
   */
  resendAdminInvitation(id: string, email: string): Observable<true> {
    return this.http
      .post<IResponse>('/users/administrators/resendAdminInvitation', {
        recipientAdminId: id,
        adminEmail: email,
        isFromNewAngularTs: true
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Generates an API secret
   * @param secret -
   * @returns
   */
  generateApiSecret(secret: string): Observable<true> {
    return this.http
      .post<IResponse>('/users/publicapi/generatesecret', { secret: secret })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Update use info when logged in first time
   * @param info - Object will have user info about user role
   * @param amountOfEmployees - Number of employee
   * @param firstname - Users firstname
   * @param lastname - Users lastname
   * @param companyName - Users company name
   * @returns - Observable containing success flag of data updated in database
   */
  updateUserInfo(
    info: UserLoggedInfo,
    amountOfEmployees?: number,
    firstname?: string,
    lastname?: string,
    companyName?: string
  ): Observable<true> {
    info.utm = this.sourcebusterCookieService.parseSourcebusterCookies();
    const data = {
      info: info,
      firstname: firstname || '',
      lastname: lastname || '',
      companyName: companyName || '',
      amountOfEmployees: amountOfEmployees || 0,
      accountId: this.authService.decodeToken().accountId,
      adminId: this.authService.decodeToken().adminId
    };
    return this.http.post<IResponse>('/users/updateUserInfo', data).pipe(this.operator.extractResponse());
  }

  /**
   * Sets the ignored internal domains
   * @memberof mailtasticApp.services.userService
   * @param val - anonymous tracking is active or not into the account
   * @returns -  observable containing success flag of data updated in database
   */
  setAnonymousTracking(val?: boolean): Observable<true> {
    return this.http
      .put<IResponse>('/users/anonymousTracking/set', {
        anonymousTracking: val
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Returns the account's outlook addin settings
   * @returns Account's outlook addin settings
   */
  getOutlookAddinSettings(): Observable<UserGetOutlookAddinSettings> {
    return this.http
      .get<ResponseData<UserGetOutlookAddinSettings>>('/users/outlookaddinsettings')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates an Outlook Addin setting with a new value
   * @param setting - Setting to update
   * @param value - New value to set
   * @returns Nr. of enabled clients and last update date
   */
  changeOutlookAddinSettings(
    setting: keyof UserGetOutlookAddinSettings,
    value: boolean
  ): Observable<UserChangeOutlookAddinSettings> {
    return this.http
      .put<ResponseData<UserChangeOutlookAddinSettings>>('/users/outlookaddinsettings', { setting, value })
      .pipe(this.operator.extractResponseData());
  }
}
