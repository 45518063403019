import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AccountService } from 'src/app/services/account/account.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private accountService: AccountService, private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      this.authService.removeLocalStorage();
      // Pendo `isReady` function will not work when Adblockers extension will activated
      if (typeof pendo.isReady === 'function' && pendo.isReady()) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (pendo as any).clearSession();
      }
      void this.router.navigate(['/login']);
      return false;
    }

    return this.authService.authorizationScope$.pipe(
      take(1),
      map(authorizationScope => {
        // Check signup tour finished
        if (authorizationScope.isUserLoggedInFirstTime) {
          // Allow URL's without finishing signup tour
          const allowedURLs = ['/tour', '/account/select'];

          // Redirect other URL's
          if (!allowedURLs.includes(state.url)) {
            void this.router.navigate(['/tour']);
          }

          return allowedURLs.includes(state.url);
        } else {
          // Redirect tour URL to dashboard
          if (state.url === '/tour') {
            void this.router.navigate(['/dashboard']);
          }

          const accountData = this.accountService.getUserAccountData();

          // Should always have email and company name
          // Pendo `isReady` function will not work when Adblockers extension will activated
          if (typeof pendo.isReady === 'function' && accountData.email && accountData.companyName) {
            // Initialize pendo
            if (!pendo.isReady()) {
              pendo.initialize({
                visitor: {
                  id: accountData.email
                },
                account: {
                  id: accountData.companyName
                }
              });
            }
            // When initialized, check if visitor or account needs to be updated
            else if (pendo.getVisitorId() !== accountData.email || pendo.getAccountId() !== accountData.companyName) {
              pendo.updateOptions({
                visitor: {
                  id: accountData.email
                },
                account: {
                  id: accountData.companyName
                }
              });
            }
          }

          return true;
        }
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
