<div class="statuscol">
  <div class="title italic" *ngIf="!gated && campaigns.length > 1">
    <div class="innertitle" style="float: left; overflow: hidden">
      <span>{{ campaigns.length }}</span>
      <span
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ tooltipText }}"
        class="upper cursor-pointer"
        transloco="campaignsAssignedShortened"
      ></span>
    </div>
    <span
      class="status_badge default"
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ 'default_tag_info' | transloco }}"
    >
      <span transloco="default"></span>
    </span>
  </div>
  <div
    class="title"
    *ngIf="!gated && campaigns.length === 1 && campaigns[0].title"
  >
    <div
      class="innertitle"
      style="float: left; overflow: hidden; max-width: 12vw"
    >
      <span>
        <mt-svg
          class="medium-svg calicon"
          [ngClass]="iconColor"
          src="assets/images/siteelements/campaign_list_svg.svg"
        ></mt-svg>
        <span
          tooltipClass="status-info-tooltip"
          tooltipPlacement="top"
          ngbTooltip="{{ campaigns[0].title }}"
          class="link"
          [routerLink]="['/campaigns/details/' + campaigns[0].id]"
          (click)="redirectToCampaignDetail.emit()"
        >
          {{ campaigns[0].title }}</span
        >
      </span>
    </div>
    <span>
      <span
        class="status_badge default"
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ 'default_tag_info' | transloco }}"
      >
        <span transloco="default"></span>
      </span>
    </span>
  </div>
  <div
    *ngIf="gated || campaigns.length === 0 || !campaigns[0].title"
    class="title schrift_rot italic"
    [ngClass]="gated ? 'mt-opacity-50' : ''"
  >
    <span
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ 'UnassignedCampaign' | transloco }}"
      [disableTooltip]="gated"
    >
      <span transloco="Unassigned"></span>
    </span>
  </div>
  <div class="subline">
    <span transloco="Campaign"></span>
  </div>
</div>
