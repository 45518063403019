import { Component, Input } from '@angular/core';

@Component({
  selector: 'mt-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true
})
export class MtCheckboxComponent {
  /**
   * Checked status
   */
  @Input() checked = false; // TODO account for disabled?

  /**
   * Label name value
   */
  @Input() labelName!: string;

  /**
   * If true, the checkbox-type input will be disabled
   */
  @Input() disabled = false;
}
