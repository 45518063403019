import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ReplaySubject } from 'rxjs';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '../account/account-service.interface';
import { DecodedToken } from './auth-service.interface';
import { SuperAdminAuthData } from '@model/interfaces/account-admin.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tokenName = 'AuthToken';
  helper: JwtHelperService;

  /**
   * Supply JWT token data to APP
   */
  private authorizationScope = new ReplaySubject<IMAILTASTIC_AUTHORIZATION_SCOPE>(1);
  authorizationScope$ = this.authorizationScope.asObservable();

  /**
   * Saves the recent customers login email by super admin
   */
  private recentCustomersEmail = new ReplaySubject<string[]>(1);
  recentCustomersEmail$ = this.recentCustomersEmail.asObservable();

  constructor() {
    this.helper = new JwtHelperService();
    const tokenName = this.getToken();
    if (tokenName !== '{}') this.setAuthorizationScope(this.decodeToken());

    // Super admin last 3 customer login
    const superAdminToken = this.getSuperAdminToken();
    if (superAdminToken !== '{}') {
      this.updateCustomerLoginEmails(this.getCustomerLoginEmail());
    }
  }

  /**
   * Get JWT token for the logged in user from localStorage
   * @returns JWT Auth token string for current login user
   */
  public getToken(): string {
    return localStorage.getItem(this.tokenName) || '{}';
  }

  /**
   * Get JWT token for the super admin from localStorage
   * @returns JWT Auth token string for super admin
   */
  public getSuperAdminToken(): string {
    const superAdminToken = localStorage.getItem(MailtasticAuthorizationDetails.superAdminData) || '{}';
    if (superAdminToken !== '{}') {
      const parseData = JSON.parse(superAdminToken) as SuperAdminAuthData;
      return parseData.token;
    }
    return '{}';
  }

  /**
   * Get the value from LocalStorage as per the key name
   * @param name - Name of LocalStorage key
   * @returns Data from localStorage
   */
  getLocalStorage(name: MailtasticAuthorizationDetails): string {
    return localStorage.getItem(name) || '';
  }

  /**
   * Decoding JWT token and returns content
   * @returns Information about logged user from JWT token
   */
  decodeToken(): DecodedToken {
    return this.helper.decodeToken(localStorage.getItem(this.tokenName) || '{}') ?? ({} as DecodedToken);
  }

  /**
   * Set JWT token to LocalStorage
   * @param token - JWT token set to localStorage
   */
  public setToken(token: string): void {
    localStorage.setItem(this.tokenName, token);
    this.setAuthorizationScope(this.decodeToken());
  }

  /**
   * Set updated recent login by super admin
   * @param customerEmail - The current customer email
   * @param recentCustomerEmails - The array of recent customer email
   */
  public updateRecentCustomerEmail(customerEmail: string, recentCustomerEmails: string[]): void {
    // If the customer email is already in recent list then remove it from recent email array
    if (recentCustomerEmails.includes(customerEmail)) {
      const index = recentCustomerEmails.indexOf(customerEmail);
      recentCustomerEmails.splice(index, 1);
    }

    // If the `recentCustomerEmails` length is 2 or more then second customer email should be at the third place
    if (recentCustomerEmails.length >= 2) {
      recentCustomerEmails[2] = recentCustomerEmails[1];
    }

    // If the `recentCustomerEmails` length is 1 or more then the first customer email should be at the second place
    if (recentCustomerEmails.length >= 1) {
      recentCustomerEmails[1] = recentCustomerEmails[0];
    }

    // The current customer should be always at the first place
    recentCustomerEmails[0] = customerEmail;

    this.updateCustomerLoginEmails(recentCustomerEmails);
  }

  /**
   * Set decoded JWT token data to DecodedToken object
   * @param data - Data object of decoded JWT token payload
   */
  public setAuthorizationScope(data: DecodedToken): void {
    this.authorizationScope.next({
      ...data,
      defaultAdmin: data.defaultAdmin ? true : false,
      userId: localStorage.getItem(MailtasticAuthorizationDetails.userId) || '',
      isUserLoggedInFirstTime:
        this.getLocalStorage(MailtasticAuthorizationDetails.isUserLoggedInFirstTime) === ''
          ? data.isUserLoggedInFirstTime
          : false,
      isUserOnBasicPlan: !data.hasCampaignTracking,
      isUserOnAdvancedPlan: data.hasCampaignTracking && !data.hasTargetLists,
      isUserOnTargetPlan: data.hasCampaignTracking && data.hasTargetLists
    });
  }

  /**
   * Remove JWT token and other values from localStorage
   */
  public removeToken(): void {
    localStorage.removeItem(this.tokenName);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.superAdminCustomerLogins);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.userId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.adminId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.isUserLoggedInFirstTime);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.accountId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.defaultUserId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.activeAccountId);

    // Super admin scope
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.superAdminData);
    this.recentCustomersEmail.next([]);
  }

  /**
   * Check whether loggedin user isAuthenticated based on JWT token
   * @returns Boolean to check JWT token valid or not
   */
  public isAuthenticated(): boolean {
    if (this.getToken() === '{}') {
      return false;
    }
    return !this.helper.isTokenExpired(this.getToken());
  }

  /**
   * Updates the user id in authorization scope while adding a loggedin user as an employee who is not exists in employee list or deleting an employee who is exist as loggedin user
   * @param id - The user id
   */
  updateUserIdToAuthScope(id?: string): void {
    if (id) {
      this.setKeyInLocalstorage(MailtasticAuthorizationDetails.userId, id);
    } else {
      this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.userId);
    }
    this.setAuthorizationScope(this.decodeToken());
  }

  /**
   * Remove local storage data
   */
  public removeLocalStorage(): void {
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.userId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.adminId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.accountId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.defaultUserId);
    this.removeKeyFromLocalstorage(MailtasticAuthorizationDetails.activeAccountId);
  }

  /**
   * Set value from LocalStorage as per the tokenName
   * @param keyName - Name of key for localStorage
   * @param value - The value of key to store in localstorage
   */
  public setKeyInLocalstorage(keyName: MailtasticAuthorizationDetails, value: string): void {
    localStorage.setItem(keyName, value);
  }

  /**
   * Updates the customers login
   * @param data - Email array of customers
   */
  updateCustomerLoginEmails(data: string[]): void {
    localStorage.setItem(MailtasticAuthorizationDetails.superAdminCustomerLogins, JSON.stringify(data));
    this.recentCustomersEmail.next(data);
  }

  /**
   * Get customer login email array to view in recent login
   * @returns The array of recent customer login email
   */
  private getCustomerLoginEmail(): string[] {
    const superAdminCustomerLogins =
      localStorage.getItem(MailtasticAuthorizationDetails.superAdminCustomerLogins) || '[]';
    return JSON.parse(superAdminCustomerLogins) as string[];
  }

  /**
   * Remove value from LocalStorage as per the tokenName
   * @param keyName - Name of key for localStorage
   */
  private removeKeyFromLocalstorage(keyName: MailtasticAuthorizationDetails): void {
    localStorage.removeItem(keyName);
  }
}
