import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICompanyInfo } from '@model/interfaces/company-info.interface';
import { IResponseData } from '@model/interfaces/response.interface';
import { Observable } from 'rxjs';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';

@Injectable({
  providedIn: 'root'
})
export class SftpSyncService {
  constructor(private http: HttpClient, private operator: CustomOperators) {}

  /**
   * Gets the connection
   * @returns Object of ICompanyInfo
   */
  getConnection(): Observable<ICompanyInfo> {
    return this.http
      .post<IResponseData<ICompanyInfo>>('/sftpSync/getConnection', null)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Checks if the connection exists
   * @param data
   * @returns
   */
  checkConnection(data: unknown): unknown {
    return this.http.post('/sftpSync', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks the CSV file
   * @param data
   * @returns
   */
  checkCSV(data: unknown): unknown {
    return this.http.post('/sftpSync/checkCSV', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Creates a connection
   * @param data
   * @returns
   */
  createConnection(data: unknown): unknown {
    return this.http.post('/sftpSync/createConnection', data).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Removes the connection
   * @returns
   */
  removeConnection(): unknown {
    return this.http.post('/sftpSync/removeConnection', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates the connection
   * @param syncSettings
   * @returns
   */
  updateConnection(syncSettings: unknown): unknown {
    return this.http
      .post('/sftpSync/updateConnection', { syncSettings: syncSettings })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Starts the sync
   * @returns
   */
  startSync(): unknown {
    return this.http.put('/sftpSync/sync/userdata/all', null).pipe(this.operator.extractUnknownResponse());
  }
}
