<div class="modal-simple" data-cy="simple-modal">
  <div *ngIf="showConfetti" class="step-completed-confetti"></div>
  <div class="modal-header">
    <div class="title">{{ parent.titleText }}</div>
    <div
      class="cursor-pointer close"
      [ngClass]="{ 'step-completed-close': showConfetti }"
      (click)="modal.close(false)"
    >
      <mt-svg
        class="x-button"
        src="assets/images/modals/close_other_svg.svg"
      ></mt-svg>
    </div>
  </div>
  <div class="modal-body" data-cy="simple-modal-body">
    <mt-box
      [type]="parent.type"
      [icon]="parent.icon"
      [text]="parent.bodyText"
    ></mt-box>
  </div>

  <div class="modal-footer">
    <mt-button
      [ngClass]="{ 'step-completed-close': showConfetti }"
      text="{{ parent.btnText ?? 'Close' }}"
      color="primary"
      (click)="modal.close(true)"
    ></mt-button>
  </div>
</div>
