<mt-custom-modal
  class="integration integration-guide-modal font25"
  headline="{{ 'installation_guide' | transloco }}"
  theme="video-theme"
>
  <div modal-body>
    <div class="content manual">
      <div class="flex scroll-div">
        <div class="flex manualscontainer">
          <div class="content signature-installation-guide">
            <div class="row">
              <div class="col-md-12">
                <div [innerHTML]="stepIntroDescription | transloco"></div>
                <p
                  [ngClass]="{
                    ' options-subpart':
                      inputData.title === 'outlook_windows_easysync_header_rec'
                  }"
                  class="signature-intro"
                  [innerHTML]="'html.signature_installation_intro' | transloco"
                ></p>
                <div
                  class="preview-info"
                  *ngIf="showSignaturePreview"
                  transloco="signature_installation_intro_multiple"
                ></div>

                <!-- Show signature list with preview -->
                <div *ngIf="showSignaturePreview">
                  <div class="martop20">
                    <div
                      class="baseonecolumn wizard"
                      *ngIf="mailClientType$ | async as mailClient"
                    >
                      <div
                        class="container signature-list-container"
                        *ngIf="signatureStepsStatus$ | async as steps"
                      >
                        <mt-wizard-tabs-container
                          [(parent)]="signaturePreviewListComponent"
                          *ngIf="inputData.signatures.length > 0"
                        >
                          <ng-container
                            *ngFor="
                              let item of inputData.signatures;
                              let i = index
                            "
                          >
                            <mt-wizard-tab
                              [(parent)]="steps[i]"
                              (headerClick)="
                                scrollToPreview(
                                  item.signatureId,
                                  steps[i],
                                  mailClient,
                                  item.signatureTxt
                                )
                              "
                              id="{{ item.signatureId }}"
                            >
                              <!-- Display signature preview before steps start except in mobile device-->
                              <!-- In mobile devices signature preview be displayed in ios mobile device only and that will be in steps component after first step description -->
                              <div
                                class="padbottom15 wizard-step"
                                *ngIf="mailClient === 'other'"
                              >
                                <div class="form-group signature-preview">
                                  <div
                                    class="preview-info preview-scroll"
                                    transloco="signature_preview_scroll"
                                  ></div>
                                  <mt-signature-rollout-preview
                                    [signature]="item"
                                  ></mt-signature-rollout-preview>
                                </div>
                              </div>
                              <!-- Steps for mobile devices -->
                              <div
                                *ngIf="
                                  mailClient === 'android' ||
                                  mailClient === 'ios'
                                "
                              >
                                <mt-installation-guide-steps
                                  [user]="inputData.user"
                                  [signature]="item"
                                  [installationData]="installationData"
                                ></mt-installation-guide-steps>
                              </div>
                              <!-- Steps excluding mobile devices -->
                              <div *ngIf="mailClient === 'other'">
                                <mt-installation-guide-steps
                                  [installationData]="installationData"
                                ></mt-installation-guide-steps>
                              </div>

                              <div class="step-mark-as-complete">
                                <b
                                  transloco="signature_installation_marked_installed_headline"
                                ></b>

                                <div
                                  class="checkbox marright20 d-inline-flex cursor-pointer"
                                  (click)="changeStepStatus(steps[i], steps)"
                                >
                                  <mt-checkbox
                                    labelName="signature_installation_marked_installed"
                                    class="complete-step-checkbox"
                                    [checked]="steps[i].completed!"
                                  ></mt-checkbox>
                                  <div
                                    transloco="signature_installation_marked_installed"
                                  ></div>
                                </div>
                              </div>
                            </mt-wizard-tab>
                          </ng-container>
                        </mt-wizard-tabs-container>
                      </div>
                      <!-- Steps -->
                      <mt-installation-guide-steps
                        *ngIf="inputData.signatures.length === 0"
                        [installationData]="installationData"
                      ></mt-installation-guide-steps>
                    </div>
                  </div>
                </div>
                <div *ngIf="!showSignaturePreview">
                  <mt-installation-guide-steps
                    [installationData]="installationData"
                  ></mt-installation-guide-steps>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mt-custom-modal>
