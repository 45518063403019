<mt-custom-modal headline="{{ 'select_default_signature' | transloco }}">
  <!-- Modal Body -->
  <ng-container modal-body>
    <div class="content">
      <div class="flex-center-space-between">
        <!-- Append Text -->
        <div class="flex">
          <mt-svg
            class="mt-grey tiny-svg"
            src="assets/images/svgs/info.svg"
          ></mt-svg>
          <div
            class="flex format3 hidden-xs"
            style="max-width: 400px; margin-left: 10px"
            transloco="select_default_signature_modal_info"
          ></div>
        </div>

        <div class="flex">
          <mt-search
            class="leftbutton"
            (search)="filterGroupTitle = $event"
            [disableEnter]="true"
          ></mt-search>

          <div ngbDropdown class="btn-group sort leftbutton">
            <mt-button
              text="sort"
              [dropdown]="true"
              id="dropdownSort"
              ngbDropdownToggle
            ></mt-button>
            <ul ngbDropdownMenu aria-labelledby="dropdownSort">
              <li>
                <a
                  ngbDropdownItem
                  (click)="list.sortList('title')"
                  transloco="sort_titel"
                >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ng-container *ngIf="list.all">
        <div
          style="max-height: 350px; margin-top: 15px; overflow: auto"
          id="main_block_with_scroll_2"
        >
          <ul style="width: 100%; table-layout: fixed" class="lists">
            <ng-container
              *ngFor="
                let group of list.all | filter : 'title' : filterGroupTitle;
                odd as isOdd;
                even as isEven
              "
            >
              <li
                class="listrow"
                (click)="list.updateSelection(group)"
                [ngClass]="{
                  selected: list.isSelected(group),
                  odd: isOdd,
                  even: isEven
                }"
              >
                <div class="tcell first select">
                  <selectcol [list]="list" [item]="group"></selectcol>
                </div>
                <div class="tcell" style="width: 65%">
                  <groupcol
                    [isFirst]="true"
                    [groups]="[group]"
                    [disableLinks]="true"
                    iconColor="mt-blau"
                  ></groupcol>
                </div>
                <div class="tcell" style="width: 50%">
                  <signaturestatuscol
                    [signatures]="group.Signature ? [group.Signature] : []"
                    [signaturestatus]="getSignatureStatus(group)"
                    [alertmo]="
                      'atLeastOneEmpHasNotIntegratedSigYet' | transloco
                    "
                    [successmo]="
                      'allEmpsHaveIntegratedLatestSignature' | transloco
                    "
                    (redirectToSignatureDetail)="modal.close()"
                  >
                  </signaturestatuscol>
                </div>
                <div class="tcell" style="width: 50%">
                  <campaignstatuscol
                    [campaigns]="group.Campaign ? [group.Campaign] : []"
                    alertmo="
                    {{ 'atLeastOneEmpHasNotIntegratedCampaignYet' | transloco }}
                  "
                    successmo="{{ 'bannerIsShownInEveryEmpsSig' | transloco }}"
                    (redirectToCampaignDetail)="modal.close()"
                  ></campaignstatuscol>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>

      <mt-emptystate
        [loadingPromise]="isLoading"
        [showEmpty]="!list.all.length"
        class="text-center"
        hltitle="emptystate_department_hl"
        subtitle="emptystate_department_sl"
        subtitleColor="mt-hellgrau"
        svg="group_circle"
        titleColor="mt-grau"
      ></mt-emptystate>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container modal-footer>
    <div class="flex-center-space-between">
      <div class="flex"></div>
      <div class="flex">
        <div class="flex-center-right">
          <mt-button
            color="default"
            text="abort"
            class="leftbutton"
            (click)="modal.close()"
          ></mt-button>
          <mt-button
            color="primary"
            text="select_default_signature_modal_button"
            icon="ok"
            (click)="accept()"
            [disabled]="!list.selected.length"
          ></mt-button>
        </div>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
