import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from '@services/alert/alert.service';
import { Observable, map, BehaviorSubject, tap, Subject, withLatestFrom, find, switchMap } from 'rxjs';
import { AccountService } from '@services/account/account.service';
import { AuthService } from '@services/auth/auth.service';
import { ModalService } from '@services/modal/modal.service';
import { IGuideSection } from 'src/app/model/interfaces/guide-section.interface';
import { IResponse, ResponseData } from 'src/app/model/interfaces/response.interface';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { IntercomService, INTERCOM_DATA } from '../intercom/intercom.module';
import { ESectionTitle } from './get-started-guide-sevice.interface';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '@services/account/account-service.interface';

const DEFAULT_SECTION: IGuideSection = {
  icon: '',
  title: '',
  steps: []
};

@Injectable({
  providedIn: 'root'
})
export class GetStartedGuideService {
  /**
   * Environment variable for check cogsig or mailtastic
   */
  isCogSig = environment.isCogSig;

  // Set a completed icon in a completed step
  private updateAsCompleted = new BehaviorSubject('able_to_do_w_cog_sig');
  updateAsCompleted$ = this.updateAsCompleted.asObservable();

  /**
   * observable to check is guide page or not
   */
  private _isGuidePage$ = new BehaviorSubject({ isGuidePage: false });
  isGuidePage$ = this._isGuidePage$.asObservable();

  /**
   * Observable is used to open multiple section at a time.
   */
  private _isSectionOpen$ = new BehaviorSubject(['get_to_kno_cog_sig']);
  isSectionOpen$ = this._isSectionOpen$.asObservable();

  //#region BehaviorSubjects/Observables

  // Expose observable as it's anti-pattern exposing`BehaviorSubject`
  private _sectionGetToKnow = new BehaviorSubject(DEFAULT_SECTION);
  sectionGetToKnow$ = this._sectionGetToKnow.asObservable();

  private _sectionCompleteAcctSetup = new BehaviorSubject(DEFAULT_SECTION);
  sectionCompleteAcctSetup$ = this._sectionCompleteAcctSetup.asObservable();

  private _sectionCreateYourSig = new BehaviorSubject(DEFAULT_SECTION);
  sectionCreateYourSig$ = this._sectionCreateYourSig.asObservable();

  private _sectionInstallYourSig = new BehaviorSubject(DEFAULT_SECTION);
  sectionInstallYourSig$ = this._sectionInstallYourSig.asObservable();

  private _sectionImportColleagues = new BehaviorSubject(DEFAULT_SECTION);
  sectionImportColleagues$ = this._sectionImportColleagues.asObservable();

  /**
   * Observable is used to get guide data
   */
  getGuideDetails$ = this.http.get<ResponseData<string>>('/accountInfo/guide').pipe(
    this.operator.extractResponseData(),
    map(data => JSON.parse(data) as IGuideSection[]),
    withLatestFrom(this.authService.authorizationScope$),
    map(([data, authorizationScope]) => {
      const remainIndex = data.findIndex(section => section.steps.some(step => step.completed === false));
      const isGuideExist = remainIndex !== -1;
      return { data, authorizationScope, remainIndex, isGuideExist };
    })
  );

  /**
   * To triger the get latest guide step
   */
  private _triggerGuideData$ = new BehaviorSubject(0);
  triggerGuideData$ = this._triggerGuideData$.asObservable();

  /**
   * To get the guide data
   */
  guideData$ = this.getGuide().pipe(
    switchMap(sections => sections),
    find(tab => {
      return Boolean(
        tab?.steps.find(step => {
          return !step.completed;
        })
      );
    })
  );

  /**
   * To get the step details
   */
  loadGuideData$ = this.triggerGuideData$.pipe(
    switchMap(() => this.guideData$),
    map(data => {
      let stepTitle = '';
      if (data) {
        data.steps.find(step => {
          if (!step.completed && stepTitle === '') stepTitle = step.tabTitle || '';
        });
        return { data, stepTitle };
      }
      return { data: { title: '', steps: [], icon: '' }, stepTitle };
    })
  );
  //#endregion

  allSections: IGuideSection[] = [];

  /**
   * Get Authorization scope values from the token
   */
  authorizationScope$ = this.authService.authorizationScope$.pipe(
    map(data => {
      const accountData = this.accountService.getUserAccountData();
      return { data, accountData: accountData, isGuideTourAccess: accountData.accountId === data.accountId };
    })
  );

  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private modalService: ModalService,
    private operator: CustomOperators,
    private translateService: TranslocoService
  ) {}

  /**
   * Returns data holding the current progress of the 'Get started guide'
   * @returns Observable of guide data
   */
  getGuide(): Observable<IGuideSection[]> {
    return this.getGuideDetails$.pipe(
      map(value => {
        value.data = this.updateStepsBasedOnSubscription(value.data, value.authorizationScope); // Hide/Show steps based on subscription
        value.data = this.addNotifiers(value.data); // Add notifiers
        this.allSections = value.data;
        this.checkGuideTourStatus(value.remainIndex);
        value.data.map(section => section.steps.forEach(step => (step.open = false)));
        this.allSections.map(section => this.populateData(section)); // Populate every `BehaviorSubject`
        return value.data;
      })
    );
  }

  /**
   * Used to hide/show steps as per the subscription plan
   * @param sections - Object holding `sections`
   * @param authorizationScope - Object of authorization scope to identify the subscription information
   * @returns Updated sections info
   */
  private updateStepsBasedOnSubscription(
    sections: IGuideSection[],
    authorizationScope: IMAILTASTIC_AUTHORIZATION_SCOPE
  ): IGuideSection[] {
    // Hide "Create your first Signature Campaign" wizard for basic plan
    if (authorizationScope.isUserOnBasicPlan) {
      const createSigStepIndex = sections.findIndex(info => info.title === ESectionTitle.CREATE_YOUR_SIG);
      if (createSigStepIndex !== -1) {
        sections[createSigStepIndex].steps[1].completed = true;
        sections[createSigStepIndex].steps[2].stepNo = 2;
        sections[createSigStepIndex].steps[3].stepNo = 3;
      }
    }
    return sections;
  }

  /**
   * Sends an updated guide with the most recent progress to be saved
   * @param stepToUpdate - Updated step
   * @returns Observable of true
   */
  updateGuide(stepToUpdate: IWizardTabComponent): Observable<true> {
    // Check if the step is completed before marking it as complete to use it later to render a message conditionally
    const completedStateToCheck = stepToUpdate.completed;

    // Mark step as complete and update it's state
    stepToUpdate = this.markStepAsComplete(stepToUpdate);

    // Find the index of the section to update
    const sectionIndex = this.allSections.findIndex(section =>
      section.steps.some(step => step.tabTitle === stepToUpdate.tabTitle)
    );

    // Find the index of the step to update
    const stepIndex = this.allSections[sectionIndex].steps.findIndex(step => step.tabTitle === stepToUpdate.tabTitle);

    // Update the local var. with the new step
    this.allSections[sectionIndex].steps[stepIndex] = stepToUpdate;

    // Remove notifiers so we can save
    this.allSections = this.removeNotifiers(this.allSections);

    // Check is guide page then only open modal
    const showConfirmPopup = this._isGuidePage$.getValue().isGuidePage;

    // We have default signature creation on signup tour for mailtastic so, it always completed
    const assignSignatureToDepartment =
      !this.isCogSig && this.allSections[sectionIndex].steps[stepIndex].tabTitle === 'assign_sigtemp_depart';

    return this.http.post<IResponse>('/accountInfo/guide', { guide: JSON.stringify(this.allSections) }).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.allSections = this.addNotifiers(this.allSections); // Add notifiers again

        this.populateData(this.allSections[sectionIndex]); // Updated the corresponding `BehaviorSubject`

        if (showConfirmPopup && !completedStateToCheck && !assignSignatureToDepartment) {
          void this.alertService.defaultSuccessMessage(
            this.translateService.translate('stepcompleted_modal_text') as string,
            this.translateService.translate('stepcompleted_modal_hl') as string,
            this.translateService.translate('stepcompleted_modal_button') as string,
            true
          );
        }

        if (completedStateToCheck) {
          void this.alertService.defaultSuccessMessage(
            this.translateService.translate('CHANGES_SAVED_SUCCESSFULLY') as string,
            this.translateService.translate('done') as string
          );
        }

        if (this.isCogSig) {
          this.intercomService.trackEvent(this.getCompletedStep(stepToUpdate)); // Gets the completed step data to send the intercom
        }
      })
    );
  }

  markStepAsComplete(stepToUpdate: IWizardTabComponent): IWizardTabComponent {
    this.updateAsCompleted.next(stepToUpdate.tabTitle ? stepToUpdate.tabTitle : 'able_to_do_w_cog_sig');
    stepToUpdate.notifier?.next();
    return { ...stepToUpdate, completed: true };
  }

  /**
   * Updates the corresponding `BehaviourSubject` with the new section data
   * @param updatedSection - Section with new data
   */
  private populateData(updatedSection: IGuideSection): void {
    switch (updatedSection.title) {
      case ESectionTitle.GET_TO_KNOW:
        this._sectionGetToKnow.next(updatedSection);
        break;
      case ESectionTitle.COMPLETE_ACCT_SETUP:
        this._sectionCompleteAcctSetup.next(updatedSection);
        break;

      case ESectionTitle.CREATE_YOUR_SIG:
        this._sectionCreateYourSig.next(updatedSection);
        break;

      case ESectionTitle.INSTALL_SIG:
        this._sectionInstallYourSig.next(updatedSection);
        break;

      case ESectionTitle.IMPORT_COLLEAGUES:
        this._sectionImportColleagues.next(updatedSection);
        break;
    }
  }

  /**
   * Initializes new subjects for each step
   *
   * Used to notify the step to refresh it's state
   * @param sections - Object holding `sections`
   * @returns Sections with added subjects to all steps
   */
  private addNotifiers(sections: IGuideSection[]): IGuideSection[] {
    return sections.map(section => {
      section.steps.map(step => {
        step.notifier = new Subject<void>();
        return step;
      });
      return section;
    });
  }

  /**
   * Removes the subject from each step
   *
   * Used to save the object in the db
   * @param sections - Object holding `sections` with subjects
   * @returns Sections without subjects
   */
  private removeNotifiers(sections: IGuideSection[]): IGuideSection[] {
    return sections.map(section => {
      section.steps.map(step => {
        delete step.notifier;
        return step;
      });
      return section;
    });
  }

  /**
   * Gets the completed step data to send the intercom
   * @param step - Section with new data
   * @returns The completed step name
   */
  private getCompletedStep(step: IWizardTabComponent): string {
    let stepName = '';
    switch (step.tabTitle) {
      case 'able_to_do_w_cog_sig':
        stepName = INTERCOM_DATA.guide_section1_1_completed;
        break;
      case 'get_to_kno_app':
        stepName = INTERCOM_DATA.guide_section1_2_completed;
        break;
      case 'complete_your_sig_info':
        stepName = INTERCOM_DATA.guide_section2_1_completed;
        break;
      case 'design_firstsigtemp':
        stepName = INTERCOM_DATA.guide_section3_1_completed;
        break;
      case 'create_firstsigcamp':
        stepName = INTERCOM_DATA.guide_section3_2_completed;
        break;
      case 'create_firstdept':
        stepName = INTERCOM_DATA.guide_section3_3_completed;
        break;
      case 'assign_sigtemp_depart':
        stepName = INTERCOM_DATA.guide_section3_4_completed;
        break;
      case 'prepare_sig_settings':
        stepName = INTERCOM_DATA.guide_section4_1_completed;
        break;
      case 'install_sig_mailclient':
        stepName = INTERCOM_DATA.guide_section4_2_completed;
        break;
      case 'connect_software_cogsig':
        stepName = INTERCOM_DATA.guide_section5_1_completed;
        break;
      case 'complete_integratio_setup':
        stepName = INTERCOM_DATA.guide_section5_2_completed;
        break;
      case 'import_colleagues_cogsig':
        stepName = INTERCOM_DATA.guide_section5_3_completed;
        break;
    }
    return stepName;
  }

  /**
   * Opens the current step and close the others
   * @param sectionData - Section with new data
   * @param step - The step to open or close
   */
  toggleGraphicTab(sectionData: IGuideSection, step: IWizardTabComponent): void {
    this.allSections.map(section => {
      if (section.title !== sectionData.title) {
        section.steps.forEach(step => (step.open = false));
      }
      this.populateData(section);
    });
    sectionData.steps.forEach(element => {
      element.open = element.stepNo === step.stepNo ? element.open : false;
    });
  }

  /**
   * Opens the current Section and close the others
   * @param title - title of the Section
   * @param sections - Currently opened sections
   */
  toggleSectionTab(title: string, sections: string[]): void {
    const index = sections.findIndex(section => section === title);
    index === -1 ? sections.push(title) : sections.splice(index, 1);
    this._isSectionOpen$.next(sections);
  }

  /**
   * Checks if the step(s) is(are) completed
   * @param steps - The step(s)
   * @returns The display status of step info component
   */
  isStepCompleted(steps: IWizardTabComponent | IWizardTabComponent[]): boolean {
    if (!steps) {
      return false;
    }

    if (!Array.isArray(steps)) {
      steps = [steps];
    }

    return steps.reduce<boolean>((acc, tab) => acc && !!tab.completed, true);
  }

  /**
   * Gets the tab title of first specific incomplete step
   * @param steps - The step(s)
   * @returns The first specific incomplete step tab title
   */
  getSpecificIncompleteStepTabTitle(steps: IWizardTabComponent | IWizardTabComponent[]): string {
    let tabTitle = '';

    if (!steps) {
      return tabTitle;
    }

    if (!Array.isArray(steps)) {
      steps = [steps];
    }

    for (const step of steps) {
      if (!step.completed) {
        tabTitle = step.tabTitle || '';

        return tabTitle;
      }
    }

    return tabTitle;
  }

  /**
   * Opens the step
   * @param sectionData - Section with new data
   * @param step - The previous step to open
   */
  openStep(sectionData: IGuideSection, step: IWizardTabComponent): void {
    this.toggleSectionTab(sectionData.title, []);
    sectionData.steps.forEach(element => {
      element.open = element.stepNo === step.stepNo;
    });
    step.notifier?.next();
    setTimeout(() => {
      step.bringIntoView?.();
    }, 0);
  }

  /**
   * @remarks Temporary removed
   *
   * Opens the first incomplete step of the section
   * @returns The section data
   */
  openFirstIncompleteStep(): (source: Observable<IGuideSection>) => Observable<IGuideSection> {
    return (source: Observable<IGuideSection>) => {
      return source.pipe(
        tap(data => {
          let isPreStepOpened = false;
          data.steps.map(step => {
            step.open = false;
            if (!(step.completed || isPreStepOpened)) {
              isPreStepOpened = true;
              step.open = true;
            }
          });
          return data;
        })
      );
    };
  }

  /**
   * Gets the changes from guide step as completed
   * @param returns - The observable for tabtitle
   */
  getAsCompleted(): Observable<string> {
    return this.updateAsCompleted$;
  }

  /**
   * The observable to check first user login guide is completed
   * @param remainIndex - The count of remaining index
   */
  checkGuideTourStatus(remainIndex: number): void {
    if (remainIndex !== -1) {
      const remainStep = this.allSections[remainIndex].steps.findIndex(step => step.completed === false);
      // Open particular incomplete step by default and make scroll into view after content loaded
      setTimeout(() => {
        this.openStep(this.allSections[remainIndex], this.allSections[remainIndex].steps[remainStep]);
      }, 700);
    }
  }

  /**
   * The observable to check if guidePage
   * @param isPage - boolean whether it is guide page or not
   */
  checkGuidePage(isPage: boolean): void {
    this._isGuidePage$.next({ isGuidePage: isPage });
  }

  /**
   * The observable to trigger the updated guide data
   */
  triggerGuideData(): void {
    this._triggerGuideData$.next(0);
  }

  /**
   * Opens the modal to play the video
   * @param url - translate tag containing url
   * @param title - translate tag containing title
   * @param preStep - required previous step
   * @param sectionData - Object holding `sections` with subjects
   * @param isOpenStep - Checks for to open required step or not
   */
  openPlayVideoModal(
    url: string,
    title: string,
    preStep?: IWizardTabComponent,
    sectionData?: IGuideSection,
    isOpenStep?: boolean
  ): void {
    if (!preStep || preStep.completed) {
      this.modalService.openPlayVideoModalV2(url, title).subscribe();
    } else if (isOpenStep && sectionData) {
      this.openStep(sectionData, preStep);
    }
  }
}
