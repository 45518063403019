<mt-custom-modal
  headline="{{ 'importusers' | transloco }}"
  *ngIf="isAgreeWith$ | async as status"
>
  <div modal-body>
    <div
      *ngIf="usersToBuyData.integrationType === 'o365'"
      [innerHTML]="
        'param.importXo365userstomailtastic'
          | transloco : { amount: usersToBuyData.totalAmount }
      "
    ></div>
    <div
      *ngIf="usersToBuyData.integrationType === 'google'"
      [innerHTML]="
        'param.importXgsuiteuserstomailtastic'
          | transloco : { amount: usersToBuyData.totalAmount }
      "
    ></div>
    <div class="integrationsentry flex-center-space-between part">
      <div class="integrationinfo">
        <div class="leftcol">
          <div class="innerheadline down">
            {{ 'neededLicenses' | transloco }} {{ usersToBuyData.validAmount }}
          </div>
        </div>
      </div>
    </div>
    <div class="integrationsentry">
      <span transloco="freeLicencesLeft"></span>
      <span class="marleft5">{{ usersToBuyData.freeAmount }}</span>
    </div>
    <div class="integrationsentry">
      <span
        [innerHTML]="
          'param.doYouWantToImpAmountEmployeeLicencesNow'
            | transloco
              : {
                  amount: usersToBuyData.validAmount - usersToBuyData.freeAmount
                }
        "
      ></span
      >?
    </div>
    <div class="integrationsentry">
      (<span>{{ usersToBuyData | usersToBuyAmount }} €</span> /
      <span transloco="Month"></span>,
      <span transloco="tillTheEndOfSubscription"></span>)
    </div>

    <div class="checkbox">
      <label
        ><input
          type="checkbox"
          required="true"
          (click)="updateIsAgreeWith(!status.isAgree)"
        />
        <span
          [innerHTML]="
            'param.yesIWantToImportAmountEmpLicencesWithCosts'
              | transloco
                : {
                    amount:
                      usersToBuyData.validAmount - usersToBuyData.freeAmount
                  }
          "
        ></span>
        <span
          [innerHTML]="'html.andHaveReadAndAcceptedTheConditions' | transloco"
        ></span
        >.</label
      >
    </div>
  </div>
  <div modal-footer>
    <div class="inline-flex-right footer">
      <div class="flex text-right">
        <mt-button
          text="abort"
          class="mt_left"
          color="secondary"
          (click)="modal.close(false)"
        >
        </mt-button>
        <mt-button
          [disabled]="!status.isAgree"
          text="Add"
          color="primary"
          class="mt_left"
          (click)="modal.close(true)"
        ></mt-button>
      </div>
    </div>
  </div>
</mt-custom-modal>
