import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GatedContentService } from 'src/app/services/gated-content/gated-content.service';
import { GatedContentIconComponent } from '@organisms/gated-content-icon/gated-content-icon.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'mt-gated-content',
  templateUrl: './gated-content.component.html',
  styleUrls: ['./gated-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, GatedContentIconComponent, AsyncPipe]
})
export class GatedContentComponent {
  /**
   * Display gate icon for the specific section
   */
  @Input() isSection!: boolean;

  /**
   * Display gated content modal directly on load
   */
  @Input() set isDirectModalOpen(isOpen: boolean) {
    if (isOpen) {
      this.openGatedContentInfoModal();
    }
  }

  /**
   * Triggers on modal close
   */
  @Output() closeModal = new EventEmitter<void>();

  /**
   * Hold value for hover
   */
  private _isHover$ = new BehaviorSubject<boolean>(false);
  isHover$ = this._isHover$.asObservable();

  /**
   * Get scroll event to open gated content modal
   */
  @HostListener('window:scroll')
  onWindowScroll() {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;
    if (pos == max && !this.isSection) {
      this.openGatedContentInfoModal();
    }
  }

  /**
   * Get mouse enter event to show gated content icon
   */
  @HostListener('mouseenter', [])
  onMouseEnter() {
    this._isHover$.next(true);
  }

  /**
   * Get mouse leave event to show gated content icon
   */
  @HostListener('mouseleave', [])
  onMouseLeave() {
    this._isHover$.next(false);
  }

  /**
   * Get click event to open gated content modal
   */
  @HostListener('click', [])
  onWindowClick() {
    this.openGatedContentInfoModal();
  }

  constructor(private gatedContentService: GatedContentService) {}

  /**
   * Open gated content modal
   */
  private openGatedContentInfoModal(): void {
    this.gatedContentService.openGatedContentInfoModal().subscribe(() => this.closeModal.emit());
  }
}
