import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ICampaign } from 'src/app/model/interfaces/campaign.interface';
import { ICampaignStatus } from 'src/app/services/signature-campaign-status/signature-campaign-status.service';
import { RouterLink } from '@angular/router';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'campaignstatuscol',
  templateUrl: './campaign-status-col.component.html',
  styleUrls: ['./campaign-status-col.component.scss'],
  standalone: true,
  imports: [NgIf, TranslocoModule, NgbTooltip, MtSvgComponent, NgClass, RouterLink]
})
export class CampaignStatusColComponent implements OnInit {
  @Input() campaigns: ICampaign[] = [];
  @Input() alertmo = '';
  @Input() isFirst = false;
  @Input() successmo = '';
  @Input() gated = false;
  @Input() iconColor = '';
  @Input()
  set campaignstatus(data: ICampaignStatus) {
    this.campaigns = data.campaigns;
  }

  tooltipText = '';

  /**
   * Emit events to redirect to campaign details page
   */
  @Output() redirectToCampaignDetail: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.campaigns.length > 1) {
      for (const campaign of this.campaigns) {
        this.tooltipText +=
          '<div> <span  class="glyphicon glyphicon-send" aria-hidden="true"></span> <span class="grouptitle">' +
          campaign.title +
          '</span></div>';
      }
    }
  }
}
