<div class="wizard mt_modal_with_head">
  <mt-custom-modal headline="{{ 'Chooseemployees' | transloco }}">
    <div modal-body>
      <div class="content">
        <div class="flex-center-space-between">
          <div class="flex">
            <mt-select-all-button
              *ngIf="list.all.length"
              class="leftbutton"
              [checked]="list.isSelectedAll()"
              (click)="list.updateSelectionAll()"
            ></mt-select-all-button>
          </div>

          <div class="flex">
            <mt-search
              *ngIf="(searchData$ | async) || list.all.length"
              class="leftbutton"
              [disableEnter]="true"
              (search)="list.setFilterText($event)"
            ></mt-search>

            <div
              *ngIf="list.all.length"
              ngbDropdown
              class="btn-group sort leftbutton"
            >
              <mt-button
                text="sort"
                [dropdown]="true"
                id="dropdownSort"
                ngbDropdownToggle
              ></mt-button>
              <ul ngbDropdownMenu aria-labelledby="dropdownSort">
                <li
                  ngbDropdownItem
                  transloco="Vorname"
                  (click)="sortList('firstname')"
                ></li>
                <li
                  ngbDropdownItem
                  transloco="Nachname"
                  (click)="sortList('lastname')"
                ></li>
                <li
                  ngbDropdownItem
                  transloco="E-Mail-Adresse"
                  (click)="sortList('email')"
                ></li>
              </ul>
            </div>
          </div>
        </div>

        <div
          style="max-height: 350px; margin-top: 15px; overflow: auto"
          class="main_block_with_scroll"
        >
          <div
            infiniteScroll
            [infiniteScrollDisabled]="infinitescrolloptions.disable"
            [infiniteScrollContainer]="'.main_block_with_scroll'"
            [fromRoot]="true"
            (scrolled)="infinitescrolloptions.scrollAction()"
            *ngIf="loadData$ | async; else loading"
          >
            <!-- list items -->
            <ul class="lists">
              <ng-container
                *ngFor="let employee of list.all; odd as isOdd; even as isEven"
              >
                <li
                  class="listrow"
                  (click)="list.updateSelection(employee)"
                  [ngClass]="{
                    selected: list.isSelected(employee),
                    odd: isOdd,
                    even: isEven
                  }"
                >
                  <div class="tcell first select">
                    <selectcol [list]="list" [item]="employee"></selectcol>
                  </div>

                  <div class="tcell" style="width: 65%">
                    <employeecol
                      [user]="employee"
                      [disableLinks]="true"
                    ></employeecol>
                  </div>

                  <div class="tcell" style="width: 30%">
                    <groupcol
                      [groups]="employee.Groups"
                      [disableLinks]="true"
                    ></groupcol>
                  </div>
                </li>
              </ng-container>
            </ul>

            <!-- no list items -->
            <mt-emptystate
              [loadingPromise]="isLoading"
              [showEmpty]="list.all.length === 0"
              class="nodataarea"
              class="text-center"
              hltitle="emptystate_employee_3_hl"
              subtitle="html.emptystate_employee_3_sl"
              svg="employees"
            ></mt-emptystate>
          </div>

          <!-- LOADING -->
          <ng-template #loading>
            <div class="card martop20">
              <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
          ></ng-template>
        </div>
      </div>
    </div>
    <div modal-footer>
      <div class="flex-center-space-between">
        <div class="flex">
          <div *ngIf="list.selected.length">
            <span>{{ list.selected.length }}</span>
            <span class="marleft5" transloco="employeesselected"></span>
          </div>
        </div>

        <div class="flex">
          <mt-button
            (click)="modal.close()"
            class="mt_left"
            color="default"
            ngbAutoFocus
            text="abort"
          ></mt-button>
          <mt-button
            (click)="assignEmployees()"
            [disabled]="!list.selected.length"
            class="roboto_light"
            color="primary"
            icon="plus"
            text="assigneemployees"
          >
          </mt-button>
        </div>
      </div>
    </div>
  </mt-custom-modal>
</div>
