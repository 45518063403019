import { InstallationGuideButton } from 'src/app/model/enums/signature-installation-button-type.enum';
import { InstallationStep } from 'src/app/model/interfaces/integrations.interface';

const outlook_mac_easysync_1_1: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.EASYSYNC_DOWNLOAD,
    tag: 'html.outlookmac_easysync_direction_1_0'
  },
  {
    image: 'guide_easysync_1',
    tag: 'html.outlookmac_easysync_direction_2_0'
  },
  { image: 'guide_easysync_2', tag: 'html.outlookmac_easysync_direction_3_0' },
  {
    class: 'options-subpart',
    tag: 'html.outlookmac_easysync_direction_3_0_subpart'
  }
];

const outlook_mac_desktop_manual_1_2: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.general_step1_direction_1_0'
  },
  { image: 'guide_outlook_manual_1', tag: 'html.outlook_manual_direction_2_0' },
  { class: 'options-subpart', image: 'guide_outlook_manual_2', tag: 'html.outlook_manual_direction_2_0_subpart' },
  { image: 'guide_outlook_manual_3', tag: 'html.outlook_manual_direction_3_0' },
  {
    image: 'guide_outlook_manual_4',
    tag: 'html.outlook_manual_direction_4_0'
  },
  { image: 'guide_outlook_manual_5', tag: 'html.outlook_manual_direction_5_0' },
  {
    buttonType: InstallationGuideButton.DONE,
    class: 'options-subpart',
    image: 'guide_outlook_manual_6',
    tag: 'html.outlook_manual_direction_5_0_subpart'
  }
];

const outlook_win_easysync_2_1: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.EASYSYNC_DOWNLOAD,
    tag: 'html.outlook_windows_easysync_direction_1_0'
  },
  {
    image: 'guide_easysync_7',
    tag: 'html.outlookmac_easysync_direction_2_0'
  },
  {
    buttonType: InstallationGuideButton.DONE,
    image: 'guide_easysync_8',
    tag: 'html.outlook_windows_easysync_direction_3_1'
  }
];

const outlook_win_desktop_manual_2_2: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.general_step1_direction_1_0'
  },
  {
    image: 'guide_outlook_manual_1',
    tag: 'html.outlook_manual_direction_2_0'
  },
  { class: 'options-subpart', image: 'guide_outlook_manual_2', tag: 'html.outlook_manual_direction_2_0_subpart' },
  {
    image: 'guide_outlook_manual_3',
    tag: 'html.outlook_manual_direction_3_0'
  },
  {
    image: 'guide_outlook_manual_4',
    tag: 'html.outlook_manual_direction_4_0'
  },
  { image: 'guide_outlook_manual_5', tag: 'html.outlook_manual_direction_5_0' },
  {
    buttonType: InstallationGuideButton.DONE,
    class: 'options-subpart',
    image: 'guide_outlook_manual_6',
    tag: 'html.outlook_manual_direction_5_0_subpart'
  }
];

const outlook_on_the_web_3: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.general_step1_direction_1_0'
  },
  {
    image: 'guide_outlook_web_manual_1',
    tag: 'html.outook_web_manual_direction_2_0'
  },
  { image: 'guide_outlook_web_manual_2', tag: 'html.outook_web_manual_direction_3_0' },
  {
    image: 'guide_outlook_web_manual_3',
    tag: 'html.outook_web_manual_direction_4_0'
  },
  {
    tag: 'html.outook_web_manual_direction_5_0'
  },
  { buttonType: InstallationGuideButton.DONE, tag: 'html.outook_web_manual_direction_6_0' }
];

const outlook_mobile_app_android_4: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.SEND_PLAINTEXT,
    class: 'martop20',
    tag: 'html.param.integration_guide_mobile_android_guide_txt_1'
  },
  {
    tag: 'html.integration_guide_outlook_mobile_android_guide_txt_3'
  }
];

const outlook_mobile_app_ios_5: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.SEND_PLAINTEXT,
    class: 'martop20',
    tag: 'html.param.integration_guide_outlook_mobile_ios_guide_txt_1'
  },
  {
    tag: 'html.integration_guide_outlook_mobile_ios_guide_txt_3'
  }
];

const gmail_manual_6: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.general_step1_direction_1_0'
  },
  {
    image: 'guide_gmail_manual_1',
    tag: 'html.gmail_manual_direction_2_0'
  },
  { class: 'options-subpart', image: 'guide_gmail_manual_2', tag: 'html.gmail_manual_direction_2_0_subpart' },
  {
    class: 'martop60 options',
    tag: 'html.gmail_manual_direction_3_0'
  },
  {
    buttonType: InstallationGuideButton.DONE,
    class: 'options-subpart w575',
    tag: 'html.gmail_manual_direction_3_0_subpart'
  },
  { class: 'options-subpart', image: 'guide_gmail_manual_3', tag: 'html.general_done' }
];

const gmail_mobile_app_android_7: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.SEND_PLAINTEXT,
    class: 'martop20',
    tag: 'html.param.integration_guide_gmail_mobile_android_guide_txt_1'
  },
  {
    tag: 'html.integration_guide_gmail_mobile_android_guide_txt_3'
  }
];

const gmail_mobile_app_ios_8: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.SEND_PLAINTEXT,
    class: 'martop20',
    tag: 'html.param.integration_guide_gmail_mobile_ios_guide_txt_1'
  },
  {
    tag: 'html.integration_guide_gmail_mobile_ios_guide_txt_3'
  }
];

const apple_mail_desktop_easysync_9_1: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.EASYSYNC_DOWNLOAD,
    tag: 'html.outlookmac_easysync_direction_1_0'
  },
  {
    image: 'guide_easysync_1',
    tag: 'html.outlookmac_easysync_direction_2_0'
  },
  { image: 'guide_easysync_2', tag: 'html.outlookmac_easysync_direction_3_0' },
  {
    class: 'options-subpart',
    tag: 'html.outlookmac_easysync_direction_3_0_subpart'
  }
];

const apple_mail_desktop_manual_9_2: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.general_step1_direction_1_0'
  },
  {
    image: 'guide_apple_manual_1',
    tag: 'html.applemail_manual_direciton_2_0'
  },
  { class: 'options-subpart', image: 'guide_apple_manual_2', tag: 'html.applemail_manual_direciton_2_0_subpart' },
  {
    class: 'options-subpart options',
    image: 'guide_apple_manual_3',
    tag: 'html.applemail_manual_direction_3_0'
  },
  {
    image: 'guide_apple_manual_4',
    tag: 'html.applemail_manual_direction_3_0_subpart'
  },
  { image: 'guide_apple_manual_5', tag: 'html.applemail_manual_direction_4_0' },
  {
    buttonType: InstallationGuideButton.DONE,
    image: 'guide_apple_manual_6',
    tag: 'html.applemail_manual_direction_5_0'
  }
];

const apple_mail_desktop_app_ios_10: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.SEND_TEMPLATE,
    class: 'martop20',
    tag: 'html.param.integration_guide_mobile_ios_guide_txt_1'
  },
  { image: 'guide_applemail_mobile_1', tag: 'html.applemail_ios_direction_3_0' },
  {
    image: 'guide_applemail_mobile_2',
    tag: 'html.applemail_ios_direction_4_0'
  },
  {
    image: 'guide_applemail_mobile_3',
    tag: 'html.applemail_ios_direction_5_0'
  },
  { image: 'guide_applemail_mobile_4', tag: 'html.general_mobile_done', class: 'marbot20' }
];

const android_samsung_mobile_email_app_11: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.SEND_PLAINTEXT,
    class: 'martop20',
    tag: 'html.param.integration_guide_mobile_android_guide_txt_1'
  }
];

const html_copy_and_paste_12_1: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.html_direction_1_0'
  },
  {
    buttonType: InstallationGuideButton.DONE,
    tag: 'html.html_direction_2_0'
  }
];

const signature_tempalte_copy_and_paste_12_2: InstallationStep[] = [
  {
    buttonType: InstallationGuideButton.COPY_HTML,
    tag: 'html.signature_template_direction_1_0'
  },
  {
    buttonType: InstallationGuideButton.DONE,
    tag: 'html.signature_template_direction_2_0'
  }
];

const outlookMacEacysyncCatalina: InstallationStep[] = [
  {
    class: 'options',
    image: 'guide_easysync_3',
    tag: 'html.mac_catalina_easysync_direction_3_1'
  },
  {
    class: 'options-subpart options',
    image: 'guide_easysync_4',
    tag: 'html.mac_catalina_easysync_direction_3_1_subpart'
  },
  { class: 'martop60 options', image: 'guide_easysync_5', tag: 'html.mac_catalina_easysync_direction_3_2' },
  {
    buttonType: InstallationGuideButton.DONE,
    image: 'guide_easysync_6',
    tag: 'html.outlookmac_easysync_direction_4_0'
  }
];

const outlookMacEacysyncMojaveBigsur: InstallationStep[] = [
  {
    class: 'martop60 options',
    image: 'guide_easysync_4_mojave',
    tag: 'html.mac_mojave_easysync_direction_3_1'
  },
  {
    class: 'martop60 options',
    image: 'guide_easysync_5_mojave',
    tag: 'html.mac_mojave_easysync_direction_3_2'
  },
  {
    buttonType: InstallationGuideButton.DONE,
    class: 'martop60 options',
    image: 'guide_easysync_6',
    tag: 'html.outlookmac_easysync_direction_4_0'
  }
];

const outlook_addin: InstallationStep[] = [
  { tag: 'html.outlook_outlook_addin_2', buttonType: InstallationGuideButton.OUTLOOK_ADDIN },
  { tag: 'html.outlook_outlook_addin_3' }
];

const chromExtenstionGSuite: InstallationStep[] = [
  {
    image: 'int_gmail_easysync_1_image_1',
    tag: 'html.int_gmail_easysync_1'
  },
  {
    image: 'int_gmail_easysync_1_image_2',
    tag: '' // Empty tag since step 1 has 2 images but only 1 text tag
  },
  {
    image: 'int_gmail_easysync_2_image_1',
    tag: 'html.int_gmail_easysync_2'
  },
  {
    image: 'int_gmail_easysync_2_image_2',
    tag: '' // Empty tag since step 2 has 2 images but only 1 text tag
  },
  {
    image: 'int_gmail_easysync_3_image',
    tag: 'html.int_gmail_easysync_3'
  },
  {
    buttonType: InstallationGuideButton.DONE,
    image: 'int_gmail_easysync_4_image',
    tag: 'html.int_gmail_easysync_4'
  }
];

export const MACDATA = {
  macTypes: [{ value: 'Choose' }, { value: 'rights_catalina' }, { value: 'rights_mojave_bigsur' }],
  outlookMacEacysyncCatalina,
  outlookMacEacysyncMojaveBigsur
};

export const INSTALLATION_GUIDE = {
  android_samsung_mobile_email_app_11,
  apple_mail_desktop_app_ios_10,
  apple_mail_desktop_easysync_9_1,
  apple_mail_desktop_manual_9_2,
  chromExtenstionGSuite,
  gmail_manual_6,
  gmail_mobile_app_android_7,
  gmail_mobile_app_ios_8,
  html_copy_and_paste_12_1,
  outlook_addin,
  outlook_mac_desktop_manual_1_2,
  outlook_mac_easysync_1_1,
  outlook_mobile_app_android_4,
  outlook_mobile_app_ios_5,
  outlook_on_the_web_3,
  outlook_win_desktop_manual_2_2,
  outlook_win_easysync_2_1,
  signature_tempalte_copy_and_paste_12_2
};
