import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mt-alert-complete-account-data',
  templateUrl: './alert-complete-account-data.component.html',
  styleUrls: ['./alert-complete-account-data.component.scss']
})
export class AlertCompleteAccountDataComponent {
  constructor(public modal: NgbActiveModal) {}
}
