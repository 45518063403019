<div
  class="mt-wizard-tab-content-wrapper text-center"
  style="padding: 35px 0; width: 100%"
>
  <ng-container *ngIf="!parent.completed">
    <span class="complete-following">
      {{ parent.completeFollowingText || '' | transloco }}
    </span>

    <div
      class="detailpanel detailpanel_inactive"
      *ngFor="let tab of invalidTabs"
    >
      <div *ngIf="!tab.completed" class="header header_only header-tab">
        <div>
          <!-- step no. -->
          <mt-svg
            src="{{ 'assets/images/tabs/' + (tab.stepNo || 1) + '.svg' }}"
            class="mt-tab-step-no htf-regent_grau"
          ></mt-svg>
          &nbsp; &nbsp;
          <!-- title -->
          <span class="mt-tab-title">{{ tab.tabTitle || '' | transloco }}</span>
          &nbsp; &nbsp;
          <!-- subtitle -->
          <span *ngIf="tab.optional" class="mt-tab-subtitle">
            {{ 'optional' | transloco }}
          </span>
          <span *ngIf="!tab.optional" class="mt-tab-subtitle">
            {{ tab.subtitle || '' | transloco }}
          </span>
        </div>

        <!-- edit link -->
        <a
          (click)="onInvalidTabClick(tab)"
          transloco="Edit"
          class="cursor-pointer mt-blau"
          style="font-size: 16px"
        ></a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="parent.completed">
    <mt-svg
      class="tiny-svg mt-checked-icon completed-icon"
      src="assets/images/tabs/tick.svg"
      style="display: inline-block"
    ></mt-svg>
    <div class="mt-checked-title" transloco="completed_info"></div>
  </ng-container>
</div>
