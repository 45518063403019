import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MailtasticAuthorizationDetails } from '@model/enums/mailtastic-authorization-details.enum';
import { AccountService } from '@services/account/account.service';
import { AlertService } from '@services/alert/alert.service';
import { AdblockService } from '@services/adblock/adblock.service';
import { AuthService } from '@services/auth/auth.service';
import { IExternalAdminGetAccounts } from '@services/external-admin/external-admin.interface';
import { ExternalAdminService } from '@services/external-admin/external-admin.service';
import { PaymentService } from '@services/payment/payment.service';
import { UserService } from '@services/user/user.service';
import { iif, Observable, of, switchMap, tap, catchError, BehaviorSubject, finalize, withLatestFrom } from 'rxjs';

@Injectable()
export class SimpleAuthService {
  isCognismSignatures = environment.isCogSig;

  /**
   * Login btn disabled
   */
  private _loginBtnDisabled$ = new BehaviorSubject<boolean>(false);
  loginBtnDisabled$ = this._loginBtnDisabled$.asObservable();

  constructor(
    private accountService: AccountService,
    private adblockService: AdblockService,
    private alert: AlertService,
    private authService: AuthService,
    private externalAdminService: ExternalAdminService,
    private paymentService: PaymentService,
    private userService: UserService
  ) {}

  /**
   * Login to the application using a mailtastic account
   * @param email - Email of account
   * @param password - Password of account
   * @param isSuperAdmin - The super admin trigger
   * @returns Array of external admin list
   */
  login(email: string, password: string, isSuperAdmin = false): Observable<IExternalAdminGetAccounts[]> {
    this.enableDisableLoginBtn(true);
    return this.userService.login(email, password, isSuperAdmin).pipe(
      tap(result => {
        this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.userId, result?.userId || '');
        this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.defaultUserId, result?.userId || '');
        this.accountService.setUserAccountData(
          result.companyName,
          result.firstname,
          result.lastname,
          result.email,
          result.adminId,
          result.accountId
        );

        // Open adblock modal if adblock extension enabled
        if (!result.isUserLoggedInFirstTime) {
          this.adblockService.openAdblockContentModalIfExist();
        }

        if (result.isSuperAdmin) {
          this.accountService.setSuperAdminAccountData(result);
          this.authService.updateCustomerLoginEmails([]);
        }

        this.authService.setToken(result.token);
      }),
      withLatestFrom(this.authService.authorizationScope$),
      switchMap(([result, authorization]) =>
        iif(
          () => this.isCognismSignatures,
          of(result),
          this.paymentService.getCustomerSubscriptionData(authorization.accountId)
        )
      ),
      switchMap(() => iif(() => isSuperAdmin, of([]), this.externalAdminService.getAccounts())),
      catchError((err: HttpErrorResponse) => {
        if (err.message === 'Authentication failed. Login incorrect' || err.message === 'Authentication failed.') {
          void this.alert.defaultErrorMessage(this.alert.translate('wrong_pw_sl'), this.alert.translate('wrong_pw_hl'));
        } else if (err.message === 'RETRY_AFTER_ONE_HOUR') {
          void this.alert.defaultErrorMessage(this.alert.translate('confirmationmail_error_note'));
        } else if (err.message === 'TRY_TO_LOGIN_WITH_SSO') {
          void this.alert.defaultErrorMessage(this.alert.translate('TRY_TO_LOGIN_WITH_SSO'));
        } else if (err.message === 'login_problem_occured') {
          void this.alert.defaultErrorMessage(this.alert.translate('login_problem_occured'));
        } else {
          void this.alert.defaultErrorMessage(err.message);
        }
        this.enableDisableLoginBtn(false);
        throw err;
      }),
      finalize(() => this.enableDisableLoginBtn(false))
    );
  }

  /**
   * Trigger login button enable disable
   * @param isDisbled - boolean
   */
  private enableDisableLoginBtn(isDisbled: boolean): void {
    this._loginBtnDisabled$.next(isDisbled);
  }
}
