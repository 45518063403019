import { Component, Input, OnInit } from '@angular/core';
import { TriggerEventTargetGroups } from 'src/app/model/interfaces/event.interface';
import {
  TargetGroupList,
  ITargetGroupListEntryCampaign,
  ITargetGroupListModel
} from 'src/app/model/interfaces/target-group-list.interface';
import { EventTargetGroup } from 'src/app/modules/events/model/event-target-groups';
import {
  TargetGroupListEntriesGetTGList,
  TargetGroupsGetTargetGroups
} from 'src/app/services/target-group/target-group-service.type';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgClass, NgIf, NgFor, LowerCasePipe } from '@angular/common';
@Component({
  selector: 'targetgroupscol',
  templateUrl: './target-group-col.component.html',
  styleUrls: ['./target-group-col.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, MtSvgComponent, TranslocoModule, NgbTooltip, RouterLink, NgFor, LowerCasePipe]
})
export class TargetGroupColComponent implements OnInit {
  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Color used for the icon
   * @defaultValue 'mt-darkgrey'
   */
  @Input() iconColor = 'mt-darkgrey';

  /**
   * Determines if specific styling is applied
   * @defaultValue false
   */
  @Input() isFirst = false;

  /**
   * Assigned target group
   */
  @Input() targetGroups!:
    | TargetGroupList[]
    | TriggerEventTargetGroups[]
    | ITargetGroupListEntryCampaign[]
    | TargetGroupsGetTargetGroups[]
    | TargetGroupListEntriesGetTGList[]
    | EventTargetGroup[];

  /**
   * Determine if target group will be shown or not
   * @defaultValue false
   */
  @Input() showTargetGroup = false;

  /**
   * Holds basic target group information for internal display purposes
   * @defaultValue []
   */
  displayedTargetGroups: ITargetGroupListModel[] = [];

  ngOnInit(): void {
    if ((this.targetGroups[0] as TriggerEventTargetGroups)?.TargetGroupList) {
      for (const item of this.targetGroups) {
        const targetGroup = item as TriggerEventTargetGroups;
        this.displayedTargetGroups.push({
          id: targetGroup.TargetGroupList.id,
          owner: targetGroup.TargetGroupList.owner,
          source: targetGroup.TargetGroupList.source,
          title: targetGroup.TargetGroupList.title,
          type: targetGroup.TargetGroupList.type
        });
      }
    } else {
      for (const item of this.targetGroups) {
        const targetGroup = item as TargetGroupList;
        if (targetGroup) {
          this.displayedTargetGroups.push({
            id: targetGroup.id,
            owner: targetGroup.owner,
            source: targetGroup.source,
            title: targetGroup.title,
            type: targetGroup.type
          });
        }
      }
    }
  }
}
