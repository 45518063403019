export enum MailtasticAuthorizationDetails {
  accountId = 'MAILTASTIC_loggedInAccountId', // Account id of logged user to identify admin is external admin or not
  activeAccountId = 'MAILTASTIC_activeAccountId', // Active account's id (Can be different when external admin)
  adminId = 'MAILTASTIC_adminId',
  companyName = 'companyName',
  defaultUserId = 'MAILTASTIC_defaultUserId', // User id of logged account default user for get dataset. used in onboarding process
  email = 'email',
  firstName = 'firstName',
  id = 'MAILTASTIC_id',
  isUserLoggedInFirstTime = 'MAILTASTIC_isUserLoggedInFirstTime',
  lastName = 'lastName',
  superAdminCustomerLogins = 'MAILTASTIC_superAdminCustomerLogins',
  superAdminData = 'MAILTASTIC_superAdminData',
  userId = 'MAILTASTIC_userId'
}
