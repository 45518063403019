<div class="active-campaign-col" [ngClass]="{ first: isFirst }">
  <div
    class="title"
    *ngIf="activeCampaign; else noActiveCampaign"
    [ngClass]="{
      'mt-blau': !disableLinks && isFirst,
      'mt-darkgrey': disableLinks
    }"
  >
    <span class="innertitle" style="overflow: hidden">
      <mt-svg
        [ngClass]="iconColor"
        class="medium-svg"
        src="assets/images/svgs/campaign_list_svg.svg"
        style="margin-right: 4px"
      ></mt-svg>
      <span (click)="$event.stopPropagation()">
        <span
          *ngIf="!disableLinks"
          class="link mt-darkgrey"
          ngbTooltip="{{ activeCampaign.title }}"
          placement="top"
          tooltipClass="status-info-tooltip"
          [routerLink]="['/campaigns/details/', activeCampaign.id]"
          (click)="redirectToCampaignDetail.emit()"
          >{{ activeCampaign.title }}</span
        >
        <span
          *ngIf="disableLinks"
          class="mt-darkgrey department-title"
          ngbTooltip="{{ activeCampaign.title }}"
          placement="top"
          tooltipClass="status-info-tooltip"
          >{{ activeCampaign.title | textTruncate: 20 }}</span
        >
      </span>
    </span>
    <span
      *ngIf="!hideLabel"
      class="status_badge default"
      ngbTooltip="{{ 'default_tag_info' | transloco }}"
      placement="top"
      tooltipClass="status-info-tooltip"
    >
      <span class="title" transloco="default"></span>
    </span>
  </div>

  <ng-template #noActiveCampaign>
    <div
      class="title schrift_rot italic"
      transloco="no_campaign_assigned"
    ></div>
  </ng-template>

  <div class="subline">
    <span transloco="active_target_group_campaign"></span>
  </div>
</div>
