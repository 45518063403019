<div
  *ngIf="showEmpty || isLoading()"
  ngClass="{'nodataarea': !horizontal, 'nodataarea_hor': horizontal}"
>
  <!-- button -->
  <div class="flex createBtn">
    <!-- button -->
    <mt-button
      (click)="clickAction()"
      [disabled]="disabled"
      [text]="buttonText"
      *ngIf="buttonText"
      color="primary"
      icon="plus"
      ngbTooltip="{{
        (disabled && disableTooltip ? disableTooltip : '') | transloco
      }}"
      placement="top"
      tooltipClass="status-info-tooltip"
    >
    </mt-button>
  </div>

  <div
    class="emptystate text-center"
    ngClass="{'flex-center-left': horizontal}"
    *ngIf="showEmpty && !isLoading()"
  >
    <img
      src="{{ svgSource }}"
      class="emptystate__img {{ emptystateImgClass }} {{ iconColor }}"
    />
    <div class="rightside">
      <!-- title -->
      <div
        class="emptystate__title {{ emptystateTitleClass }} {{ titleColor }}"
        [innerHtml]="hltitle | transloco"
      ></div>
      <!-- subtitle -->
      <div
        class="emptystate__subtitle {{ emptystateSubitleClass }} {{
          subtitleColor
        }}"
        [innerHtml]="subtitle | transloco"
      ></div>
    </div>
  </div>

  <div class="loadingstate text-center" *ngIf="isLoading()">
    <!-- spinner -->
    <div class="spinner-border" role="status"></div>
    <!-- icon -->
    <div class="martop20">
      <div class="rightside">
        <!-- title -->
        <div
          class="loadingstate__title {{ loadingTitleClass }}"
          transloco="loading_data"
        ></div>
        <!-- subtitle -->
        <div
          class="loadingstate__subtitle {{ loadingSubitleClass }}"
          transloco="please_wait"
        ></div>
      </div>
    </div>
  </div>
</div>
