export const EMPLOYEE_FIELDS_MAPPING = {
  ma_abteilung: 'emp_department',
  ma_add_info_1: 'emp_add_info_1',
  ma_add_info_2: 'emp_add_info_2',
  ma_add_info_3: 'emp_add_info_3',
  ma_add_info_4: 'emp_add_info_4',
  ma_akad_grad: 'emp_acad_degree',
  ma_bundesland: 'emp_state',
  ma_disclaimer: 'emp_disclaimer',
  ma_email: 'emp_email',
  ma_fax: 'emp_fax',
  ma_fb: 'emp_fb',
  ma_firma: 'emp_company',
  ma_foto: 'emp_picture',
  ma_googlep: 'emp_googlep',
  ma_grussformel: 'emp_salutation',
  ma_instagram: 'emp_instagram',
  ma_land: 'emp_land',
  ma_linkedin: 'emp_linkedin',
  ma_mobil: 'emp_mobil',
  ma_ms_teams: 'emp_ms_teams',
  ma_nachname: 'emp_lastname',
  ma_office: 'emp_office',
  ma_ort: 'emp_city',
  ma_out_of_office: 'emp_out_of_office',
  ma_pos_extern: 'emp_pos_extern',
  ma_pos_intern: 'emp_pos_intern',
  ma_position: 'emp_position',
  ma_postal_code: 'emp_postal_code',
  ma_skype: 'emp_skype',
  ma_strasse: 'emp_street',
  ma_team: 'emp_team',
  ma_tel: 'emp_phone',
  ma_tel2: 'emp_phone2',
  ma_titel: 'emp_titel',
  ma_twitter: 'emp_twitter',
  ma_voip: 'emp_voip',
  ma_vorname: 'emp_firstname',
  ma_website_2: 'emp_website_2',
  ma_website: 'emp_website',
  ma_xing: 'emp_xing',
  placeholder_branche1_1: 'placeholder_branche1_1',
  placeholder_branche1_2: 'placeholder_branche1_2',
  placeholder_branche1_3: 'placeholder_branche1_3',
  placeholder_branche2_1: 'placeholder_branche2_1',
  placeholder_branche2_2: 'placeholder_branche2_2',
  placeholder_branche2_3: 'placeholder_branche2_3'
};

export const COMPANY_FIELDS_MAPPING = {
  c_add_info_1: 'c_add_info_1',
  c_add_info_2: 'c_add_info_2',
  c_add_info_3: 'c_add_info_3',
  c_add_info_4: 'c_add_info_4',
  c_add_info_5: 'c_add_info_5',
  c_add_info_6: 'c_add_info_6',
  c_office: 'c_office',
  c_state: 'c_state',
  c_website2: 'c_website_2',
  c_zip_code: 'c_postal_code',
  u_blog: 'c_blog',
  u_country: 'c_country',
  u_disclaimer: 'c_disclaimer',
  u_fax: 'c_fax',
  u_fb: 'c_fb',
  u_googlep: 'c_googlep',
  u_instagram: 'c_instagram',
  u_linkedin: 'c_linkedin',
  u_logo: 'c_logo',
  u_name: 'c_name',
  u_ort: 'c_city',
  u_pinterest: 'c_pinterest',
  u_snapc: 'c_snapc',
  u_strasse: 'c_street',
  u_tel: 'c_phone',
  u_twitter: 'c_twitter',
  u_website: 'c_website',
  u_whatsapp: 'c_whatsapp',
  u_xing: 'c_xing',
  u_youtube: 'c_youtube'
};

export const SIGNATURE_FIELDS_MAPPING = {
  sig_grafik: 'sig_grafik'
};

/**
 * Signature mapping fields used in toolbar details to show/hide & lock/unlock information fields
 */
export const SIGNATURE_MAPPING_FIELDS = {
  ma_abteilung: 'ma_abteilung',
  ma_add_info_1: 'ma_add_info_1',
  ma_add_info_2: 'ma_add_info_2',
  ma_add_info_3: 'ma_add_info_3',
  ma_add_info_4: 'ma_add_info_4',
  ma_akad_grad: 'ma_akad_grad',
  ma_bundesland: 'ma_bundesland',
  ma_disclaimer: 'ma_disclaimer',
  ma_email: 'ma_email',
  ma_fax: 'ma_fax',
  ma_fb: 'ma_fb',
  ma_firma: 'ma_firma',
  ma_foto: 'ma_foto',
  ma_googlep: 'ma_googlep',
  ma_grussformel: 'ma_grussformel',
  ma_instagram: 'ma_instagram',
  ma_land: 'ma_land',
  ma_linkedin: 'ma_linkedin',
  ma_mobil: 'ma_mobil',
  ma_ms_teams: 'ma_ms_teams',
  ma_nachname: 'ma_nachname',
  ma_office: 'ma_office',
  ma_ort: 'ma_ort',
  ma_out_of_office: 'ma_out_of_office',
  ma_pos_extern: 'ma_pos_extern',
  ma_pos_intern: 'ma_pos_intern',
  ma_position: 'ma_position',
  ma_postal_code: 'ma_postal_code',
  ma_skype: 'ma_skype',
  ma_strasse: 'ma_strasse',
  ma_team: 'ma_team',
  ma_tel: 'ma_tel',
  ma_tel2: 'ma_tel2',
  ma_titel: 'ma_titel',
  ma_twitter: 'ma_twitter',
  ma_voip: 'ma_voip',
  ma_vorname: 'ma_vorname',
  ma_website_2: 'ma_website_2',
  ma_website: 'ma_website',
  ma_xing: 'ma_xing',
  placeholder_branche1_1: 'placeholder_branche1_1',
  placeholder_branche1_2: 'placeholder_branche1_2',
  placeholder_branche1_3: 'placeholder_branche1_3',
  placeholder_branche2_1: 'placeholder_branche2_1',
  placeholder_branche2_2: 'placeholder_branche2_2',
  placeholder_branche2_3: 'placeholder_branche2_3',

  sig_grafik: 'sig_grafik',

  c_add_info_1: 'c_add_info_1',
  c_add_info_2: 'c_add_info_2',
  c_add_info_3: 'c_add_info_3',
  c_add_info_4: 'c_add_info_4',
  c_add_info_5: 'c_add_info_5',
  c_add_info_6: 'c_add_info_6',
  c_office: 'c_office',
  c_state: 'c_state',
  c_website2: 'c_website2',
  c_zip_code: 'c_zip_code',
  u_blog: 'u_blog',
  u_country: 'u_country',
  u_disclaimer: 'u_disclaimer',
  u_fax: 'u_fax',
  u_fb: 'u_fb',
  u_googlep: 'u_googlep',
  u_instagram: 'u_instagram',
  u_linkedin: 'u_linkedin',
  u_logo: 'u_logo',
  u_name: 'u_name',
  u_ort: 'u_ort',
  u_pinterest: 'u_pinterest',
  u_snapc: 'u_snapc',
  u_strasse: 'u_strasse',
  u_tel: 'u_tel',
  u_twitter: 'u_twitter',
  u_website: 'u_website',
  u_whatsapp: 'u_whatsapp',
  u_xing: 'u_xing',
  u_youtube: 'u_youtube'
};

/**
 * Common value for SIGNATURE_INPUTS
 */
const SIGNATURE_INPUTS_SETTINGS = {
  locked: false,
  disabled: false
};

/**
 * Signature information fields used to show/hide or lock/unlock the fields
 */
export const SIGNATURE_INPUTS = {
  company: {
    c_add_info_1: { ...SIGNATURE_INPUTS_SETTINGS },
    c_add_info_2: { ...SIGNATURE_INPUTS_SETTINGS },
    c_add_info_3: { ...SIGNATURE_INPUTS_SETTINGS },
    c_add_info_4: { ...SIGNATURE_INPUTS_SETTINGS },
    c_add_info_5: { ...SIGNATURE_INPUTS_SETTINGS },
    c_add_info_6: { ...SIGNATURE_INPUTS_SETTINGS },
    c_office: { ...SIGNATURE_INPUTS_SETTINGS },
    c_state: { ...SIGNATURE_INPUTS_SETTINGS },
    c_website2: { ...SIGNATURE_INPUTS_SETTINGS },
    c_zip_code: { ...SIGNATURE_INPUTS_SETTINGS },
    u_blog: { ...SIGNATURE_INPUTS_SETTINGS },
    u_country: { ...SIGNATURE_INPUTS_SETTINGS },
    u_disclaimer: { ...SIGNATURE_INPUTS_SETTINGS },
    u_fax: { ...SIGNATURE_INPUTS_SETTINGS },
    u_fb: { ...SIGNATURE_INPUTS_SETTINGS },
    u_googlep: { ...SIGNATURE_INPUTS_SETTINGS },
    u_instagram: { ...SIGNATURE_INPUTS_SETTINGS },
    u_linkedin: { ...SIGNATURE_INPUTS_SETTINGS },
    u_logo: { ...SIGNATURE_INPUTS_SETTINGS },
    u_name: { ...SIGNATURE_INPUTS_SETTINGS },
    u_ort: { ...SIGNATURE_INPUTS_SETTINGS },
    u_pinterest: { ...SIGNATURE_INPUTS_SETTINGS },
    u_snapc: { ...SIGNATURE_INPUTS_SETTINGS },
    u_strasse: { ...SIGNATURE_INPUTS_SETTINGS },
    u_tel: { ...SIGNATURE_INPUTS_SETTINGS },
    u_twitter: { ...SIGNATURE_INPUTS_SETTINGS },
    u_website: { ...SIGNATURE_INPUTS_SETTINGS },
    u_whatsapp: { ...SIGNATURE_INPUTS_SETTINGS },
    u_xing: { ...SIGNATURE_INPUTS_SETTINGS },
    u_youtube: SIGNATURE_INPUTS_SETTINGS
  },
  employee: {
    ma_abteilung: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_add_info_1: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_add_info_2: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_add_info_3: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_add_info_4: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_akad_grad: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_bundesland: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_disclaimer: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_email: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_fax: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_fb: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_firma: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_foto: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_googlep: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_grussformel: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_instagram: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_land: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_linkedin: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_mobil: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_ms_teams: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_nachname: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_office: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_ort: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_out_of_office: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_pos_extern: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_pos_intern: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_position: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_postal_code: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_skype: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_strasse: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_team: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_tel: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_tel2: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_titel: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_twitter: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_voip: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_vorname: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_website_2: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_website: { ...SIGNATURE_INPUTS_SETTINGS },
    ma_xing: { ...SIGNATURE_INPUTS_SETTINGS },
    placeholder_branche1_1: { ...SIGNATURE_INPUTS_SETTINGS },
    placeholder_branche1_2: { ...SIGNATURE_INPUTS_SETTINGS },
    placeholder_branche1_3: { ...SIGNATURE_INPUTS_SETTINGS },
    placeholder_branche2_1: { ...SIGNATURE_INPUTS_SETTINGS },
    placeholder_branche2_2: { ...SIGNATURE_INPUTS_SETTINGS },
    placeholder_branche2_3: { ...SIGNATURE_INPUTS_SETTINGS }
  },
  information: {
    sig_grafik: { ...SIGNATURE_INPUTS_SETTINGS }
  }
};
