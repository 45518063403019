<div *ngFor="let item of installationData">
  <div
    class="{{ item.class }}"
    [ngClass]="{
      martop60: !item.class
    }"
  >
    <!-- For mobile devices step 1 -->
    <span
      *ngIf="user"
      [innerHTML]="item.tag | transloco : { value: user.email }"
    ></span>

    <!-- For other mail client -->
    <span *ngIf="!user" [innerHTML]="item.tag | transloco"></span>

    <!-- For mobile devices -->
    <div *ngIf="user">
      <!-- Show the plain text if using an Android mobile device. -->
      <div *ngIf="item.buttonType === 'send_plain_text'">
        <textarea
          [(ngModel)]="signature.signatureTxt"
          class="plain-text-preview"
          disabled
        >
        </textarea>
      </div>
      <!-- Show the signature template if using an IOS mobile device. -->
      <div
        *ngIf="item.buttonType === 'mobile_iphone_btn'"
        class="padbottom15 wizard-step"
      >
        <div class="form-group signature-preview">
          <div
            class="preview-info preview-scroll"
            transloco="signature_preview_scroll"
          ></div>
          <mt-signature-rollout-preview
            [signature]="signature"
          ></mt-signature-rollout-preview>
        </div>
      </div>
      <!-- Button to send the plain text or template. -->
      <button
        *ngIf="
          item.buttonType === 'send_plain_text' ||
          item.buttonType === 'mobile_iphone_btn'
        "
        color="primary"
        class="send-signature-btn mt-button-primary"
        type="button"
      >
        <span
          [innerHTML]="
            'param.integration_guide_mobile_send_plaintext_btn'
              | transloco : { value: user.email }
          "
          (click)="sendSignatureInEmail(item.buttonType)"
        ></span>
      </button>
      <!-- Note for email -->
      <p *ngIf="item.buttonType" transloco="integration_guide_mobile_note"></p>
    </div>

    <!-- For other devices -->
    <div class="martop20" *ngIf="item.buttonType === 'easysync_download'">
      <a href="{{ installationData[0].tag | getDownloadLink : isCogsig }}">
        <mt-button
          text="dleasysync"
          color="primary"
          icon="download-alt"
        ></mt-button>
      </a>
    </div>
    <div *ngIf="item.tag === 'html.html_direction_1_0'">
      <textarea
        *ngIf="copiedSignatureHTML$ | async as html"
        class="w575 marbot20"
        rows="8"
        cols="50"
        disabled="true"
      >
      </textarea>
    </div>

    <mt-button
      *ngIf="item.buttonType === 'copy_html'"
      class="copy-html-btn"
      text="general_step1_button_1_0"
      color="primary"
      (click)="copySignatureHTML(item.tag)"
    ></mt-button>

    <mt-button
      *ngIf="item.buttonType === 'outlook_addin'"
      text="outlook_outlook_addin_button_1"
      color="primary"
      (click)="
        alert.defaultInfoMessage(
          alert.translate('int_outlook_addin_modal_txt'),
          alert.translate('int_outlook_addin_modal_hl')
        )
      "
    ></mt-button>

    <div class="overflowxscroll">
      <img
        class="mt-2"
        [ngClass]="{ marbot20: item.buttonType === 'done' }"
        *ngIf="item.image"
        src="{{ item.image | transloco }}"
      />
    </div>

    <h6 *ngIf="item.buttonType === 'done'" transloco="Done"></h6>

    <div *ngIf="item.tag === 'html.outlookmac_easysync_direction_3_0_subpart'">
      <div #optionList>
        <div class="martop25">
          <select
            class="exampleempselect input-sm leftbutton"
            (change)="changeMacType($event)"
          >
            <option
              class="font16"
              *ngFor="let mac of macData.macTypes"
              [value]="mac.value"
              [selected]="mac.value === 'Choose'"
              transloco="{{ mac.value }}"
            ></option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectedMacOption$ | async as macType">
  <mt-installation-guide-steps
    [installationData]="
      macType === 'rights_catalina'
        ? macData.outlookMacEacysyncCatalina
        : macData.outlookMacEacysyncMojaveBigsur
    "
  ></mt-installation-guide-steps>
</div>
