<div (click)="$event.stopPropagation()">
  <img
    src="assets/images/common/gatedContentIconBackground.png"
    class="mt-gated-content-icon-background"
    *ngIf="isSection"
  />

  <mt-svg
    (click)="openGatedContentInfoModal()"
    [ngbTooltip]="htmlContent"
    placement="top"
    #iconTooltip="ngbTooltip"
    [autoClose]="true"
    triggers="manual"
    (mouseenter)="iconTooltip.open()"
    tooltipClass="mt-gated-content-icon-tooltip-wrapper"
    src="assets/images/common/gatedContentIconAnimated.gif"
    [ngClass]="isSection ? 'mt-gated-content-icon-lg' : 'mt-gated-content-icon'"
    [class]="isSection ? 'position-absolute' : ''"
  ></mt-svg>
</div>

<ng-template #htmlContent>
  <div class="container" (mouseleave)="iconTooltip.close()">
    <div class="row" style="margin: 0">
      <div class="col-xs-4" style="padding-right: 0; padding-left: 10px">
        <img src="assets/images/common/gatedContentMarketer.png" />
      </div>
      <div
        class="col-xs-8 text-left"
        style="padding-top: 5px; padding-right: 0; padding-left: 15px"
      >
        <p transloco="campaign_banner_tooltip_promo"></p>
        <p
          (click)="openGatedContentInfoModal()"
          class="mt-gated-content-icon-tooltip-wrapper_link"
          transloco="find_out_more"
        ></p>
      </div>
    </div>
  </div>
</ng-template>
