import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Handlebars from 'handlebars';
import { map } from 'rxjs/operators';
import { TemplateEntry } from 'src/app/model/template.entry';
import { DummyData } from 'src/app/model/interfaces/signature-data.interface';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all available templates/
   */
  getMany(): Observable<TemplateEntry[]> {
    const result: Observable<any> = this.http.get('/assets/resources/templates.json');
    return result.pipe(map(({ entries }) => entries));
  }

  /**
   * Gets the dummy data to fill the missing properties.
   */
  getDummyInfo(): Observable<DummyData> {
    return this.http.get<DummyData>('/assets/resources/dummy.json');
  }

  /**
   * Gets the template code based on the template key provided.
   * @param key Unique template identifier.
   */
  getTemplateCode(key: string): Observable<string> {
    return this.http.get(`/assets/resources/templates/template_${key}.html`, {
      responseType: 'text'
    });
  }

  /**
   * Gets the compiled code based on template and data provided. At this moment only handlebars is supported.
   * @param key Unique template identifier.
   * @param data Data to apply for the template rendering.
   */
  getTemplateCompiledHtml(key: string, data: any): Observable<string> {
    const code$ = this.getTemplateCode(key);
    const dummy$ = this.getDummyInfo();

    const combined$ = combineLatest(code$, dummy$);

    return combined$.pipe(
      map(([tpl, dummy]) => {
        const params = Object.assign({}, dummy, data);
        const template = Handlebars.compile(tpl);

        return template(params);
      })
    );
  }
}
