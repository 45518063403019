import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard/auth.guard';
import { CanActiveCogsigRouteGuard } from './guards/cogsig-guard/can-active-cogsig-route.guard';
import { GuideTourGuard } from './guards/guide-tour/guide-tour.guard';
import { CanActiveMailtasticRouteGuard } from './guards/mailtastic-guard/can-active-mailtastic-route.guard';
import { SubscriptionGuard } from './guards/subscription-guard/subscription.guard';
import { NavigationSidebarComponent } from './modules/root/components/navigation-sidebar/navigation-sidebar/navigation-sidebar.component';

// Lazy load each module and handle module's internal routes on its router
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    redirectTo: 'dashboard', // Prevents broken page from being shown if user types /overview
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'account',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'endusercockpit',
    loadChildren: () => import('./modules/enduser-cockpit/enduser-cockpit.module').then(m => m.EnduserCockpitModule)
  },
  {
    path: '',
    component: NavigationSidebarComponent,
    children: [
      {
        path: 'activation/employee',
        canActivateChild: [AuthGuard],
        redirectTo: 'dashboard'
        // TODO create component
      },
      {
        path: 'basic',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('./modules/basic-integrations/basic-integrations.module').then(m => m.BasicIntegrationsModule)
      },
      {
        path: 'booking',
        canActivateChild: [AuthGuard, CanActiveCogsigRouteGuard],
        loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: 'campaigns',
        canActivateChild: [AuthGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule)
      },
      {
        path: 'chrome',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('./modules/chrome-extension/chrome-extension.module').then(m => m.ChromeExtensionModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dataprofiles',
        canActivateChild: [AuthGuard, CanActiveCogsigRouteGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/data-profiles/data-profiles.module').then(m => m.DataProfilesModule)
      },
      {
        path: 'easysync',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/easy-sync/easy-sync.module').then(m => m.EasySyncModule)
      },
      {
        path: 'employees',
        canActivateChild: [AuthGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/employees/employees.module').then(m => m.EmployeesModule)
      },
      {
        path: 'events',
        canActivateChild: [AuthGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'getstartedguide',
        canActivateChild: [AuthGuard, GuideTourGuard],
        loadChildren: () =>
          import('./modules/get-started-guide/get-started-guide.module').then(m => m.GetStartedGuideModule)
      },
      {
        path: 'gmail',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/gmail/gmail.module').then(m => m.GmailModule)
      },
      {
        path: 'googlesync',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/google-workspace/gsuite.module').then(m => m.GSuiteModule)
      },
      {
        path: 'groups',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/groups/groups.module').then(m => m.GroupsModule)
      },
      {
        path: 'hubspot',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/hubspot/hubspot.module').then(m => m.HubspotModule)
      },
      {
        path: 'integrations',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/integrations/integrations.module').then(m => m.IntegrationsModule)
      },
      {
        path: 'productchanges',
        canActivateChild: [AuthGuard],
        redirectTo: 'dashboard'
        // TODO create component
      },
      {
        path: 'recipients',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/recipients/recipients.module').then(m => m.RecipientsModule)
      },
      {
        path: 'leads',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/leads/leads.module').then(m => m.LeadsModule)
      },
      {
        path: 'marketo',
        canActivateChild: [AuthGuard, CanActiveCogsigRouteGuard],
        loadChildren: () => import('./modules/marketo/marketo.module').then(m => m.MarketoModule)
      },
      {
        path: 'mtapi',
        canActivateChild: [AuthGuard, CanActiveCogsigRouteGuard],
        loadChildren: () => import('./modules/mailtastic-api/mailtastic-api.module').then(m => m.MailtasticApiModule)
      },
      {
        path: 'notifications',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'o365',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/o365/o365.module').then(m => m.O365Module)
      },
      {
        path: 'outlook',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/outlook/outlook.module').then(m => m.OutlookModule)
      },
      {
        path: 'pardot',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/pardot/pardot.module').then(m => m.PardotModule)
      },
      {
        path: 'performancefeed',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('./modules/performance-feed/performance-feed.module').then(m => m.PerformanceFeedModule)
      },
      {
        path: 'productchanges',
        canActivateChild: [AuthGuard],
        redirectTo: 'dashboard'
        // TODO create component
      },
      {
        path: 'recipients',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/recipients/recipients.module').then(m => m.RecipientsModule)
      },
      {
        path: 'rerouting',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/o365-rerouting/o365-rerouting.module').then(m => m.O365ReroutingModule)
      },
      {
        path: 'settings',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'signatures',
        canActivateChild: [AuthGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/signatures/signature.module').then(m => m.SignatureModule)
      },
      {
        path: 'targetgroups',
        canActivateChild: [AuthGuard, CanActiveCogsigRouteGuard, SubscriptionGuard],
        loadChildren: () => import('./modules/target-groups/target-groups.module').then(m => m.TargetGroupsModule)
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 800] // cool option, or ideal option when you have a fixed header on top.
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
