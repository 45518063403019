<li
  class="listrow mb-1"
  [ngClass]="{
    even: isEven,
    odd: isOdd,
    redrow: isRedRow,
    selected: isSelected,
    timingerror: rowError
  }"
>
  <ng-content></ng-content>
</li>
