<mt-custom-modal
  class="alertSetupAccountModal"
  headline="{{ 'welcome_to_sig_gen_hl' | transloco }}"
  [closeBtn]="false"
>
  <ng-container modal-body>
    <div [innerHTML]="'html.welcome_to_sig_gen_copy' | transloco"></div>
  </ng-container>
  <ng-container modal-footer>
    <mt-button
      (click)="modal.close(true)"
      color="primary"
      text="welcome_to_sig_gen_btn"
    >
    </mt-button>
  </ng-container>
</mt-custom-modal>
