<div class="container-fluid">
  <div class="row">
    <div class="col-4 img-testimonial">
      <img
        src="assets/images/login/testimonials/welcome_modal_user.png"
        alt="welcome_user"
        class="img-responsive fit-image"
      />
    </div>
    <div class="col-8" *transloco="let translate">
      <div class="container">
        <div class="header">
          <span
            class="welcome-text"
            [innerHTML]="
              'param.whyareyouherefor_hl'
                | transloco: { value: accountData.firstName }
            "
          ></span>
          <form [formGroup]="welcomeForm">
            <div class="guide-text">{{ translate('whyareyouherefor_sl') }}</div>
            <div class="selection-div martop20">
              <div class="text-bold">
                {{ translate('whyareyouherefor_q1_hl') }}
              </div>
              <div>{{ translate('whyareyouherefor_q1_sl') }}</div>
            </div>
            <div class="option-design">
              <div class="form-check">
                <ng-container
                  *ngIf="createSignatureFlag$ | async"
                ></ng-container>
                <input
                  type="checkbox"
                  formControlName="create_email_signature"
                  class="form-check-input"
                  id="1"
                />
                <span>{{ translate('whyareyouherefor_q1_option_1') }}</span>
              </div>
            </div>
            <div class="option-design">
              <div class="form-check">
                <ng-container
                  *ngIf="manageEmailForCompanyFlag$ | async"
                ></ng-container>
                <input
                  type="checkbox"
                  formControlName="manage_email_for_company"
                  class="form-check-input"
                  id="2"
                />
                <span>{{ translate('whyareyouherefor_q1_option_2') }}</span>
              </div>
            </div>
            <div class="option-design">
              <div class="form-check">
                <ng-container
                  *ngIf="marketingBannerEmailSignature$ | async"
                ></ng-container>
                <input
                  type="checkbox"
                  formControlName="marketing_banner_email_signature"
                  class="form-check-input"
                  id="3"
                />
                <span class="form-check-span" for="3"
                  >{{ translate('whyareyouherefor_q1_option_3') }}</span
                >
              </div>
            </div>
            <div class="option-design">
              <div class="form-check">
                <ng-container
                  *ngIf="analyseMarketCampaign$ | async"
                ></ng-container>
                <input
                  type="checkbox"
                  formControlName="analyse_market_campaign"
                  class="form-check-input"
                  id="4"
                />
                <span class="form-check-span" for="4"
                  >{{ translate('whyareyouherefor_q1_option_4') }}</span
                >
              </div>
            </div>
            <!-- Other Option -->
            <div class="option-design">
              <div class="form-check">
                <ng-container *ngIf="other_option_Flag$ | async"></ng-container>
                <span class="form-check-span" for="5"
                  >{{ translate('whyareyouherefor_q1_option_5') }}
                  <input
                    type="checkbox"
                    class="form-check-input"
                    formControlName="other_option"
                    id="5"
                /></span>
              </div>
            </div>
            <div *ngIf="isOptionSelected" class="text-danger">
              {{ translate('select_option_required') }}
            </div>

            <div *ngIf="showTextField">
              <ng-container *ngIf="otherDescription$ | async"></ng-container>
              <input
                formControlName="otherText"
                class="input-width"
                type="text"
              />
            </div>
            <div *ngIf="optionTextFlag" class="text-danger">
              {{ translate('enter_text_required') }}
            </div>
            <div class="selection-div martop20">
              <div>{{ translate('whyareyouherefor_q2_hl') }}</div>
            </div>
            <div class="flex topnavrow">
              <ng-container *ngIf="otherRole$ | async"></ng-container>
              <select
                class="form-control example-empselect input-sm select-roles"
                formControlName="role"
                aria-placeholder="Krunal"
              >
                <option selected value="" disabled>
                  {{ translate('whyareyouherefor_q2_option_1') }}
                </option>
                <option
                  *ngFor="let item of roleSection"
                  value="{{ item.translateKey }}"
                >
                  <span>{{ translate(item.translateKey) }}</span>
                </option>
              </select>
            </div>
            <div *ngIf="roleSelected" class="text-danger">
              {{ translate('select_role_required') }}
            </div>
            <div *ngIf="showRoleText" class="martop10">
              <ng-container
                *ngIf="otherRoleDescription$ | async"
              ></ng-container>
              <input
                formControlName="otherRoleText"
                class="input-width"
                type="text"
              />
            </div>
            <div *ngIf="roleTextFlag" class="text-danger">
              {{ translate('enter_text_required') }}
            </div>
          </form>
          <div class="padtop20">
            <mt-button
              color="primary"
              text="whyareyouherefor_btn"
              (click)="closeModal()"
            >
            </mt-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
