import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChooseCreateTypeModalData } from 'src/app/model/interfaces/event.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'select-creation-modal',
  templateUrl: './select-creation.component.html',
  styleUrls: ['./select-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CustomModalComponent, NgIf, NgClass, TranslocoModule, RouterLink, AsyncPipe, ButtonComponent]
})
export class SelectCreationModalComponent {
  /**
   * Determines the counter of submenu
   * @defaultValue {
   * groupData: [],
   * title: '',
   * textSender: '',
   * textTarget: '',
   * linkText: '',
   * routeSender: '',
   * routeTarget: '',
   * link: ''
   *}
   */
  @Input() inputData: ChooseCreateTypeModalData = {
    groupData: [],
    title: '',
    textSender: '',
    textTarget: '',
    linkText: '',
    routeSender: '',
    routeTarget: '',
    link: ''
  };

  constructor(public authService: AuthService, public modal: NgbActiveModal, private router: Router) {}

  /**
   * Used when create event
   * @param url - The url to redirect
   */
  openUrl(url: string): void {
    // While create event from group details
    if (this.inputData.groupData && this.inputData.groupData.length > 0) {
      void this.router.navigate([url], { state: { groupData: this.inputData.groupData } });
    } else {
      void this.router.navigate([url]);
    }
  }
}
