import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem
} from '@ng-bootstrap/ng-bootstrap';
import { Bounds, CropperSettings, ImageCropperComponent, ImageCropperModule } from 'ngx-img-cropper';
import { ISignaturePlaceholderImg } from 'src/app/model/interfaces/signature.interface';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    ImageCropperModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    TranslocoModule
  ]
})
export class ImageCropperModalComponent implements OnInit {
  // default sizes for crop area
  CROP_RANGE = {
    min: 80,
    max: 300
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any; // holds cropped changes // natively set as such in ngx-img-cropper

  cropperSettings: CropperSettings;

  cropManualSize = 200;

  rangeValue = 50;

  @Input() displayImage!: HTMLImageElement;
  @Input() imageData!: ISignaturePlaceholderImg;

  @ViewChild('cropper')
  imageCropperComponent!: ImageCropperComponent;

  constructor(public modal: NgbActiveModal) {
    this.cropperSettings = this.getInitialCropperSettings();

    this.data = {}; // holds cropped changes
  }

  //#region LIFE CYCLE
  ngOnInit(): void {
    this.loadImage();
  }
  //#endregion

  //#region PUBLIC
  /**
   * Crops and saves the image.
   * @param useCrop - should crop
   */
  cropAndSave(useCrop: boolean): void {
    let cropImageData: ISignaturePlaceholderImg;

    if (useCrop) {
      const data = {
        value: {
          cropType: this.cropperSettings.rounded ? 'circle' : 'square',
          isCropped: useCrop,
          image: {
            // eslint-disable-next-line
            $ngfDataUrl: this.data.image
          }
        }
      } as ISignaturePlaceholderImg;

      cropImageData = Object.assign(this.imageData ?? {}, data);
    } else {
      cropImageData = Object.assign(this.imageData ?? {}, {
        value: {
          isCropped: useCrop,
          cropType: undefined,
          image: {
            // eslint-disable-next-line
            $ngfDataUrl: this.data.original.src
          }
        }
      } as ISignaturePlaceholderImg);
    }

    this.modal.close(cropImageData);
  }

  /**
   * Sets the crop selection to either square or circle.
   * @param type
   */
  cropSelection(type: 'square' | 'circle'): void {
    this.cropperSettings.rounded = type === 'circle';
    this.loadImage();
  }

  /**
   * Gets the translate tag for the dropdown.
   * @returns translate tag
   */
  getCropTitle(): string {
    if (this.cropperSettings.rounded) {
      return 'circle_image_section';
    } else {
      return 'square_image_section';
    }
  }

  /**
   * Loads the image.
   */
  loadImage(): void {
    if (this.displayImage) {
      setTimeout(() => {
        this.imageCropperComponent.reset();
        this.imageCropperComponent.setImage(this.displayImage);
        this.CROP_RANGE.max = this.getCanvasSize();
      });
    }
  }

  /**
   * Adjusts the range value in correlation to the cropped window.
   * @param bounds
   */
  onChangeCrop(bounds: Bounds): void {
    const size = bounds.bottom - bounds.top;

    // adjusts the rangeValue by moving crop handles
    this.rangeValue = this.getRangeValue(size);
  }

  /**
   * Resize the final cropped image.
   * @param ev
   */
  onChangeManualSize(ev: Event): void {
    const size = Number((ev.target as HTMLInputElement).value);

    this.cropperSettings.croppedWidth = size;
    this.cropperSettings.croppedHeight = size;

    // TODO figure out how to save image size
  }

  /**
   * Rescales the cropper window depending on the range percentage.
   * @param ev input range event
   */
  onChangeRange(ev: Event): void {
    const range = Number((ev.target as HTMLInputElement).value);
    const cropSize = this.getCropSize(range);

    const b = this.imageCropperComponent.cropper.getCropBounds();
    const p = this.getRescaleBounds(b, cropSize);

    this.imageCropperComponent.cropper.setBounds(p);
    this.imageCropperComponent.cropper.updateCropPosition(p);
  }
  //#endregion

  //#region PRIVATE
  /**
   * Gets the canvas size.
   * @returns {number}
   */
  private getCanvasSize() {
    return Math.min(this.displayImage.height, this.displayImage.width);
  }

  /**
   * Gets the width or height for the crop window.
   * @private
   * @param rangeValue range.value
   */
  private getCropSize(rangeValue: number) {
    return Math.floor((rangeValue / 100) * (this.CROP_RANGE.max - this.CROP_RANGE.min) + this.CROP_RANGE.min);
  }

  /**
   * Gets the initial cropper settings.
   * @returns
   */
  private getInitialCropperSettings(): CropperSettings {
    const initialSettings = new CropperSettings();
    initialSettings.noFileInput = true;

    // sets the smallest crop window
    initialSettings.minWidth = this.CROP_RANGE.min;
    initialSettings.minHeight = this.CROP_RANGE.min;

    // sets the default crop size
    initialSettings.croppedHeight = this.cropManualSize;
    initialSettings.croppedWidth = this.cropManualSize;

    initialSettings.canvasWidth = 540;

    return initialSettings;
  }

  /**
   * Gets the percentage from the crop window.
   * @private
   * @param cropSize cropHeight|cropWidth
   */
  private getRangeValue(cropSize: number) {
    return Math.floor((100 * (cropSize - this.CROP_RANGE.min)) / (this.CROP_RANGE.max - this.CROP_RANGE.min));
  }

  /**
   * Gets the new bounds for the crop window.
   * @param init current bounds
   * @param chosenSize height|width size
   * @returns rescaled bounds
   */
  private getRescaleBounds(init: Bounds, chosenSize: number): Bounds {
    const initSize = init.bottom - init.top;

    const centerPos = {
      x: init.left + Math.floor(initSize / 2),
      y: init.top + Math.floor(initSize / 2)
    };

    const radius = Math.floor(chosenSize / 2);

    return new Bounds(centerPos.x - radius, centerPos.y - radius, chosenSize, chosenSize);
  }
  //#endregion
}
