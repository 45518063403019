<ng-container *ngIf="selectedCampaign$ | async"></ng-container>
<div id="linegraphPanel" class="panel full-width line-graph-panel">
  <div class="panel-heading">
    <h5 transloco="{{ lineTitle }}"></h5>

    <select
      *ngIf="!this.campaignId && !this.targetGroupEntryId"
      class="form-control selectItem"
      [formControl]="selectedCampaign"
    >
      <option value="Active" transloco="Activecampaigns"></option>
      <option value="All" transloco="Allcampaigns"></option>
      <ng-container *ngFor="let item of allCampaigns">
        <option value="{{ item.title }}">{{ item.title }}</option>
      </ng-container>
    </select>

    <div class="btn-group middle" role="group" aria-label="...">
      <button
        type="button"
        (click)="changeLineChartMode('views', selectedCampaign.value)"
        [ngClass]="{
          darkbutton: lineChartOptions.typeOfSelectedData === 'views'
        }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_impressions"
      ></button>
      <button
        type="button"
        (click)="changeLineChartMode('clicks', selectedCampaign.value)"
        [ngClass]="{
          darkbutton: lineChartOptions.typeOfSelectedData === 'clicks'
        }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_clicks"
      ></button>
      <button
        type="button"
        (click)="changeLineChartMode('clickRate', selectedCampaign.value)"
        [ngClass]="{
          darkbutton: lineChartOptions.typeOfSelectedData === 'clickRate'
        }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_rate"
      ></button>
    </div>

    <div class="form-group has-feedback daterange-wrapper down form-inline">
      <div class="form-group">
        <!-- Date picker -->
        <!-- TODO: add localization support -->
        <div class="input-group">
          <input
            readonly
            name="calendar"
            class="calendar cursor-pointer"
            ngbDatepicker
            #calendar="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event, selectedCampaign.value)"
            [displayMonths]="2"
            [dayTemplate]="tooltipRange"
            outsideDays="hidden"
            value="{{ dateRange }}"
            [startDate]="fromDate!"
            (click)="calendar.toggle()"
          />
          <!-- Range tooltip -->
          <ng-template #tooltipRange let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
          <div class="input-group-append">
            <i class="glyphicon glyphicon-calendar form-control-feedback"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix main-section">
    <!-- TODO: amount of selected statistics-->
    <div class="linegraphdisplay">
      <div
        *ngIf="lineChartOptions.typeOfSelectedData !== 'clickRate'"
        class="count"
        placement="top"
        ngbTooltip="{{ lineChartOptions.totalShown | number : '1.0-0' }}"
      >
        {{ lineChartOptions.totalShown | shortNumber }}
      </div>
      <div
        *ngIf="lineChartOptions.typeOfSelectedData === 'clickRate'"
        class="count"
      >
        {{ lineChartOptions.totalShown }} %
      </div>

      <div
        *ngIf="lineChartOptions.typeOfSelectedData === 'views'"
        class="countlabel"
        transloco="sort_impressions"
      ></div>
      <div
        *ngIf="lineChartOptions.typeOfSelectedData === 'clicks'"
        class="countlabel"
        transloco="sort_clicks"
      ></div>
      <div
        *ngIf="lineChartOptions.typeOfSelectedData === 'clickRate'"
        class="countlabel"
        transloco="sort_rate"
      ></div>
    </div>

    <ngx-charts-line-chart
      [customColors]="lineChartOptions.displayedColors"
      [results]="lineChartOptions.dataToShow"
      [gradient]="false"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="true"
      [legendPosition]="lineChartOptions.legendPosition"
      [legendTitle]="''"
      (select)="onSelectLegend($event)"
      [showGridLines]="true"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxisTickFormatting]="xTickFormatting"
      [animations]="true"
      [roundDomains]="true"
      [curve]="lineChartOptions.curve"
      style="fill: #ffffff"
      #lineChart
    >
      <!-- Tooltips -->
      <ng-template #tooltipTemplate let-model="model">
        <div style="text-align: left">
          <ng-container *ngFor="let data of lineChartOptions.dataToShow">
            <ng-container *ngIf="data.name === model.series">
              <ng-container *ngFor="let seriesData of data.series">
                <ng-container *ngIf="seriesData.name === model.name">
                  <!-- TODO: add more data here -->
                  <span><b>{{ seriesData.extra.label }}</b></span>
                  <br />
                  <span>{{ data.name }}</span>
                  <br />
                  <!-- <div class="line"></div> -->
                  <br />
                  {{ 'sort_impressions' | transloco }}: {{
                  seriesData.extra.impressions }}
                  <br />
                  {{ 'sort_clicks' | transloco }}: {{ seriesData.extra.clicks }}
                  <br />
                  {{ 'sort_rate' | transloco }}: {{ seriesData.extra.clickRate
                  }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
</div>
