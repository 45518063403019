import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { zip } from 'rxjs';

import { AlertService } from 'src/app/services/alert/alert.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';

import { IEmployeeGet } from 'src/app/services/employee/employee-service.interface';

@Component({
  selector: 'mt-add-employee-to-group',
  templateUrl: './add-employee-to-group.component.html',
  styleUrls: ['./add-employee-to-group.component.scss'],
  providers: [ListHelperService, QueryHelperService]
})
export class AddEmployeeToGroupComponent {
  assignedEmployees: IEmployeeGet[] = [];

  sortDir = false;

  isLoading = false; // Loading spinner shown when loading more data or filtering

  infinitescrolloptions = {
    disable: true,
    scrollAction: (): void => {
      this.infinitescrolloptions.disable = true;

      // Load more group members until all are loaded
      if (this.list.all.length < this.list.denominator - this.assignedEmployees.length) {
        this.loadMoreData();
      }
    }
  };

  loadData$ = this.queryHelperService.queryObject$.pipe(
    tap(() => (this.isLoading = true)),
    switchMap(queryObj => zip(this.employeeService.get(queryObj), this.employeeService.count(queryObj))),
    map(([employees, count]) => {
      // Filter out employees already assigned
      const filteredEmployees = employees.filter(
        employee => !this.assignedEmployees.some(assigned => assigned.id === employee.id)
      );
      return { employees: filteredEmployees, count };
    }),
    tap(({ employees, count }) => {
      this.list.all = this.list.all.concat(employees);
      this.list.denominator = count;
      this.list.setFiltered();
      this.list.createFraction(this.list.all.length);
      this.infinitescrolloptions.disable = false;
      this.isLoading = false;
    }),
    catchError((err: HttpErrorResponse) => {
      void this.alert.defaultErrorMessage(err.message);
      throw err;
    })
  );

  // Pipes the result from the search bar
  // and sets the search accordingly
  searchData$ = this.list.filterText$.pipe(
    tap(text => {
      this.queryHelperService.resetPagination();
      this.queryHelperService.setSearch(text);
      this.loadFirstPageData();
    }),
    shareReplay(1)
  );

  constructor(
    private alert: AlertService,
    private employeeService: EmployeeService,
    private queryHelperService: QueryHelperService,
    public list: ListHelperService<IEmployeeGet>,
    public modal: NgbActiveModal
  ) {}

  /**
   * Triggers more data to be loaded
   */
  loadMoreData(): void {
    this.queryHelperService.getNextPage();
    this.queryHelperService.setQueryObservable(this.queryHelperService.getObject());
  }

  /**
   * Refreshes the list and loads first page with the applied query changes
   */
  loadFirstPageData(): void {
    this.list.resetData();
    this.queryHelperService.setQueryObservable(this.queryHelperService.getObject());
  }

  /**
   * Closes the modal with the list of selected employees
   */
  assignEmployees(): void {
    this.modal.close(this.list.selected);
  }

  /**
   * Sorts the list of users based on the selected parameter
   * @param mode - Parameter to sort by
   */
  sortList(mode: string): void {
    this.sortDir = !this.sortDir;
    this.queryHelperService.setQuerySort(mode, this.sortDir);
    this.loadFirstPageData();
  }
}
