import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GatedContentService } from 'src/app/services/gated-content/gated-content.service';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'mt-gated-content-icon',
  templateUrl: './gated-content-icon.component.html',
  styleUrls: ['./gated-content-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MtSvgComponent, NgbTooltip, NgClass, TranslocoModule]
})
export class GatedContentIconComponent {
  /**
   * Display gate icon for the specific section
   */
  @Input() isSection!: boolean;

  constructor(private gatedContentService: GatedContentService) {}

  /**
   * Open gated content modal
   */
  openGatedContentInfoModal(): void {
    this.gatedContentService.openGatedContentInfoModal();
  }
}
