<div class="valueeditmodal urlvalue">
  <div class="modal-body">
    <div class="cursor-pointer pull-right" (click)="modal.close()">
      <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
    </div>

    <div class="modal-title">
      <span class="headlinefirst">{{ modalOptions.heading }}</span>
      <span class="headlinesecond">{{ modalOptions.subheading }}</span>
    </div>

    <div role="separator" class="line full-width-imp"></div>

    <form
      *ngIf="imageForm && signatureHelper.isImg(modalOptions.formData)"
      [formGroup]="imageForm"
    >
      <div class="form-group">
        <label transloco="NameTags"></label>
        <input
          [disabled]="true"
          formControlName="tag"
          type="text"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label transloco="targetlink"></label>
        <input formControlName="url" type="text" class="form-control" />
      </div>

      <div
        class="flex-center-left textorimage"
        *ngIf="modalOptions.formData.tag !== 'sig_grafik'"
      >
        <span class="text" transloco="Viewas"></span>
        <div class="radio">
          <label class="container-radio">
            <input formControlName="textorgraphic" value="text" type="radio" />
            <span class="checkmark-radio"></span>
            <span>Text</span>
          </label>
        </div>
        <div class="radio">
          <label class="container-radio">
            <input formControlName="textorgraphic" value="image" type="radio" />
            <span class="checkmark-radio"></span>
            <span transloco="graphic"></span>
          </label>
        </div>
      </div>

      <!-- View as: TEXT -->
      <ng-container *ngIf="imageForm.get('textorgraphic')?.value === 'text'">
        <div class="form-group">
          <label transloco="linktext"></label>
          <input formControlName="linkText" type="text" class="form-control" />
          <!-- TODO required="imageForm.get('textorgraphic')?.value === 'text'" -->
          <br />
          <!-- REMARK: this feature doesn't do anything currently and is only for show -->
          <label class="format2light" transloco="linkcolor"></label>
          <mt-color-picker
            [color]="imageForm.get('linkcolor')?.value"
            (colorChange)="setLinkColor($event)"
          ></mt-color-picker>
          <br />
          <label class="format2light" transloco="underline"></label>
          <div class="form-group">
            <div class="radio">
              <label class="container-radio">
                <input formControlName="underline" value="true" type="radio" />
                <span class="checkmark-radio"></span>
                <span transloco="underline"></span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="radio">
              <label class="container-radio">
                <input formControlName="underline" value="false" type="radio" />
                <span class="checkmark-radio"></span>
                <span transloco="no_underline"></span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- View as: IMAGE -->
      <ng-container *ngIf="imageForm.get('textorgraphic')?.value === 'image'">
        <div class="form-group">
          <label transloco="Alternativetextfordisplayerrors"></label>
          <input formControlName="altText" type="text" class="form-control" />
        </div>

        <div role="separator" class="line full-width-imp"></div>

        <div class="form-group martop25">
          <label
            *ngIf="modalOptions.formData.tag !== 'sig_grafik'"
            transloco="GraphicsSource"
          >
          </label>
          <label
            *ngIf="modalOptions.formData.tag === 'sig_grafik'"
            transloco="graphic_signature_preview"
          >
          </label>
        </div>

        <div class="imageprevarea flex">
          <div class="formarea">
            <div
              class="radio top"
              *ngIf="modalOptions.formData.tag !== 'sig_grafik'"
            >
              <label class="container-radio">
                <input
                  type="radio"
                  formControlName="imgradio"
                  value="default"
                />
                <span class="checkmark-radio"></span>
                <span transloco="StandardDisplay"></span>
              </label>
            </div>

            <div
              class="radio bottom"
              *ngIf="modalOptions.formData.tag !== 'sig_grafik'"
            >
              <label class="container-radio">
                <input type="radio" value="own" formControlName="imgradio" />
                <span class="checkmark-radio"></span>
                <span transloco="owngraphic"></span>
              </label>
            </div>

            <ng-container *ngIf="imageForm.get('imgradio')?.value === 'own'">
              <mt-upload-image-button
                (loadImage)="setImage($event)"
                [max-width]="4500"
                [max-size-mb]="5"
              ></mt-upload-image-button>
            </ng-container>
          </div>
          <div class="imgpreview">
            <div
              class="hint"
              transloco="Preview"
              *ngIf="modalOptions.formData.tag !== 'sig_grafik'"
            ></div>

            <!-- OWN IAMGE -->
            <ng-container *ngIf="imageForm.get('imgradio')?.value === 'own'">
              <img
                [ngStyle]="{
                  'border-radius':
                    modalOptions.formData.value?.cropType === 'circle' &&
                    modalOptions.formData.value?.isCropped
                      ? '50%'
                      : '0%',
                  'margin-top':
                    modalOptions.formData.tag === 'sig_grafik' ? '-42px' : ''
                }"
                [src]="getNoImageCropSrc()"
              />
            </ng-container>

            <!-- DEFAULT IMAGE -->
            <img
              *ngIf="imageForm.get('imgradio')?.value === 'default'"
              [ngStyle]="{
                'margin-top':
                  modalOptions.formData.tag === 'sig_grafik' ? '-42px' : ''
              }"
              [src]="modalOptions.formData.defaultImage"
            />
          </div>
        </div>

        <!-- SIGNATURE -->
        <!-- TODO implement signature section -->
        <ng-container
          *ngIf="
            modalOptions.showSignatureRelevantData &&
            imageDimensionsAvailable === true &&
            imageForm.get('imgradio')?.value === 'own'
          "
        >
          <div role="separator" class="line full-width-imp"></div>

          <div class="form-group martop25">
            <label
              transloco="Sizeofthepermanentlysetaftersavingthesignature"
            ></label>
          </div>

          <div class="imageprevarea flex">
            <div class="formarea">
              <div class="radio top">
                <label class="container-radio">
                  <input
                    type="radio"
                    formControlName="imgdimradio"
                    value="default"
                  />
                  <span class="checkmark-radio"></span>
                  <span transloco="originalsize"></span>
                </label>
              </div>
              <div class="radio bottom">
                <label class="container-radio">
                  <input
                    type="radio"
                    value="custom"
                    formControlName="imgdimradio"
                  />
                  <span class="checkmark-radio"></span>
                  <span transloco="Manually"></span>
                </label>
              </div>

              <div
                *ngIf="imageForm.get('imgdimradio')?.value === 'custom'"
                class="form-group"
              >
                <label transloco="Widthinpx"></label>
                <input
                  ng-model="imageDimensionData.width"
                  ng-required="imageForm.get('imgdimradio')?.value === 'custom'"
                  type="text"
                  class="form-control"
                /><!-- TODO replace ng-model and ng-required -->
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <mt-box
        *ngIf="!modalOptions.formData.tag.startsWith('ma_')"
        type="info"
        icon="info-sign"
        translateTag="Achangetothesignaturesallemployees"
      >
        <div class="additionaltext">
          <span
            transloco="Changesinthisinformationareincludedsignaturesemployees"
          ></span>
        </div>
      </mt-box>

      <div role="separator" class="line full-width-imp"></div>
      <div class="flex" style="justify-content: space-between">
        <div class="flex-center-left">
          <mt-button
            *ngIf="
              modalOptions.formData.tag === 'ma_foto' &&
              imageForm.get('textorgraphic')?.value === 'image'
            "
            text="delete_image"
            class="mt_left"
            color="primary"
            (click)="deleteImage()"
          ></mt-button>
        </div>
        <div class="flex-center-right">
          <mt-button
            (click)="modal.close()"
            class="leftbutton"
            text="rejection"
            color="default"
          ></mt-button>
          <mt-button
            (click)="saveValue()"
            color="primary"
            text="Save"
          ></mt-button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Create New Department Modal -->
<ng-template #modalDeleteImage let-modal>
  <mt-custom-modal>
    <div modal-header-close>
      <div class="title-big">{{ 'HOWDOYOUWANTTOPROCEED' | transloco }}</div>
    </div>
    <div modal-body>
      <mt-box
        type="info"
        icon="info-sign"
        translateTag="delete_image_for_all_or_what"
      ></mt-box>
    </div>
    <div modal-footer>
      <mt-button
        text="abort"
        color="default"
        (click)="modal.close()"
        class="mt_left"
      ></mt-button>
      <mt-button
        text="delete_for_all"
        color="primary"
        (click)="deleteUserImage('all')"
        class="mt_left"
      ></mt-button>
      <mt-button
        text="delete_for_selected"
        color="primary"
        (click)="deleteUserImage(datasetId)"
      ></mt-button>
    </div>
  </mt-custom-modal>
</ng-template>
