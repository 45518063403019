import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import {
  EventGetAllEventsForTargetGroup,
  IEventCheckDate,
  IEventGetAllEventsForDepartment
} from './event-service.interface';
import { QueryHelperService } from '../query-helper/query-helper.service';
import { IResponse, IResponseCount, IResponseData, ResponseData } from 'src/app/model/interfaces/response.interface';
import { IEventCreate, IEventEdit, IEventGet, TriggerEvent } from 'src/app/model/interfaces/event.interface';
import { IQueryObj } from '../query-helper/query-helper-service.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { IntercomService, INTERCOM_DATA } from '../intercom/intercom.module';
import { environment } from 'src/environments/environment';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { format } from 'date-fns';
import { TargetSender } from 'src/app/model/enums/target-sender.enum';
import { AlertService } from '@services/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators,
    private queryHelperService: QueryHelperService
  ) {}

  /**
   * Gets all events
   * @param queryObj - The query object to load lazy loading
   * @returns Observable containing Events
   */
  getAllEvents(queryObj?: IQueryObj): Observable<IEventGet[]> {
    return this.http
      .get<IResponseData<IEventGet[]>>(`/events${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all events for a specific campaign
   * @param id
   * @returns
   */
  getAllEventsForCampaign(id: string): Observable<TriggerEvent[]> {
    return this.http
      .get<IResponseData<TriggerEvent[]>>(`/events/campaign/${id}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all events for a specific target group
   * @param id - Target group id
   * @returns Observable of events assigned to the target group
   */
  getAllEventsForTargetGroup(id: string): Observable<EventGetAllEventsForTargetGroup[]> {
    return this.http
      .post<IResponseData<EventGetAllEventsForTargetGroup[]>>(`/events/targetGroup/${id}`, null)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all events for a specific group
   * @param id - Group identifier
   * @returns Observable containing assigned events
   */
  getAllEventsForDepartment(id: number): Observable<IEventGetAllEventsForDepartment[]> {
    return this.http
      .get<IResponseData<IEventGetAllEventsForDepartment[]>>(`/events/department/${id}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a new event
   * @param data - Event object for creating new event
   * @returns Observable of created event
   */
  add(data: Partial<IEventCreate | IEventEdit>): Observable<IEventGet> {
    return this.http.post<IResponseData<IEventGet> & { message?: string }>('/events', data).pipe(
      map(value => {
        if (!value.success) {
          if (value.message && value.message === 'event_title_already_exists') {
            throw new Error(value.message);
          }
          throw new Error(this.alert.translateDataNotLoaded());
        }
        return value.data;
      }),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.event_created);
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'events');
      })
    );
  }

  /**
   * Given an id returns the corresponding
   * @param id - Id of event
   * @returns Event with given id
   */
  getOneEvent(id: string): Observable<IEventGet> {
    return this.http.get<ResponseData<IEventGet>>(`/events/${id}`).pipe(
      this.operator.extractResponseData(),
      tap(data => {
        // '0000-00-00' is used to mark an event as durable
        // undefined is a better representation of this
        if (data?.endDate?.toString() === '0000-00-00') {
          data.endDate = undefined;
        }
      })
    );
  }

  /**
   * Gets the all existing event title to check and avoid the duplication
   * @returns The string array of event title
   */
  getTitles(): Observable<string[]> {
    return this.http.get<ResponseData<{ title: string }[]>>(`/events/titles`).pipe(
      this.operator.extractResponseData(),
      map(data => data.map(data => data.title) ?? [])
    );
  }

  /**
   * Gets the count of events
   * @param queryObj - query object holding the keys or empty
   * @returns Count of events that exist
   */
  count(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<IResponseCount>(`/events/count${this.queryHelperService.createQuerySearch(queryObj)}`)
      .pipe(this.operator.extractResponseCount());
  }

  /**
   * @deprecated use v2
   * Checks if date range exists for account
   * @param data - Date of event
   * @returns Observable of events
   */
  checkEventDate(data: IEventCheckDate): Observable<IEventCreate[]> {
    return this.http
      .post<IResponseData<IEventCreate[]>>('/events/check/event/date', data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Checks which events are running for the given dates and type
   * @param type - Type of events to check
   * @param startDate - Events start date
   * @param endDate - Events end date
   * @returns Events running that meet the criteria
   */
  checkEventDateV2(type: TargetSender, startDate: Date, endDate?: Date): Observable<IEventGet[]> {
    // Format both dates to accepatable values
    const formatStartDate = startDate && startDate.toString() !== '0000-00-00' ? format(startDate, 'yyyy-MM-dd') : '0000-00-00';
  
    // For durable events the key `0000-00-00` must be used
    const formatEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '0000-00-00';

    return this.http
      .post<ResponseData<IEventGet[]>>('/events/check/event/date', {
        type,
        startDate: formatStartDate,
        endDate: formatEndDate
      })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates an event
   * @param eventBody - Event details
   * @returns Observable of events
   */
  update(eventBody: Partial<IEventEdit>): Observable<IEventEdit[]> {
    return this.http.post<ResponseData<IEventEdit[]> & { message?: string }>('/events/updateEvent', eventBody).pipe(
      map(value => {
        if (!value.success) {
          if (value.message && value.message === 'event_title_already_exists') {
            throw new Error(value.message);
          }
          throw new Error(this.alert.translateDataNotLoaded());
        }
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.event_modified);
        return value.data;
      })
    );
  }

  /**
   * Removes many events
   * @param ids - Array of events
   * @returns Observable with number of removed event
   */
  removeManyEvents(ids: string[]): Observable<number> {
    return this.http.post<IResponseData<number>>('/events/delete', { eventsIds: ids }).pipe(
      this.operator.extractResponseData(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-ids.length, 'events');
      })
    );
  }
  /**
   * Duplicate events
   * @param ids - Id of event
   * @returns Returns true when action are finished
   */
  duplicateEvent(ids: string[]): Observable<true> {
    return this.http.post<IResponse>('/events/duplicate', { eventsIds: ids }).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'events');
      })
    );
  }
}
