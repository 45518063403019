import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, filter, from, map } from 'rxjs';
import { CompanyDataImage, ISignaturePlaceholderImg } from 'src/app/model/interfaces/signature.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilService } from 'src/app/services/util/util.service';
import { TranslocoModule } from '@ngneat/transloco';
import { IconButtonComponent } from '@molecules/buttons/icon-button/icon-button.component';
import { UploadImageButtonComponent } from '@molecules/buttons/upload-image-button/upload-image-button.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { NgIf, NgStyle, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

const DEFAULT_IMAGE: CompanyDataImage = {
  altText: 'u_logo',
  etag: null,
  image: 'https://app.mailtastic.de/api/images/default/Home.png',
  initialdimension: {
    height: 50,
    width: 50
  },
  linkText: '',
  showAs: 'image',
  source: 'uploaded',
  type: 'image',
  url: '#',
  useForAllProfiles: false,
  whichImage: 'own'
};

@Component({
  selector: 'mt-upload-company-logo',
  templateUrl: './upload-company-logo.component.html',
  styleUrls: ['./upload-company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    IconButtonComponent,
    MtSvgComponent,
    NgIf,
    NgStyle,
    ReactiveFormsModule,
    TranslocoModule,
    UploadImageButtonComponent
  ]
})
export class UploadCompanyLogoComponent {
  constructor(
    private alert: AlertService,
    private modalService: ModalService,
    private employeeService: EmployeeService,
    private utilService: UtilService,
    public modal: NgbActiveModal
  ) {}

  @Input() set companyLogo(logo: CompanyDataImage | undefined) {
    // When deleting the logo the backend can return an object with the default image
    // but with the property whichImage as 'own' so we fix that here and apply the correct string 'default'
    if (logo) {
      if (logo.image === DEFAULT_IMAGE.image && logo.whichImage !== DEFAULT_IMAGE.whichImage) {
        logo.whichImage = 'own';
      }

      // Special case handled by the backend, no need to patch the form with this value
      if (logo.url === '#' || this.utilService.removeHttpHttpsWww(logo.url) === '#') {
        logo.url = '';
      }

      this.photoUrl.patchValue(this.utilService.removeHttpHttpsWww(logo.url));

      this.initialPhoto$.next(logo);
      this.currentPhoto$.next(logo);
    }
  }

  // Current photo being displayed
  private currentPhoto$ = new BehaviorSubject(DEFAULT_IMAGE);

  // Original photo, used when cancelling the modal
  private initialPhoto$ = new BehaviorSubject(DEFAULT_IMAGE);

  // Has a new photo been uploaded by the user?
  private isNewPhoto$ = new BehaviorSubject(false);

  photoAndStatus$ = combineLatest([
    this.currentPhoto$.asObservable(),
    this.initialPhoto$.asObservable(),
    this.isNewPhoto$.asObservable()
  ]).pipe(map(([currentPhoto, initialPhoto, isNewPhoto]) => ({ currentPhoto, initialPhoto, isNewPhoto })));

  photoUrl = new FormControl('', { nonNullable: true });

  /**
   * Displays the image cropping modal to the user.
   *
   * Sets the newly uploaded photo as displayed.
   * Marks the photo as new.
   * Resets the url formcontrol.
   *
   * @param image - Image uploaded by the user
   */
  processImage(image: HTMLImageElement): void {
    from(this.modalService.openCropImageModal(image)).subscribe(logo => {
      this.currentPhoto$.next({
        ...this.currentPhoto$.value,
        image: logo.value.image.$ngfDataUrl,
        isCropped: logo.value.isCropped,
        cropType: logo.value.cropType,
        whichImage: 'own'
      });
      this.isNewPhoto$.next(true);
      this.photoUrl.reset();
    });
  }

  /**
   * Closes the modal and saves the logo/changes
   * @param companyLogo - Company logo to save
   * @param isNewPhoto - Whether this is a new logo that needs to be saved first
   */
  saveLogo(companyLogo: CompanyDataImage, isNewPhoto: boolean): void {
    const logoUrl = this.utilService.checkAndAddHttps(this.photoUrl.value);
    if (!isNewPhoto) {
      companyLogo.url = logoUrl;
      this.modal.close(companyLogo);
    } else {
      this.employeeService.getUploadedImageData(this.logoToImgDTO(companyLogo)).subscribe(image => {
        // Always string when uploading a photo
        if (typeof image.image === 'string') {
          const newPhoto: CompanyDataImage = {
            altText: image.altText,
            cropType: image.cropType,
            etag: image.etag,
            image: image.image,
            initialdimension: image.initialdimension,
            isCropped: image.isCropped,
            linkText: image.linkText,
            showAs: 'image',
            source: 'uploaded',
            type: 'image',
            url: logoUrl,
            useForAllProfiles: image.useForAllProfiles,
            whichImage: 'own'
          };
          this.modal.close(newPhoto);
        }
      });
    }
  }

  /**
   * Prompts the user to delete the currently displayed logo
   */
  deleteLogo(): void {
    from(this.alert.defaultConfirmPrompt('sure_you_want_to_delete'))
      .pipe(filter(accepted => accepted))
      .subscribe(() => {
        this.currentPhoto$.next(DEFAULT_IMAGE);
        this.isNewPhoto$.next(false);
        this.photoUrl.reset();
      });
  }

  /**
   * Converts a logo to an object accepted by the backend for storing a new image
   * @param logo - New logo to store
   * @returns Object in accepted format
   */
  private logoToImgDTO(logo: CompanyDataImage): ISignaturePlaceholderImg {
    return {
      defaultImage: 'https://signatures.cognism.com/api/images/default/Home.png',
      disabled: false,
      global: false,
      imgdimension: {
        mode: 'default',
        width: logo.initialdimension?.width || 150,
        height: logo.initialdimension?.height || 150
      },
      label: 'Firmenlogo',
      locked: true,
      tag: 'u_logo',
      type: 'image',
      styles: {},
      value: {
        altText: 'u_logo',
        cropType: logo.cropType,
        etag: null,
        image: {
          $ngfDataUrl: logo.image,
          $ngfHeight: logo.initialdimension?.height,
          $ngfWidth: logo.initialdimension?.width
        },
        initialdimension: {
          width: logo.initialdimension?.width || 150,
          height: logo.initialdimension?.height || 150
        },
        isCropped: logo.isCropped,
        linkText: logo.url || '',
        showAs: 'image',
        source: 'uploaded',
        type: 'image',
        url: logo.url || '#',
        useForAllProfiles: false,
        whichImage: 'own'
      }
    };
  }
}
