import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { ICampaignGet } from 'src/app/services/campaign/campaign-services.interface';
import { GroupService } from 'src/app/services/group/group.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { AuthService } from 'src/app/services/auth/auth.service';

import { BehaviorSubject, combineLatest, of, map, take, tap, switchMap } from 'rxjs';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { format, isAfter, parseISO } from 'date-fns';

import { IGroupGetStatistics } from 'src/app/services/group/group-service.interface';
import { IEmployeeStats } from 'src/app/services/employee/employee-service.interface';
import { BarData, CustomColor, PieChartData } from 'src/app/model/interfaces/line-graph.interface';

@Component({
  selector: 'mt-dashboard-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailtasticDashboardComponent {
  @ViewChild('pieChart') pieChart: any;

  pieChartOptions = {
    dataToShow: [] as PieChartData[],
    impressionsData: [] as PieChartData[],
    clicksData: [] as PieChartData[],
    rateData: [] as PieChartData[],
    totalImpressions: 0,
    totalClicks: 0,
    totalRate: 0,
    progressImpressions: [] as BarData[],
    progressClicks: [] as BarData[],
    progressRate: [] as BarData[],
    customColors: [] as CustomColor[],
    typeOfSelectedData: {
      // Used to determine which type of data is being shown
      views: true,
      clicks: false,
      clickRate: false
    },
    showRelativeBarData: false // Used to determine which type of data the progress bars show
  };

  best3Criteria = {
    employees: [] as IEmployeeStats[],
    best3Employees: [] as IEmployeeStats[],
    sortBy: {
      // Used to determine which type of data is being sorted by
      views: true,
      clicks: false,
      clickRate: false
    }
  };

  overAllStats = {
    email: '',
    userName: '',
    companyName: ' ',
    amountOfUsers: 0,
    amountOfCampaigns: 0,
    amountOfClicks: 0,
    amountOfViews: 0,
    hideTop3Employees: false
  };

  allCampaigns: ICampaignGet[] = [];
  activeCampaigns: ICampaignGet[] = [];
  activeCampaignIds: number[] = [];

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  dateRange = '';

  /**
   * Determines if a loading spinner should be shown until receiving dashboard data
   */
  set loadingPromise(val: boolean) {
    this._loadingPromise$.next(val);
  }
  private _loadingPromise$ = new BehaviorSubject(false);
  loadingPromise$ = this._loadingPromise$.asObservable();

  // The pie chart color counter to set dynamic departments section
  pieChartColorCounter = 0;

  // The colors of pie chart groups
  pieChartColors = [
    '#009fe3',
    '#e6391e',
    '#a0d117',
    '#143d60',
    '#ffc000',
    '#d11796',
    '#008000',
    '#4b0082',
    '#3dc0a0',
    '#dd7d2f'
  ];

  // Campaign data
  campaignData$ = this.campaignService.get().pipe(
    take(1),
    tap(campaigns => {
      this.allCampaigns = campaigns;
    }), // Save all campaigns
    map(campaigns => campaigns.filter(campaign => this.isCampaignActive(campaign))) // Filter inactive campaigns
  );

  loadDashboardData$ = this.authService.authorizationScope$.pipe(
    switchMap(() => {
      this.loadingPromise = true;
      return combineLatest([
        this.userService.getOverallStats(),
        this.campaignData$,
        this.groupService.getStatstics(),
        this.employeeService.getWithImpressionsAndClicks()
      ]);
    }),
    tap(([[stat], campaigns, groupStats, employeeStats]) => {
      if (stat) {
        this.overAllStats = {
          ...this.overAllStats,
          amountOfCampaigns: stat.amountOfCampaigns,
          amountOfUsers: stat.amountOfUsers,
          companyName: stat.companyName,
          email: stat.email,
          hideTop3Employees: stat.hideTop3Employees || false,
          userName: stat.userName
        };
      }
      this.loadingPromise = false;
      this.activeCampaigns = campaigns;
      this.activeCampaigns.map(campaign => {
        this.activeCampaignIds.push(campaign.id);
      });

      for (const campaign of this.activeCampaigns) {
        this.overAllStats.amountOfClicks += campaign.clicks;
        this.overAllStats.amountOfViews += campaign.views;
      }

      // Create pie chart data when there are group statistics available
      if (groupStats.views.length > 0 || groupStats.clicks.length > 0) {
        this.createPieChartData(groupStats);
      }

      if (employeeStats.success && employeeStats.data.length > 0) {
        this.best3Criteria.employees = employeeStats.data;
        this.changeBest3CriteriaSort();
      }
      this.cdr.detectChanges();
    })
  );

  /**
   * Hold loader and authorization data
   */
  loadData$ = this.authService.authorizationScope$.pipe(
    switchMap(authorizationScope => combineLatest([of(authorizationScope), this.loadingPromise$])),
    map(([authorizationScope, loadingPromise]) => ({ authorizationScope, loadingPromise }))
  );

  constructor(
    private authService: AuthService,
    private campaignService: CampaignService,
    private cdr: ChangeDetectorRef,
    private employeeService: EmployeeService,
    private groupService: GroupService,
    private userService: UserService,
    public formatter: NgbDateParserFormatter
  ) {}

  /**
   * Determines if a campaign is active.
   * Active campaign was created before today AND
   * Is in at least 1 group, target group list or trigger event.
   * @param campaign - Campaign to check.
   * @returns Whether campaign is active or not.
   */
  isCampaignActive(campaign: ICampaignGet): boolean {
    const today = format(new Date(), 'yyyy-MM-dd');
    const isBeforeToday = isAfter(parseISO(today), parseISO(campaign.createdAt.toString()));

    const isAssigned =
      campaign.Groups.length > 0 || campaign.TargetGroupLists?.length > 0 || campaign.TriggerEvent.length > 0;

    return isBeforeToday && isAssigned;
  }

  /**
   * Generate data to be shown in the pie chart.
   * @param data - Group statistics data to process
   */
  createPieChartData(data: IGroupGetStatistics): void {
    this.pieChartOptions.impressionsData = [];
    this.pieChartOptions.clicksData = [];

    // Get total number of impressions, clicks and clickrate
    // Used to calculate a group's contribution
    this.pieChartOptions.totalImpressions = data.views.reduce((acc, curr) => acc + curr.anzahl, 0);
    this.pieChartOptions.totalClicks = data.clicks.reduce((acc, curr) => acc + curr.anzahl, 0);

    // Calculate the accumulated click rate of all groups
    data.views.map(view => {
      const foundClick = data.clicks.find(click => {
        return view.groupId === click.groupId;
      });

      if (foundClick) {
        this.pieChartOptions.totalRate += (foundClick.anzahl / view.anzahl) * 100;
      }
    });

    this.pieChartOptions.totalRate = Math.round(this.pieChartOptions.totalRate * 100) / 100;

    for (const view of data.views) {
      const title = view.title;
      const amount = view.anzahl;

      const percentage = (amount / this.pieChartOptions.totalImpressions) * 100;
      const contribution = Math.round(percentage * 1) / 1;

      this.pieChartOptions.impressionsData.push({
        name: title,
        value: amount
      });

      // Grab a color from the color scheme and assign it to the current group
      const color = this.getNextDepartmentPieColor() || '';

      // Should never cause errors since if there are no views, there will be no clicks or click rate
      this.pieChartOptions.customColors.push({
        name: title,
        value: color
      });

      this.pieChartOptions.progressImpressions.push({
        name: title,
        value: amount,
        contribution,
        color
      });
    }

    for (const click of data.clicks) {
      const title = click.title;
      const amount = click.anzahl;
      const barColor = this.pieChartOptions.customColors.find(color => {
        return click.title === color.name && color.value;
      });

      const color = barColor ? barColor.value : '';

      const percentage = (amount / this.pieChartOptions.totalClicks) * 100;
      const contribution = Math.round(percentage * 1) / 1;

      this.pieChartOptions.clicksData.push({
        name: title,
        value: amount
      });

      this.pieChartOptions.progressClicks.push({
        name: title,
        value: amount,
        contribution,
        color
      });

      const foundView = data.views.find(view => {
        return view.groupId === click.groupId;
      });

      if (foundView) {
        const rate = (click.anzahl / foundView.anzahl) * 100;
        const value = Math.round(rate * 100) / 100;
        this.pieChartOptions.rateData.push({
          name: click.title,
          value
        });

        this.pieChartOptions.progressRate.push({
          name: title,
          value,
          contribution: Math.round((value / this.pieChartOptions.totalRate) * 100 * 1) / 1,
          color
        });
      }
    }

    // Sort bars descending by value
    this.pieChartOptions.progressImpressions.sort((a, b) => {
      return b.value - a.value;
    });

    this.pieChartOptions.progressClicks.sort((a, b) => {
      return b.value - a.value;
    });

    this.pieChartOptions.progressRate.sort((a, b) => {
      return b.value - a.value;
    });

    // Set the pie chart to the default impressions view
    this.changePieChartMode();
  }

  /**
   * Gets the next color
   * @returns The color name
   */
  private getNextDepartmentPieColor(): string {
    const result = this.pieChartColors[this.pieChartColorCounter];
    this.pieChartColorCounter++;
    if (this.pieChartColorCounter >= 10) {
      this.pieChartColorCounter = 0;
    }
    return result;
  }

  /**
   * Changes the displayed data type in the pie chart.
   * Impressions by default.
   * @param mode - New data type to display
   */
  changePieChartMode(mode = 'views'): void {
    switch (mode) {
      case 'views':
        this.pieChartOptions.typeOfSelectedData = {
          ...this.pieChartOptions.typeOfSelectedData,
          views: true,
          clicks: false,
          clickRate: false
        };
        this.pieChartOptions.dataToShow = this.pieChartOptions.impressionsData;
        break;
      case 'clicks':
        this.pieChartOptions.typeOfSelectedData = {
          ...this.pieChartOptions.typeOfSelectedData,
          views: false,
          clicks: true,
          clickRate: false
        };
        this.pieChartOptions.dataToShow = this.pieChartOptions.clicksData;
        break;
      case 'clickRate':
        this.pieChartOptions.typeOfSelectedData = {
          ...this.pieChartOptions.typeOfSelectedData,
          views: false,
          clicks: false,
          clickRate: true
        };
        this.pieChartOptions.dataToShow = this.pieChartOptions.rateData;
        break;
    }
  }

  /**
   * Changes the sorting type of the best 3 employees.
   * @param mode - Data type to sort by
   */
  changeBest3CriteriaSort(mode = 'views'): void {
    switch (mode) {
      case 'views':
        this.best3Criteria.sortBy = {
          ...this.best3Criteria.sortBy,
          views: true,
          clicks: false,
          clickRate: false
        };
        this.best3Criteria.best3Employees = this.best3Criteria.employees.sort((a, b) => {
          return b.views - a.views;
        });
        break;
      case 'clicks':
        this.best3Criteria.sortBy = {
          ...this.best3Criteria.sortBy,
          views: false,
          clicks: true,
          clickRate: false
        };
        this.best3Criteria.best3Employees = this.best3Criteria.employees.sort((a, b) => {
          return b.clicks - a.clicks;
        });
        break;
      case 'clickRate':
        this.best3Criteria.sortBy = {
          ...this.best3Criteria.sortBy,
          views: false,
          clicks: false,
          clickRate: true
        };
        this.best3Criteria.best3Employees = this.best3Criteria.employees.sort((a, b) => {
          return b.clicks / b.views - a.clicks / a.views;
        });
        break;
    }
    this.best3Criteria.best3Employees = this.best3Criteria.best3Employees.slice(0, 3);
  }
}
