import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, tap } from 'rxjs';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { IAccountData } from 'src/app/model/interfaces/account-admin.interface';
import { AccountService } from 'src/app/services/account/account.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserLoggedInfo } from 'src/app/services/user/user-service.interface';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

interface ISelectionOption {
  create_email_signature: boolean;
  other_option: boolean;
  analyse_market_campaign: boolean;
  otherText: string;
  role: string;
  manage_email_for_company: boolean;
  marketing_banner_email_signature: boolean;
}

@Component({
  selector: 'mt-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {
  isCognismSignatures = environment.isCogSig;
  accountData: IAccountData = {
    companyName: '',
    firstName: '',
    lastName: '',
    email: ''
  };
  showTextField = false;

  roleSection = [
    {
      translateKey: 'whyareyouherefor_q2_option_2',
      translateValue: this.alert.translate('whyareyouherefor_q2_option_2')
    },
    {
      translateKey: 'whyareyouherefor_q2_option_3',
      translateValue: this.alert.translate('whyareyouherefor_q2_option_3')
    },
    {
      translateKey: 'whyareyouherefor_q2_option_4',
      translateValue: this.alert.translate('whyareyouherefor_q2_option_4')
    },
    {
      translateKey: 'whyareyouherefor_q2_option_5',
      translateValue: this.alert.translate('whyareyouherefor_q2_option_5')
    },
    {
      translateKey: 'whyareyouherefor_q2_option_6',
      translateValue: this.alert.translate('whyareyouherefor_q2_option_6')
    }
  ];
  optionArray = [] as string[];

  welcomeForm!: UntypedFormGroup;
  createSignatureFlag$!: Observable<boolean> | undefined;
  manageEmailForCompanyFlag$!: Observable<boolean> | undefined;
  marketingBannerEmailSignature$!: Observable<boolean> | undefined;
  analyseMarketCampaign$!: Observable<boolean> | undefined;
  other_option_Flag$!: Observable<boolean> | undefined;
  otherDescription$!: Observable<string> | undefined;
  otherRole$!: Observable<string> | undefined;
  otherRoleDescription$!: Observable<string> | undefined;
  showRoleText = false;
  isOptionSelected = false;
  optionTextFlag = false;
  roleTextFlag = false;
  roleSelected = false;
  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private alert: AlertService,
    private fb: UntypedFormBuilder,
    private modal: NgbActiveModal,
    private userService: UserService
  ) {
    this.accountData = this.accountService.getUserAccountData();
  }

  ngOnInit(): void {
    this.welcomeForm = this.fb.group({
      create_email_signature: [false],
      manage_email_for_company: [false],
      marketing_banner_email_signature: [false],
      analyse_market_campaign: [false],
      other_option: [false],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      otherText: ['', Validators.required],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      role: ['', Validators.required],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      otherRoleText: ['', Validators.required]
    });

    this.createSignatureFlag$ = this.welcomeForm.get('create_email_signature')?.valueChanges.pipe(
      tap(data => {
        if (data && this.isOptionSelected) {
          this.isOptionSelected = false;
        }
      })
    );

    this.manageEmailForCompanyFlag$ = this.welcomeForm.get('manage_email_for_company')?.valueChanges.pipe(
      tap(data => {
        if (data && this.isOptionSelected) {
          this.isOptionSelected = false;
        }
      })
    );

    this.marketingBannerEmailSignature$ = this.welcomeForm.get('marketing_banner_email_signature')?.valueChanges.pipe(
      tap(data => {
        if (data && this.isOptionSelected) {
          this.isOptionSelected = false;
        }
      })
    );

    this.analyseMarketCampaign$ = this.welcomeForm.get('analyse_market_campaign')?.valueChanges.pipe(
      tap(data => {
        if (data && this.isOptionSelected) {
          this.isOptionSelected = false;
        }
      })
    );

    this.other_option_Flag$ = this.welcomeForm.get('other_option')?.valueChanges.pipe(
      tap(data => {
        this.showTextField = data ? true : false;
        if (data && this.isOptionSelected) {
          this.isOptionSelected = false;
        }
      })
    );

    this.otherDescription$ = this.welcomeForm.get('otherText')?.valueChanges.pipe(
      tap(data => {
        if (data) {
          this.optionTextFlag = false;
        }
      })
    );

    this.otherRoleDescription$ = this.welcomeForm.get('otherRoleText')?.valueChanges.pipe(
      tap(data => {
        if (data) {
          this.roleTextFlag = false;
        }
      })
    );

    this.otherRole$ = this.welcomeForm.get('role')?.valueChanges.pipe(
      tap(data => {
        this.showRoleText = data === 'whyareyouherefor_q2_option_6' ? true : false;
        if (!this.showRoleText) {
          this.welcomeForm.get('otherRoleText')?.setValue('');
        }
        if (data) {
          this.roleSelected = false;
        }
      })
    );
  }

  /**
   * Close modal and save the user information into backend
   */
  closeModal(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const selected: ISelectionOption = this.welcomeForm.value;

    let otherWhatBringsYouHere = '';
    let otherWhatIsYourRole = '';

    // Saves multiselect values to array || What brings you here
    if (selected.create_email_signature) {
      this.optionArray.push(this.alert.translate('whyareyouherefor_q1_option_1'));
    }
    if (selected.manage_email_for_company) {
      this.optionArray.push(this.alert.translate('whyareyouherefor_q1_option_2'));
    }
    if (selected.marketing_banner_email_signature) {
      this.optionArray.push(this.alert.translate('whyareyouherefor_q1_option_3'));
    }
    if (selected.analyse_market_campaign) {
      this.optionArray.push(this.alert.translate('whyareyouherefor_q1_option_4'));
    }
    if (selected.other_option) {
      this.optionArray.push(this.alert.translate('whyareyouherefor_q1_option_5'));
      otherWhatBringsYouHere = this.welcomeForm.get('otherText')?.value as string;
    }

    // Saves dropdown selection || What is your role
    const selectRole = this.alert.translate(this.welcomeForm.get('role')?.value as string);
    if (this.welcomeForm.get('role')?.value === 'whyareyouherefor_q2_option_6') {
      otherWhatIsYourRole = this.welcomeForm.get('otherRoleText')?.value as string;
    }

    // Checks if form evaluated correctly
    if (
      !selected.analyse_market_campaign &&
      !selected.create_email_signature &&
      !selected.manage_email_for_company &&
      !selected.marketing_banner_email_signature &&
      !selected.other_option
    ) {
      this.isOptionSelected = true;
      return;
    }
    if (!this.welcomeForm.get('otherText')?.value && selected.other_option) {
      this.optionTextFlag = true;
      return;
    }
    if (!this.welcomeForm.get('role')?.value) {
      this.roleSelected = true;
      return;
    }
    if (
      !this.welcomeForm.get('otherRoleText')?.value &&
      this.welcomeForm.get('role')?.value === 'whyareyouherefor_q2_option_6'
    ) {
      this.roleTextFlag = true;
      return;
    }

    const userInfo: UserLoggedInfo = {
      whatBringsYouHere: {
        answers: this.optionArray,
        other: otherWhatBringsYouHere
      },
      whatIsYourRole: {
        answer: selectRole,
        other: otherWhatIsYourRole
      }
    };

    this.userService.updateUserInfo(userInfo).subscribe(() => {
      this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.isUserLoggedInFirstTime, '0');
      this.modal.close(true);
    });
  }
}
