<mt-custom-modal
  headline="{{ 'edit_company_data' | transloco }}"
  [closeBtn]="false"
  *ngIf="datasetInfo$ | async as dataset"
>
  <div class="container-fluid" modal-body>
    <form class="formsAnime form-horizontal" [formGroup]="companyForm">
      <div
        class="section-title"
        transloco="SECTIONS.COMPANY_INFORMATION_TITLE"
      ></div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_name" transloco="SECTIONS.COMPANY_NAME"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_name"
            placeholder="{{ 'SECTIONS.INPUT_PLACEHOLDER' | transloco }}"
            formControlName="c_name"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_name" transloco="SECTIONS.CITY"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_city"
            placeholder="{{ 'sigeditor_field_cityex' | transloco }}"
            formControlName="c_city"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_postal_code" transloco="SECTIONS.POSTAL_CODE"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_postal_code"
            placeholder="{{ 'sigeditor_field_postalcodeex' | transloco }}"
            formControlName="c_postal_code"
            maxlength="10"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_state" transloco="SECTIONS.STATE"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_state"
            placeholder="{{ 'sigeditor_field_stateex' | transloco }}"
            formControlName="c_state"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_country" transloco="SECTIONS.COUNTRY"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_country"
            placeholder="{{ 'sigeditor_field_countryex' | transloco }}"
            formControlName="c_country"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_street" transloco="SECTIONS.STREET"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_street"
            placeholder="{{ 'sigeditor_field_streetex' | transloco }}"
            formControlName="c_street"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_office" transloco="SECTIONS.OFFICE"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_office"
            placeholder="{{ 'sigeditor_field_officeex' | transloco }}"
            formControlName="c_office"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_logo" transloco="SECTIONS.COMPANY_LOGO_LABEL"> </label>
        </div>
        <div class="col-8 d-flex">
          <div
            class="ownbtn btnblau text-center"
            (click)="openUploadCompanyLogoModal()"
            transloco="uploade_photo_btn_3"
          ></div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_fax" transloco="SECTIONS.FAX"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_fax"
            placeholder="{{ 'sigeditor_field_faxex' | transloco }}"
            formControlName="c_fax"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_phone" transloco="SECTIONS.PHONE_GENERAL"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_phone"
            placeholder="{{ 'sigeditor_field_phoneex' | transloco }}"
            formControlName="c_phone"
            maxlength="50"
            class="form-control input-sm"
            (input)="
              utils.forbidInputCharacters(companyForm, $event, '[^- \\+()0-9]')
            "
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_add_info_1" transloco="SECTIONS.ADDITIONAL_INFO_1">
          </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_add_info_1"
            placeholder="{{ 'sigeditor_field_compaddinfo1ex' | transloco }}"
            formControlName="c_add_info_1"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_add_info_2" transloco="SECTIONS.ADDITIONAL_INFO_2">
          </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_add_info_2"
            placeholder="{{ 'sigeditor_field_compaddinfo2ex' | transloco }}"
            formControlName="c_add_info_2"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label
            for="c_add_info_3"
            transloco="placeholder_company_add_info_3"
          ></label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_add_info_3"
            placeholder="{{ 'sigeditor_field_compaddinfo3ex' | transloco }}"
            formControlName="c_add_info_3"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label
            for="c_add_info_4"
            transloco="placeholder_company_add_info_4"
          ></label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_add_info_4"
            placeholder="{{ 'sigeditor_field_compaddinfo4ex' | transloco }}"
            formControlName="c_add_info_4"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label
            for="c_add_info_5"
            transloco="placeholder_company_add_info_5"
          ></label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_add_info_5"
            placeholder="{{ 'sigeditor_field_compaddinfo5ex' | transloco }}"
            formControlName="c_add_info_5"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label
            for="c_add_info_6"
            transloco="placeholder_company_add_info_6"
          ></label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_add_info_6"
            placeholder="{{ 'sigeditor_field_compaddinfo6ex' | transloco }}"
            formControlName="c_add_info_6"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_disclaimer" transloco="SECTIONS.DISCLAIMER"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_disclaimer"
            placeholder="{{ 'sigeditor_field_disclaimerex' | transloco }}"
            formControlName="c_disclaimer"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_website" transloco="SECTIONS.WEBSITE_URL"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_website"
            placeholder="{{ 'sigeditor_field_web1ex' | transloco }}"
            formControlName="c_website"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_website_2" transloco="SECTIONS.WEBSITE_URL_2"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_website_2"
            placeholder="{{ 'sigeditor_field_web2ex' | transloco }}"
            formControlName="c_website_2"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="line"></div>
      <div class="section-title" transloco="SECTIONS.SOCIAL_MEDIA_TITLE"></div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_linkedin" transloco="SECTIONS.LINKEDIN"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_linkedin"
            placeholder="{{ 'sigeditor_field_linkedinex' | transloco }}"
            formControlName="c_linkedin"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_xing" transloco="SECTIONS.XING"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_xing"
            placeholder="{{ 'sigeditor_field_xingex' | transloco }}"
            formControlName="c_xing"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_fb" transloco="SECTIONS.FACEBOOK"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_fb"
            placeholder="{{ 'sigeditor_field_facebookex' | transloco }}"
            formControlName="c_fb"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_twitter" transloco="SECTIONS.TWITTER"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_twitter"
            placeholder="{{ 'sigeditor_field_facebookex' | transloco }}"
            formControlName="c_twitter"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">
          <label for="c_instagram" transloco="SECTIONS.INSTAGRAM"> </label>
        </div>
        <div class="col-8 formcontrolright">
          <input
            type="text"
            id="c_instagram"
            placeholder="{{ 'sigeditor_field_instagramex' | transloco }}"
            formControlName="c_instagram"
            maxlength="50"
            class="form-control input-sm"
          />
        </div>
      </div>
      <ng-container *ngIf="!isCognismSignatures">
        <div class="row form-group">
          <div class="col-4">
            <label for="c_youtube" transloco="SECTIONS.YOUTUBE"> </label>
          </div>
          <div class="col-8 formcontrolright">
            <input
              type="text"
              id="c_youtube"
              placeholder="{{ 'sigeditor_field_youtubeex' | transloco }}"
              formControlName="c_youtube"
              maxlength="50"
              class="form-control input-sm"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="c_pinterest" transloco="SECTIONS.PINTEREST"> </label>
          </div>
          <div class="col-8 formcontrolright">
            <input
              type="text"
              id="c_pinterest"
              placeholder="{{ 'sigeditor_field_pinterestex' | transloco }}"
              formControlName="c_pinterest"
              maxlength="50"
              class="form-control input-sm"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="c_whatsapp" transloco="SECTIONS.WHATSAPP"> </label>
          </div>
          <div class="col-8 formcontrolright">
            <input
              type="text"
              id="c_whatsapp"
              placeholder="{{ 'sigeditor_field_whatsappex' | transloco }}"
              formControlName="c_whatsapp"
              maxlength="50"
              class="form-control input-sm"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="c_snapc" transloco="SECTIONS.SNAPC"> </label>
          </div>
          <div class="col-8 formcontrolright">
            <input
              type="text"
              id="c_snapc"
              placeholder="{{ 'sigeditor_field_snapcex' | transloco }}"
              formControlName="c_snapc"
              maxlength="50"
              class="form-control input-sm"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="c_googlep" transloco="SECTIONS.GOOGLEP"> </label>
          </div>
          <div class="col-8 formcontrolright">
            <input
              type="text"
              id="c_googlep"
              placeholder="{{ 'webseite1_linktext_example' | transloco }}"
              formControlName="c_googlep"
              maxlength="50"
              class="form-control input-sm"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <label for="c_blog" transloco="SECTIONS.BLOG"> </label>
          </div>
          <div class="col-8 formcontrolright">
            <input
              type="text"
              id="c_blog"
              placeholder="{{ 'sigeditor_field_blogex' | transloco }}"
              formControlName="c_blog"
              maxlength="50"
              class="form-control input-sm"
            />
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div modal-footer-right>
    <div class="flex">
      <mt-button
        (click)="modal.close(false)"
        class="mt_left"
        color="default"
        text="abort"
      ></mt-button>
      <mt-button
        (click)="saveEmployeeCompanyData(dataset)"
        class="roboto_light"
        color="primary"
        text="endprematurely"
      >
      </mt-button>
    </div>
  </div>
</mt-custom-modal>

<ng-template #loading>
  <mt-emptystate [loadingPromise]="true"></mt-emptystate>
</ng-template>
