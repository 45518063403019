<span
  class="info-icon baseline-top"
  tooltipClass="{{ tooltipClass }} status-info-tooltip"
  tooltipPlacement="top"
  ngbTooltip="{{ tooltip | transloco }}"
>
  <mt-svg
    [filter]="filter"
    src="assets/images/svgs/{{ icon }}.svg"
    [size]="size"
  ></mt-svg>
</span>
