import {
  IResponse,
  IResponseMessage,
  IResponseMessageStatus,
  ResponseDataOrCode,
  ResponseMessageOrCode
} from 'src/app/model/interfaces/response.interface';
import { TSignaturePlaceholderValue } from 'src/app/model/interfaces/signature.interface';
import { ECountry } from 'src/app/model/enums/country.enum';
import { IAdmin } from 'src/app/model/interfaces/admin.interface';

export interface IUserGetAccountData {
  activationCode?: string;
  anonymousTracking: boolean;
  appleMailSyncNewUsers?: boolean;
  billingEntity?: string;
  chargifyCustomerNumber?: number;
  companyName?: string;
  connectionErrorOnO365?: boolean;
  customCampaignAltText?: string;
  customDefaultIcons?: string;
  disableCampaignTracking?: boolean;
  email: string;
  employeeCount: number;
  firstname?: string;
  forceAllow?: boolean;
  forwardMailsTo?: string;
  fullName: string;
  hasABMCampaigns?: boolean;
  hasActionfeed?: boolean;
  hasCampaignplanningEvent?: boolean;
  hasCampaignTracking?: boolean;
  hasMultiAdmin?: boolean;
  hasNotifs?: boolean;
  hasTargetLists?: boolean;
  hideTop3Employees?: boolean;
  hideUsernameInLastClick?: boolean;
  id: string;
  isCommunityEditionAccount?: boolean;
  lastname?: string;
  mailPolicy?: string;
  maxCommunityUsers: number;
  notificationLanguage?: string;
  outlookMacSyncNewUsers?: boolean;
  showLeads?: boolean;
  ssoOnly?: boolean;
  tourSeen?: boolean;
}

export interface IUserLoginFailed extends IResponseMessageStatus {
  attempts: number | boolean;
}

export interface IUserLoginSuccess extends ResponseMessageOrCode {
  accountId: string;
  adminId: string;
  amountOfEmployees: number;
  companyName: string;
  createdAt: Date;
  defaultAdmin: boolean;
  email: string;
  firstname: string;
  gender: string;
  isCommunityEditionAccount: boolean;
  isSuperAdmin: boolean;
  isUserLoggedInFirstTime: boolean;
  lastname: string;
  maxCommunityUsers?: number;
  token: string;
  userId: string;
}

export interface IUserSetAccountData {
  amountOfEmployees?: number;
  billingEntity?: ECountry;
  chanel?: string;
  companyName: string;
  email: string;
  firstname: string;
  gender: string;
  hideTop3Employees?: boolean;
  hideUsernameInLastClick?: boolean;
  lastname: string;
  referer?: string;
  // TODO: check registrationCompleted
}

export interface IUserOptOutDetails {
  email: string;
  gRecaptchaToken: string;
}

export interface IUserUpdateNewAdminByInvitation extends IResponseMessage {
  login: IAdmin;
}

export interface IUserCheckInvitationCode extends ResponseDataOrCode {
  email?: string;
}

export interface IUserSetCompanyInfoSingle extends IResponse {
  savedObject?: TSignaturePlaceholderValue;
}

export interface ResponseEasySync {
  disableAppleMailSync: boolean;
  disableEasySync: boolean;
  disableOutlookMacSync: boolean;
  disableUpdate: boolean;
}

/**
 * Check if response is of failed login type
 * @param variableToCheck - Response to check
 * @returns Whether variable is of type failed login
 */
export const isLoginFailed = (variableToCheck: unknown): variableToCheck is IUserLoginFailed => {
  return (variableToCheck as IUserLoginFailed).success === false;
};

export interface UserLoggedInfo {
  companyName?: string;
  companyWebsite?: string;
  country?: string;
  demoRequest?: boolean;
  firstName?: string;
  howDoYouWantToDoFirst?: {
    answer: string;
  };
  howDoYouWantToProcess?: {
    answer: string;
  };
  jobTitle?: string;
  language?: string;
  lastName?: string;
  phoneNumber?: string;
  whatBringsYouHere?: {
    answers: string[];
    other: string;
  };
  whatIsYourRole?: {
    answer: string;
    other: string;
  };
  whereDoYourEmployeeDataLive?: {
    answer: string;
    other: string;
  };
  whichCrmDoYouHaveInPlace?: {
    answers: string[];
    other: string;
  };
  utm?: UtmSignUp & UtmSignUpFirst;
}

export interface UtmSignUp {
  campaign: string;
  content: string;
  medium: string;
  source: string;
  term: string;
}

export interface UtmSignUpFirst {
  campaignFirst: string;
  contentFirst: string;
  mediumFirst: string;
  sourceFirst: string;
  termFirst: string;
}

export interface GetOverallStatsResponse {
  amountOfCampaigns: number;
  amountOfUsers: number;
  chargifyCustomerNumber?: boolean;
  companyName: string;
  createdAt: Date;
  email: string;
  forceAllow?: boolean;
  forwardMailsTo?: boolean;
  hasMultiSignature: boolean;
  hideTop3Employees?: boolean;
  isCommunityEditionAccount: boolean;
  isLocked: boolean;
  mailPolicy: string;
  maxCommunityUsers: number;
  newLeadsSeen: boolean;
  registrationCompleted: boolean;
  tourSeen?: boolean;
  userName: string;
}

export interface ChangeEasySyncSettings extends ResponseEasySync {
  id: string;
  updatedAt: string;
}

export interface RegisterForm {
  email: string;
  password: string;
  policyChecked: string;
  policyCheckedSecond: string;
  repeatPassword: string;
}

export interface CompanyForm {
  amountOfEmployees: number;
  companyName: string;
  firstName: string;
  gender: string;
  lastName: string;
}

export type UserGetOutlookAddinSettings = {
  webEnabled: boolean;
  desktopEnabled: boolean;
  mobileEnabled: boolean;
};

export type UserChangeOutlookAddinSettings = {
  id: string;
  outlookAddinEnabledClients: number;
  updatedAt: Date;
};

export type ActivateExternalAdminResponse = Pick<IUserGetAccountData, 'companyName' | 'email'>;

export type CustomerEmails = {
  email: string;
};

export type CustomerLogin = {
  customerEmail: string;
  isSuperAdminLogin: boolean;
};
