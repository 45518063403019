<div class="flex sidebarcolumnlayout">
  <!-- Top3 menus -->
  <!-- First three menus of sidebar section including company, signatures and marketings -->
  <ul class="main-menu">
    <ng-container
      *ngFor="let menus of topMenus"
      [ngTemplateOutlet]="renderMenus"
      [ngTemplateOutletContext]="{ menus }"
    ></ng-container>
  </ul>

  <!-- Bottom3 menus -->
  <!-- Bottom three menus of sidebar section including integrations, guide, news and settings -->
  <ul class="main-menu">
    <ng-container
      *ngFor="let menus of bottomMenus"
      [ngTemplateOutlet]="renderMenus"
      [ngTemplateOutletContext]="{ menus }"
    ></ng-container>

    <!-- Bottom settings menu -->
    <!-- Settings menu -->
    <li
      id="settings"
      class="mt-menu-icon-wrapper"
      [ngClass]="{
        'mt-menu-icon-wrapper-active': isSubmenuSectionActive(
          'settings',
          submenuOpened
        )
      }"
      (mouseenter)="showSettingsTooltip.emit(settingsTooltip)"
      (click)="showSettingsTooltip.emit(settingsTooltip)"
      (mouseleave)="hideSettingsTooltip.emit(settingsTooltip)"
      placement="right"
      [ngbTooltip]="mtMenuTooltip"
      [autoClose]="false"
      triggers="manual"
      #settingsTooltip="ngbTooltip"
      tooltipClass="mt-menu-tooltip-wrapper mt-menu-tooltip-wrapper_menu settings-tooltip side-bar-tooltip navigation-sidemenu-settings-tooltip"
      container="body"
    >
      <div class="mt-menu-icon">
        <mt-svg class="mt-white" src="assets/images/sidebar/settings.svg">
        </mt-svg>
      </div>
    </li>
  </ul>
</div>

<ng-template let-menus="menus" #renderMenus>
  <li
    *ngIf="menus.name && menus.name !== 'news'"
    [id]="menus.name"
    class="mt-menu-icon-wrapper"
    [ngClass]="{
      'mt-menu-icon-wrapper-active':
        isSubmenuSectionActive(menus.name, submenuOpened) ||
        activeRoute === menus.url
    }"
    container="body"
    placement="right"
    tooltip-trigger="'none'"
    tooltipClass="side-bar-tooltip"
    ngbTooltip="{{ tooltips.message | transloco }}"
    (mouseenter)="showTooltip.emit(menus.name)"
    (mouseleave)="hideTooltip.emit(menus.name)"
    (click)="menuClick(menus.name)"
  >
    <div class="mt-menu-icon">
      <mt-svg
        [ngClass]="
          menus.name === 'campaigns' && isUserOnBasicPlan
            ? 'mt-grau'
            : 'mt-white'
        "
        src="{{ menus.image }}"
      >
      </mt-svg>
    </div>
  </li>
  <li
    *ngIf="menus.name === 'settings'"
    id="settings"
    class="mt-menu-icon-wrapper"
    [ngClass]="{
      'mt-menu-icon-wrapper-active': isSubmenuSectionActive(
        'settings',
        submenuOpened
      )
    }"
    (mouseenter)="showSettingsTooltip.emit(settingsTooltip)"
    (click)="showSettingsTooltip.emit(settingsTooltip)"
    (mouseleave)="hideSettingsTooltip.emit(settingsTooltip)"
    placement="right"
    [ngbTooltip]="mtMenuTooltip"
    [autoClose]="false"
    triggers="manual"
    #settingsTooltip="ngbTooltip"
    tooltipClass="mt-menu-tooltip-wrapper mt-menu-tooltip-wrapper_menu settings-tooltip side-bar-tooltip navigation-sidemenu-settings-tooltip"
    container="body"
  >
    <div class="mt-menu-icon">
      <mt-svg class="mt-white" src="assets/images/sidebar/settings.svg">
      </mt-svg>
    </div>
  </li>
</ng-template>

<!-- Settings tooltip menu  -->
<ng-template #mtMenuTooltip>
  <mt-navigation-sidebar-settings-tooltip
    *ngIf="tooltips.opened.settings"
    (mouseleave)="hideTooltip.emit('settings')"
    (toggleSettingsSubmenu)="toggleSubmenuSidebar.emit($event)"
    (toggleLanguageSubTooltip)="showHideLanguageSubtooltip.emit()"
    [tooltips]="tooltips"
    (toggleSettingsTooltip)="showHideSettingsTooltip.emit($event)"
  ></mt-navigation-sidebar-settings-tooltip>
</ng-template>
