import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SignatureInstallSelectionType } from '@model/interfaces/signature.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

// Services
import { AccountService } from 'src/app/services/account/account.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'mt-install-signature-modal-to-choose',
  templateUrl: './install-signature-modal-to-choose.component.html',
  styleUrls: ['./install-signature-modal-to-choose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallSignatureModalToChooseComponent {
  /**
   * Determines the signatureId for installation
   * @defaultValue ''
   */
  @Input() signatureId = '';

  /**
   * Determines admin is external admin or not
   * @defaultValue false
   */
  @Input() isExternalAdmin = false;

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private modalService: ModalService,
    public authService: AuthService,
    public modal: NgbActiveModal,
    public translate: TranslocoService
  ) {}

  /**
   * To display the signature install guide for selected option you or college
   * @param step - Selected for which signature to install you or college
   * @param userId - Loggedin userId if exist
   */
  stepTwoForSignatureInstallGuide(step: SignatureInstallSelectionType, userId: string): void {
    if (step === 'forMe' && this.isExternalAdmin) {
      void this.alert.defaultInfoMessage(
        this.translate.translate('alert_installsig_notaccess_to_external_admin') as string
      );
    } else if (!userId && step === 'forMe') {
      void this.alert.defaultInfoMessage(
        this.translate.translate('html.param.no_user_available_to_install_sig', {
          value: this.accountService.getUserAccountData().email
        }) as string,
        this.translate.translate('ups') as string
      );
    } else {
      if (step === 'forMe') {
        this.modalService.openInstallSignatureModal(this.signatureId, userId);
      } else {
        this.modalService.openInstallSignatureModalForColleagues(this.signatureId, userId, this.isExternalAdmin);
      }
      this.modal.close();
    }
  }

  /**
   * To play the video on click of image
   * @param url - The translation tag of youtube link
   * @param title - The translation tag of the title
   */
  async videoPlay(url: string, title: string): Promise<void> {
    // Opens the modal and play the video within
    await this.modalService.openPlayVideoModal(url, title);
  }
}
