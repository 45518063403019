import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('assets') && request.url !== 'https://email-signature-uploader.dev.jsguru.io') {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.auth.getToken()}`
        },
        url: `${environment.apiUrl}${request.url}`
      });
    }
    return next.handle(request);
  }
}
