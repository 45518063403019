<div
  class="wizard baseonecolumn mt_modal_with_head modal-container-position"
  cg-busy="promise"
>
  <div class="modal-header">
    <div class="flex-center-space-between modal-title-width">
      <div class="title" transloco="{{ modalTitle }}"></div>
      <div class="cursor-pointer close close-btn" (click)="cancel()">
        <mt-svg
          class="x-button"
          src="assets/images/modals/close_other_svg.svg"
        ></mt-svg>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <form #inviteAdminForm="ngForm">
      <div class="content modal-body-position">
        <div class="schrift_blau infoline" transloco="{{ modalBody }}"></div>
        <input
          id="inputPassword"
          [(ngModel)]="inputModel"
          name="inviteAdminEmail"
          type="text"
          class="form-control form input-sm"
          required
          email
        />
      </div>
    </form>

    <div
      class="infobox full-width-imp infobox-info error-message-info"
      *ngIf="showMessage"
    >
      <span class="symbol glyphicon glyphicon-question-sign"></span>
      <div class="text" transloco="{{ modalInfoMessage }}"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="flex-center-space-between">
      <div class="flex"></div>

      <div class="flex">
        <mt-button
          (click)="cancel()"
          class="mt_left"
          color="default"
          text="abort"
        ></mt-button>
        <mt-button
          (click)="submit()"
          [disabled]="!inputModel || inviteAdminForm.form.invalid"
          color="primary"
          icon="plus"
          text="send_invitation"
        >
        </mt-button>
      </div>
    </div>
  </div>
</div>
