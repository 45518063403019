<mt-custom-modal headline="{{ inputData.title | transloco }}">
  <!-- Modal Body -->
  <ng-container modal-body>
    <div
      class="campaign_creation_modal"
      *ngIf="authService.authorizationScope$ | async as authorizationScope"
    >
      <div class="baseonecolumn existing">
        <div class="flex-space-between selection">
          <!-- sender based campaign creation -->
          <div
            (click)="openUrl(inputData.routeSender); modal.dismiss('cancelled')"
            [ngClass]="{
              'margin-auto': !(
                authorizationScope.hasTargetLists &&
                authorizationScope.hasABMCampaigns
              )
            }"
            class="flex-center-left"
          >
            <div class="campaign_creation_path_wrapper">
              <img
                src="assets/images/campaigns/campaign_type_senderbased.png"
                class="campaign_image"
              />
              <br />
              <span class="campaign_based" transloco="sender_based"></span>
              <br />
              <span
                class="campaign_info"
                transloco="{{ inputData.textSender }}"
              ></span>
              <br />
              <mt-button text="create" color="primary"></mt-button>
            </div>
          </div>

          <!-- recipient based campaign creation -->
          <div
            *ngIf="authorizationScope.hasTargetLists"
            (click)="modal.dismiss('cancelled')"
            [routerLink]="[inputData.routeTarget]"
            class="flex-center-right marleft15"
          >
            <div class="campaign_creation_path_wrapper">
              <img
                src="assets/images/campaigns/campaign_type_targetgroupbased.png"
                class="campaign_image"
              />
              <br />
              <span
                class="campaign_based"
                transloco="target_group_based"
              ></span>
              <br />
              <span
                class="campaign_info"
                transloco="{{ inputData.textTarget }}"
              ></span>
              <br />
              <mt-button text="create" color="primary"></mt-button>
            </div>
          </div>
        </div>

        <div class="about_campaign_types">
          <a
            transloco="{{ inputData.linkText }}"
            target="_blank"
            href="{{ inputData.link }}"
          ></a>
        </div>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
