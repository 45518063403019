import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';
import { UserService } from 'src/app/services/user/user.service';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import {
  ICampaignGet,
  ICampaignStatistics,
  StatisticsCampaignResponse
} from 'src/app/services/campaign/campaign-services.interface';
import { GroupService } from 'src/app/services/group/group.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';

import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbInputDatepicker,
  NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import { differenceInDays, format, isAfter, parseISO, sub } from 'date-fns';
import { PieChartComponent, LineChartComponent, LegendPosition, LineChartModule } from '@swimlane/ngx-charts';

import * as shape from 'd3-shape';

import { AllPointsShownService } from 'src/app/shared/services/allPointsShown.service';
import { IGroupGetStatistics } from 'src/app/services/group/group-service.interface';
import { IEmployeeStats } from 'src/app/services/employee/employee-service.interface';
import { ICampaignModel } from 'src/app/model/interfaces/campaign.interface';
import {
  BarData,
  CustomColor,
  LineChartData,
  LineChartDataPoint,
  PieChartData
} from 'src/app/model/interfaces/line-graph.interface';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';
import {
  StatisticsTargetGroupResponse,
  ITargetGroupListEntriesStatistics,
  TargetGroupGetTargetGroupEntries
} from 'src/app/services/target-group/target-group-service.type';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgFor, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { ShortNumberPipe } from '@shared/pipes/short-number/short-number.pipe';

type ShowStatistic = 'clicks' | 'clickRate' | 'views';

type SelectedCampaign = 'All' | 'Active' | string;

/**
 * Type of when trigger event when click on line on line chart
 */
type LineChartEventData = LineChartDataPoint & { series: string };

/**
 * To check object type is line chart data
 */
const isLineChartEventData = (obj: unknown): obj is LineChartEventData => {
  return !!(obj as LineChartEventData).series;
};

@Component({
  selector: 'mt-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
  standalone: true,
  providers: [AllPointsShownService],
  imports: [
    AsyncPipe,
    DecimalPipe,
    FormsModule,
    LineChartModule,
    NgbInputDatepicker,
    NgbTooltip,
    NgClass,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    ShortNumberPipe,
    TranslocoModule
  ]
})
export class LineGraphComponent implements OnInit {
  @ViewChild('lineChart') lineChart!: LineChartComponent;
  @ViewChild('pieChart') pieChart!: PieChartComponent;

  /**
   * Dropdown of shown campaign on line chart
   */
  selectedCampaign = new FormControl<SelectedCampaign>('Active', { nonNullable: true });

  /**
   * Change the displayed campaign in the line chart
   */
  selectedCampaign$ = this.selectedCampaign.valueChanges.pipe(
    tap(val => {
      // Display the data with selected options
      this.determineDataToShow(this.lineChartOptions.typeOfSelectedData, val);
    })
  );

  lineChartOptions = {
    activeCampaignsData: [] as LineChartData[],
    allData: [] as LineChartData[],
    clicksData: [] as LineChartData[],
    curve: shape.curveMonotoneX,
    customColors: [] as CustomColor[],
    dataToShow: [] as LineChartData[],
    displayedColors: [] as CustomColor[],
    impressionsData: [] as LineChartData[],
    interpolationValue: 0,
    labels: [] as string[],
    legendPosition: LegendPosition.Below,
    rateData: [] as LineChartData[],
    totalShown: 0,
    typeOfSelectedData: 'views' as ShowStatistic
  };

  pieChartOptions = {
    clicksData: [] as PieChartData[],
    customColors: [] as CustomColor[],
    dataToShow: [] as PieChartData[],
    impressionsData: [] as PieChartData[],
    progressClicks: [] as BarData[],
    progressImpressions: [] as BarData[],
    progressRate: [] as BarData[],
    rateData: [] as PieChartData[],
    showRelativeBarData: false, // Used to determine which type of data the progress bars show
    totalClicks: 0,
    totalImpressions: 0,
    totalRate: 0,
    typeOfSelectedData: {
      clickRate: false,
      clicks: false,
      // Used to determine which type of data is being shown
      views: true
    }
  };

  best3Criteria = {
    best3Employees: [] as IEmployeeStats[],
    employees: [] as IEmployeeStats[],
    sortBy: {
      clickRate: false,
      clicks: false,
      // Used to determine which type of data is being sorted by
      views: true
    }
  };

  overAllStats = {
    amountOfCampaigns: 0,
    amountOfClicks: 0,
    amountOfUsers: 0,
    amountOfViews: 0,
    companyName: ' ',
    email: '',
    hideTop3Employees: false,
    userName: ''
  };

  allCampaigns: ICampaignModel[] = [];
  activeCampaigns: ICampaignGet[] = [];
  activeCampaignIds: number[] = [];

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  dateRange = '';

  /**
   * Title for linechart section
   * @defaultValue ''
   */
  @Input() lineTitle = '';

  /**
   * Show line chart if passed campaign id
   */
  @Input() campaignId?: string;

  /**
   * Show line chart as per the target group id
   */
  @Input() targetGroupId?: string;

  /**
   * Show line chart as per the target group entry id
   */
  @Input() targetGroupEntryId?: string;

  /**
   * Recipients details page to show target group entry information
   */
  targetGroupEntry!: TargetGroupGetTargetGroupEntries;

  constructor(
    private allPointsShownService: AllPointsShownService,
    private calendar: NgbCalendar,
    private campaignService: CampaignService,
    private cdr: ChangeDetectorRef,
    private employeeService: EmployeeService,
    private formatter: NgbDateParserFormatter,
    private groupService: GroupService,
    private targetGroupService: TargetGroupService,
    private userService: UserService
  ) {
    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = calendar.getToday();
    this.dateRange = this.formatSelectedDates(this.fromDate, this.toDate);
  }

  ngOnInit(): void {
    // By default set start date to 30 days ago and end date to today
    const endDate = format(new Date(), 'yyyy-MM-dd');
    const beginDate = format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd');

    const stats$ = this.userService.getOverallStats();

    if (this.targetGroupEntryId) {
      // To store target group entry details
      const targetGroupEntry$ = this.targetGroupService
        .getTargetGroupEntry(this.targetGroupEntryId, '', { doNotUseProspects: true })
        .pipe(tap(targetGroupEntry => (this.targetGroupEntry = targetGroupEntry)));

      const targetGroupEntryStats$ = this.targetGroupService.getTargetGroupEntryStatisticsSingle(
        beginDate,
        endDate,
        this.targetGroupId,
        this.targetGroupEntryId
      );

      forkJoin([stats$, targetGroupEntry$, targetGroupEntryStats$]).subscribe(result => {
        this.setOverAllStats(result[0]);

        this.overAllStats.amountOfClicks += this.targetGroupEntry.clicks;
        this.overAllStats.amountOfViews += this.targetGroupEntry.views;

        // Create line chart data
        this.createTGEntriesChartData(result[2], 'views', this.selectedCampaign.value, beginDate, endDate);
      });
    } else {
      let campaigns$: Observable<ICampaignGet | ICampaignGet[]>;

      if (this.campaignId) {
        campaigns$ = this.campaignService
          .getOne(Number(this.campaignId))
          .pipe(tap(campaigns => (this.allCampaigns[0] = campaigns)));
      } else {
        campaigns$ = this.campaignService.get().pipe(
          tap(campaigns => (this.allCampaigns = campaigns)), // Save all campaigns
          map(campaigns => campaigns.filter(campaign => this.isCampaignActive(campaign))) // Filter inactive campaigns
        );
      }

      let campaingsStats$: Observable<ICampaignStatistics>;
      if (this.campaignId)
        campaingsStats$ = this.campaignService.getStatisticsSingle(beginDate, endDate, this.campaignId);
      else campaingsStats$ = this.campaignService.getStatistics(beginDate, endDate);

      const groupStats$ = this.groupService.getStatstics();
      const employeeStats$ = this.employeeService.getWithImpressionsAndClicks();

      forkJoin([stats$, campaigns$, campaingsStats$, groupStats$, employeeStats$]).subscribe(result => {
        this.setOverAllStats(result[0]);

        if (this.campaignId) this.activeCampaigns.push(result[1] as ICampaignGet);
        else this.activeCampaigns = result[1] as ICampaignGet[];
        this.activeCampaigns.map(campaign => {
          this.activeCampaignIds.push(campaign.id);
          this.overAllStats.amountOfClicks += campaign.clicks;
          this.overAllStats.amountOfViews += campaign.views;
        });

        // Create line chart data
        this.createChartData(result[2], 'views', this.selectedCampaign.value, beginDate, endDate);

        // Create pie chart data when there are group statistics available
        if (result[3].views.length > 0 || result[3].clicks.length > 0) this.createPieChartData(result[3]);

        if (result[4].success && result[4].data.length > 0) {
          this.best3Criteria.employees = result[4].data;
          this.changeBest3CriteriaSort();
        }
      });
    }
  }

  /**
   * Formation of basic information from API response
   * @param res - Object of GetOverallStatsResponse
   */
  setOverAllStats(res: GetOverallStatsResponse[]): void {
    if (res.length > 0) {
      this.overAllStats = {
        ...this.overAllStats,
        amountOfCampaigns: res[0].amountOfCampaigns,
        amountOfUsers: res[0].amountOfUsers,
        companyName: res[0].companyName,
        email: res[0].email,
        hideTop3Employees: res[0].hideTop3Employees || false,
        userName: res[0].userName
      };
    }
  }

  /**
   * Updates the line chart according to the range of time specified from the datepicker
   * @param selectedCampaign - New selected campaign
   * @param beginDate - New starting date
   * @param endDate - New ending date
   */
  changeDateRange(selectedCampaign: SelectedCampaign, beginDate: string, endDate: string): void {
    this.resetLineChartData();
    // Check whether from target group entry or campaign
    if (this.targetGroupEntryId) {
      this.targetGroupService
        .getTargetGroupEntryStatisticsSingle(beginDate, endDate, this.targetGroupId, this.targetGroupEntryId)
        .subscribe(result =>
          this.createTGEntriesChartData(
            result,
            this.lineChartOptions.typeOfSelectedData,
            selectedCampaign,
            beginDate,
            endDate
          )
        );
    } else {
      this.campaignService
        .getStatistics(beginDate, endDate)
        .subscribe(result =>
          this.createChartData(result, this.lineChartOptions.typeOfSelectedData, selectedCampaign, beginDate, endDate)
        );
    }
  }

  /**
   * Generate data to be shown on the chart
   * @param data - Statistics data for the given period
   * @param typeOfData - New data type to display
   * @param selectedCampaign - New selected campaign
   * @param begin - Start date
   * @param end - End date
   */
  createChartData(
    data: ICampaignStatistics,
    typeOfData: ShowStatistic,
    selectedCampaign: SelectedCampaign,
    begin: string,
    end: string
  ): void {
    // Generate labels for the specified time frame
    this.generateGraphLabels(begin, end);

    // Process the data
    const seriesData = this.createSeriesDataForChart(this.lineChartOptions.labels, data);

    // Save all data
    this.lineChartOptions.allData = seriesData;

    // Save data from active campaigns
    seriesData.map(series => {
      const isActive = this.activeCampaigns.find(campaign => campaign.title === series.name);
      if (isActive) {
        this.lineChartOptions.activeCampaignsData.push(series);
      }
    });

    // Display the data with default options
    this.determineDataToShow(typeOfData, selectedCampaign);
  }

  /**
   * Process statistics data and generate series for each active campaign and corresponding day
   * @param labels - Days to generate data for
   * @param data - Statistics data to process
   * @returns Array with impressions, clicks and click rate data
   */
  createSeriesDataForChart(labels: string[], data: ICampaignStatistics): LineChartData[] {
    const allSeries: LineChartData[] = [];

    this.lineChartOptions.customColors = [];

    for (const campaign of this.allCampaigns) {
      this.lineChartOptions.customColors.push({
        name: campaign.title,
        value: campaign.color
      } as CustomColor);

      const viewsSeries: LineChartData = {
        name: campaign.title,
        series: [],
        type: 'views'
      };

      const clicksSeries: LineChartData = {
        name: campaign.title,
        series: [],
        type: 'clicks'
      };

      const clickRateSeries: LineChartData = {
        name: campaign.title,
        series: [],
        type: 'clickRate'
      };

      for (const label of labels) {
        const foundViewEntry: StatisticsCampaignResponse | undefined = data.views.find(view => {
          return view.campaignId === campaign.id && format(parseISO(view.day), 'd.MM') === label;
        });

        const foundClickEntry: StatisticsCampaignResponse | undefined = data.clicks.find(click => {
          return click.campaignId === campaign.id && format(parseISO(click.day), 'd.MM') === label;
        });

        const clickRate = this.calculateClickRateForPoint(foundViewEntry, foundClickEntry);

        const currentView: LineChartDataPoint = {
          name: label,
          value: foundViewEntry ? foundViewEntry.anzahl : 0,
          extra: {
            impressions: foundViewEntry ? foundViewEntry.anzahl : 0,
            clicks: foundClickEntry ? foundClickEntry.anzahl : 0,
            clickRate,
            label
          }
        };

        const currentClick: LineChartDataPoint = {
          name: label,
          value: foundClickEntry ? foundClickEntry.anzahl : 0,
          extra: {
            impressions: foundViewEntry ? foundViewEntry.anzahl : 0,
            clicks: foundClickEntry ? foundClickEntry.anzahl : 0,
            clickRate,
            label
          }
        };

        const currentClickRate: LineChartDataPoint = {
          name: label,
          value: clickRate,
          extra: {
            impressions: foundViewEntry ? foundViewEntry.anzahl : 0,
            clicks: foundClickEntry ? foundClickEntry.anzahl : 0,
            clickRate,
            label
          }
        };

        viewsSeries.series.push(currentView);
        clicksSeries.series.push(currentClick);
        clickRateSeries.series.push(currentClickRate);
      }

      allSeries.push(viewsSeries);
      allSeries.push(clicksSeries);
      allSeries.push(clickRateSeries);
    }

    return allSeries;
  }

  /**
   * For target group entry data to be shown on the chart
   * @param data - Statistics data for the given period
   * @param typeOfData - New data type to display
   * @param selectedCampaign - New selected campaign
   * @param begin - Start date
   * @param end - End date
   */
  createTGEntriesChartData(
    data: ITargetGroupListEntriesStatistics,
    typeOfData: ShowStatistic,
    selectedCampaign: SelectedCampaign,
    begin: string,
    end: string
  ): void {
    // Generate labels for the specified time frame
    this.generateGraphLabels(begin, end);

    // Process the data
    const seriesData = this.createTGEntriesDataForChart(this.lineChartOptions.labels, data);

    // Save all data
    this.lineChartOptions.allData = seriesData;

    // Display the data with default options
    this.determineDataToShow(typeOfData, selectedCampaign);
  }

  /**
   * For process target group entry statistics data and generate series for corresponding day
   * @param labels - Days to generate data for
   * @param data - Statistics data to process
   * @returns Array with impressions, clicks and click rate data
   */
  createTGEntriesDataForChart(labels: string[], data: ITargetGroupListEntriesStatistics): LineChartData[] {
    const allSeries: LineChartData[] = [];

    this.lineChartOptions.customColors = [];

    this.lineChartOptions.customColors.push({
      name: this.targetGroupEntry.content,
      value: '#009fe3'
    } as CustomColor);

    const viewsSeries: LineChartData = {
      name: this.targetGroupEntry.content,
      series: [],
      type: 'views'
    };

    const clicksSeries: LineChartData = {
      name: this.targetGroupEntry.content,
      series: [],
      type: 'clicks'
    };

    const clickRateSeries: LineChartData = {
      name: this.targetGroupEntry.content,
      series: [],
      type: 'clickRate'
    };

    for (const label of labels) {
      const foundViewEntry: StatisticsTargetGroupResponse | undefined = data.views.find(view => {
        return view.targetGroupListEntryId === this.targetGroupEntry.id && format(parseISO(view.day), 'd.MM') === label;
      });

      const foundClickEntry: StatisticsTargetGroupResponse | undefined = data.clicks.find(click => {
        return (
          click.targetGroupListEntryId === this.targetGroupEntry.id && format(parseISO(click.day), 'd.MM') === label
        );
      });

      const clickRate = this.calculateClickRateForPoint(foundViewEntry, foundClickEntry);

      const currentView: LineChartDataPoint = {
        extra: {
          clickRate,
          clicks: foundClickEntry ? foundClickEntry.anzahl : 0,
          impressions: foundViewEntry ? foundViewEntry.anzahl : 0,
          label
        },
        name: label,
        value: foundViewEntry ? foundViewEntry.anzahl : 0
      };

      const currentClick: LineChartDataPoint = {
        extra: {
          clickRate,
          clicks: foundClickEntry ? foundClickEntry.anzahl : 0,
          impressions: foundViewEntry ? foundViewEntry.anzahl : 0,
          label
        },
        name: label,
        value: foundClickEntry ? foundClickEntry.anzahl : 0
      };

      const currentClickRate: LineChartDataPoint = {
        extra: {
          clickRate,
          clicks: foundClickEntry ? foundClickEntry.anzahl : 0,
          impressions: foundViewEntry ? foundViewEntry.anzahl : 0,
          label
        },
        name: label,
        value: clickRate
      };

      clickRateSeries.series.push(currentClickRate);
      clicksSeries.series.push(currentClick);
      viewsSeries.series.push(currentView);
    }

    allSeries.push(viewsSeries);
    allSeries.push(clicksSeries);
    allSeries.push(clickRateSeries);

    return allSeries;
  }

  /**
   * Given impression and click data for one point, calculate the click rate.
   * Round up the result to decimal places.
   * Returns 0 when no impression or click data.
   * @param view - Impression data
   * @param click - Click data
   * @returns Click rate
   */
  calculateClickRateForPoint(
    view?: StatisticsCampaignResponse | StatisticsTargetGroupResponse,
    click?: StatisticsCampaignResponse | StatisticsTargetGroupResponse
  ): number {
    if (view?.anzahl && view.anzahl > 0 && click?.anzahl && click.anzahl > 0) {
      const rate: number = (click.anzahl / view.anzahl) * 100;
      return Math.round(rate * 100) / 100;
    }

    return 0;
  }

  /**
   * Determine what type of data and for which campaign(s) to display it for.
   * By default, impressions data for active campaings is shown.
   * @param typeOfData - New data type to display
   * @param selectedCampaign - New selected campaign
   */
  determineDataToShow(typeOfData: ShowStatistic, selectedCampaign: SelectedCampaign): void {
    if (this.targetGroupEntryId) this.filterDataTypes(this.lineChartOptions.allData);
    else {
      if (selectedCampaign === 'All') this.filterDataTypes(this.lineChartOptions.allData);
      else if (selectedCampaign === 'Active') this.filterDataTypes(this.lineChartOptions.activeCampaignsData);
      else {
        const campaignDataToDisplay = this.lineChartOptions.allData.filter(series => series.name === selectedCampaign);
        this.filterDataTypes(campaignDataToDisplay);
      }
    }

    switch (typeOfData) {
      case 'views':
        this.lineChartOptions.dataToShow = this.lineChartOptions.impressionsData;
        break;
      case 'clicks':
        this.lineChartOptions.dataToShow = this.lineChartOptions.clicksData;
        break;
      case 'clickRate':
        this.lineChartOptions.dataToShow = this.lineChartOptions.rateData;
        break;
    }

    // When dealing with click rate we need to determine which campaings are being shown first
    if (typeOfData === 'clickRate') {
      // For active campaigns we already have the total amount of clicks and views so it's a direct calculation
      if (selectedCampaign === 'Active') {
        this.lineChartOptions.totalShown =
          this.overAllStats.amountOfClicks === 0 && this.overAllStats.amountOfViews === 0
            ? 0
            : Math.round((this.overAllStats.amountOfClicks / this.overAllStats.amountOfViews) * 100 * 100) / 100;
      } else {
        // For all campaigns we need to take into account the possible clicks and views from inactive campaings
        const totalClicks = this.lineChartOptions.clicksData.reduce((allLines, line) => {
          const sum = line.series.reduce((acc, cur) => {
            return acc + cur.value;
          }, 0);
          return allLines + sum;
        }, 0);

        const totalViews = this.lineChartOptions.impressionsData.reduce((allLines, line) => {
          const sum = line.series.reduce((acc, cur) => {
            return acc + cur.value;
          }, 0);
          return allLines + sum;
        }, 0);

        this.lineChartOptions.totalShown =
          totalClicks === 0 && totalViews === 0 ? 0 : Math.round((totalClicks / totalViews) * 100 * 100) / 100;
      }
    } else {
      // For clicks and views we simply calculate the sum of the data being displayed
      this.lineChartOptions.totalShown = this.lineChartOptions.dataToShow.reduce((allLines, line) => {
        const sum = line.series.reduce((acc, cur) => {
          return acc + cur.value;
        }, 0);
        return allLines + sum;
      }, 0);
    }

    // Determine the colors being displayed
    this.lineChartOptions.displayedColors = this.lineChartOptions.customColors.filter(color => {
      return this.lineChartOptions.dataToShow.find(data => {
        return color.name === data.name;
      });
    });

    // Used to display all points in the chart at all times
    // On a timeout to wait for the chart to be rendered
    setTimeout(() => {
      this.allPointsShownService.showDots(this.lineChart);
    }, 1000);
    this.cdr.detectChanges();
  }

  /**
   * Filters series data to different arrays depending on type
   * @param seriesData - Series data to filter
   */
  filterDataTypes(seriesData: LineChartData[]): void {
    this.lineChartOptions.impressionsData = seriesData.filter(data => data.type === 'views');
    this.lineChartOptions.clicksData = seriesData.filter(data => data.type === 'clicks');
    this.lineChartOptions.rateData = seriesData.filter(data => data.type === 'clickRate');
  }

  /**
   * Changes the displayed data type in the line chart.
   * Impressions by default.
   * @param typeOfData - New data type to display
   * @param selectedCampaign - New selected campaign
   */
  changeLineChartMode(typeOfData: ShowStatistic, selectedCampaign: SelectedCampaign): void {
    this.lineChartOptions.typeOfSelectedData = typeOfData;
    this.determineDataToShow(typeOfData, selectedCampaign);
  }

  /**
   * Show graph based on selected campaign form legend (Line chart label)
   * @param val - Campaign name Or Event object when click on line graph dot's
   */
  onSelectLegend(val: LineChartEventData | string): void {
    // Identify selected from lines into the graph or selected from legend
    const selectedCampaign = isLineChartEventData(val) ? val.series : val;
    // Set dropdown value for selected campaign
    this.selectedCampaign.setValue(this.selectedCampaign.value !== selectedCampaign ? selectedCampaign : 'Active');
  }

  /**
   * Generate labels for each day from start to end.
   * Used to determine which labels to hide in the graph.
   * @param beginDate - Starting date
   * @param endDate - Ending date
   */
  generateGraphLabels(beginDate: string, endDate: string): void {
    this.lineChartOptions.labels = [];

    const amountOfLabels = window.innerWidth < 600 ? 5 : 10;

    const parsedEnd = parseISO(endDate);
    const parsedBegin = parseISO(beginDate);
    const amountOfDays = differenceInDays(parsedEnd, parsedBegin);

    this.lineChartOptions.interpolationValue = amountOfDays < 11 ? 1 : Math.round(amountOfDays / amountOfLabels);

    for (let index = amountOfDays; index >= 0; index--) {
      this.lineChartOptions.labels.push(format(sub(parsedEnd, { days: index }), 'd.MM'));
    }
  }

  /**
   * Formats the x-axis labels for the line chart.
   * @param val - X-axis label
   * @returns String of x-axis label OR empty
   */
  xTickFormatting = (val: string): string => {
    for (let index = 0; index < this.lineChartOptions.labels.length; index++) {
      if (index % this.lineChartOptions.interpolationValue !== 0) {
        this.lineChartOptions.labels[index] = '';
      }
    }

    const found = this.lineChartOptions.labels.find(date => date === val);

    return found || '';
  };

  /**
   * Used to track campaign by id in the list of active campaigns
   * @param index - Position in the list
   * @param campaign - Campaign object
   * @returns Campaign id
   */
  trackByCampaign(index: number, campaign: ICampaignGet): number {
    return campaign.id;
  }

  /**
   * Determines if a campaign is active.
   * Active campaign was created before today AND
   * Is in at least 1 group, target group list or trigger event.
   * @param campaign - Campaign to check.
   * @returns Whether campaign is active or not.
   */
  isCampaignActive(campaign: ICampaignGet): boolean {
    const today = format(new Date(), 'yyyy-MM-dd');
    const isBeforeToday = isAfter(parseISO(today), parseISO(campaign.createdAt.toString()));

    const isAssigned =
      campaign.Groups.length > 0 || campaign.TargetGroupLists?.length > 0 || campaign.TriggerEvent.length > 0;

    return isBeforeToday && isAssigned;
  }

  /**
   * Resets line chart data to default.
   * Used when changing date range.
   */
  resetLineChartData(): void {
    this.lineChartOptions = {
      ...this.lineChartOptions,
      activeCampaignsData: [],
      allData: [],
      clicksData: [],
      customColors: [],
      dataToShow: [],
      displayedColors: [],
      impressionsData: [],
      interpolationValue: 0,
      labels: [],
      rateData: []
    };
  }

  /**
   * Generate data to be shown in the pie chart.
   * @param data - Group statistics data to process
   */
  createPieChartData(data: IGroupGetStatistics): void {
    this.pieChartOptions.impressionsData = [];
    this.pieChartOptions.clicksData = [];

    // Get total number of impressions, clicks and clickrate
    // Used to calculate a group's contribution
    this.pieChartOptions.totalImpressions = data.views.reduce((acc, curr) => acc + curr.anzahl, 0);
    this.pieChartOptions.totalClicks = data.clicks.reduce((acc, curr) => acc + curr.anzahl, 0);

    // Calculate the accumulated click rate of all groups
    data.views.map(view => {
      const foundClick = data.clicks.find(click => {
        return view.groupId === click.groupId;
      });

      if (foundClick) {
        this.pieChartOptions.totalRate += (foundClick.anzahl / view.anzahl) * 100;
      }
    });

    this.pieChartOptions.totalRate = Math.round(this.pieChartOptions.totalRate * 100) / 100;

    for (const view of data.views) {
      const title = view.title;
      const amount = view.anzahl;

      // TODO fix this color assignment part with better types
      // eslint-disable-next-line
      const pieChartColors = this.pieChart ? this.pieChart.colors : '';
      // eslint-disable-next-line
      const color = pieChartColors ? (pieChartColors.colorDomain[data.views.indexOf(view)] as string) : '';

      const percentage = (amount / this.pieChartOptions.totalImpressions) * 100;
      const contribution = Math.round(percentage * 1) / 1;

      this.pieChartOptions.impressionsData.push({
        name: title,
        value: amount
      });

      // Grab a color from the color scheme and assign it to the current group
      // Should never cause errors since if there are no views, there will be no clicks or click rate
      this.pieChartOptions.customColors.push({
        name: title,
        value: color
      });

      this.pieChartOptions.progressImpressions.push({
        name: title,
        value: amount,
        contribution,
        color
      });
    }

    for (const click of data.clicks) {
      const title = click.title;
      const amount = click.anzahl;
      const barColor = this.pieChartOptions.customColors.find(color => {
        return click.title === color.name && color.value;
      });

      const color = barColor ? barColor.value : '';

      const percentage = (amount / this.pieChartOptions.totalClicks) * 100;
      const contribution = Math.round(percentage * 1) / 1;

      this.pieChartOptions.clicksData.push({
        name: title,
        value: amount
      });

      this.pieChartOptions.progressClicks.push({
        name: title,
        value: amount,
        contribution,
        color
      });

      const foundView = data.views.find(view => {
        return view.groupId === click.groupId;
      });

      if (foundView) {
        const rate = (click.anzahl / foundView.anzahl) * 100;
        const value = Math.round(rate * 100) / 100;
        this.pieChartOptions.rateData.push({
          name: click.title,
          value
        });

        this.pieChartOptions.progressRate.push({
          name: title,
          value,
          contribution: Math.round((value / this.pieChartOptions.totalRate) * 100 * 1) / 1,
          color
        });
      }
    }

    // Sort bars descending by value
    this.pieChartOptions.progressImpressions.sort((a, b) => {
      return b.value - a.value;
    });

    this.pieChartOptions.progressClicks.sort((a, b) => {
      return b.value - a.value;
    });

    this.pieChartOptions.progressRate.sort((a, b) => {
      return b.value - a.value;
    });

    // Set the pie chart to the default impressions view
    this.changePieChartMode();
  }

  /**
   * Changes the displayed data type in the pie chart.
   * Impressions by default.
   * @param mode - New data type to display
   */
  changePieChartMode(mode = 'views'): void {
    switch (mode) {
      case 'views':
        this.pieChartOptions.typeOfSelectedData = {
          ...this.pieChartOptions.typeOfSelectedData,
          views: true,
          clicks: false,
          clickRate: false
        };
        this.pieChartOptions.dataToShow = this.pieChartOptions.impressionsData;
        break;
      case 'clicks':
        this.pieChartOptions.typeOfSelectedData = {
          ...this.pieChartOptions.typeOfSelectedData,
          views: false,
          clicks: true,
          clickRate: false
        };
        this.pieChartOptions.dataToShow = this.pieChartOptions.clicksData;
        break;
      case 'clickRate':
        this.pieChartOptions.typeOfSelectedData = {
          ...this.pieChartOptions.typeOfSelectedData,
          views: false,
          clicks: false,
          clickRate: true
        };
        this.pieChartOptions.dataToShow = this.pieChartOptions.rateData;
        break;
    }
  }

  /**
   * Changes the sorting type of the best 3 employees.
   * @param mode - Data type to sort by
   */
  changeBest3CriteriaSort(mode = 'views'): void {
    switch (mode) {
      case 'views':
        this.best3Criteria.sortBy = {
          ...this.best3Criteria.sortBy,
          views: true,
          clicks: false,
          clickRate: false
        };
        this.best3Criteria.best3Employees = this.best3Criteria.employees.sort((a, b) => {
          return b.views - a.views;
        });
        break;
      case 'clicks':
        this.best3Criteria.sortBy = {
          ...this.best3Criteria.sortBy,
          views: false,
          clicks: true,
          clickRate: false
        };
        this.best3Criteria.best3Employees = this.best3Criteria.employees.sort((a, b) => {
          return b.clicks - a.clicks;
        });
        break;
      case 'clickRate':
        this.best3Criteria.sortBy = {
          ...this.best3Criteria.sortBy,
          views: false,
          clicks: false,
          clickRate: true
        };
        this.best3Criteria.best3Employees = this.best3Criteria.employees.sort((a, b) => {
          return b.clicks / b.views - a.clicks / a.views;
        });
        break;
    }
    this.best3Criteria.best3Employees = this.best3Criteria.best3Employees.slice(0, 3);
  }

  /**
   * Open the modal to create a campaign
   */
  goCreateCampaign(): void {
    //TODO: implement create campaign modal
  }

  /**
   * Action done when a date is selected.
   * @param date - Date selected
   * @param selectedCampaign - New selected campaign
   */
  onDateSelection(date: NgbDate, selectedCampaign: SelectedCampaign): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.dateRange = this.formatSelectedDates(this.fromDate, this.toDate);
      this.changeDateRange(selectedCampaign, this.formatter.format(this.fromDate), this.formatter.format(this.toDate));
      // calendar.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  /**
   * Check date is hovered
   * @param date - Object of date
   * @returns Boolean if date is hovered
   */
  isHovered(date: NgbDate): boolean {
    return !!(
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  /**
   * Date is inside in range
   * @param date - Object of date
   * @returns Boolean if date inside range
   */
  isInside(date: NgbDate): boolean {
    return !!(this.toDate && date.after(this.fromDate) && date.before(this.toDate));
  }

  /**
   * Check date in range of selection
   * @param date - Object of date
   * @returns Boolean if date in range
   */
  isRange(date: NgbDate): boolean {
    return !!(
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  /**
   * Validate input string date
   * @param input - Date string
   * @param currentValue - Object of date
   * @returns Object of date or null
   */
  validateInput(input: string, currentValue?: NgbDate): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : (currentValue as NgbDate);
  }

  /**
   * Formats the starting and end date to be displayed in the input field.
   * Formatted as dd.MM.yyyy - dd.MM.yyyy
   * @param startDate - Start date
   * @param endDate - End date
   * @returns Formatted date.
   */
  formatSelectedDates(startDate: NgbDate, endDate: NgbDate): string {
    return (
      format(new Date(startDate.year, startDate.month - 1, startDate.day), 'dd.MM.yyyy') +
      ' - ' +
      format(new Date(endDate.year, endDate.month - 1, endDate.day), 'dd.MM.yyyy')
    );
  }
}
