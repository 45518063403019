<ng-container *transloco="let translate">
  <div class="product-container-header">
    <div class="image">
      <img
        src="/assets/images/planOverview/icon_target.png"
        alt=""
        width="125"
        height="125"
      />
    </div>
    <div class="title">{{ translate('plan_short_sig_marketing_pro') }}</div>
    <div id="sticky-headers" class="sticky-section"></div>
    <div class="title-content">
      <span class="content">{{ translate('info_target_new') }}</span>
    </div>
    <div class="seperation-line martop30"></div>
  </div>

  <ng-container *ngIf="currentProduct !== 'Target'; else hasSubscribed">
    <div *ngIf="priceData.totalUsers < maxUsers">
      <!-- For booking page -->
      <mt-product-container-price
        *ngIf="!currentProduct"
        [priceBig]="priceData.targetBig"
        [priceSmall]="priceData.targetSmall"
        [totalUsers]="priceData.totalUsers >= 18 ? priceData.totalUsers : 18"
        [costPerUser]="priceData.basePrices.Target.yearly.costPerUser"
        [billingInterval]="billingInterval"
      ></mt-product-container-price>

      <!-- For upgrade plan modal -->
      <mt-product-container-modal-price
        *ngIf="currentProduct"
        [costPerUser]="priceData.basePrices.Target.yearly.costPerUser"
        [currentAmountOfSystemUsers]="currentAmountOfSystemUsers"
        [tariff]="translate('target_plan')"
      ></mt-product-container-modal-price>

      <div class="martop20 booking-buy-term-box">
        <div class="flex padtop20">
          <div>
            <input type="checkbox" [(ngModel)]="acceptedTargetTechnicalInfo" />
          </div>
          <span
            class="accepted-target-term"
            [innerHtml]="translate('html.target_tech_info_3')"
          ></span>
        </div>
      </div>
      <a
        (click)="setProduct.emit('Target')"
        class="btn firstbutton btn-lg mt_blau lineheight15 font13"
        [attr.disabled]="!acceptedTargetTechnicalInfo ? true : null"
        >{{ translate('buy_plan_sig_marketing_pro') }}</a
      >
    </div>
    <div *ngIf="priceData.totalUsers >= maxUsers" class="martop40">
      <div class="product-container-demo-content">
        <div class="image">
          <img
            src="/assets/images/planOverview/avatar_sales_tao.png"
            alt=""
            width="145"
            height="145"
          />
        </div>
        <div>
          <span class="content">{{ translate('out_team_adivces_you') }}</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="priceData.totalUsers >= 250"
      [ngClass]="{ martop20: priceData.totalUsers >= maxUsers }"
    >
      <a
        class="btn firstbutton btn-lg btn-quote lineheight15 martop10"
        href="mailto:t.bauer@mailtastic.com"
        >{{ translate('get_proposal') }}</a
      >
    </div>
  </ng-container>

  <ng-template #hasSubscribed>
    <div *ngIf="currentProduct === 'Target'">
      <div class="current-product fontsize20 padtop140">
        {{ translate('your_current_plan') }}
      </div>
    </div>
  </ng-template>
</ng-container>
