/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'mt-upload-image-button',
  templateUrl: './upload-image-button.component.html',
  styleUrls: ['./upload-image-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent]
})
export class UploadImageButtonComponent {
  @Input('max-height') maxHeight?: number;
  @Input('max-width') maxWidth?: number;

  @Input('min-height') minHeight = 0;
  @Input('min-width') minWidth = 0;

  @Input('max-size-b') maxSizeB?: number;
  @Input('max-size-kb') maxSizeKB?: number;
  @Input('max-size-mb') maxSizeMB?: number;

  /**
   * Allows setting a custom button title
   *
   * @defaultValue 'UploadPhoto'
   */
  @Input() buttonTitle = 'UploadPhoto';

  @Output() loadImage = new EventEmitter<HTMLImageElement>();

  constructor(private alert: AlertService, private translate: TranslocoService) {}

  // eslint-disable-next-line
  processFile(imageInput: any): void {
    // eslint-disable-next-line
    const file: File = imageInput.target.files[0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    imageInput.target.value = null;
    const reader = new FileReader();
    const img = new Image();

    if (!file) {
      console.error('No file.');
      return;
    }

    if (!this.checkMaxFileSize(file)) {
      void this.alert.defaultErrorMessage(this.translate.translate('IMAGE_DIMENSIONS_AND_SIZE_INFO_MESSAGE') as string);
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = event => {
      const url = event.target?.result as string;

      img.src = url;

      img.onload = () => {
        if (!this.checkImageDimensions(img)) {
          void this.alert.defaultErrorMessage(
            this.translate.translate('IMAGE_DIMENSIONS_AND_SIZE_INFO_MESSAGE_2MB') as string
          );
          return;
        }
        this.loadImage.emit(img);
      };
    };
  }

  /**
   * Checks if the `file` is smaller than the allowed size defined be the input parameter
   * @param file - the file
   * @returns
   */
  private checkMaxFileSize(file: File): boolean {
    // max size in bytes
    if (this.maxSizeB) {
      return file.size <= this.maxSizeB;
    }

    // max size in kilo-bytes
    const fileSizeInKB = file.size / 1024;
    if (this.maxSizeKB) {
      return fileSizeInKB <= this.maxSizeKB;
    }

    // max size in mega-bytes
    const fileSizeInM = fileSizeInKB / 1024;
    if (this.maxSizeMB) {
      return fileSizeInM <= this.maxSizeMB;
    }

    // no max size parameter given
    return true;
  }

  /**
   * Checks if the `image` falls within the given input parameters
   * @param image - the image
   * @returns
   */
  private checkImageDimensions(image: HTMLImageElement): boolean {
    let fitsDimensionRequirements = true;

    if (image.width < this.minWidth) {
      fitsDimensionRequirements = false;
    } else if (this.maxWidth && image.width > this.maxWidth) {
      fitsDimensionRequirements = false;
    } else if (image.height < this.minHeight) {
      fitsDimensionRequirements = false;
    } else if (this.maxHeight && image.height > this.maxHeight) {
      fitsDimensionRequirements = false;
    }

    return fitsDimensionRequirements;
  }
}
