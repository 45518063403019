import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, switchMap, map, combineLatest, of, Subject } from 'rxjs';
import { isFreeSubscription } from 'src/app/model/interfaces/payment.interface';
import {
  INavigationSidebarCounter,
  NavigationSidebarMenu
} from 'src/app/modules/root/model/navigation-sidebar-menu.interface';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account/account.service';
import { AuthService } from '../auth/auth.service';
import { PaymentService } from '../payment/payment.service';
import { UserService } from '../user/user.service';

/**
 * Default value for cogsig environment
 */
const COGSIG_HAS_SUBSCRIPTION = {
  forceAllow: false,
  hasSubscription: true,
  hasTestTime: false,
  isUserFromAngularTs: true,
  trialDaysLeft: 0
};

@Injectable({
  providedIn: 'root'
})
export class NavigationSidebarService {
  queryParam = this.route.snapshot.queryParamMap.get('type');

  /**
   * Triggers the integration option which user has selected
   */
  _integrationOption = new BehaviorSubject<string>(this.queryParam || 'all_integrations');

  integrationOption$ = this._integrationOption.asObservable();

  /**
   * Triggers the counter changes from user action like add employee, delete employee
   */
  private _submenuCount = new BehaviorSubject<INavigationSidebarCounter>({
    amount: 0,
    menu: 'employees'
  });

  submenuCount$ = this._submenuCount.asObservable();

  /**
   * Loads the my integration if active any
   */
  private _loadMyIntegrations = new BehaviorSubject<void>(undefined);
  loadMyIntegrations$ = this._loadMyIntegrations.asObservable();

  /**
   * Trigger event changes when menu lock and unlock by user
   */
  private _isMenuLocked$ = new BehaviorSubject<boolean>(false);
  isMenuLocked$ = this._isMenuLocked$.asObservable();

  /**
   * Trigger the sync problem list count on filter data
   */
  private _syncProblemListCount$ = new Subject<number>();
  syncProblemListCount$ = this._syncProblemListCount$.asObservable();

  /**
   * Refresh the sync problem list count based on filter data from the list
   * @param count - The count of sync problem list
   */
  set syncProblemCount(count: number) {
    this._syncProblemListCount$.next(count);
  }

  /**
   * Toogle menu lock or unlocked
   * @param isOpen - boolean value for toogle
   */
  setMenuLocked(isOpen: boolean): void {
    localStorage.setItem('submenuLocked', isOpen ? 'true' : 'false');
    this._isMenuLocked$.next(isOpen);
  }

  /**
   * Trigger event changes when menu opened by user
   */
  public _isSubMenuOpened$ = new BehaviorSubject<boolean>(false);
  isSubMenuOpened$ = this._isSubMenuOpened$.asObservable();

  /**
   * Changes from user action to open menu
   * @param isOpen - boolean value for toogle open / close menu
   */
  setSubMenuIsOpened(isOpen: boolean): void {
    localStorage.setItem('submenuOpened', isOpen ? 'true' : 'false');
    this._isSubMenuOpened$.next(isOpen);
  }

  /**
   * Trigger event changes when sub menu opened by user
   */
  public _isClickedSubMenu$ = new BehaviorSubject<string>('');
  isClickedSubMenu$ = this._isClickedSubMenu$.asObservable();

  /**
   * Observable is used to determine weather recipients leads menu will be show or not
   */
  private _isRecipientsLeads$ = new BehaviorSubject(0);
  isRecipientsLeads$ = this._isRecipientsLeads$
    .asObservable()
    .pipe(switchMap(() => this.userService.getRecipientTracking()));

  /**
   * Refresh recipients leads status on user change tracking
   */
  refreshRecipientTracking(): void {
    this._isRecipientsLeads$.next(1);
  }

  /**
   * Observable is used to get user has subscription
   */
  private _hasSubscription$ = new BehaviorSubject(0);
  hasSubscription$ = this._hasSubscription$.asObservable().pipe(
    switchMap(() => this.authService.authorizationScope$),
    switchMap(authorizationScope =>
      combineLatest([of(authorizationScope), this.accountService.getCustomerSubscriptionData()])
    ),
    map(([authorizationScope, subscriptionData]) => {
      const userStatus = environment.isCogSig
        ? COGSIG_HAS_SUBSCRIPTION
        : this.paymentService.getUserStatus(authorizationScope, subscriptionData);

      return {
        hasSubscription: environment.isCogSig
          ? userStatus.hasSubscription
          : !isFreeSubscription(subscriptionData) && subscriptionData.state !== 'expired',
        hasTestTime: userStatus.hasTestTime,
        isUserFromAngularTs: userStatus.isUserFromAngularTs,
        trialDaysLeft: userStatus.trialDaysLeft,
        forceAllow: userStatus.forceAllow,
        topbarExist: true // TODO: Apply the logic of topbar should be displayed or not
      };
    })
  );

  /**
   * Hold account basic information
   */
  account$ = this.accountService.getUserAccountData();

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private userService: UserService,
    private paymentService: PaymentService,
    private route: ActivatedRoute
  ) {
    const isLock = localStorage.getItem('submenuLocked') === 'true';
    if (isLock) {
      this._isMenuLocked$.next(isLock);
      this.setSubMenuIsOpened(isLock);
    }
  }

  /**
   * Closes other dropdowns
   * @param menuItems - Menu items of sidebar
   * @param name - Active dropdown name
   */
  closeOtherDropDown(menuItems: NavigationSidebarMenu[], name: string): void {
    menuItems.map(item => {
      if (item.name !== name) {
        item.isDropdownOpen = false;
      }
    });
  }

  /**
   * Gets the changes from user action to sidebar submenu counts
   * @param returns - The observable for object of counter
   */
  getSidebarSubmenuCount(): Observable<INavigationSidebarCounter> {
    return this.submenuCount$;
  }

  /**
   * Changes from user action to sidebar submenu counts
   * @param amount - The count of submenu
   * @param menu - The name of submenu
   */
  updateSidebarSubmenuCounter(amount: number, menu: string): void {
    this._submenuCount.next({ amount, menu });
  }

  /**
   * Loads my integrations to if any (To display the My Integrations submenu)
   */
  loadMyIntegrations(): void {
    this._loadMyIntegrations.next();
  }

  /**
   * Refresh user has subscription after subscription
   */
  refreshUserHasSubscription(): void {
    this._hasSubscription$.next(1);
  }

  /**
   * Logout from the application
   */
  logout(): void {
    this.userService.logout();
  }
}
