import { Pipe, PipeTransform } from '@angular/core';

/**
 * Check feature exist on subscribed plan or not
 * To change style of submenu
 */
@Pipe({
  name: 'submenuInactive'
})
export class SubmenuInactivePipe implements PipeTransform {
  transform(subMenuName: string, isUserOnBasicPlan: boolean, isUserOnAdvancedPlan: boolean): boolean {
    return (
      ((subMenuName === 'CAMPAIGNS' || subMenuName === 'events' || subMenuName === 'navi_dashboard_overview') &&
        isUserOnBasicPlan) ||
      ((subMenuName === 'target_group_s' || subMenuName === 'notifications' || subMenuName === 'performance_feed') &&
        (isUserOnBasicPlan || isUserOnAdvancedPlan))
    );
  }
}
