<div class="container-fluid">
  <div class="row">
    <div
      class="col-5 img-testimonial onboardimg-left"
      [ngClass]="isCogsig ? 'cogsig' : 'mailtastic'"
    ></div>
    <div class="col-7">
      <div class="container">
        <div class="modal-body">
          <div class="header upgrade-info">
            <p
              class="marbot20 medium"
              [innerHtml]="
                'param.trial_over_modal_1'
                  | transloco: { value: (accountData.firstName | titlecase) }
              "
            ></p>
            <p class="marbot20 medium" transloco="trial_over_modal_2"></p>
            <p class="marbot20 bold" transloco="trial_over_modal_3"></p>
            <p class="marbot20 light" transloco="trial_over_modal_4"></p>
          </div>
          <div
            class="ownbtn btn btnblau"
            transloco="trial_over_modal_btn_1"
            (click)="modal.close(true)"
          ></div>
          <div class="text-center">
            <a
              transloco="trial_over_modal_btn_2"
              (click)="modal.close(true)"
            ></a>
            <!-- Temporory hide -->
            <!-- <a
              transloco="trial_over_modal_btn_3"
              href="mailto:t.bauer@mailtastic.com"
            ></a> -->
            <span
              class="cursor-pointer"
              (click)="modal.close(false)"
              transloco="logout"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
