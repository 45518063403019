import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { SIDEBAR_MENU } from '@modules/root/model/navigation-sidebar.const';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'mt-navigation-sidebar-signature-submenu',
  templateUrl: './navigation-sidebar-signature-submenu.component.html',
  styleUrls: ['./navigation-sidebar-signature-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarSignatureSubmenuComponent {
  /**
   * Environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Determines the counter of signatures
   * @defaultValue 0
   */
  @Input() signatureCount = 0;

  /**
   * The menus of signature
   */
  menus: NavigationSidebarMenu[] = cloneDeep(SIDEBAR_MENU.signatures);

  constructor(public navigationSidebarService: NavigationSidebarService) {}
}
