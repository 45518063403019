import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslocoRootModule } from './transloco-root.module';

// Http interceptors
import { TokenInterceptor } from './interceptors/token.interceptor';

// Guards
import { AuthGuard } from './guards/auth-guard/auth.guard';
import { CanActiveCogsigRouteGuard } from './guards/cogsig-guard/can-active-cogsig-route.guard';

// Services
import { AuthService } from './services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './services/user/user.service';

// Bootstrap
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Intercom
import { IntercomModule } from '@supy-io/ngx-intercom';

// TODO: Disable HotjarCookies until Hotjar is addressed in our cookie policy.
// Ref: Install Hotjar in MT-1756 ticket
// import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
// NgxHotjarModule.forRoot(environment.hotJarAppId),
// NgxHotjarRouterModule,

import { environment } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { TemplateService } from './services/template/template.service';
import { UserSessionService } from './services/user-session/user-session.service';
import { GeneratorStoreService, OwnGeneratorStoreService } from './services/generator-store/generator-store.service';
import { MtCookiesBannerModule } from './modules/root/components/cookies-banner/cookies-banner.module';
import { NoMobileViewModule } from './modules/root/components/no-mobile-view/no-mobile-view.module';
import { MtNavigationSidebarModule } from './modules/root/components/navigation-sidebar/navigation-sidebar.module';
import { DatasetService } from './services/dataset/dataset.service';
import { SignatureDataService } from './services/signature-data/signature-data.service';

/**
 * If we use Material component in modal then we need to import in AppModule
 * Ref: https://github.com/angular/components/issues/19335
 */
import { MatSelectModule } from '@angular/material/select';

// German currency pipe
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { AngularSvgIconModule } from 'angular-svg-icon';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularSvgIconModule,
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    MatSelectModule,
    MtCookiesBannerModule,
    MtNavigationSidebarModule,
    NgbModule,
    NoMobileViewModule,
    ToastrModule.forRoot(),
    TranslocoRootModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: OwnGeneratorStoreService,
      useFactory:
        (
          datasetService: DatasetService,
          signatureDataService: SignatureDataService,
          templateService: TemplateService
        ) =>
        (id: string) => {
          const ownUserSessionService = new UserSessionService();
          return new GeneratorStoreService(
            datasetService,
            signatureDataService,
            templateService,
            ownUserSessionService,
            id
          );
        },
      deps: [DatasetService, SignatureDataService, TemplateService]
    },
    AuthGuard,
    AuthService,
    CanActiveCogsigRouteGuard,
    CookieService,
    NgbActiveModal,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
