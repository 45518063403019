<mt-custom-modal headline="{{ 'edit_image' | transloco }}">
  <div [formGroup]="advancedSettingsForm" modal-body>
    <div
      class="container-fluid text-center"
      *ngIf="loadUserPhoto$ | async as userDatasetData"
    >
      <div class="row mt-5 justify-content-center">
        <div class="col-5">
          <div
            class="image-displayed"
            *ngIf="displayImage$ | async as userUploadedPhoto"
          >
            <ng-container *ngIf="userUploadedPhoto === 'none'; else showPhoto">
              <div class="no-photo-preview">
                <mt-svg
                  src="assets/images/svgs/user_(1).svg"
                  size="150"
                  filter="--filter-white"
                ></mt-svg>
              </div>
            </ng-container>
            <ng-template #showPhoto
              ><img
                [src]="userUploadedPhoto"
                alt="uploaded_photo"
                [ngStyle]="{
                  'max-width': '300px',
                  'max-height': '300px',
                  'border-radius': getBorderRadius(uploadProfilePhotoData)
                }"
            /></ng-template>
          </div>
        </div>
      </div>

      <div class="row mt-5 justify-content-center">
        <div class="col-5">
          <span class="text-break" transloco="note_picture_appereance"></span>
        </div>
      </div>

      <div class="row mt-3 justify-content-center">
        <div class="col">
          <mt-upload-image-button
            (loadImage)="processImage($event)"
            [max-width]="3000"
            [max-size-mb]="2"
            [buttonTitle]="
              userDatasetData.ma_foto?.image ||
              uploadProfilePhotoData.imageUploaded
                ? 'change_photo_btn_2'
                : 'uploade_photo_btn_2'
            "
            style="display: inline-flex"
          ></mt-upload-image-button>

          <mt-button
            *ngIf="
              userDatasetData.ma_foto?.image ||
              uploadProfilePhotoData.imageUploaded
            "
            class="mt_left ms-2"
            text="remove_photo_btn_2"
            color="primary-outline"
            (click)="
              deletePhoto(
                userDatasetData.ma_foto,
                uploadProfilePhotoData.imageUploaded
              )
            "
          ></mt-button>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-8">
          <p
            *ngIf="
              availableOptions.useForAllProfiles ||
              availableOptions.useForAllEmployees ||
              availableOptions.photoUrl
            "
            class="font-weight-bold"
            transloco="advanced_settings"
          ></p>

          <div class="form-check" *ngIf="availableOptions.useForAllProfiles">
            <input
              class="form-check-input"
              type="checkbox"
              id="check_use_image_for_all"
              formControlName="useForAllProfiles"
            />
            <label
              class="form-check-label fw-normal"
              for="check_use_image_for_all"
              transloco="use_image_for_all"
            >
            </label>
          </div>

          <div class="form-check" *ngIf="availableOptions.useForAllEmployees">
            <input
              class="form-check-input"
              type="checkbox"
              id="check_use_graphic_for_all"
              formControlName="global"
              (click)="graphicsForAllConfirmation($event);"
            />
            <label
              class="form-check-label fw-normal"
              for="check_use_graphic_for_all"
              [innerHtml]="'html.use_graphic_for_all_emp_2' | transloco"
            >
            </label>
          </div>

          <div class="form-floating mt-3" *ngIf="availableOptions.photoUrl">
            <input
              type="text"
              class="form-control"
              id="input_link_photo_url"
              placeholder="{{ 'link_photo_url_example' | transloco }}"
              formControlName="photoUrl"
            />
            <label
              for="input_link_photo_url"
              class="form-label fw-normal"
              transloco="link_photo_url"
            ></label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container modal-footer>
    <div class="d-flex flex-row-reverse">
      <div class="d-flex">
        <mt-button
          color="default"
          text="abort"
          class="leftbutton"
          (click)="modal.close()"
        ></mt-button>
        <mt-button
          color="primary"
          text="Save"
          (click)="savePhoto()"
        ></mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
