// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://integration-signatures.cognism.com/api',
  webpage: 'https://signatures-stg.cognism.com',
  legacyUrl: 'https://signatures-js.cognism.com/#',
  uploadImageUri: 'https://email-signature-uploader.dev.jsguru.io',
  daysTillInactive: 14,
  isCogSig: true,
  intercomAppId: 'mgzhwkzg',
  hotJarAppId: '2651637',
  azureClientId: '27f66ee0-cb8e-4383-8c28-55fb9bae9f7c',
  title: 'Cognism Signatures',
  favicon: 'assets/favicon/cogsig/favicon-32x32.ico'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
