<div class="signaturecol" [ngClass]="{ first: !!isFirst }">
  <div class="title overflowhidden">
    <div class="innertitle" style="overflow: hidden">
      <span
        *ngIf="!disablelinks"
        class="stitle link"
        ngbTooltip="{{ item.title }}"
        tooltipClass="status-info-tooltip"
        placement="top"
        triggers="hover"
        [routerLink]="['/signatures/details/', item.id]"
        routerLinkActive="active"
      >
        <span
          style="margin-right: 3px"
          class="glyphicon glyphicon-list-alt"
          aria-hidden="true"
        ></span>
        {{ item.title | textTruncate }}
      </span>
      <span
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ item.title }}"
        *ngIf="disablelinks"
        class="stitle"
      >
        <span
          style="margin-right: 3px"
          class="glyphicon glyphicon-list-alt"
          aria-hidden="true"
        ></span>
        {{ item.title }}
      </span>
    </div>
  </div>
  <div class="subline">
    <normal transloco="updatedon"></normal>
    {{ item.signatureUpdatedAt || item.createdAt | date: 'dd.MM.yyyy' }}
  </div>
</div>
