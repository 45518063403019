import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { ICampaign } from 'src/app/model/interfaces/campaign.interface';
import { TriggerEvent } from 'src/app/model/interfaces/event.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'eventscampaignstatuscol',
  templateUrl: './events-campaign-status-col.component.html',
  styleUrls: ['./events-campaign-status-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MtSvgComponent, NgClass, RouterLink, NgbTooltip, TranslocoModule]
})
export class EventsCampaignStatusColComponent {
  /**
   * Message to display when user action is required
   * @defaultValue ''
   */
  @Input() alertmo = '';

  /**
   * Retrieve the campaign Id, route use that Id for redirect to the campaign detail page
   */
  @Input() campaignStatus?: ICampaign;

  /**
   * If is true will show Unassigned
   */
  @Input() gated = false;

  /**
   * Determines if show label or not
   * @defaultValue false
   */
  @Input() hideLabel = false;

  /**
   * Color used for the icon
   * @defaultValue 'mt-darkgrey'
   */
  @Input() iconColor = 'mt-darkgrey';

  /**
   * Message to display when no issues with signature status
   */
  @Input() successmo = '';

  /**
   * Used for display title and additional information
   */
  @Input() triggerEvent = {} as TriggerEvent;

  /**
   * Emit events to redirect to campaign details page
   */
  @Output() redirectToCampaignDetail: EventEmitter<void> = new EventEmitter<void>();
}
