import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, ReplaySubject, Subject, tap } from 'rxjs';
import { CockpitIntegrationTypes } from 'src/app/model/interfaces/integrations.interface';
import { IntegrationsSignature } from 'src/app/model/interfaces/signature.interface';
import { IUsers } from 'src/app/model/interfaces/user.interface';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { InstallationGuideModalComponent } from '@organisms/installation/modals/installation-guide-modal/installation-guide-modal.component';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {
  isCogsig = environment.isCogSig;

  getEasySyncIntegration$ = this.userService.getEasySyncSettings();

  /**
   * Holds the selected list step info to show or not
   */
  private _isSelectedListStepShown$ = new BehaviorSubject(false);
  isSelectedListStepShown$ = this._isSelectedListStepShown$.asObservable().pipe();

  /**
   * Holds the signature list with mark as integrated status
   */
  private _stepStatus$ = new ReplaySubject<IWizardTabComponent[]>(1);
  stepStatus$ = this._stepStatus$.asObservable();

  /**
   * Holds the integration list data
   */
  private _integrationList$ = new Subject<CockpitIntegrationTypes[]>();

  integrationList$ = this._integrationList$.asObservable().pipe(
    tap(data => {
      // If user selects the `Outlook Mac Desktop` of Microsoft it will display the integration list via `EasySync Client` and `Manual Integration`
      // Temporary hide (for Cogsig and MT users) the outlook mac desktop manual integration
      // if (data[0].parts[0].checked === true && this.isCogsig) {
      //   data[0].parts[1].checked = true; // Temporary hide (for MT users) the outlook mac desktop manual integration
      // }

      // If user selects the `Outlook Windows Desktop` of Microsoft it will display the integration list via `EasySync Client` and `Manual Integration`
      // Temporary hide (for Cogsig and MT users) the outlook window desktop manual integration
      // if (data[0].parts[2].checked === true && this.isCogsig) {
      //   data[0].parts[3].checked = true; // Temporary hide (for MT users) the outlook window desktop manual integration
      // }

      const outlookAddinIndex = data[0].parts.findIndex(integration => integration.title === 'outlook_addin_header_hl');

      if (outlookAddinIndex > -1) {
        const outlookIntegrationTitles = new Set([
          'outlook_mac_easysync_header_rec',
          'outlook_windows_easysync_header_rec',
          'integration_outlook_1_3_title'
        ]);

        for (const integration of data[0].parts) {
          if (outlookIntegrationTitles.has(integration.title)) {
            if (integration.checked) {
              data[0].parts[outlookAddinIndex].checked = true;
              break;
            }
          }
        }
      }

      // Display both manual and chrome extension guides when selecting `Gmail (Browser)`
      if (data[1].parts[0].checked) {
        data[1].parts[1].checked = true;
      }

      // If user selects the `Mail Desktop` of Apple it will display the integration list via `EasySync Client` and `Manual Integration`
      // Temporary hide (for Cogsig and MT users) the mail desktop - via manual integration
      // if (data[2].parts[0].checked === true && this.isCogsig) {
      //   data[2].parts[1].checked = true; // Temporary hide (for MT users) the mail desktop - via manual integration
      // }

      // If user selects the `Other` of Other for use signature it will display the integration list of `HTML signature` and `Signature template`
      if (data[4].parts[0].checked === true) {
        data[4].parts[1].checked = true;
      }
    })
  );

  constructor(private modal: NgbModal, private userService: UserService) {}

  /**
   * Changes from filtered integration checklist
   * @param value - The checkbox value
   */
  updateFilteredIntegrationList(value: CockpitIntegrationTypes[]): void {
    this._integrationList$.next(value);
  }

  /**
   * Update the step staus of signature preview list
   * @param steps - the steps with status
   */
  updateSignaturePreviewStepStatus(steps: IWizardTabComponent[]): void {
    this._stepStatus$.next(steps);
  }

  /**
   * Opens selection step
   * @param value - Open the selection step
   */
  openSelectionStep(value: boolean): void {
    this._isSelectedListStepShown$.next(value);
  }

  /**
   * Get the number of integration parts
   * @param data - The object of integration type
   * @returns The number of section to display
   */
  getSelectedClientLength(data: CockpitIntegrationTypes): number {
    return data.parts.filter(({ checked }) => checked).length;
  }

  /**
   * Open installation guide
   * @param title - The title of signature mail client
   * @param signatures - The list of signatures
   * @param user - The user details
   */
  openSignatureInstallationGuide(title: string, signatures?: IntegrationsSignature[], user?: IUsers): void {
    const modalRef = this.modal.open(InstallationGuideModalComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal_990'
    });

    const modalData = {
      signatures,
      title,
      user
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance['inputData'] = modalData;
  }
}
