<mt-custom-modal headline="{{ 'edit_image' | transloco }}">
  <div modal-body>
    <div class="flex">
      <div class="formarea">
        <div transloco="image_section" class="marbot15"></div>
        <div ngbDropdown class="btn-group">
          <mt-button
            klass="mt_listnav mt_button"
            class="mt_dropdown"
            style="padding-left: 0"
            text="{{ getCropTitle() }}"
            id="dropdownCrop"
            ngbDropdownToggle
            [dropdown]="true"
          ></mt-button>
          <ul ngbDropdownMenu aria-labelledby="dropdownCrop">
            <a
              ngbDropdownItem
              (click)="cropSelection('square')"
              transloco="square_image_section"
            ></a>
            <a
              ngbDropdownItem
              (click)="cropSelection('circle')"
              transloco="circle_image_section"
            ></a>
          </ul>
        </div>

        <div transloco="image_size" class="martop25"></div>

        <div class="mt_range martop25">
          <input
            type="range"
            [(ngModel)]="rangeValue"
            (change)="onChangeRange($event)"
          />
        </div>

        <div class="martop25" transloco="image_dimension"></div>

        <div class="martop25">
          <input
            type="number"
            class="form-control input-sm"
            [(ngModel)]="cropManualSize"
            (change)="onChangeManualSize($event)"
          />
        </div>
      </div>

      <div class="imgpreview">
        <div class="hint" transloco="Preview"></div>

        <img-cropper
          #cropper
          [image]="data"
          [settings]="cropperSettings"
          (onCrop)="onChangeCrop($event)"
        ></img-cropper>
      </div>
    </div>
  </div>

  <ng-container modal-footer>
    <div class="flex-center-right">
      <mt-button
        text="abort"
        class="mt_left"
        color="default"
        (click)="modal.dismiss('cancelled')"
      ></mt-button>
      <mt-button
        text="continue_without_crop"
        class="mt_left"
        color="default"
        (click)="cropAndSave(false)"
      >
      </mt-button>
      <mt-button
        text="crop_and_save"
        color="primary"
        (click)="cropAndSave(true)"
      ></mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
