<input
  class="picker"
  [style.background]="color"
  [cpPosition]="'bottom'"
  [cpOutputFormat]="format"
  [(colorPicker)]="color"
  (colorPickerChange)="onColorChange($event)"
/>

<input
  class="input"
  type="text"
  [value]="color"
  (change)="checkFormat($event)"
/>
