<div class="modal-header" data-cy="confirm-modal">
  <div class="title">{{ parent.headline }}</div>
  <div class="cursor-pointer close" (click)="modal.close(false)">
    <mt-svg
      class="x-button"
      src="assets/images/modals/close_other_svg.svg"
    ></mt-svg>
  </div>
</div>
<div class="modal-body">
  <mt-box [type]="parent.type" [translateTag]="parent.text"></mt-box>
</div>
<div class="modal-footer">
  <div class="flex-center-space-between">
    <div class="flex">
      <mt-button
        data-cy="confirm-modal-false"
        *ngIf="parent.showCloseBtn"
        text="abort"
        class="mt_left"
        color="default"
        (click)="modal.close(false)"
      >
      </mt-button>
      <mt-button
        data-cy="confirm-modal-true"
        text="{{ parent.confirmBtn }}"
        color="primary"
        (click)="modal.close(true)"
      ></mt-button>
    </div>
  </div>
</div>
