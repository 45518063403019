import { Component, Input } from '@angular/core';
import { ITargetGroupListModel } from 'src/app/model/interfaces/target-group-list.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgIf, LowerCasePipe } from '@angular/common';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';

@Component({
  selector: 'targetgroupentrytitlecol',
  templateUrl: './target-group-entry-title-col.component.html',
  styleUrls: ['./target-group-entry-title-col.component.scss'],
  standalone: true,
  imports: [MtSvgComponent, NgClass, NgbTooltip, NgIf, TranslocoModule, LowerCasePipe]
})
export class TargetGroupEntryTitleColComponent {
  /**
   * Object where is stored Target Group
   */
  @Input() item!: ITargetGroupListModel;

  /**
   * Color used for the icon
   * @defaultValue 'mt-darkgrey'
   */
  @Input() iconColor = 'mt-darkgrey';
}
