import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { catchError, map, Observable } from 'rxjs';
import { IResponseData, ResponseDataOrMessage } from 'src/app/model/interfaces/response.interface';
import {
  CalcPriceResponse,
  CustomerHasFreeSubscription,
  CustomerSubscriptionData,
  isAuthorizationsScopeInstance,
  isFreeSubscription,
  Product,
  ProductData,
  ProductDuration,
  SubscriptionInvoices,
  SubscriptionInvoicesAutomaticPayment,
  UserStatus
} from 'src/app/model/interfaces/payment.interface';
import { environment } from 'src/environments/environment';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';
import { differenceInDays, format, parseISO } from 'date-fns';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AccountService } from '../account/account.service';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '../account/account-service.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  /**
   * User signup subscription trial days
   * @defaultValue 14
   */
  SUBSCRIPTION_TRIAL_DAYS = 14;

  servicedata = {
    plans: {
      Professional: {
        yearly: {
          costPerUser: 2.49
        },
        monthly: {
          costPerUser: 3
        }
      },
      Business: {
        yearly: {
          costPerUser: 3.99
        },
        monthly: {
          costPerUser: 4.5
        }
      },
      Basic: {
        minBookableAmount: 90,
        yearly: {
          costPerUser: 1
        },
        monthly: {
          costPerUser: 1
        }
      },
      Advanced: {
        minBookableAmount: 30,
        yearly: {
          costPerUser: 3
        },
        monthly: {
          costPerUser: 3
        }
      },
      Target: {
        minBookableAmount: 18,
        yearly: {
          costPerUser: 5
        },
        monthly: {
          costPerUser: 5
        }
      }
    },
    minEmps: 1,
    currentAmountOfEmployees: null
  };

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private operator: CustomOperators
  ) {}

  /**
   * Gets the subscription data for the customer via API or localstorage
   * @param accountId - The accountId
   * @returns Observable of CustomerSubscriptionData or CustomerHasFreeSubscription
   *
   */
  getCustomerSubscriptionData(accountId: string): Observable<CustomerSubscriptionData | CustomerHasFreeSubscription> {
    return this.http.get<ResponseDataOrMessage<CustomerSubscriptionData>>('/payment/subscription/saas').pipe(
      map(value => {
        // Check if success not true
        if (!value.success) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        // Saving saas data into local storage
        if (!value.message && value.data) {
          value.data.accountId = accountId;
          this.accountService.setSubscriptionDataCache(value.data);
          return value.data;
        }
        this.accountService.removeSubscriptionDataCache();
        return { hasFreeSubscription: true };
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Sets the minimal bookable amount of meta data
   * @param amount
   * @returns
   */
  setMinBookableAmountMetaData(amount: number): unknown {
    return this.http
      .post('/payment/setminbookablemetaamount', { minAmount: amount })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets all invoices from chargify if payment_collection_method is invoice
   * @returns List of invoice data
   */
  getInvoices(): Observable<SubscriptionInvoices[]> {
    return this.http
      .get<IResponseData<SubscriptionInvoices[]>>('/payment/invoices')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the invoices for customers with automatic payment method (paypal, creditcard)
   * @returns List of invoice data
   */
  getInvoicesForAutomaticPayment(): Observable<SubscriptionInvoicesAutomaticPayment[]> {
    return this.http
      .get<IResponseData<SubscriptionInvoicesAutomaticPayment[]>>('/payment/invoicesforautomaticpayment')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all URLs with which the statements can be downloaded.
   * Statements are the invoices for automatic payment customers.
   * @returns
   */
  getStatements(): unknown {
    return this.http.get('/payment/statements').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Shows the invoice as a PDF in a new browser window.
   * @param invoiceId - Invoice id to open in new tab
   */
  downloadInvoice(invoiceId: string): void {
    window.open(
      `${environment.apiUrl}/payment/invoices/${invoiceId}?token=${this.authService.getToken()}`,
      'Download your invoice',
      'location=0,status=0,width=800,height=800'
    );
  }

  /**
   * Shows the statement as a PDF in a new browser window.
   * Statements are the invoices for automatic payment customers.
   * @param invoiceId
   * @returns
   */
  downloadStatement(invoiceId: string): void {
    // TODO implement function downloadStatement
  }

  /**
   * Synchronizes the current amount of employees in the mailtastic system
   * with the amount which is in the payment system. Uses the current plan and additions.
   * @returns
   */
  syncEmployees(): unknown {
    return this.http.post('/payment/sync', null).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets account data from isaac like adress and email of customer
   * @returns
   */
  getIsaacAccountData(): unknown {
    return this.http.get('/payment/customerdata').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Switches to a yearly billing interval but stays with the same product
   * @returns
   */
  switchToYearlyPlan(): void {
    // TODO implement function switchToYearlyPlan
  }

  /**
   * Switches the plans like from professional to business or from yearly account to monthly account
   * @param product - Product name
   * @param billingInterval - Interval type
   */
  switchPlan(product: Product, billingInterval: ProductDuration): Observable<void> {
    return this.http
      .put<IResponseData<void>>('/payment/changeproduct', { product, billingInterval })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Calculates the prices for a given billing interval and the given users
   * @param billingInterval - String for plan duration like 'yearly'
   * @param amountOfUsers - Per user amount
   * @param useYearlyPrice - Boolean if get yearly price instead monthly
   * @returns Object of CalcPriceResponse
   */
  calcPrice(billingInterval: ProductDuration, amountOfUsers: number, useYearlyPrice: boolean): CalcPriceResponse {
    // Base prices
    let priceBasicPerUser = this.servicedata.plans.Basic.monthly.costPerUser;
    let priceAdvancedPerUser = this.servicedata.plans.Advanced.monthly.costPerUser;
    let priceTargetPerUser = this.servicedata.plans.Target.monthly.costPerUser;

    // Calc price per user per product
    if (billingInterval === 'yearly') {
      priceBasicPerUser = this.servicedata.plans.Basic.yearly.costPerUser;
      priceAdvancedPerUser = this.servicedata.plans.Advanced.yearly.costPerUser;
      priceTargetPerUser = this.servicedata.plans.Target.yearly.costPerUser;
    }

    // If yearly use yearly base price for calculation
    if (useYearlyPrice) {
      priceBasicPerUser *= 12;
      priceAdvancedPerUser *= 12;
      priceTargetPerUser *= 12;
    }

    // Round numbers
    priceBasicPerUser = Number((Math.round(priceBasicPerUser * 100) / 100).toFixed(2));
    priceAdvancedPerUser = Number((Math.round(priceAdvancedPerUser * 100) / 100).toFixed(2));
    priceTargetPerUser = Number((Math.round(priceTargetPerUser * 100) / 100).toFixed(2));

    // Hold total calc price per product
    let priceBasic, priceAdvanced, priceTarget;

    // For basic plan
    if (amountOfUsers >= this.servicedata.plans.Basic.minBookableAmount) {
      priceBasic = amountOfUsers * priceBasicPerUser;
    } else {
      priceBasic = this.servicedata.plans.Basic.minBookableAmount * priceBasicPerUser;
    }

    // For advanced plan
    if (amountOfUsers >= this.servicedata.plans.Advanced.minBookableAmount) {
      priceAdvanced = amountOfUsers * priceAdvancedPerUser;
    } else {
      priceAdvanced = this.servicedata.plans.Advanced.minBookableAmount * priceAdvancedPerUser;
    }

    // For target plan
    if (amountOfUsers >= this.servicedata.plans.Target.minBookableAmount) {
      priceTarget = amountOfUsers * priceTargetPerUser;
    } else {
      priceTarget = this.servicedata.plans.Target.minBookableAmount * priceTargetPerUser;
    }

    // Round numbers
    const priceBasicFractional = (Math.round(priceBasic * 100) / 100).toFixed(2);
    const priceAdvancedFractional = (Math.round(priceAdvanced * 100) / 100).toFixed(2);
    const priceTargetFractional = (Math.round(priceTarget * 100) / 100).toFixed(2);

    return {
      basePrices: this.servicedata.plans,
      priceBasicPerUser: priceBasicPerUser,
      priceAdvancedPerUser: priceAdvancedPerUser,
      priceTargetPerUser: priceTargetPerUser,
      priceBasic: Number(priceBasicFractional),
      priceAdvanced: Number(priceAdvancedFractional),
      priceTarget: Number(priceTargetFractional),
      formatted: {
        basic: {
          afterComma: priceBasicFractional.split('.')[1],
          beforeComma: priceBasicFractional.split('.')[0]
        },
        advanced: {
          afterComma: priceAdvancedFractional.split('.')[1],
          beforeComma: priceAdvancedFractional.split('.')[0]
        },
        target: {
          afterComma: priceTargetFractional.split('.')[1],
          beforeComma: priceTargetFractional.split('.')[0]
        }
      }
    };
  }

  /**
   * Gets the individual url to the customer billing portal
   * @returns URL
   */
  getCustomerPortalUrl(): Observable<string> {
    return this.http.get<IResponseData<string>>('/payment/customerportalurl').pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the subscription status
   * @param deferred
   * @returns
   */
  getSubscriptionStatus(deferred: unknown): void {
    // TODO implement function getSubscriptionStatus
  }

  /**
   * Checks if user has a subscription and if so checks remaining free seats
   * Checks the amountOfUsers, forceFlag and other options
   * @param state - Object of GetOverallStatsResponse
   * @param subscription - Object of logged in user subscription data
   * @returns Object of IUserStatus
   */
  getUserStatus(
    data: GetOverallStatsResponse | IMAILTASTIC_AUTHORIZATION_SCOPE,
    subscription: CustomerSubscriptionData | CustomerHasFreeSubscription
  ): UserStatus {
    let userStatus!: UserStatus;

    // Assign common object data to response object
    userStatus = {
      ...userStatus,
      forceAllow: data.forceAllow ? true : false, // Check force allow
      hasMultiSignature: data.hasMultiSignature === true,
      isCommunityEditionAccount: data.isCommunityEditionAccount === true ? true : false,
      isLocked: data.isLocked ? true : false, // Check trial is expired
      success: true
    };

    if (!isAuthorizationsScopeInstance(data)) {
      userStatus = {
        ...userStatus,
        mailPolicy: data.mailPolicy,
        forwardMailsTo: data.forwardMailsTo,
        amountOfFreeMembers: data.maxCommunityUsers, // Check allowed free users
        amountOfUsers: data.amountOfUsers // Amount of free members
      };
    }

    // For community account enabled trial
    // For trial accounts check not subscription and not isLocked then update remaining days trial
    const parsedBegin = parseISO(data.createdAt.toString());
    if (data.isCommunityEditionAccount === true) {
      userStatus.hasTestTime = true;
      userStatus.trialDaysLeft = 0;
    } else if (isFreeSubscription(subscription) && !data.isLocked) {
      userStatus.hasTestTime = true;

      // Calculate trial remaining days
      const parsedEnd = parseISO(format(new Date(), 'yyyy-MM-dd'));
      const amountOfDays = differenceInDays(parsedEnd, parsedBegin);
      userStatus.trialDaysLeft =
        amountOfDays < this.SUBSCRIPTION_TRIAL_DAYS ? this.SUBSCRIPTION_TRIAL_DAYS - amountOfDays : 0;
    } else {
      userStatus.hasTestTime = false;
    }

    // Check to signup with new angular after 30-01-2023
    userStatus.isUserFromAngularTs = parsedBegin >= parseISO('2023-01-31');

    if (isFreeSubscription(subscription)) {
      userStatus.hasSubscription = false;
    } else if (subscription.state === 'expired') {
      userStatus.state = subscription.state;
      userStatus.hasSubscription = false;
    } else {
      // Subscription is running
      userStatus.state = subscription.state;
      userStatus.hasSubscription = true;
      userStatus.interval = subscription.product.interval;

      if (subscription.customFields) {
        const maxEmpsInPeriod = subscription.customFields.find(info => info.name === 'MaxEmpsInPeriod');
        if (maxEmpsInPeriod) {
          userStatus.currentAmountOfLicenses = maxEmpsInPeriod.value;
        }
      }

      // Use component quantity when Max emps value is not set
      if (!userStatus.currentAmountOfLicenses) {
        userStatus.currentAmountOfLicenses = subscription.component.allocated_quantity;
      }

      // Determine price per user
      userStatus.monthlyPricePerUser = subscription.pricePerUser / subscription.product.interval;
      userStatus.hasTestTime = false;
      userStatus.billingInterval = subscription.product.interval === 1 ? 'monthly' : 'yearly';

      // Calc current contract value (total amount)
      userStatus.contractValue =
        userStatus.monthlyPricePerUser * subscription.product.interval * userStatus.currentAmountOfLicenses;

      // Get product name
      if (subscription.component.name.includes('Business')) {
        userStatus.currentProduct = 'Business';
      } else if (subscription.component.name.includes('Professional')) {
        userStatus.currentProduct = 'Professional';
      } else if (subscription.component.name.includes('ABM')) {
        userStatus.currentProduct = 'ABM';
      } else if (subscription.component.name.includes('Basic')) {
        userStatus.currentProduct = 'Basic';
      } else if (subscription.component.name.includes('Advanced')) {
        userStatus.currentProduct = 'Advanced';
      } else if (subscription.component.name.includes('Target')) {
        userStatus.currentProduct = 'Target';
      }

      // Get amount of employees the customer is currently paying for
      const freeEmps = userStatus.currentAmountOfLicenses - userStatus.amountOfUsers;
      if (freeEmps > 0) {
        userStatus.amountOfFreeMembers = freeEmps;
      } else {
        userStatus.amountOfFreeMembers = 0;
      }
    }

    return userStatus;
  }

  /**
   * Gets the all plan/product data objects
   * @returns Objects of plans
   */
  getProducts(): Observable<ProductData> {
    return this.http.get<IResponseData<ProductData>>('/payment/productdata').pipe(this.operator.extractResponseData());
  }
}
