import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAccountData } from 'src/app/model/interfaces/account-admin.interface';

@Component({
  selector: 'mt-upgrade-subscription',
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeSubscriptionComponent {
  isCogsig = environment.isCogSig;

  /**
   * Hold Account basic information
   */
  @Input() accountData!: IAccountData;

  constructor(public modal: NgbActiveModal) {}
}
