import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { ICompanyInfo } from 'src/app/model/interfaces/company-info.interface';
import { IResponse, IResponseData, IResponseMessage, ResponseData } from 'src/app/model/interfaces/response.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { googleUser, ResponseAddedSyncData, updateSyncSigResponse } from './google-sync.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleSyncService {
  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators
  ) {}

  /**
   * Get auth URL
   * @returns The auth url
   */
  getAuthURL(): Observable<string> {
    return this.http.get(`/googleapi/authurl?isFromNewAngularTs=true`, { responseType: 'text' }).pipe(
      map(value => {
        if (!value) {
          throw new Error(this.alert.translateTechnicalError());
        }
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Get GoogleAPI token
   * @param code - The code to get the google token
   * @returns The google token
   */
  getGoogleToken(code: string): Observable<string> {
    return this.http.get(`/googleapi/token?isFromNewAngularTs=true&code=${decodeURIComponent(code)}`).pipe(
      map(value => {
        if (value) {
          return JSON.stringify(value);
        }
        throw new Error(this.alert.translateTechnicalError());
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets sync data
   * @returns The company data of synced account
   */
  get(): Observable<ICompanyInfo> {
    return this.http.get<ResponseData<ICompanyInfo>>('/googleSync').pipe(
      map(value => {
        if (!value.success) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        return value.data;
      })
    );
  }

  /**
   * Creates a new Google Sync
   * @param googleSync - The data to add syncronization
   * @returns The added data
   */
  add(googleSync: ICompanyInfo): Observable<ResponseAddedSyncData> {
    return this.http.post<ResponseAddedSyncData>('/googleSync', googleSync);
  }

  /**
   * Updates the Google sync
   * @param googleSync - The data to update sync data
   * @returns The success
   */
  update(googleSync: ICompanyInfo): Observable<true> {
    return this.http.put<IResponse>('/googleSync', googleSync).pipe(this.operator.extractResponse());
  }

  /**
   * Deletes the info
   * @param syncInfoId - The sync id
   * @returns The success
   */
  deleteInfo(syncInfoId: number): Observable<true> {
    return this.http.delete<IResponse>(`/googleSync/${syncInfoId}`).pipe(
      this.operator.extractResponse(),
      tap(() => this.navigationSidebarService.loadMyIntegrations())
    );
  }

  /**
   * Gets the list of all employees
   * @param token - The google token
   * @returns The google users list
   */
  getListOfUsers(token: string): Observable<googleUser[]> {
    return this.http
      .post<IResponseMessage | googleUser[]>('/googleSync/listusers', {
        googleToken: JSON.parse(token),
        isFromAngularTs: true
      })
      .pipe(
        map(value => {
          // eslint-disable-next-line no-prototype-builtins
          if (value.hasOwnProperty('success')) {
            void this.alert.defaultErrorMessage(this.alert.translate('GAPPSFORWOK_USERS_ONLY'));
            return [] as googleUser[];
          }
          return value as googleUser[];
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Sync employee details data like phone and address
   * @param token - The google token
   * @returns The success
   */
  syncUsersDetailData(token: string): Observable<true> {
    return this.http
      .post<IResponse>('/googleSync/syncUserDetails', { googleToken: JSON.parse(token) })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Pushes current signature to employees
   * @param token - The google token
   * @returns The success
   */
  updateSignatureForAllUsers(token: string): Observable<true> {
    return this.http
      .post<IResponse>('/googleSync/syncUserSignatures', { googleToken: JSON.parse(token) })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Updates signature for a list of given users via google api
   * @param users - google user list
   * @param token - The google token
   * @returns The response of updated data
   */
  updateSignature(users: googleUser[], token: string): Observable<updateSyncSigResponse[]> {
    const data = {
      users: users,
      googleToken: JSON.parse(token)
    };
    return this.http
      .put<IResponseData<updateSyncSigResponse[]>>('/googleSync/updatesignature', data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Update signature for a list of given groups via google api
   * @param groups - Group ids
   * @returns The updated users data
   */
  updateGoogleSignatureForGroups(groups: number[]): Observable<{ users: [] }> {
    return this.http.post<{ users: [] }>('/googleSync/updatesignature/groups', groups).pipe(
      map(value => {
        if (!value) {
          throw new Error(this.alert.translateTechnicalError());
        }
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }
}
