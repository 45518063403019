/* 🤖 this file was generated by svg-to-ts*/
import { mtIconsDetailsBtnSvg } from './mtIcons-details_btn_svg.icon';
import { mtIconsDropdownBtnSvg } from './mtIcons-dropdown_btn_svg.icon';
import { mtIconsEditBtnSvg } from './mtIcons-edit_btn_svg.icon';
import { mtIconsLinkBtnSvg } from './mtIcons-link_btn_svg.icon';
import { mtIconsNotificationBtnSvg } from './mtIcons-notification_btn_svg.icon';
import { mtIconsOptionsBtnSvg } from './mtIcons-options_btn_svg.icon';
import { mtIconsPlusBtnSvg } from './mtIcons-plus_btn_svg.icon';
import { mtIconsRefreshBtnSvg } from './mtIcons-refresh_btn_svg.icon';
import { mtIconsSwitchBtnSvg } from './mtIcons-switch_btn_svg.icon';
import { mtIconsTest1BtnSvg } from './mtIcons-test1_btn_svg.icon';
import { mtIconsTest2BtnSvg } from './mtIcons-test2_btn_svg.icon';
import { mtIconsTickBtnSvg } from './mtIcons-tick_btn_svg.icon';
import { mtIconsUnlinkBtnSvg } from './mtIcons-unlink_btn_svg.icon';
import { mtIconsUploadBtnSvg } from './mtIcons-upload_btn_svg.icon';
import { mtIconsCampaignSender } from './mtIcons-campaign_sender.icon';
import { mtIconsCampaignsTargetgroupsign } from './mtIcons-campaigns_targetgroupsign.icon';
import { mtIconsCancel } from './mtIcons-cancel.icon';
import { mtIconsTargegroupTarget } from './mtIcons-targegroup_target.icon';
import { mtIconsCampaignCircleEsSvg } from './mtIcons-campaign_circle_es_svg.icon';
import { mtIconsCampaignEsSvg } from './mtIcons-campaign_es_svg.icon';
import { mtIconsConnectionEsSvg } from './mtIcons-connection_es_svg.icon';
import { mtIconsContactEsSvg } from './mtIcons-contact_es_svg.icon';
import { mtIconsDataEsSvg } from './mtIcons-data_es_svg.icon';
import { mtIconsDataStorageEsSvg } from './mtIcons-data_storage_es_svg.icon';
import { mtIconsDomainEsSvg } from './mtIcons-domain_es_svg.icon';
import { mtIconsEmployeesEsSvg } from './mtIcons-employees_es_svg.icon';
import { mtIconsEventEsSvg } from './mtIcons-event_es_svg.icon';
import { mtIconsFolderEsSvg } from './mtIcons-folder_es_svg.icon';
import { mtIconsGroupCircleEsSvg } from './mtIcons-group_circle_es_svg.icon';
import { mtIconsImportRuleEsSvg } from './mtIcons-import-rule_es_svg.icon';
import { mtIconsInfoEsSvg } from './mtIcons-info_es_svg.icon';
import { mtIconsNotificationEsSvg } from './mtIcons-notification_es_svg.icon';
import { mtIconsPreferencesEsSvg } from './mtIcons-preferences_es_svg.icon';
import { mtIconsProvenexpertEsSvg } from './mtIcons-provenexpert_es_svg.icon';
import { mtIconsSelectionCircleEsSvg } from './mtIcons-selection_circle_es_svg.icon';
import { mtIconsSignatureCircleEsSvg } from './mtIcons-signature_circle_es_svg.icon';
import { mtIconsSignatureEsSvg } from './mtIcons-signature_es_svg.icon';
import { mtIconsSignaturesEsSvg } from './mtIcons-signatures_es_svg.icon';
import { mtIconsStatisticCircleEsSvg } from './mtIcons-statistic_circle_es_svg.icon';
import { mtIconsTargetgroupEsSvg } from './mtIcons-targetgroup_es_svg.icon';
import { mtIconsUserCircleEsSvg } from './mtIcons-user_circle_es_svg.icon';
import { mtIconsMinus } from './mtIcons-minus.icon';
import { mtIconsSftpExchangeSvg } from './mtIcons-sftp-exchange_svg.icon';
import { mtIconsProvenExpertAccount } from './mtIcons-proven_expert_account.icon';
import { mtIconsCircle } from './mtIcons-circle.icon';
import { mtIconsCognismShapesExplosion } from './mtIcons-cognism_shapes_explosion.icon';
import { mtIconsLogoWhite } from './mtIcons-logo_white.icon';
import { mtIconsSquare } from './mtIcons-square.icon';
import { mtIconsCloseOtherSvg } from './mtIcons-close_other_svg.icon';
import { mtIconsConfirmationModalSvg } from './mtIcons-confirmation_modal_svg.icon';
import { mtIconsImportantModalSvg } from './mtIcons-important_modal_svg.icon';
import { mtIconsInfoModalSvg } from './mtIcons-info_modal_svg.icon';
import { mtIconsInfoOtherSvg } from './mtIcons-info_other_svg.icon';
import { mtIconsWarningModalSvg } from './mtIcons-warning_modal_svg.icon';
import { mtIconsAccount } from './mtIcons-account.icon';
import { mtIconsAccounts } from './mtIcons-accounts.icon';
import { mtIconsCampaign } from './mtIcons-campaign.icon';
import { mtIconsCognism } from './mtIcons-cognism.icon';
import { mtIconsDocument } from './mtIcons-document.icon';
import { mtIconsDownTriangle } from './mtIcons-down-triangle.icon';
import { mtIconsEmployees } from './mtIcons-employees.icon';
import { mtIconsEvents } from './mtIcons-events.icon';
import { mtIconsFolder } from './mtIcons-folder.icon';
import { mtIconsGraph } from './mtIcons-graph.icon';
import { mtIconsGuide } from './mtIcons-guide.icon';
import { mtIconsIntegrations } from './mtIcons-integrations.icon';
import { mtIconsLeads } from './mtIcons-leads.icon';
import { mtIconsLocked } from './mtIcons-locked.icon';
import { mtIconsMailtasticWhite } from './mtIcons-mailtastic_white.icon';
import { mtIconsMailtastic } from './mtIcons-mailtastic.icon';
import { mtIconsNewsWithCircleBlack } from './mtIcons-news_with_circle_black.icon';
import { mtIconsNews } from './mtIcons-news.icon';
import { mtIconsPayment } from './mtIcons-payment.icon';
import { mtIconsRightTriangle } from './mtIcons-right-triangle.icon';
import { mtIconsSettings } from './mtIcons-settings.icon';
import { mtIconsSignatures } from './mtIcons-signatures.icon';
import { mtIconsUnlocked } from './mtIcons-unlocked.icon';
import { mtIconsCampaignListSvg } from './mtIcons-campaign_list_svg.icon';
import { mtIconsDepartmentListSvg } from './mtIcons-department_list_svg.icon';
import { mtIconsDomainListSvg } from './mtIcons-domain_list_svg.icon';
import { mtIconsEventListSvg } from './mtIcons-event_list_svg.icon';
import { mtIconsNotificationListSvg } from './mtIcons-notification_list_svg.icon';
import { mtIconsSignatureListSvg } from './mtIcons-signature_list_svg.icon';
import { mtIconsTargetgroupListSvg } from './mtIcons-targetgroup_list_svg.icon';
import { mtIconsBlogCircle } from './mtIcons-blog-circle.icon';
import { mtIconsBlogRadiusSquare } from './mtIcons-blog-radius-square.icon';
import { mtIconsBlogSquare } from './mtIcons-blog-square.icon';
import { mtIconsFacebookCircle } from './mtIcons-facebook-circle.icon';
import { mtIconsFacebookRadiusSquare } from './mtIcons-facebook-radius-square.icon';
import { mtIconsFacebookSquare } from './mtIcons-facebook-square.icon';
import { mtIconsGooglepCircle } from './mtIcons-googlep-circle.icon';
import { mtIconsGooglepRadiusSquare } from './mtIcons-googlep-radius-square.icon';
import { mtIconsGooglepSquare } from './mtIcons-googlep-square.icon';
import { mtIconsInstagramCircle } from './mtIcons-instagram-circle.icon';
import { mtIconsInstagramRadiusSquare } from './mtIcons-instagram-radius-square.icon';
import { mtIconsInstagramSquare } from './mtIcons-instagram-square.icon';
import { mtIconsLinkedinCircle } from './mtIcons-linkedin-circle.icon';
import { mtIconsLinkedinRadiusSquare } from './mtIcons-linkedin-radius-square.icon';
import { mtIconsLinkedinSquare } from './mtIcons-linkedin-square.icon';
import { mtIconsMsTeamsCircle } from './mtIcons-ms_teams-circle.icon';
import { mtIconsMsTeamsRadiusSquare } from './mtIcons-ms_teams-radius-square.icon';
import { mtIconsMsTeamsSquare } from './mtIcons-ms_teams-square.icon';
import { mtIconsPinterestCircle } from './mtIcons-pinterest-circle.icon';
import { mtIconsPinterestRadiusSquare } from './mtIcons-pinterest-radius-square.icon';
import { mtIconsPinterestSquare } from './mtIcons-pinterest-square.icon';
import { mtIconsSkypeCircle } from './mtIcons-skype-circle.icon';
import { mtIconsSkypeRadiusSquare } from './mtIcons-skype-radius-square.icon';
import { mtIconsSkypeSquare } from './mtIcons-skype-square.icon';
import { mtIconsSnapcCircle } from './mtIcons-snapc-circle.icon';
import { mtIconsSnapcRadiusSquare } from './mtIcons-snapc-radius-square.icon';
import { mtIconsSnapcSquare } from './mtIcons-snapc-square.icon';
import { mtIconsTwitterCircle } from './mtIcons-twitter-circle.icon';
import { mtIconsTwitterRadiusSquare } from './mtIcons-twitter-radius-square.icon';
import { mtIconsTwitterSquare } from './mtIcons-twitter-square.icon';
import { mtIconsWhatsappCircle } from './mtIcons-whatsapp-circle.icon';
import { mtIconsWhatsappRadiusSquare } from './mtIcons-whatsapp-radius-square.icon';
import { mtIconsWhatsappSquare } from './mtIcons-whatsapp-square.icon';
import { mtIconsXingCircle } from './mtIcons-xing-circle.icon';
import { mtIconsXingRadiusSquare } from './mtIcons-xing-radius-square.icon';
import { mtIconsXingSquare } from './mtIcons-xing-square.icon';
import { mtIconsYoutubeCircle } from './mtIcons-youtube-circle.icon';
import { mtIconsYoutubeRadiusSquare } from './mtIcons-youtube-radius-square.icon';
import { mtIconsYoutubeSquare } from './mtIcons-youtube-square.icon';
import { mtIconsAlarm } from './mtIcons-alarm.icon';
import { mtIconsArrowDownDropdown } from './mtIcons-arrow-down-dropdown.icon';
import { mtIconsArticle } from './mtIcons-article.icon';
import { mtIconsAt } from './mtIcons-at.icon';
import { mtIconsCalendar2 } from './mtIcons-calendar_2.icon';
import { mtIconsCalendar } from './mtIcons-calendar.icon';
import { mtIconsCheckmark } from './mtIcons-checkmark.icon';
import { mtIconsCircleFolder } from './mtIcons-circle_folder.icon';
import { mtIconsCircleSignature } from './mtIcons-circle_signature.icon';
import { mtIconsClose } from './mtIcons-close.icon';
import { mtIconsContactList } from './mtIcons-contact_list.icon';
import { mtIconsDetails } from './mtIcons-details.icon';
import { mtIconsDomainDetailsSvg } from './mtIcons-domain_details_svg.icon';
import { mtIconsDragField } from './mtIcons-drag_field.icon';
import { mtIconsEmptystateNotification } from './mtIcons-emptystate_notification.icon';
import { mtIconsEmptystateProvenExpert } from './mtIcons-emptystate_proven_expert.icon';
import { mtIconsExclamation } from './mtIcons-exclamation.icon';
import { mtIconsGear } from './mtIcons-gear.icon';
import { mtIconsGlobe } from './mtIcons-globe.icon';
import { mtIconsGuideSection1Lightbulb } from './mtIcons-guide_section1_lightbulb.icon';
import { mtIconsGuideSection2Pen } from './mtIcons-guide_section2_pen.icon';
import { mtIconsGuideSection3Paint } from './mtIcons-guide_section3_paint.icon';
import { mtIconsGuideSection4Download } from './mtIcons-guide_section4_download.icon';
import { mtIconsGuideSection5Employees } from './mtIcons-guide_section5_employees.icon';
import { mtIconsHelp } from './mtIcons-help.icon';
import { mtIconsHourglass } from './mtIcons-hourglass.icon';
import { mtIconsHtml } from './mtIcons-html.icon';
import { mtIconsHubspot } from './mtIcons-hubspot.icon';
import { mtIconsImage } from './mtIcons-image.icon';
import { mtIconsInfo } from './mtIcons-info.icon';
import { mtIconsInfoRound } from './mtIcons-infoRound.icon';
import { mtIconsInvisible } from './mtIcons-invisible.icon';
import { mtIconsLayout } from './mtIcons-layout.icon';
import { mtIconsList } from './mtIcons-list.icon';
import { mtIconsLoginBlueShapeBg } from './mtIcons-login_blue_shape_bg.icon';
import { mtIconsLoginCogsigLogo } from './mtIcons-login_cogsig_logo.icon';
import { mtIconsLoginGreenShapeBg } from './mtIcons-login_green_shape_bg.icon';
import { mtIconsLoginPurpleShapeBg } from './mtIcons-login_purple_shape_bg.icon';
import { mtIconsManually } from './mtIcons-Manually.icon';
import { mtIconsMark } from './mtIcons-mark.icon';
import { mtIconsMarketo } from './mtIcons-marketo.icon';
import { mtIconsMenu } from './mtIcons-menu.icon';
import { mtIconsMobile } from './mtIcons-mobile.icon';
import { mtIconsNoMobileView } from './mtIcons-no_mobile_view.icon';
import { mtIconsNotification } from './mtIcons-notification.icon';
import { mtIconsO365ConnectionDetailsSvg } from './mtIcons-O365-connection_details_svg.icon';
import { mtIconsOriginal } from './mtIcons-original.icon';
import { mtIconsPaintbrush } from './mtIcons-paintbrush.icon';
import { mtIconsPardot } from './mtIcons-pardot.icon';
import { mtIconsPlus } from './mtIcons-plus.icon';
import { mtIconsProspectLabelSvg } from './mtIcons-prospect_label_svg.icon';
import { mtIconsSender } from './mtIcons-sender.icon';
import { mtIconsSwitch } from './mtIcons-switch.icon';
import { mtIconsSyncArrows } from './mtIcons-sync-arrows.icon';
import { mtIconsTargegroup2 } from './mtIcons-targegroup_2.icon';
import { mtIconsTargegroup } from './mtIcons-targegroup.icon';
import { mtIconsTargetgroupsign } from './mtIcons-targetgroupsign.icon';
import { mtIconsTargetgroupsign2 } from './mtIcons-targetgroupsign2.icon';
import { mtIconsTemplate } from './mtIcons-template.icon';
import { mtIconsTick } from './mtIcons-tick.icon';
import { mtIconsTiles } from './mtIcons-tiles.icon';
import { mtIconsTour } from './mtIcons-tour.icon';
import { mtIconsUnlink } from './mtIcons-unlink.icon';
import { mtIconsUploadJsguru } from './mtIcons-upload_jsguru.icon';
import { mtIconsUpload } from './mtIcons-Upload.icon';
import { mtIconsUser1 } from './mtIcons-user_(1).icon';
import { mtIconsUser } from './mtIcons-user.icon';
import { mtIconsUsers } from './mtIcons-users.icon';
import { mtIconsVideo } from './mtIcons-video.icon';
import { mtIconsVisible } from './mtIcons-visible.icon';
import { mtIconsWebDesign } from './mtIcons-web-design.icon';
import { mtIcons1 } from './mtIcons-1.icon';
import { mtIcons2 } from './mtIcons-2.icon';
import { mtIcons3 } from './mtIcons-3.icon';
import { mtIcons4 } from './mtIcons-4.icon';
import { mtIcons5 } from './mtIcons-5.icon';
import { mtIcons6 } from './mtIcons-6.icon';
import { mtIcons7 } from './mtIcons-7.icon';
import { mtIcons8 } from './mtIcons-8.icon';
import { mtIcons9 } from './mtIcons-9.icon';
import { mtIconsImportant } from './mtIcons-important.icon';
export const completeIconSet = [
    mtIconsDetailsBtnSvg,
    mtIconsDropdownBtnSvg,
    mtIconsEditBtnSvg,
    mtIconsLinkBtnSvg,
    mtIconsNotificationBtnSvg,
    mtIconsOptionsBtnSvg,
    mtIconsPlusBtnSvg,
    mtIconsRefreshBtnSvg,
    mtIconsSwitchBtnSvg,
    mtIconsTest1BtnSvg,
    mtIconsTest2BtnSvg,
    mtIconsTickBtnSvg,
    mtIconsUnlinkBtnSvg,
    mtIconsUploadBtnSvg,
    mtIconsCampaignSender,
    mtIconsCampaignsTargetgroupsign,
    mtIconsCancel,
    mtIconsTargegroupTarget,
    mtIconsCampaignCircleEsSvg,
    mtIconsCampaignEsSvg,
    mtIconsConnectionEsSvg,
    mtIconsContactEsSvg,
    mtIconsDataEsSvg,
    mtIconsDataStorageEsSvg,
    mtIconsDomainEsSvg,
    mtIconsEmployeesEsSvg,
    mtIconsEventEsSvg,
    mtIconsFolderEsSvg,
    mtIconsGroupCircleEsSvg,
    mtIconsImportRuleEsSvg,
    mtIconsInfoEsSvg,
    mtIconsNotificationEsSvg,
    mtIconsPreferencesEsSvg,
    mtIconsProvenexpertEsSvg,
    mtIconsSelectionCircleEsSvg,
    mtIconsSignatureCircleEsSvg,
    mtIconsSignatureEsSvg,
    mtIconsSignaturesEsSvg,
    mtIconsStatisticCircleEsSvg,
    mtIconsTargetgroupEsSvg,
    mtIconsUserCircleEsSvg,
    mtIconsMinus,
    mtIconsSftpExchangeSvg,
    mtIconsProvenExpertAccount,
    mtIconsCircle,
    mtIconsCognismShapesExplosion,
    mtIconsLogoWhite,
    mtIconsSquare,
    mtIconsCloseOtherSvg,
    mtIconsConfirmationModalSvg,
    mtIconsImportantModalSvg,
    mtIconsInfoModalSvg,
    mtIconsInfoOtherSvg,
    mtIconsWarningModalSvg,
    mtIconsLogoWhite,
    mtIconsAccount,
    mtIconsAccounts,
    mtIconsCampaign,
    mtIconsCognism,
    mtIconsDocument,
    mtIconsDownTriangle,
    mtIconsEmployees,
    mtIconsEvents,
    mtIconsFolder,
    mtIconsGraph,
    mtIconsGuide,
    mtIconsIntegrations,
    mtIconsLeads,
    mtIconsLocked,
    mtIconsMailtasticWhite,
    mtIconsMailtastic,
    mtIconsNewsWithCircleBlack,
    mtIconsNews,
    mtIconsPayment,
    mtIconsRightTriangle,
    mtIconsSettings,
    mtIconsSignatures,
    mtIconsUnlocked,
    mtIconsCampaignListSvg,
    mtIconsDepartmentListSvg,
    mtIconsDomainListSvg,
    mtIconsEventListSvg,
    mtIconsNotificationListSvg,
    mtIconsSignatureListSvg,
    mtIconsTargetgroupListSvg,
    mtIconsBlogCircle,
    mtIconsBlogRadiusSquare,
    mtIconsBlogSquare,
    mtIconsFacebookCircle,
    mtIconsFacebookRadiusSquare,
    mtIconsFacebookSquare,
    mtIconsGooglepCircle,
    mtIconsGooglepRadiusSquare,
    mtIconsGooglepSquare,
    mtIconsInstagramCircle,
    mtIconsInstagramRadiusSquare,
    mtIconsInstagramSquare,
    mtIconsLinkedinCircle,
    mtIconsLinkedinRadiusSquare,
    mtIconsLinkedinSquare,
    mtIconsMsTeamsCircle,
    mtIconsMsTeamsRadiusSquare,
    mtIconsMsTeamsSquare,
    mtIconsPinterestCircle,
    mtIconsPinterestRadiusSquare,
    mtIconsPinterestSquare,
    mtIconsSkypeCircle,
    mtIconsSkypeRadiusSquare,
    mtIconsSkypeSquare,
    mtIconsSnapcCircle,
    mtIconsSnapcRadiusSquare,
    mtIconsSnapcSquare,
    mtIconsTwitterCircle,
    mtIconsTwitterRadiusSquare,
    mtIconsTwitterSquare,
    mtIconsWhatsappCircle,
    mtIconsWhatsappRadiusSquare,
    mtIconsWhatsappSquare,
    mtIconsXingCircle,
    mtIconsXingRadiusSquare,
    mtIconsXingSquare,
    mtIconsYoutubeCircle,
    mtIconsYoutubeRadiusSquare,
    mtIconsYoutubeSquare,
    mtIconsAccount,
    mtIconsAlarm,
    mtIconsArrowDownDropdown,
    mtIconsArticle,
    mtIconsAt,
    mtIconsCalendar2,
    mtIconsCalendar,
    mtIconsCampaignListSvg,
    mtIconsCampaign,
    mtIconsCancel,
    mtIconsCheckmark,
    mtIconsCircleFolder,
    mtIconsCircleSignature,
    mtIconsCloseOtherSvg,
    mtIconsClose,
    mtIconsConnectionEsSvg,
    mtIconsContactList,
    mtIconsDetails,
    mtIconsDocument,
    mtIconsDomainDetailsSvg,
    mtIconsDomainListSvg,
    mtIconsDragField,
    mtIconsEmployees,
    mtIconsEmptystateNotification,
    mtIconsEmptystateProvenExpert,
    mtIconsEvents,
    mtIconsExclamation,
    mtIconsFolder,
    mtIconsGear,
    mtIconsGlobe,
    mtIconsGraph,
    mtIconsGuideSection1Lightbulb,
    mtIconsGuideSection2Pen,
    mtIconsGuideSection3Paint,
    mtIconsGuideSection4Download,
    mtIconsGuideSection5Employees,
    mtIconsHelp,
    mtIconsHourglass,
    mtIconsHtml,
    mtIconsHubspot,
    mtIconsImage,
    mtIconsInfoOtherSvg,
    mtIconsInfo,
    mtIconsInfoRound,
    mtIconsIntegrations,
    mtIconsInvisible,
    mtIconsLayout,
    mtIconsList,
    mtIconsLocked,
    mtIconsLoginBlueShapeBg,
    mtIconsLoginCogsigLogo,
    mtIconsLoginGreenShapeBg,
    mtIconsLoginPurpleShapeBg,
    mtIconsMailtastic,
    mtIconsManually,
    mtIconsMark,
    mtIconsMarketo,
    mtIconsMenu,
    mtIconsMobile,
    mtIconsNews,
    mtIconsNoMobileView,
    mtIconsNotification,
    mtIconsO365ConnectionDetailsSvg,
    mtIconsOriginal,
    mtIconsPaintbrush,
    mtIconsPardot,
    mtIconsPayment,
    mtIconsPlus,
    mtIconsProspectLabelSvg,
    mtIconsSender,
    mtIconsSettings,
    mtIconsSignatures,
    mtIconsSwitch,
    mtIconsSyncArrows,
    mtIconsTargegroup2,
    mtIconsTargegroup,
    mtIconsTargetgroupListSvg,
    mtIconsTargetgroupsign,
    mtIconsTargetgroupsign2,
    mtIconsTemplate,
    mtIconsTick,
    mtIconsTiles,
    mtIconsTour,
    mtIconsUnlink,
    mtIconsUnlocked,
    mtIconsUploadJsguru,
    mtIconsUpload,
    mtIconsUser1,
    mtIconsUser,
    mtIconsUsers,
    mtIconsVideo,
    mtIconsVisible,
    mtIconsWebDesign,
    mtIcons1,
    mtIcons2,
    mtIcons3,
    mtIcons4,
    mtIcons5,
    mtIcons6,
    mtIcons7,
    mtIcons8,
    mtIcons9,
    mtIconsImportant,
    mtIconsInfo,
    mtIconsTick
];
