import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { IntercomService } from './services/intercom/intercom.service';
import { ModalService } from './services/modal/modal.service';

@Component({
  selector: 'mt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCognismSignatures = environment.isCogSig;

  constructor(
    private intercomService: IntercomService,
    private modalService: ModalService,
    private titleService: Title
  ) {
    // sets the title
    this.titleService.setTitle(environment.title);

    // sets the favicon
    (document.querySelector('#favIcon') as HTMLLinkElement).href = environment.favicon;

    // the intercom service initialization
    this.intercomService.boot();
  }

  /**
   * Using Hostlistener Deco. detect DOM events : Like Browser Back/Front Button click
   * Hostlistener will call only when Browser back/front buttons will be clicked by user
   */
  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.modalService.closeAllModalPopup();
  }
}
