import { Component } from '@angular/core';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs';
import { DasboardModalService } from 'src/app/modules/dashboard/services/dashboard-modal/dashboard-modal.service';
import { Router } from '@angular/router';
import { AccountService } from '@services/account/account.service';
import { IAccountData } from '@model/interfaces/account-admin.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mt-dashboard',
  templateUrl: './dashboard.component.html',
  providers: [DasboardModalService],
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  isCogSig = environment.isCogSig;

  /**
   * Observable is used to get user subscription data
   * And open trial expired modal if it is.
   */
  hasSubscription$ = this.navigationSidebarService.hasSubscription$.pipe(
    tap(hasSubscription => {
      if (
        !this.modal.hasOpenModals() &&
        !hasSubscription.forceAllow &&
        !hasSubscription.hasSubscription &&
        !hasSubscription.hasTestTime
      ) {
        this.openExpireToUpgradeSubscriptionModal(this.accountService.getUserAccountData());
      }
    })
  );

  constructor(
    private accountService: AccountService,
    private dasboardModalService: DasboardModalService,
    private modal: NgbModal,
    private navigationSidebarService: NavigationSidebarService,
    private router: Router
  ) {}

  /**
   * Open upgrade subscription modal
   * @param accountdata - Object of account data
   */
  openExpireToUpgradeSubscriptionModal(accountData: IAccountData): void {
    this.dasboardModalService.openExpireToUpgradeSubscriptionModal(accountData).subscribe(res => {
      res ? void this.router.navigate(['booking']) : this.navigationSidebarService.logout();
    });
  }
}
