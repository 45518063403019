import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  NonNullableFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash-es';

// RxJs
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take, tap, skip, withLatestFrom } from 'rxjs/operators';

// Interfaces
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import {
  IEmployeeSignaturePlaceholders,
  IFieldImage,
  ISignatureDbField,
  ISignaturePlaceholderImg,
  ISignaturePlaceholderTxt,
  ISignaturePlaceholderURL,
  ISignaturePlaceholderValueImg,
  ISignaturePlaceholderValueImgAdditional,
  ISignaturePlaceholderValueURL,
  TSignaturePlaceholder,
  TSignaturePlaceholderValue,
  UploadProfilePhotoData
} from 'src/app/model/interfaces/signature.interface';
import { ISignaturePlaceholderTypeModalOptions } from 'src/app/services/alert/alert-service.interface';
import { EmployeeDatasetToCreate, IEmployeeGetOne } from 'src/app/services/employee/employee-service.interface';
import { IJsonInfoObject } from 'src/app/services/signature/signature-service.interface';

// Service
import { AlertService } from 'src/app/services/alert/alert.service';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { SignatureHelperService } from 'src/app/services/signature-helper/signature-helper.service';

// Modals
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';
import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgFor, CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { ShouldDisplayFieldPipe } from '@shared/pipes/should-display-field/should-display-field.pipe';
import { SignaturePlaceholderTypeImageModalComponent } from '@organisms/modals/signature-placeholder-type/image/image.component';
import { SignaturePlaceholderTypeLinkModalComponent } from '@organisms/modals/signature-placeholder-type/link/link.component';

const defaultImage: IFieldImage = {
  altText: '',
  image: '',
  initialdimension: { height: 50, width: 500 },
  linkText: '',
  showAs: 'text',
  type: 'image',
  url: '',
  whichImage: 'own'
};

@Component({
  selector: 'mt-edit-user-data',
  templateUrl: './edit-user-data.component.html',
  styleUrls: ['./edit-user-data.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    DefaultBoxComponent,
    EmptyStateComponent,
    FormsModule,
    MtSvgComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    ShouldDisplayFieldPipe,
    TranslocoModule
  ]
})
export class EditUserDataModalComponent implements OnInit {
  isCognismSignatures = environment.isCogSig;

  /**
   * Default want to show the complete modal html with all dataset fields
   * If render only dataset dropdown and input fields then set to true
   * @defaultValue false
   */
  @Input() showOnlyDataset = false;

  private _user!: IEmployeeGetOne;
  /**
   * The user to be edited
   */
  @Input() set user(val: IEmployeeGetOne) {
    this._user = val;
  }
  get user(): IEmployeeGetOne {
    return this._user;
  }

  /**
   * Getting button text for editing self master data and company data
   */
  @Input() btnText!: string;

  /**
   * From user profile cancel button should be available but from signature designer it should not
   * @defaultValue false
   */
  @Input() showCancelButton = false;

  datasets = {
    all: [] as IDataset[],
    selected: '',
    specificUserSetId: ''
  };

  // Holds the values of the input fields
  employeeMasterData = [] as ISignaturePlaceholderTxt[];
  employeeTextData = [] as ISignaturePlaceholderTxt[];
  employeeLinkData = [] as ISignaturePlaceholderURL[];
  employeeImageData = [] as ISignaturePlaceholderImg[];

  showAdminEmailChangeWarning = false;
  showEmailExistingErrorMessage = false;

  signaturePlaceholderTypeImageModalRef!: NgbModalRef;
  signaturePlaceholderTypeLinkModalRef!: NgbModalRef;
  signaturePlaceholderTypeSingleValueModalRef!: NgbModalRef;

  isLoading = false;

  changesMade = false;
  uploadProfilePhotoData!: UploadProfilePhotoData;

  /**
   * Emit value changes to the parent component
   */
  @Output() datasetChanged = new EventEmitter<EmployeeDatasetToCreate[]>();

  /**
   * Hold dataset array's of object to send the parent component
   */
  set allDatasetValues(val: EmployeeDatasetToCreate[]) {
    this._allDatasetValues$.next(val);
  }
  private _allDatasetValues$ = new BehaviorSubject<EmployeeDatasetToCreate[]>([]);
  allDatasetValues$ = this._allDatasetValues$.asObservable().pipe(
    skip(1),
    tap(val => {
      this.datasetChanged.emit(val);
    })
  );

  /**
   * Hold dataset form input's
   */
  userDataForm = this.formBuilder.group({
    master: this.formBuilder.group({}),
    texts: this.formBuilder.group({}),
    images: this.formBuilder.group({}),
    links: this.formBuilder.group({}),
    datasetProfile: ''
  });

  /**
   * After prepared dynamic form fields
   * Need to start observing form value changes
   */
  private _userDataFormValueChange$ = new BehaviorSubject<void>(undefined);
  userDataFormValueChange$ = this._userDataFormValueChange$.pipe(
    switchMap(() => this.userDataForm.valueChanges),
    debounceTime(400),
    withLatestFrom(this.allDatasetValues$),
    tap(([val, allDatasetValues]) => {
      this.allDatasetValues = this.prepareAndUpdateAllDatasetValues(val.datasetProfile as string, allDatasetValues);
    })
  );

  constructor(
    private alert: AlertService,
    private cdRef: ChangeDetectorRef,
    private datasetService: DatasetService,
    private employeeService: EmployeeService,
    private formBuilder: NonNullableFormBuilder,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private signatureHelper: SignatureHelperService,
    public mainModalService: ModalService
  ) {}

  //#region LIFE CYCLE
  ngOnInit(): void {
    this.isLoading = true;
    this.datasetService
      .getAllUser()
      .pipe(
        tap(result => {
          if (!result.length) {
            throw new Error(this.alert.translateDataNotLoaded());
          }

          this.datasets.all = result;

          // Sets up the dropdown selection
          // Default dataset or first one available
          this.datasets.selected =
            this.datasets.all.find(dataset => dataset.isDefault === true)?.id || this.datasets.all[0].id;
        }),
        switchMap(() => this.prepareData([])),
        tap(() => (this.isLoading = false)),
        take(1)
      )
      .subscribe();
  }

  //#endregion

  //#region PUBLIC

  /**
   * prepare dataset input field's object for emit on parent
   * @param datasetId - Id of dataset
   * @param allDatasetValues - Array of employee dataset
   */
  private prepareAndUpdateAllDatasetValues(
    datasetId: string,
    allDatasetValues: EmployeeDatasetToCreate[]
  ): EmployeeDatasetToCreate[] {
    // Prepare dataset object
    const formData = this.userDataForm.getRawValue();
    this.updateDataManagerFields(formData.master, formData.texts, formData.images, formData.links);
    const data: EmployeeDatasetToCreate = {
      data: this.signatureHelper.prepareDataForBackend('employee', this.signatureHelper.dataManager.fields),
      datasetProfile: datasetId,
      datasetTitle: this.datasets.all.find(e => e.id === this.datasets.selected)?.title || ''
    };

    if (this.uploadProfilePhotoData) {
      data.uploadProfilePhotoData = this.uploadProfilePhotoData.imageUploaded;
    }

    // Push or Add dataset object to allDatasetValues observable
    return this.updateDatasetChanged(data, allDatasetValues);
  }

  /**
   * Edits the user signature data.
   * @param item -
   * @param placer - location of where the `item` should be updated in `this.employeeImageData | this.employeeLinkData`
   * @param subheadingTranslateTag -
   */
  async editUserSignatureData(
    item: ISignaturePlaceholderImg | ISignaturePlaceholderURL,
    placer: number,
    subheadingTranslateTag: string
  ): Promise<void> {
    switch (item.type) {
      case 'image':
        await this.openDetailsModal(
          item,
          placer,
          subheadingTranslateTag,
          'images',
          SignaturePlaceholderTypeImageModalComponent,
          this.employeeImageData
        );
        break;
      case 'link':
        await this.openDetailsModal(
          item,
          placer,
          subheadingTranslateTag,
          'links',
          SignaturePlaceholderTypeLinkModalComponent,
          this.employeeLinkData
        );
        break;
    }
  }

  /**
   * Manage dataset's value and emit to the parent component if exist
   * @param val - Object of dataset fileds
   * @param allDatasetValues - Array of employee dataset
   */
  private updateDatasetChanged(
    val: EmployeeDatasetToCreate,
    allDatasetValues: EmployeeDatasetToCreate[]
  ): EmployeeDatasetToCreate[] {
    const index = allDatasetValues.findIndex(info => info.datasetProfile === val.datasetProfile);
    if (index !== -1) {
      allDatasetValues[index] = val;
    } else {
      allDatasetValues.push(val);
    }
    return allDatasetValues;
  }

  /**
   * Checks if the datasetId as `id` is a default dataset.
   * @param id - id to check
   * @returns if default profile
   */
  isDefaultDataset(id: string): boolean {
    const isDefaultProfile = this.datasets.all.find((data: IDataset) => data.id === id);
    return isDefaultProfile?.isDefault || false;
  }

  /**
   * Checks if the form control is invalid.
   * @param tag - form control
   * @returns validity of form
   */
  isMasterInvalid(tag: string): boolean | undefined {
    return this.userDataForm?.get(['master', tag])?.invalid;
  }

  /**
   * Check if any unsaved changes remain then open prompt before modal close
   * @returns Prevent to close the modal if any unsaved changes remain
   */
  async modalClose(): Promise<boolean> {
    if (this.userDataForm?.dirty) {
      const completed = await this.mainModalService.openUnsavedChangesAlertModal();
      if (!completed || completed === 'abort') return false;
    }
    this.modal.close(false);
    return true;
  }

  /**
   * Changes the dataset to the selected dataset.
   * @param allDatasetValues - Array of employee dataset
   */
  onDatasetProfileChange(datasetId: string, allDatasetValues: EmployeeDatasetToCreate[]): void {
    const prevDataset = this.datasets.selected;
    if (this.userDataForm?.dirty && !this.showOnlyDataset) {
      // Confirm the user wants to change the assigned company data profile
      this.openConfirmationModal()
        .pipe(
          tap((confirmed: string) => {
            if (confirmed === 'save_and_exit') {
              this.saveEmployeeInfoData('save_on_profile_change');
              this.datasets.selected = datasetId;
              this.setNewDatasetInForm(allDatasetValues);
            } else if (confirmed === 'abort') {
              // If click on abort then set previous dataset
              this.datasets.selected = prevDataset;
            } else {
              this.datasets.selected = datasetId;
              this.setNewDatasetInForm(allDatasetValues);
            }
          })
        )
        .subscribe();
    } else {
      this.datasets.selected = datasetId;
      this.setNewDatasetInForm(allDatasetValues);
    }
  }

  /**
   * Reset current dataset from form and set new dataset
   * @param allDatasetValues - Array of employee dataset
   */
  setNewDatasetInForm(allDatasetValues: EmployeeDatasetToCreate[]): void {
    this.resetUserForm();
    this.isLoading = true;
    this.prepareData(allDatasetValues).subscribe({
      error: (err: HttpErrorResponse) => void this.alert.defaultErrorMessage(err.message),
      complete: () => {
        this.isLoading = false;
        this.cdRef.detectChanges();
      }
    });
  }

  /**
   * Save button of modal.
   */

  saveEmployeeInfoData(type: string): void {
    if (!this.userDataForm?.dirty && (!this.uploadProfilePhotoData || !this.uploadProfilePhotoData.imageUploaded)) {
      this.modal.close(false);
      return;
    }

    this.updateDataManagerFields(
      this.userDataForm?.get('master')?.value,
      this.userDataForm?.get('texts')?.value,
      this.userDataForm?.get('images')?.value,
      this.userDataForm?.get('links')?.value
    );

    if (
      this.uploadProfilePhotoData?.imageUploaded?.value?.image &&
      (this.uploadProfilePhotoData.imageUploaded.value.image as ISignaturePlaceholderValueImgAdditional).$ngfDataUrl
    ) {
      // Update image with data save
      this.employeeService
        .getUploadedImageData(this.uploadProfilePhotoData.imageUploaded)
        .pipe(
          tap(res => {
            this.processVisibleDatasetDataChanges(res);
          })
        )
        .subscribe();
    } else if (this.uploadProfilePhotoData && this.uploadProfilePhotoData.imageUploaded) {
      // Delete image with data save
      this.processVisibleDatasetDataChanges(defaultImage as TSignaturePlaceholderValue);
    } else {
      // Only data save
      this.processVisibleDatasetDataChanges();
    }
  }

  /**
   * Shows an info message box.
   * @param state -
   */
  setShowAdminEmailChangeWarning(state: boolean): void {
    this.showAdminEmailChangeWarning = state;
  }

  /**
   * Get existing dataset value's if exist
   * @param allDatasetValues - Array of employee dataset
   * @returns Existing dataset Obj or undefined
   */
  getExistingDatasetValuesFromObservable(
    allDatasetValues: EmployeeDatasetToCreate[]
  ): EmployeeDatasetToCreate | undefined {
    // While creating employee - we have user id set to dummy to get the dataset
    if (this._user.id === 'dummy') {
      return allDatasetValues.find(info => info.datasetProfile === this.datasets.selected);
    }
    return;
  }

  /**
   * Opens the modal to upload/manage the user profile photo
   * @param datasetId - Id of dataset to update
   * @param userId - User id
   * @param allDatasetValues - Array of employee dataset
   */
  openUploadProfilePhotoModal(datasetId: string, userId: string, allDatasetValues: EmployeeDatasetToCreate[]): void {
    const modalOptions = this.showOnlyDataset
      ? { photoUrl: true, useForAllEmployees: false, useForAllProfiles: false }
      : { photoUrl: true, useForAllEmployees: true, useForAllProfiles: true };
    this.uploadProfilePhotoData = this.uploadProfilePhotoData || {};
    this.uploadProfilePhotoData.userId = userId;
    this.uploadProfilePhotoData.datasetId = datasetId;

    const existingDataset = this.getExistingDatasetValuesFromObservable(allDatasetValues);
    if (existingDataset) {
      this.uploadProfilePhotoData = {
        ...this.uploadProfilePhotoData,
        imageUploaded: existingDataset.uploadProfilePhotoData as ISignaturePlaceholderImg
      };
    }

    this.mainModalService
      .openUploadProfilePhotoModal(this.uploadProfilePhotoData, modalOptions)
      .pipe(
        tap(uploadProfilePhotoData => {
          if (uploadProfilePhotoData && uploadProfilePhotoData.imageUploaded && uploadProfilePhotoData.userId) {
            // If change the profile image
            this.uploadProfilePhotoData = uploadProfilePhotoData;
            this.changesMade = true;
          }

          if (existingDataset) {
            existingDataset.uploadProfilePhotoData = this.uploadProfilePhotoData.imageUploaded;
            // Push or Add dataset object to allDatasetValues observable
            this.allDatasetValues = this.updateDatasetChanged(existingDataset, allDatasetValues);
          }
        })
      )
      .subscribe();
  }

  //#endregion

  //#region PRIVATE
  /**
   * Creates the form group with the data from the different sections: master, texts, images, and links.
   * @returns form group
   */
  private createUserDataForm(): UntypedFormGroup {
    const masterGroupForm = {} as { [keys: string]: UntypedFormControl };
    this.employeeMasterData.forEach(e => {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      masterGroupForm[e.tag] = this.formBuilder.control(e.value || '', [Validators.required]);
      if (e.tag === 'ma_email') {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        masterGroupForm[e.tag].setValidators([Validators.required, Validators.email]);
        masterGroupForm[e.tag].updateValueAndValidity();
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const textsGroupForm = {} as { [keys: string]: UntypedFormControl };
    this.employeeTextData.forEach(e => {
      if (e.tag === 'ma_email') {
        textsGroupForm[e.tag] = this.formBuilder.control({
          value: e.value || '',
          disabled: this.isDefaultDataset(this.datasets.selected)
        });
      } else {
        textsGroupForm[e.tag] = this.formBuilder.control(e.value || '');
      }
    });
    const imagesGroupForm = {} as { [keys: string]: UntypedFormControl };
    this.employeeImageData.forEach(e => {
      imagesGroupForm[e.tag] = this.formBuilder.control(e.value?.url || '');
    });
    const linksGroupForm = {} as { [keys: string]: UntypedFormControl };
    this.employeeLinkData.forEach(e => {
      linksGroupForm[e.tag] = this.formBuilder.control(e.value?.url || '');
    });

    return this.formBuilder.group({
      master: this.formBuilder.group(masterGroupForm),
      texts: this.formBuilder.group(textsGroupForm),
      images: this.formBuilder.group(imagesGroupForm),
      links: this.formBuilder.group(linksGroupForm),
      datasetProfile: this.formBuilder.control(this.datasets.selected)
    });
  }

  /**
   * Opens the Details modal.
   * @param item -
   * @param placer - location of where the `item` should be updated in `localTypeData`
   * @param subheadingTranslateTag -
   * @param formControlName - the location of where the form data should be saved
   * @param modal - SignaturePlaceholderTypeImageModalComponent | SignaturePlaceholderTypeLinkModalComponent
   * @param localTypeData - this.employeeImageData | this.employeeLinkData
   * @returns
   */
  private async openDetailsModal<T extends ISignaturePlaceholderImg | ISignaturePlaceholderURL>(
    item: T,
    placer: number,
    subheadingTranslateTag: string,
    formControlName: 'images' | 'links',
    modal: unknown,
    localTypeData: T[]
  ): Promise<void> {
    const detailsModalRef = this.modalService.open(modal, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'signature_value_edit_modal mt_modal_700'
    });

    // type-casting of item
    let typedItem;
    if (this.signatureHelper.isImg(item)) {
      typedItem = item;
    } else if (this.signatureHelper.isLink(item)) {
      typedItem = item;
    } else {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    detailsModalRef.componentInstance['modalOptions'] = {
      formData: typedItem,
      heading: this.alert.translate(item.label),
      mode: 'create',
      subheading: this.alert.translate(subheadingTranslateTag)
    } as ISignaturePlaceholderTypeModalOptions;

    // needed to possibly delete the image
    if (item.tag === 'ma_foto') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      detailsModalRef.componentInstance['datasetId'] = this.datasets.selected;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      detailsModalRef.componentInstance['employeeId'] = this._user.id;
    }

    const modalResponse = (await detailsModalRef.result) as T;

    // Changes were made in the modal
    if (modalResponse) {
      this.userDataForm?.get([formControlName, item.tag])?.setValue(modalResponse?.value?.url);

      this.employeeService
        .setEmployeeInfoSingle(this.signatureHelper.dataManager.specificUserSetId, modalResponse)
        .subscribe((infoSingleResponse: TSignaturePlaceholderValue) => {
          // saves the changes
          this.signatureHelper.dataManager.fields.employee.groups.find((info: IJsonInfoObject, ii: number) => {
            info.entries.find((e, jj: number) => {
              if (e.tag === item.tag) {
                // deep copy
                e = { ...e, ...{ value: infoSingleResponse } } as T;

                // saves changes to the list
                this.signatureHelper.dataManager.fields.employee.groups[ii].entries[jj] = e;
                localTypeData[placer] = e as T;
              }
            });
          });
        });
    }
  }

  /**
   * Prepares the data by making backend calls and propagating it to local variables.
   */
  private prepareData(allDatasetValues: EmployeeDatasetToCreate[]): Observable<void> {
    return this.signatureHelper.loadJsonFieldStructure().pipe(
      tap(fields => {
        // Sets up the blank fields of the JSON
        this.signatureHelper.dataManager.fields = fields;
      }),
      switchMap(() => {
        // Sets up the employee data
        if (this._user.id !== 'dummy') {
          return this.signatureHelper.getEmployeeAccountData(
            this._user.id,
            this.datasets.selected,
            this.signatureHelper.dataManager.fields
          );
        } else {
          // Assign existing dataset values if exist otherwise create it
          const existingDataset = this.getExistingDatasetValuesFromObservable(allDatasetValues);
          const employeeDataset = existingDataset
            ? (existingDataset.data as ISignatureDbField)
            : ({ ma_email: this._user.email } as ISignatureDbField);

          // Manage upload foto if exist on existing dataset
          if (existingDataset && existingDataset.uploadProfilePhotoData) {
            this.uploadProfilePhotoData.imageUploaded =
              existingDataset.uploadProfilePhotoData || ({} as ISignaturePlaceholderImg);
          } else {
            this.uploadProfilePhotoData = {} as UploadProfilePhotoData;
          }

          return this.signatureHelper.getDummyEmployeeAccountData(
            this.signatureHelper.dataManager.fields,
            employeeDataset
          );
        }
      }),
      map(() => {
        // Prepares the user info fields form
        this.datasets.specificUserSetId = this.signatureHelper.dataManager.specificUserSetId;

        if (
          Object.keys(this.signatureHelper.dataManager.employeeData).length === 0 ||
          Object.keys(this.signatureHelper.dataManager.fields).length === 0
        ) {
          this.modal.close('cancelled, because could not load all data for employee user info edit modal');
        } else {
          this.setGeneralData(allDatasetValues);
        }

        // Dynamic form fields value set then start observing form value changes
        this._userDataFormValueChange$.next();
      })
    );
  }

  /**
   * Processes the data into a form that is accepted for the backend db.Dataset.
   */
  private processVisibleDatasetDataChanges(newPhotos?: TSignaturePlaceholderValue): void {
    const simpleForm = this.signatureHelper.prepareDataForBackend('employee', this.signatureHelper.dataManager.fields);
    const datasetTitle = this.datasets.all.find(e => e.id === this.datasets.selected)?.title || '';
    if (newPhotos) {
      simpleForm.ma_foto = newPhotos;
      let userData!: IEmployeeSignaturePlaceholders;
      if (this.uploadProfilePhotoData.imageUploaded && this.uploadProfilePhotoData.imageUploaded.value) {
        userData = {
          ...userData,
          ma_foto: newPhotos as ISignaturePlaceholderValueImg
        };

        this.uploadProfilePhotoData.imageUploaded.value = userData.ma_foto;
        this.setGlobalSettings();
      }
    } else {
      this.setGlobalSettings();
      simpleForm.ma_foto = Object.assign({}, simpleForm.ma_foto, this.uploadProfilePhotoData);
    }

    this.datasetService
      .update(this.datasets.specificUserSetId, simpleForm, datasetTitle)
      .pipe(
        map(() => (newPhotos ? true : false)),
        tap(newPhotos => {
          if (!newPhotos) {
            this.modal.close(true);
          }
          if (this.btnText === 'endprematurely') {
            void this.alert.defaultSuccessMessage(this.alert.translate('CHANGES_SAVED_SUCCESSFULLY'));
          }
        }),
        filter(isNewPhotos => isNewPhotos),
        switchMap(() => {
          return this.employeeService.setEmployeeInfoSingle(
            this.uploadProfilePhotoData.userDatasetId,
            this.uploadProfilePhotoData.imageUploaded as TSignaturePlaceholder
          );
        }),
        tap(() => this.modal.close(true)),
        catchError((err: HttpErrorResponse) => {
          if (err.message === 'EMAIL_ALREADY_EXISTING') {
            void this.alert.defaultErrorMessage(this.alert.translate('EMPLOYEE_MAIL_ALREADY_EXISTING'));
          } else {
            void this.alert.defaultErrorMessage(this.alert.translateTechnicalError());
          }
          return of(err);
        })
      )
      .subscribe();
  }

  /**
   * Set global employee profile settings value
   */
  setGlobalSettings(): void {
    if (this.uploadProfilePhotoData?.imageUploaded?.value) {
      this.uploadProfilePhotoData.imageUploaded.value.global = this.uploadProfilePhotoData.imageUploaded?.global;
    }
  }

  /**
   * Opens the confirmation modal to save the data of datasets
   * @returns - The status of confirmation as a string
   */
  openConfirmationModal(): Observable<string> {
    // Confirm the user wants to change the assigned data profile
    const confirmed = this.mainModalService.openUnsavedChangesAlertModal(
      'save_current_employee_changes',
      'SAVEANDPROCEED',
      'Attention',
      'proceed_no_save'
    );
    return from(confirmed as Promise<string>).pipe(take(1));
  }

  /**
   * Resets the user form.
   */
  private resetUserForm(): void {
    this.employeeMasterData = [];
    this.employeeTextData = [];
    this.employeeImageData = [];
    this.employeeLinkData = [];
  }

  /**
   * Takes the local saved data and separates it into four sections: master, texts, images, and links.
   * @param allDatasetValues - Array of employee dataset
   */
  private setGeneralData(allDatasetValues: EmployeeDatasetToCreate[]): void {
    this.signatureHelper.dataManager.fields.employee.groups.forEach((info: IJsonInfoObject) => {
      info.entries.forEach((entry: ISignaturePlaceholderTxt | ISignaturePlaceholderURL | ISignaturePlaceholderImg) => {
        switch (entry.type) {
          case 'singlevalue':
            if (!this.isCognismSignatures && entry.tag === 'ma_tel') {
              entry.label = 'SECTIONS.BUSINESS_PHONE_NUMBER';
            }
            if (entry.tag === 'ma_vorname' || entry.tag === 'ma_nachname') {
              this.employeeMasterData.push(entry as ISignaturePlaceholderTxt);
            } else if (entry.tag === 'ma_email') {
              this.employeeMasterData.push(entry as ISignaturePlaceholderTxt);
              this.employeeTextData.push(entry as ISignaturePlaceholderTxt);
            } else {
              this.employeeTextData.push(entry as ISignaturePlaceholderTxt);
            }
            break;
          case 'link':
            // creates default values if empty
            if (entry?.value === undefined || !this.signatureHelper.isLinkValue(entry.value)) {
              entry.value = { url: '', linkText: '', type: 'link' } as ISignaturePlaceholderValueURL;
            }

            this.employeeLinkData.unshift(entry as ISignaturePlaceholderURL);
            break;
          case 'image':
            // creates default values if empty
            if (entry?.value === undefined || !this.signatureHelper.isImgValue(entry.value)) {
              if (entry.tag === 'ma_foto') {
                entry.value = cloneDeep(defaultImage) as ISignaturePlaceholderValueImg;
              } else {
                const defaultsocialIcon = cloneDeep(defaultImage);
                defaultsocialIcon.showAs = 'image';
                entry.value = defaultsocialIcon as ISignaturePlaceholderValueImg;
              }
            }

            this.employeeImageData.push(entry as ISignaturePlaceholderImg);
            break;
        }
      });
    });

    // creates the form
    this.userDataForm = this.createUserDataForm();

    // Emit first prepared default dataset profile
    const existingDataset = this.getExistingDatasetValuesFromObservable(allDatasetValues);
    if (!existingDataset) {
      this.allDatasetValues = this.prepareAndUpdateAllDatasetValues(this.datasets.selected, allDatasetValues);
    }
  }

  /**
   * Updates the data manager fields of employee groups,
   * so that it can further be used in preparing data for backend
   * @param master - value from FormGroup
   * @param texts - value from FormGroup
   * @param images - value from FormGroup
   * @param links - value from FormGroup
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateDataManagerFields(master: any, texts: any, images: any, links: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const partsToUpdate = Object.assign({}, texts, master, images, links);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const keys = Object.keys(partsToUpdate);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const values = Object.values(partsToUpdate);
    const compactData = new Map();
    keys.forEach((k: string, i: number) => {
      compactData.set(k, values[i]);
    });

    const fieldsToSearch = this.signatureHelper.dataManager.fields.employee.groups;
    for (const field of fieldsToSearch) {
      for (const entry of field.entries) {
        if (entry.value !== undefined) {
          const valueToSet = entry.value;
          if (this.signatureHelper.isImg(entry) && this.signatureHelper.isImgValue(valueToSet)) {
            entry.value = cloneDeep(Object.assign(entry.value, { url: compactData.get(entry.tag) as string }));
          } else if (this.signatureHelper.isLink(entry) && this.signatureHelper.isLinkValue(valueToSet)) {
            entry.value = cloneDeep(Object.assign(entry.value, { url: compactData.get(entry.tag) as string }));
          } else if (this.signatureHelper.isTxt(entry) && typeof valueToSet === 'string') {
            entry.value = cloneDeep(compactData.get(entry.tag) as string);
          }
        }
      }
    }
  }
  //#endregion
}
