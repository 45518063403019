import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

// Rxjs
import { forkJoin, iif, map, Observable, of, switchMap } from 'rxjs';

// Interfaces
import { IEmployeeGet } from '@services/employee/employee-service.interface';
import { ISignatureGetOne } from '@services/signature/signature-service.interface';
import { SignatureEditWizardStepName } from '@model/interfaces/signature.interface';

// Services
import { EmployeeService } from '@services/employee/employee.service';
import { ModalService } from '@services/modal/modal.service';
import { SignatureDataService } from '@services/signature-data/signature-data.service';
import { SignatureService } from '@services/signature/signature.service';

@Component({
  selector: 'mt-install-signature-modal-colleagues',
  templateUrl: './install-signature-modal-colleagues.component.html',
  styleUrls: ['./install-signature-modal-colleagues.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallSignatureModalColleaguesComponent {
  isCogsig = environment.isCogSig;

  /**
   * Id of the selected signature
   */
  @Input() set modalData(value: { signatureId: string; userId: string; isExternalAdmin: boolean }) {
    this.loadData$ = of(value).pipe(
      switchMap(modalData =>
        forkJoin([
          this.signatureService.getOne(value.signatureId),
          iif(() => !!modalData.userId, this.employeeService.getOne(value.userId), of({ activationCode: '' }))
        ])
      ),
      map(([signature, userData]) => {
        const totalAmountOfMember = signature.activeGroups
          .map(group => group.amountOfMembers)
          .reduce((sum, current) => sum + current, 0);

        const checkSetup = totalAmountOfMember === 0 || signature.lastRollout === null;
        return {
          activationCode: userData?.activationCode || '',
          checkSetup,
          employeeId: value.userId,
          isExternalAdmin: value.isExternalAdmin,
          signature,
          totalAmountOfMember
        };
      })
    );
  }

  /**
   * Get total amount of members associated with signature
   */
  loadData$!: Observable<{
    activationCode: string;
    checkSetup: boolean;
    employeeId: string;
    isExternalAdmin: boolean;
    signature: ISignatureGetOne;
    totalAmountOfMember: number;
  }>;

  constructor(
    private employeeService: EmployeeService,
    private modalService: ModalService,
    private router: Router,
    private signatureDataService: SignatureDataService,
    private signatureService: SignatureService,
    public modal: NgbActiveModal,
    public translate: TranslocoService
  ) {}

  /**
   * To play the video on click of image
   * @param url - The translation tag of youtube link
   * @param title - The translation tag of the title
   */
  videoPlay(url: string, title: string): void {
    // Opens the modal and play the video within
    this.modalService.openPlayVideoModalV2(url, title).subscribe();
  }

  /**
   * Redirect to signature edit wizard and opens the related step
   * @param stepNoToOpen - Step name to open in edit signature wizard
   */
  redirectToSignatureEditWizard(stepNoToOpen: SignatureEditWizardStepName, signatureId: string): void {
    this.signatureDataService.setOpenSignatureEditStep(stepNoToOpen);
    void this.router.navigate(['/signatures/edit/', signatureId], {
      state: { signatureIdFromInstallation: signatureId, installationType: 'forColleagues' }
    });
    this.modal.close();
  }
}
