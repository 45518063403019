import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { IResponse, IResponseData } from 'src/app/model/interfaces/response.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AlertService } from '../alert/alert.service';
import { IExternalAdminChangeAccount, IExternalAdminGetAccounts } from './external-admin.interface';

@Injectable({
  providedIn: 'root'
})
export class ExternalAdminService {
  constructor(private alert: AlertService, private http: HttpClient, private operator: CustomOperators) {}

  /**
   * Gets the accounts
   * @returns Data of external admin account
   */
  getAccounts(): Observable<IExternalAdminGetAccounts[]> {
    return this.http
      .get<IResponseData<IExternalAdminGetAccounts[]>>('/externalAdmin/getAccounts')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Changes the account
   * @param accountId - The account id of user
   * @returns Data of changed account
   */
  changeAccount(accountId: string): Observable<IExternalAdminChangeAccount> {
    return this.http
      .post<IResponseData<IExternalAdminChangeAccount>>('/externalAdmin/changeAccount', { accountId })
      .pipe(this.operator.extractResponseData());
  }
}
