import { NavigationSidebarMenu } from 'src/app/modules/root/model/navigation-sidebar-menu.interface';
import { environment } from 'src/environments/environment';

const isAvailableOnCogSig = environment.isCogSig;

const TOP_MENUS: NavigationSidebarMenu[] = [
  { image: 'assets/images/sidebar/employees.svg', name: 'employees', url: '/employees/list' },
  { image: 'assets/images/sidebar/signatures.svg', name: 'signatures', url: '/signatures/list' },
  { image: 'assets/images/sidebar/campaign.svg', name: 'campaigns', url: '/campaigns/list' }
];

const BOTTOM_MENU: NavigationSidebarMenu[] = [
  { image: 'assets/images/sidebar/guide.svg', name: 'guide', url: '/getstartedguide' },
  { image: 'assets/images/sidebar/integrations.svg', name: 'integrations', url: '/integrations/filter' },
  { image: 'assets/images/sidebar/news.svg', name: 'news', url: '/' } // TODO: news component url needs to be created
];

const EMPLOYEES_MENU: NavigationSidebarMenu[] = !isAvailableOnCogSig
  ? [
      {
        image: 'assets/images/sidebar/employees.svg',
        name: 'EMPLOYEE',
        isDropdownOpen: true,
        submenu: [
          {
            image: 'assets/images/sidebar/employees.svg',
            name: 'all-employees',
            url: '/employees/list',
            hasCounter: true
          },
          {
            image: 'assets/images/sidebar/employees.svg',
            name: 'broken_sync_menupoint',
            url: '/employees/outdated',
            hasCounter: true
          },
          {
            image: 'assets/images/sidebar/employees.svg',
            name: 'available-from-directory',
            url: '/employees/not-yet-imported',
            hasCounter: false
          }
        ]
      },
      {
        image: 'assets/images/sidebar/folder.svg',
        name: 'DEPARTMENTS',
        url: '/groups/list',
        hasCounter: true
      },
      {
        image: 'assets/images/sidebar/document.svg',
        name: 'datasets',
        isDropdownOpen: false,
        submenu: [
          { image: 'assets/images/sidebar/accounts.svg', name: 'Employee', url: '/dataprofiles/employee' },
          { image: 'assets/images/sidebar/account.svg', name: 'company', url: 'dataprofiles/company' }
        ]
      }
    ]
  : [
      {
        image: 'assets/images/sidebar/employees.svg',
        name: 'EMPLOYEE',
        url: '/employees/list',
        hasCounter: true
      },
      {
        image: 'assets/images/sidebar/folder.svg',
        name: 'DEPARTMENTS',
        url: '/groups/list',
        hasCounter: true
      }
    ];

const SIGNATURES_MENU: NavigationSidebarMenu[] = !isAvailableOnCogSig
  ? [
      { hasCounter: true, image: 'assets/images/sidebar/signatures.svg', name: 'SIGNATURES', url: '/signatures/list' },
      { image: 'assets/images/sidebar/settings.svg', name: 'Default_Signatures', url: '/signatures/default' }
    ]
  : [{ hasCounter: true, image: 'assets/images/sidebar/signatures.svg', name: 'SIGNATURES', url: '/signatures/list' }];

const MARKETINGS_MENU: NavigationSidebarMenu[] = isAvailableOnCogSig
  ? [
      { hasCounter: true, image: 'assets/images/sidebar/campaign.svg', name: 'CAMPAIGNS', url: '/campaigns/list' },
      { hasCounter: true, image: 'assets/images/sidebar/events.svg', name: 'events', url: '/events' },
      { image: 'assets/images/sidebar/graph.svg', name: 'navi_dashboard_overview', url: '/dashboard/overview' }
    ]
  : [
      { hasCounter: true, image: 'assets/images/sidebar/campaign.svg', name: 'CAMPAIGNS', url: '/campaigns/list' },
      { hasCounter: true, image: 'assets/images/sidebar/events.svg', name: 'events', url: '/events' },
      { image: 'assets/images/sidebar/graph.svg', name: 'navi_dashboard_overview', url: '/dashboard/overview' },
      {
        hasCounter: true,
        image: 'assets/images/svgs/targetgroupsign2.svg',
        name: 'target_group_s',
        url: '/targetgroups'
      },
      {
        hasCounter: true,
        image: 'assets/images/svgs/notification.svg',
        name: 'notifications',
        url: '/notifications/list'
      },
      { image: 'assets/images/svgs/Upload.svg', name: 'performance_feed', url: '/performancefeed/list' },
      {
        image: 'assets/images/sidebar/employees.svg',
        name: 'recipients',
        isDropdownOpen: true,
        submenu: [
          { image: 'assets/images/sidebar/accounts.svg', name: 'accounts', url: 'recipients/accounts/list' },
          { image: 'assets/images/sidebar/account.svg', name: 'contacts', url: 'recipients/contacts/list' }
        ]
      },
      {
        image: 'assets/images/sidebar/leads.svg',
        name: 'Leads',
        isDropdownOpen: true,
        submenu: [
          { image: 'assets/images/sidebar/accounts.svg', name: 'accounts', url: 'leads/accounts/list' },
          { image: 'assets/images/sidebar/account.svg', name: 'contacts', url: 'leads/contacts/list' }
        ]
      }
    ];

const INTEGRATIONS_MENU: NavigationSidebarMenu[] = [
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'integration_finder',
    url: '/integrations/filter'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'myintegrations',
    url: '/integrations/allintegrations'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'nav_integrations',
    isDropdownOpen: true,
    submenu: [
      { name: 'all_integrations', url: '/integrations/allintegrations' },
      { name: 'installsignature', url: '/integrations/allintegrations' },
      { name: 'importusers', url: '/integrations/allintegrations' },
      { name: 'marketing_integrations', url: '/integrations/allintegrations' }
    ]
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'integration_list_office365',
    url: '/integrations/allintegrations'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'source_gsuite',
    url: '/integrations/allintegrations'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'software_and_downloads',
    url: '/integrations/softwareanddownloads'
  }
];

const SETTINGS_MIDDLE_MENU: NavigationSidebarMenu[] = [
  { name: 'nav_help_center', url: 'sidebar_settings_help' },
  { name: 'resources', url: 'sidebar_settings_resources' },
  { name: 'imprint', url: 'sidebar_settings_imprint' }
];

const SUBMENU: NavigationSidebarMenu[] = [
  { name: 'employees', title: 'company' },
  { name: 'signatures', title: 'SIGNATURES' },
  { name: 'campaigns', title: 'dc_d0003' },
  { name: 'settings', title: 'int_option_3' },
  { name: 'integrations', title: 'nav_integrations' }
];

const ADVANCE_SETTINGS_MENU: NavigationSidebarMenu[] = isAvailableOnCogSig
  ? [
      { image: 'assets/images/sidebar/account.svg', name: 'administration', url: '/settings/administration' },
      {
        image: 'assets/images/sidebar/settings.svg',
        name: 'advancedsettings',
        isDropdownOpen: true,
        submenu: [
          { name: 'EMAIL_NOTIFICATIONS', url: 'settings/advancedsettings/emailnotification' },
          { name: 'displaysettings', url: 'settings/advancedsettings/displaysettings' },
          { name: 'utm_settings', url: 'settings/advancedsettings/utm' }
        ]
      }
    ]
  : [
      // { name: 'nav_custom_domain', url: 'settings/advancedsettings/customdomain' }, TODO: Currently removed
      { image: 'assets/images/sidebar/account.svg', name: 'administration', url: '/settings/administration' },
      {
        image: 'assets/images/sidebar/payment.svg',
        name: 'nav_plan_and_billing',
        isDropdownOpen: true,
        submenu: [
          { name: 'overview', url: 'booking/overview' },
          { name: 'PAYMENTINFORMATION', url: 'booking/payment-information' },
          { name: 'subscriptionbills', url: 'booking/invoices' }
        ]
      },
      {
        image: 'assets/images/sidebar/payment.svg',
        name: 'nav_plan_and_billing',
        url: 'booking/overview'
      },
      {
        image: 'assets/images/sidebar/settings.svg',
        name: 'advancedsettings',
        isDropdownOpen: true,
        submenu: [
          { name: 'EMAIL_NOTIFICATIONS', url: 'settings/advancedsettings/emailnotification' },
          { name: 'adv_stngs_internal_domains_hl', url: 'settings/advancedsettings/internaldomains' },
          { name: 'tracking', url: 'settings/advancedsettings/domains' },
          { name: 'displaysettings', url: 'settings/advancedsettings/displaysettings' },
          { name: 'Public API', url: 'settings/advancedsettings/publicapi' },
          { name: 'prio_headline', url: 'settings/advancedsettings/campaignpriority' },
          { name: 'utm_settings', url: 'settings/advancedsettings/utm' }
        ]
      }
    ];

export const SIDEBAR_MENU = {
  advanced: ADVANCE_SETTINGS_MENU,
  bottomMenus: BOTTOM_MENU,
  employees: EMPLOYEES_MENU,
  integrations: INTEGRATIONS_MENU,
  marketing: MARKETINGS_MENU,
  settings_middle: SETTINGS_MIDDLE_MENU,
  signatures: SIGNATURES_MENU,
  submenus: SUBMENU,
  topMenus: TOP_MENUS
};
