import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NonNullableFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import {
  CompanyDataImage,
  ICompanySignaturePlaceholders,
  ISignatureDbField,
  ISignaturePlaceholderImg,
  ISignaturePlaceholderURL
} from 'src/app/model/interfaces/signature.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilService } from 'src/app/services/util/util.service';
import { PatternService } from 'src/app/shared/services/pattern.service';
import { environment } from 'src/environments/environment';
import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-edit-user-company-data',
  templateUrl: './edit-user-company-data.component.html',
  styleUrls: ['./edit-user-company-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmptyStateComponent,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule
  ]
})
export class EditUserCompanyDataModalComponent {
  isCognismSignatures = environment.isCogSig;

  constructor(
    private alert: AlertService,
    private formBuilder: NonNullableFormBuilder,
    private mainModalService: ModalService,
    private utilService: UtilService,
    public datasetService: DatasetService,
    public modal: NgbActiveModal,
    public utils: PatternService
  ) {}

  @Input() set dataset(dataset: IDataset) {
    if (dataset.data) {
      this._datasetInfo$.next(dataset);

      const datasetData = JSON.parse(dataset.data) as ICompanySignaturePlaceholders;
      this.companyForm.patchValue({
        ...datasetData,
        c_blog: this.utilService.removeHttpHttpsWww(datasetData.u_blog?.url),
        c_city: datasetData.u_ort,
        c_country: datasetData.u_country,
        c_disclaimer: datasetData.u_disclaimer,
        c_fax: datasetData.u_fax,
        c_fb: this.utilService.removeHttpHttpsWww(datasetData.u_fb?.url),
        c_googlep: this.utilService.removeHttpHttpsWww(datasetData.u_googlep?.url),
        c_instagram: this.utilService.removeHttpHttpsWww(datasetData.u_instagram?.url),
        c_linkedin: this.utilService.removeHttpHttpsWww(datasetData.u_linkedin?.url),
        c_logo: datasetData.u_logo,
        c_name: datasetData.u_name,
        c_phone: datasetData.u_tel,
        c_pinterest: this.utilService.removeHttpHttpsWww(datasetData.u_pinterest?.url),
        c_postal_code: datasetData.c_zip_code,
        c_snapc: this.utilService.removeHttpHttpsWww(datasetData.u_snapc?.url),
        c_street: datasetData.u_strasse,
        c_twitter: this.utilService.removeHttpHttpsWww(datasetData.u_twitter?.url),
        c_website_2: datasetData.c_website2?.url,
        c_website: datasetData.u_website?.url,
        c_whatsapp: this.utilService.removeHttpHttpsWww(datasetData.u_whatsapp?.url),
        c_xing: this.utilService.removeHttpHttpsWww(datasetData.u_xing?.url),
        c_youtube: this.utilService.removeHttpHttpsWww(datasetData.u_youtube?.url)
      });
    }
  }

  private _datasetInfo$ = new ReplaySubject<IDataset>(1);
  datasetInfo$ = this._datasetInfo$.asObservable();

  companyForm = this.formBuilder.group({
    c_add_info_1: '',
    c_add_info_2: '',
    c_add_info_3: '',
    c_add_info_4: '',
    c_add_info_5: '',
    c_add_info_6: '',
    c_blog: '',
    c_city: '',
    c_country: '',
    c_disclaimer: '',
    c_fax: '',
    c_fb: '',
    c_googlep: '',
    c_instagram: '',
    c_linkedin: '',
    c_logo: {} as CompanyDataImage,
    c_name: '',
    c_office: '',
    c_phone: '',
    c_pinterest: '',
    c_postal_code: '',
    c_snapc: '',
    c_state: '',
    c_street: '',
    c_twitter: '',
    c_website_2: '',
    c_website: '',
    c_whatsapp: '',
    c_xing: '',
    c_youtube: ''
  });

  /**
   * Saves the dataset data
   * @param dataset - Current dataset
   */
  saveEmployeeCompanyData(dataset: IDataset): void {
    const dbFields = JSON.parse(dataset.data || '{}') as ISignatureDbField;
    this.datasetService.update(dataset.id, this.processChanges(dbFields), dataset.title || '').subscribe(() => {
      void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
      this.modal.close(true);
    });
  }

  /**
   * Opens the modal to upload/manage the company logo
   *
   * Updates the form value for company logo with the new logo
   */
  openUploadCompanyLogoModal(): void {
    this.mainModalService
      .openUploadCompanyLogoModal(this.companyForm.value.c_logo)
      .subscribe(newLogo => this.companyForm.patchValue({ c_logo: newLogo }));
  }

  /**
   * Parses the dataset data into a format accepted by the backend
   * @param datasetData - Dataset data to parse
   * @returns Dataset data in a backend-ready format
   */
  private processChanges(datasetData: ISignatureDbField): ISignatureDbField {
    return {
      c_add_info_1: this.companyForm.value.c_add_info_1 || '',
      c_add_info_2: this.companyForm.value.c_add_info_2 || '',
      c_add_info_3: this.companyForm.value.c_add_info_3 || '',
      c_add_info_4: this.companyForm.value.c_add_info_4 || '',
      c_add_info_5: this.companyForm.value.c_add_info_5 || '',
      c_add_info_6: this.companyForm.value.c_add_info_6 || '',
      c_office: this.companyForm.value.c_office || '',
      c_state: this.companyForm.value.c_state || '',
      c_website2: {
        ...(datasetData.c_website2 as ISignaturePlaceholderURL),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_website_2) || ''
      } as ISignaturePlaceholderURL,
      c_zip_code: this.companyForm.value.c_postal_code || '',
      u_country: this.companyForm.value.c_country || '',
      u_disclaimer: this.companyForm.value.c_disclaimer || '',
      u_fax: this.companyForm.value.c_fax || '',
      u_fb: {
        ...(datasetData.u_fb as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_fb)
      } as ISignaturePlaceholderImg,
      u_instagram: {
        ...(datasetData.u_instagram as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_instagram)
      } as ISignaturePlaceholderImg,
      u_linkedin: {
        ...(datasetData.u_linkedin as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_linkedin)
      } as ISignaturePlaceholderImg,
      u_logo: {
        ...(datasetData.u_logo as ISignaturePlaceholderImg),
        image: this.utilService.checkAndAddHttps(this.companyForm.value.c_logo?.image),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_logo?.url) ?? '#',
        whichImage: this.companyForm.value.c_logo?.image ? 'own' : this.companyForm.value.c_logo?.whichImage,
        showAs: this.companyForm.value.c_logo?.image ? 'image' : this.companyForm.value.c_logo?.showAs
      } as ISignaturePlaceholderImg,
      u_name: this.companyForm.value.c_name || '',
      u_ort: this.companyForm.value.c_city || '',
      u_strasse: this.companyForm.value.c_street || '',
      u_tel: this.companyForm.value.c_phone || '',
      u_twitter: {
        ...(datasetData.u_twitter as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_twitter)
      } as ISignaturePlaceholderImg,
      u_website: {
        ...(datasetData.u_website as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_website)
      } as ISignaturePlaceholderImg,
      u_xing: {
        ...(datasetData.u_xing as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_xing)
      } as ISignaturePlaceholderImg,
      u_youtube: {
        ...(datasetData.u_youtube as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_youtube)
      } as ISignaturePlaceholderImg,
      u_pinterest: {
        ...(datasetData.u_pinterest as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_pinterest)
      } as ISignaturePlaceholderImg,
      u_whatsapp: {
        ...(datasetData.u_whatsapp as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_whatsapp)
      } as ISignaturePlaceholderImg,
      u_snapc: {
        ...(datasetData.u_snapc as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_snapc)
      } as ISignaturePlaceholderImg,
      u_googlep: {
        ...(datasetData.u_googlep as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_googlep)
      } as ISignaturePlaceholderImg,
      u_blog: {
        ...(datasetData.u_blog as ISignaturePlaceholderImg),
        url: this.utilService.checkAndAddHttps(this.companyForm.value.c_blog)
      } as ISignaturePlaceholderImg
    };
  }
}
