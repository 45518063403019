<div
  (mouseenter)="showHideSettingsTooltip(true)"
  (mouseleave)="showHideSettingsTooltip(false)"
  class="mt-tooltip-container settings-tooltip-container"
  [ngClass]="{ 'cognism-signatures': isCognismSignatures }"
>
  <!-- Settings tooltip options -->
  <ul class="sidebar-submenu">
    <li (click)="toggleSubmenuSidebar('settings')" class="settings-menu-item">
      <span transloco="preferences"></span>
    </li>
    <li class="mt-tooltip-menu-divider" *ngIf="!isCognismSignatures">
      <div role="separator"></div>
    </li>
    <li
      *ngIf="!isCognismSignatures"
      class="languages-menu settings-menu-item"
      (mouseenter)="showHideLanguageSubtooltip()"
      (mouseleave)="showHideLanguageSubtooltip()"
    >
      <div><span transloco="language"></span></div>
      <mt-svg
        class="mt-tooltip-menu-icon mt-tooltip-menu-icon_arrow sidebar-languages-right-triangle"
        src="assets/images/sidebar/right-triangle.svg"
      >
      </mt-svg>
      <div
        class="mt-tooltip-container mt-subtooltip mt-choose-language-tooltip"
        *ngIf="tooltips.languageTooltipOpened"
      >
        <div class="settings-tooltip-arrow language-arrow"></div>

        <!-- Settings language dropdown -->
        <ul
          *ngFor="let language of languages; let i = index"
          class="language-list"
        >
          <li
            class="languages-menu settings-menu-item"
            (click)="languageService.changeLanguage(language.key)"
          >
            <div>
              <span>{{ language.fullname }}</span>
            </div>
            <mt-svg
              class="mt-tooltip-menu-icon h12 language"
              *ngIf="translateService.getActiveLang() === language.key"
              src="assets/images/buttons/tick_btn_svg.svg"
            >
            </mt-svg>
          </li>
          <li *ngIf="i + 1 < languages.length" class="mt-tooltip-menu-divider">
            <div role="separator"></div>
          </li>
        </ul>
      </div>
    </li>
    <li class="mt-tooltip-menu-divider" *ngIf="!isCognismSignatures">
      <div role="separator"></div>
    </li>

    <!-- List of middle options in settings tooltip including help center, resources and imprint -->
    <ng-container *ngFor="let middleMenus of sidebarMenus.settings_middle">
      <li
        *ngIf="
          !(
            isCognismSignatures &&
            (middleMenus.name === 'resources' || middleMenus.name === 'imprint')
          )
        "
      >
        <a
          *ngIf="middleMenus.name === 'nav_help_center'"
          href="{{ middleMenus.url! | transloco }}"
          target="_blank"
          class="settings-menu-item"
        >
          <span transloco="{{ middleMenus.name }}"></span>
        </a>
        <a
          *ngIf="middleMenus.name !== 'nav_help_center'"
          href="{{ middleMenus.url! | transloco }}"
          class="settings-menu-item"
        >
          <span transloco="{{ middleMenus.name }}"></span>
        </a>
      </li>
    </ng-container>
    <li class="mt-tooltip-menu-divider"><div role="separator"></div></li>
    <li (click)="logout()" class="settings-menu-item">
      <span transloco="logout"></span>
    </li>
    <div class="arrow settings-tooltip-arrow"></div>
  </ul>
</div>
