<div modal-body class="congratulationsaftercheckout">
  <div class="headline"><normal transloco="Congratulations"></normal>!</div>
  <div
    class="subline"
    [innerHtml]="
      'param.youHaveBookedProductSucessfully' | transloco: { product: product }
    "
  ></div>

  <div class="flex-center-all imagearea">
    <img src="assets/images/multisignature/giphy_glitter.gif" />
  </div>

  <div
    class="description"
    [innerHTML]="'html.knowHowToThisTopicInHelpCenter' | transloco"
  ></div>

  <!-- Enable/Disabled buttons on getting chargify data -->
  <div *ngIf="updateSubscriptionData$ | async; else loadingData">
    <div
      *ngIf="isPaymentMethodInvoice"
      class="text-center text-danger martop20 trial-over-invoice"
    >
      <span transloco="trial_over_invoice"></span>
      <span
        transloco="subscriptionbills"
        (click)="modal.close(false)"
        class="cursor-pointer"
      >
      </span>
    </div>

    <div class="flex-center-all">
      <mt-button
        (click)="modal.close(true)"
        color="primary"
        text="Continue"
      ></mt-button>
    </div>
  </div>
</div>

<ng-template #loadingData>
  <div
    *ngIf="isPaymentMethodInvoice"
    class="text-center text-danger martop20 trial-over-invoice"
  >
    <span transloco="trial_over_invoice"></span>
    <span
      transloco="subscriptionbills"
      class="cursor-pointer"
      [class.disabled]="true"
    >
    </span>
  </div>

  <div class="flex-center-all">
    <mt-button [disabled]="true" color="primary" text="Continue"></mt-button>
  </div>
</ng-template>
