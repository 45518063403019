import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { catchError, tap } from 'rxjs';

import { AlertService } from 'src/app/services/alert/alert.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { ISignatureGetAll } from 'src/app/services/signature/signature-service.interface';
import { SignatureService } from 'src/app/services/signature/signature.service';

@Component({
  selector: 'mt-add-signature-to-group',
  templateUrl: './add-signature-to-group.component.html',
  styleUrls: ['./add-signature-to-group.component.scss'],
  providers: [ListHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSignatureToGroupComponent {
  filterTextSig = '';

  selectedSignature?: string;

  loadSignatures$ = this.signatureService.getAll().pipe(
    tap(signatures => {
      this.list.all = signatures;

      const selected = this.list.all.find(signature => signature.id === this.selectedSignature);
      if (selected) {
        this.list.selected.push(selected);
      }

      this.list.selectionType = 'single';
      this.list.setFiltered();
    }),
    catchError((err: HttpErrorResponse) => {
      void this.alert.defaultErrorMessage(err.message);
      throw err;
    })
  );

  constructor(
    private alert: AlertService,
    private signatureService: SignatureService,
    public list: ListHelperService<ISignatureGetAll>,
    public modal: NgbActiveModal
  ) {}

  /**
   * Closes the modal and assigns the selected signature
   */
  assignSignature(): void {
    this.modal.close(this.list.selected[0]);
  }
}
