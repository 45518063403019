import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { SIDEBAR_MENU } from '@modules/root/model/navigation-sidebar.const';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '@services/account/account-service.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'mt-navigation-sidebar-marketing-submenu',
  templateUrl: './navigation-sidebar-marketing-submenu.component.html',
  styleUrls: ['./navigation-sidebar-marketing-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarMarketingSubmenuComponent {
  /**
   * Environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Get authorization scope to identify the plan information
   */
  @Input() authorizationScope!: IMAILTASTIC_AUTHORIZATION_SCOPE;

  /**
   * Determines the count of campaign
   * @defaultValue 0
   */
  @Input() campaignCount = 0;

  /**
   * Determines the count of event
   * @defaultValue 0
   */
  @Input() eventCount = 0;

  /**
   * Determines the count of target group
   * @defaultValue 0
   */
  @Input() targetGroupCount = 0;

  /**
   * Determines the count of notifications
   * @defaultValue 0
   */
  @Input() notificationCount = 0;

  /**
   * Marketing sidebar menus
   */
  menus: NavigationSidebarMenu[] = cloneDeep(SIDEBAR_MENU.marketing);

  constructor(public navigationSidebarService: NavigationSidebarService) {}

  /**
   * Get counts of submenu options
   * @param menuName - The submenu name
   * @returns The count
   */
  getCount(menuName: string): number {
    let count = 0;
    switch (menuName) {
      case 'CAMPAIGNS': {
        count = this.campaignCount;
        break;
      }
      case 'events': {
        count = this.eventCount;
        break;
      }
      case 'notifications': {
        count = this.notificationCount;
        break;
      }
      case 'target_group_s': {
        count = this.targetGroupCount;
        break;
      }
    }
    return count;
  }
}
