import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NonNullableFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs';

import { noWhiteSpaceValidator } from 'src/app/shared/validators/no-whitespace-validator.directive';
import { urlValidator } from 'src/app/shared/validators/url-validator.directive';

// services
import { AuthService } from 'src/app/services/auth/auth.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';

import { alreadyExistValidator } from 'src/app/shared/validators/already-exist-validator.directive';
import { DOMAIN_INPUT_FIELDS } from 'src/app/model/const/domain.const';
import { DomainInputFields } from 'src/app/model/interfaces/recipient.interface';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { EmptyStateComponent } from '@organisms//empty-states/empty-state.component';

@Component({
  selector: 'mt-manually-add-domains-modal',
  templateUrl: './manually-add-domains-modal.component.html',
  styleUrls: ['./manually-add-domains-modal.component.scss'],
  providers: [QueryHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmptyStateComponent,
    FormsModule,
    MtSvgComponent,
    NgbTooltip,
    NgClass,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule
  ]
})
export class ManuallyAddDomainsModalComponent {
  constructor(
    private fb: NonNullableFormBuilder,
    private targetGroupService: TargetGroupService,
    public authService: AuthService,
    public modal: NgbActiveModal,
    public translate: TranslocoService
  ) {}

  /**
   * Provided when used in wizard, since those accounts should only be created if the wizard is completed
   *
   * @defaultValue []
   */
  @Input() currentAccounts: string[] = [];

  /**
   * Form data
   */
  domainDataForm = this.fb.group({
    company: '',
    // eslint-disable-next-line @typescript-eslint/unbound-method
    content: ['', [Validators.required, urlValidator, noWhiteSpaceValidator]],
    custom1: '',
    custom2: '',
    custom3: '',
    location: '',
    type: ''
  });

  //TODO: REFACTOR ME TO OBSERVABLES
  //#region REFACTOR ME
  /**
   * Holds the domain input fields
   */
  accountData = DOMAIN_INPUT_FIELDS;

  /**
   * Hold the list of created domain list
   * @defaultValue []
   */
  manuallyAddedDomains: DomainInputFields[] = [];

  /**
   * Selected domain for the editing
   */
  selectedDomain?: DomainInputFields;

  /**
   * Accept tracking terms checkbox value for Opt In
   * @defaultValue false
   */
  acceptedTrackingTerms = false;
  //#endregion REFACTOR ME

  /**
   * Load all target group entries and prospects
   */
  targetGroupEntriesAndProspectsList$ = this.targetGroupService.getTargetGroupEntries().pipe(
    map(targetGroupEntries => targetGroupEntries.map(entry => entry.content).concat(this.currentAccounts)),
    tap(targetGroupEntries => {
      this.domainDataForm.controls.content.addValidators([alreadyExistValidator(targetGroupEntries)]);
      this.domainDataForm.controls.content.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    })
  );

  /**
   * Set selected domain values to input fields
   * @param domain - Object of recently created domain input
   */
  onCreatedDomainClick(domain: DomainInputFields): void {
    this.resetForm();
    this.selectedDomain = domain;
    this.domainDataForm.controls.content.disable();

    this.domainDataForm.patchValue({ ...domain });
  }

  /**
   * Remove recently created domain from the list
   * @param domain - Object of recently created domain input
   */
  clearDomain(domain: DomainInputFields): void {
    if (this.selectedDomain == domain) this.resetForm();

    const index = this.manuallyAddedDomains.findIndex(info => info.content === domain.content);
    this.manuallyAddedDomains.splice(index, 1);
  }

  /**
   * Save/Update recently created domain list data
   * @param adminId - Authorization scope of adminId
   * @param accountId - Authorization scope of accountId
   */
  onAddCreatedDomains(adminId: string, accountId: string): void {
    const targetGroupEntries = this.manuallyAddedDomains.map(targetGroupEntry => ({
      ...targetGroupEntry,
      isOptedOut: false,
      isProspect: false,
      owner: accountId,
      termsAcceptedBy: adminId,
      type: 'domain'
    }));

    this.modal.close(targetGroupEntries);
  }

  /**
   * Reset entire form values
   */
  resetForm(): void {
    if (this.selectedDomain) delete this.selectedDomain;
    this.domainDataForm.reset();
    this.domainDataForm.controls.content.enable();
  }

  /**
   * Check already inserted and if not then set in domain list
   */
  onSubmit(): void {
    if (this.domainDataForm.invalid) return;

    const newData = this.domainDataForm.getRawValue() as DomainInputFields;

    // Add domain or update selected domain
    const index = this.manuallyAddedDomains.findIndex(info => info.content === newData.content);
    if (index !== -1) this.manuallyAddedDomains[index] = newData;
    else this.manuallyAddedDomains.push(newData);

    // Reset form
    this.resetForm();
  }
}
