<mt-custom-modal
  headline="{{ 'edit_image' | transloco }}"
  [closeBtn]="false"
  *ngIf="photoAndStatus$ | async as companyLogo"
>
  <div modal-body>
    <div class="container-fluid text-center">
      <div class="row mt-5 justify-content-center">
        <div class="col-5">
          <div class="image-displayed">
            <ng-container
              *ngIf="
                companyLogo.currentPhoto.whichImage === 'default';
                else showPhoto
              "
            >
              <div class="no-photo-preview">
                <mt-svg
                  src="assets/images/svgs/image.svg"
                  size="150"
                  filter="--filter-white"
                ></mt-svg>
              </div>
            </ng-container>
            <ng-template #showPhoto>
              <img
                class="uploaded_photo"
                [src]="companyLogo.currentPhoto.image"
                alt="uploaded_photo"
                [ngStyle]="{
                  'border-radius':
                    companyLogo.currentPhoto.cropType === 'circle' &&
                    companyLogo.currentPhoto.isCropped
                      ? '50%'
                      : '0%'
                }"
            /></ng-template>
          </div>
        </div>
      </div>

      <div class="row mt-3 justify-content-center">
        <div class="col">
          <mt-upload-image-button
            (loadImage)="processImage($event)"
            [max-width]="3000"
            [max-size-mb]="2"
            [buttonTitle]="
              companyLogo.currentPhoto.whichImage === 'own'
                ? 'change_photo_btn_2'
                : 'uploade_photo_btn_2'
            "
            style="display: inline-flex"
          ></mt-upload-image-button>
          <mt-icon-button
            *ngIf="companyLogo.currentPhoto.whichImage === 'own'"
            class="mt_left ms-2"
            text="remove_photo_btn_2"
            color="white"
            style="display: inline-flex"
            (click)="deleteLogo()"
          ></mt-icon-button>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-8">
          <p class="font-weight-bold" transloco="advanced_settings"></p>

          <div class="form-floating mt-3">
            <input
              type="text"
              class="form-control"
              id="input_link_photo_url"
              placeholder="{{ 'link_photo_url_example' | transloco }}"
              [formControl]="photoUrl"
            />
            <label
              for="input_link_photo_url"
              class="form-label fw-normal"
              transloco="link_photo_url"
            ></label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container modal-footer>
    <div class="d-flex flex-row-reverse">
      <div class="d-flex">
        <mt-button
          color="default"
          text="abort"
          class="leftbutton"
          (click)="modal.close(companyLogo.initialPhoto)"
        ></mt-button>
        <mt-button
          color="primary"
          text="Save"
          (click)="saveLogo(companyLogo.currentPhoto, companyLogo.isNewPhoto)"
        ></mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
