import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SIDEBAR_MENU } from 'src/app/modules/root/model/navigation-sidebar.const';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, map } from 'rxjs';

// Interfaces
import { IntegrationTypes } from '@model/interfaces/integrations.interface';
import { NavigationSidebarMenu } from 'src/app/modules/root/model/navigation-sidebar-menu.interface';

// Services
import { NavigationSidebarService } from 'src/app/services/navigation-sidebar/navigation-sidebar.service';

@Component({
  selector: 'mt-navigation-sidebar-employee-submenu',
  templateUrl: './navigation-sidebar-employee-submenu.component.html',
  styleUrls: ['./navigation-sidebar-employee-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarEmployeeSubmenuComponent {
  /**
   * Environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Display `Not yet imported` submenu or not
   */
  set showNotYetImported(val: boolean) {
    this._showNotYetImported$.next(val);
  }
  private _showNotYetImported$ = new BehaviorSubject<boolean>(false);
  showNotYetImported$ = this._showNotYetImported$.asObservable().pipe(
    map(val => ({
      val
    }))
  );

  /**
   * Determines the count of all employees
   * @defaultValue 0
   */
  @Input() allEmployeeCount = 0;

  /**
   * Determines the count of outdated employees
   * @defaultValue 0
   */
  @Input() outdatedEmployeeCount = 0;

  /**
   * Determines the counte of group
   * @defaultValue 0
   */
  @Input() groupCount = 0;

  /**
   * Determines to available office365 or gSuite connection
   */
  @Input() set myIntegrations(val: IntegrationTypes[]) {
    const gSuiteIndex = val.find(info => info.title === 'Google_headline');
    const office365Index = val.find(info => info.title === 'Microsoft_headline');
    if (gSuiteIndex || office365Index) {
      this.showNotYetImported = true;
    }
  }

  /**
   * Employees sidebar menus
   */
  menus: NavigationSidebarMenu[] = cloneDeep(SIDEBAR_MENU.employees);

  constructor(public navigationSidebarService: NavigationSidebarService) {}
}
