export const EMPLOYEE_DUMMY_DATA = {
  ma_abteilung: 'Department',
  ma_email: 'john@doe.com',
  u_fax: '06685 558 665 55',
  u_fb: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  },
  u_name: 'Company XYZ',
  ma_googlep: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  },
  u_instagram: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  },
  u_linkedin: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  },
  ma_mobil: '0176 4588 6665',
  ma_nachname: 'Doe',
  u_ort: 'Anytown',
  ma_position: 'CEO',
  c_zip_code: '12345',
  ma_skype: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  },
  u_strasse: '123 Main Street',
  ma_tel: '06685 558 665',
  u_tel: '06685 558 667',
  u_twitter: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  },
  ma_vorname: 'John',
  u_website: {
    linkText: 'www.johndoe.com',
    url: 'http://www.johndoe.com'
  },
  c_website2: {
    linkText: 'www.johndoe.com',
    url: 'http://www.johndoe.com'
  },
  u_xing: {
    showAs: 'image',
    altText: 'te',
    linkText: 'text',
    url: 'fsfdsfdd',
    whichImage: 'default',
    linkcolor: '',
    underline: 'true'
  }
};
