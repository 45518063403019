<ng-container *ngIf="loadCount$ | async"></ng-container>
<ng-container *ngIf="getOutDatedCountByFilterData$ | async"></ng-container>
<ng-container *ngIf="loadInfo$ | async as loadInfo">
  <!-- Top info banner -->
  <mt-top-info-banner
    *ngIf="!isCognismSignatures; else cogsigTopInfoBanner"
    [hasSubscription]="loadInfo.hasSubscription"
    [hasTestTime]="loadInfo.hasTestTime"
    [trialDaysLeft]="loadInfo.trialDaysLeft"
    [forceAllow]="loadInfo.forceAllow"
    [isUserFromAngularTs]="loadInfo.isUserFromAngularTs"
    [accountData]="loadInfo.accountData.accountData"
    [adminAccounts]="loadInfo.accountData.adminAccounts"
    [allCustomers]="loadInfo.allCustomers"
    [recentCustomersEmail]="loadInfo.recentCustomersEmail"
    [superAdminAccountData]="loadInfo.superAdminAccountData"
    (searchValue)="topInfoBannerService.refreshSearchValue($event)"
    (customerLogin)="
      topInfoBannerService.customerLogin($event, loadInfo.recentCustomersEmail)
    "
    (changeAccount)="topInfoBannerService.changeAccount($event)"
    (logout)="topInfoBannerService.doLogout()"
  >
  </mt-top-info-banner>

  <ng-template #cogsigTopInfoBanner>
    <mt-cogsig-top-info-banner
      [isGuideExist]="loadInfo.isGuideExist"
      [isGuidePage]="loadInfo.isGuidePage.isGuidePage"
    >
    </mt-cogsig-top-info-banner>
  </ng-template>

  <div
    class="page-container"
    [ngClass]="{
      'cogsig-bg': isCognismSignatures,
      'padtop0 no-green-topbar':
        isCognismSignatures &&
        (!loadInfo.isGuideExist || loadInfo.isGuidePage.isGuidePage),
      padtop50: (loadInfo | topAreaShown : isCognismSignatures),
      'topview trialareashown':
        (isCognismSignatures && router.url !== '/getstartedguide') ||
        (loadInfo | topAreaShown : isCognismSignatures)
    }"
  >
    <nav
      *ngIf="
        loadInfo.hasSubscription || loadInfo.hasTestTime || loadInfo.forceAllow
      "
      class="page-sidebar"
      [ngClass]="{
        'page-sidebar_floating':
          !loadInfo.submenuLocked || !loadInfo.submenuOpened
      }"
      (mouseleave)="clickOutside(loadInfo.submenuLocked, $event)"
    >
      <div class="sidebar-fixed">
        <!-- Sidebar header with logo and router link -->
        <mt-navigation-sidebar-menu-header
          [className]="loadInfo | headerAdjustment : isCognismSignatures"
          (closeSubmenuSidebar)="closeSubmenuSidebar(loadInfo.submenuLocked)"
        ></mt-navigation-sidebar-menu-header>

        <div class="flex sidebarcolumnlayout">
          <!--Sidebar main menus section -->
          <mt-navigation-sidebar-main-menus
            [topMenus]="sidebarMenus.topMenus"
            [bottomMenus]="sidebarMenus.bottomMenus"
            [isUserOnBasicPlan]="loadInfo.authorizationScope.isUserOnBasicPlan"
            [activeRoute]="activeRoute"
            [tooltips]="tooltips"
            [submenuSection]="submenuSection"
            [submenuOpened]="loadInfo.submenuOpened"
            [routeString]="route.snapshot.firstChild?.routeConfig?.path || ''"
            (showTooltip)="showTooltip($event)"
            (hideTooltip)="hideTooltip($event)"
            (toggleSubmenuSidebar)="
              toggleSubmenuSidebar(
                $event,
                loadInfo.submenuLocked,
                loadInfo.submenuOpened
              )
            "
            (navigateToDefaultPageOfSection)="
              navigateToDefaultPageOfSection($event)
            "
            (showSettingsTooltip)="
              showSettingsTooltip(
                $event,
                loadInfo.submenuLocked,
                loadInfo.submenuOpened
              )
            "
            (hideSubmenu)="hideSubmenu()"
            (showHideLanguageSubtooltip)="showHideLanguageSubtooltip()"
            (showHideSettingsTooltip)="showHideSettingsTooltip($event)"
            (hideSettingsTooltip)="hideSettingsTooltip($event)"
          ></mt-navigation-sidebar-main-menus>
        </div>
      </div>

      <!-- Sidebar submenu section -->
      <div
        class="mt-submenu-sidebar-wrapper padtop10"
        [ngClass]="{
          'mt-submenu-sidebar-wrapper_floating': !loadInfo.submenuLocked,
          'cognism-signatures': isCognismSignatures,
          martop0imp:
            isCognismSignatures &&
            (!loadInfo.isGuideExist || loadInfo.isGuidePage.isGuidePage),
          'mt-submenu-sidebar-wrapper-trial-area-shown':
            isCognismSignatures ||
            (loadInfo | topAreaShown : isCognismSignatures)
        }"
        *ngIf="loadInfo.submenuOpened"
      >
        <ng-container>
          <div *ngFor="let submenus of sidebarMenus.submenus">
            <div
              (clickOutside)="clickOutside(loadInfo.submenuLocked)"
              class="mt-submenu-sidebar-inner-wrapper sidebar-submenus"
              *ngIf="
                isSubmenuSectionActive(submenus.name, loadInfo.submenuOpened)
              "
            >
              <mt-navigation-sidebar-submenu-header
                [submenuTitle]="submenus.title || ''"
                [submenuLocked]="loadInfo.submenuLocked"
                (toggleSubmenuLock)="toggleSubmenuLock(loadInfo.submenuLocked)"
              ></mt-navigation-sidebar-submenu-header>

              <ng-container
                *ngIf="getMyIntegrations$ | async as myIntegrations"
              >
                <ng-container [ngSwitch]="submenus.name">
                  <!-- Employee menu options -->
                  <mt-navigation-sidebar-employee-submenu
                    *ngSwitchCase="'employees'"
                    [allEmployeeCount]="submenuCounters.employees"
                    [myIntegrations]="myIntegrations"
                    [outdatedEmployeeCount]="submenuCounters.employeesOutdated"
                    [groupCount]="submenuCounters.departments"
                  ></mt-navigation-sidebar-employee-submenu>

                  <!-- Signature menu options -->
                  <mt-navigation-sidebar-signature-submenu
                    *ngSwitchCase="'signatures'"
                    [signatureCount]="submenuCounters.signatures"
                  ></mt-navigation-sidebar-signature-submenu>

                  <!-- Marketing menu options -->
                  <mt-navigation-sidebar-marketing-submenu
                    *ngSwitchCase="'campaigns'"
                    [campaignCount]="submenuCounters.campaigns"
                    [eventCount]="submenuCounters.events"
                    [targetGroupCount]="submenuCounters.targetGroups"
                    [notificationCount]="submenuCounters.notifications"
                    [authorizationScope]="loadInfo.authorizationScope"
                  ></mt-navigation-sidebar-marketing-submenu>

                  <!-- Integration menu options -->
                  <mt-navigation-sidebar-integration-submenu
                    *ngSwitchCase="'integrations'"
                    [activeIntegrationCount]="myIntegrations.length"
                  ></mt-navigation-sidebar-integration-submenu>

                  <!-- Sidebar settings menu options -->
                  <mt-navigation-sidebar-settings-submenu
                    *ngSwitchCase="'settings'"
                    [authorizationScope]="loadInfo.authorizationScope"
                    [hasSubscription]="loadInfo.hasSubscription"
                  ></mt-navigation-sidebar-settings-submenu>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </nav>

    <!-- Router Outlet -->
    <router-outlet class="body"></router-outlet>

    <!-- Intercom -->
    <div
      class="adblock-exclamation-mark"
      *ngIf="(isIntercomExist$ | async) === false"
    >
      <img
        src="assets/images/adblock/notification_icon.png"
        (click)="adblockService.openAdblockContentModal()"
      />
    </div>
  </div>
</ng-container>
