import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
import { GetStartedGuideService } from 'src/app/services/get-started-guide/get-started-guide.service';

import { WizardTabLastComponent } from '@organisms/wizard/wizard-tab-last/wizard-tab-last.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgClass, NgStyle } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { TextTruncatePipe } from '@shared/pipes/text-truncate/text-truncate.pipe';

@Component({
  selector: 'mt-wizard-tab',
  templateUrl: './wizard-tab.component.html',
  styleUrls: ['./wizard-tab.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MtSvgComponent,
    NgbTooltip,
    NgClass,
    NgIf,
    NgStyle,
    TextTruncatePipe,
    TranslocoModule,
    WizardTabLastComponent
  ]
})
export class WizardTabComponent implements OnInit, DoCheck, OnDestroy {
  _headerHovered = false;
  _parent = {
    classContent: '',
    completed: false,
    completedBtnText: this.translateService.translate('ready') as string,
    isStepDisplay: true,
    notifier: new Subject<void>(),
    formInputValid: true,
    open: false,
    optional: false,
    showRemovedBtn: false,
    subtitle: '',
    subtitleStrong: '',
    tabThemeClass: 'detailpanel_blue',
    tabTitle: ''
  } as IWizardTabComponent;

  @Input() invalidTabs = [] as IWizardTabComponent[];
  @Input()
  get parent(): IWizardTabComponent {
    return this._parent;
  }
  set parent(data: IWizardTabComponent) {
    this._parent = Object.assign(this._parent, data);
    this._parent.tabThemeClass += data?.isFirstTab ? ' no-margin-top' : ' margin-top-10';
    if (!this._parent.bringIntoView) {
      this.elRef.nativeElement.style.scrollMarginTop = '64px';
      Object.defineProperty(this._parent, 'bringIntoView', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: () => this.elRef.nativeElement.scrollIntoView?.({ block: 'start', inline: 'nearest' })
      });
    }
    this._parent.notifier?.next();
    this._parent.completedBtnText = this.translateService.translate(data.completedBtnText || 'ready') as string;
  }

  @Output() parentChange: EventEmitter<IWizardTabComponent> = new EventEmitter<IWizardTabComponent>();
  @Output() completed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() headerClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() removed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() editInvalid: EventEmitter<IWizardTabComponent> = new EventEmitter<IWizardTabComponent>();

  stepNoSrc = '';
  tabSubtitle = '';
  tabTitle = '';

  notificationSubscription!: Subscription;
  guideCompletedStep!: Subscription;

  constructor(
    private guideService: GetStartedGuideService,
    private translateService: TranslocoService,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.parent.subtitle) {
      this.tabSubtitle = this.parent.subtitle;
    } else if (!this.parent.isLastStep && this.parent.optional) {
      this.tabSubtitle = this.translateService.translate('optional') as string;
    }

    if (this.parent.tabTitle) {
      this.tabTitle = this.parent.tabTitle;
    }

    if (this.parent.isLastStep && !this.parent.tabTitle) {
      this.tabTitle = this.translateService.translate('check_and_finish') as string;
      if (this.allTabsFinished()) {
        this.tabSubtitle = this.translateService.translate('completed') as string;
        this.stepNoSrc = `assets/images/tabs/tick.svg`;
      } else {
        this.stepNoSrc = `assets/images/tabs/${this.parent.stepNo || 1}.svg`;
      }
    } else {
      if (this.parent.formInputValid) {
        this.stepNoSrc = `assets/images/tabs/${this.parent.completed ? 'tick' : this.parent.stepNo || 1}.svg`;
      } else {
        this.stepNoSrc = `assets/images/modals/important_modal_svg.svg`;
      }
    }

    if (this._parent.notifier) {
      this.notificationSubscription = this._parent.notifier.subscribe({ next: () => this.updateStates() });
    }
    // if (this.guideCompletedStep) {
    this.guideCompletedStep = this.guideService.getAsCompleted().subscribe({
      next: (data: string) => {
        if (this.parent.tabTitle === data) {
          setTimeout(() => {
            this.stepNoSrc = `assets/images/tabs/tick.svg`;
          }, 0);
        }
      }
    });
    // }
  }

  ngOnDestroy(): void {
    // Avoid memory leaks
    this.notificationSubscription.unsubscribe();
    this.guideCompletedStep.unsubscribe();
  }

  ngDoCheck(): void {
    this.parentChange.next(this.parent);
  }

  onCompletedBtnClick(ev: MouseEvent): void {
    this.parent.open = false;
    this.completed.emit(ev);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHeaderClickHandler(ev: MouseEvent | any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (ev.target.type !== 'submit') {
      this.parent.open = true;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.headerClick.emit(ev);
    this.updateStates();
  }

  onInvalidTabClick(tab: IWizardTabComponent): void {
    this.editInvalid.emit(tab);
  }

  onRemovedBtnClick(ev: MouseEvent): void {
    this.removed.emit(ev);
  }

  /**
   * Update state in wizard tab, sets tabSubtitle and change icon on wizard tab if is completed
   */
  updateStates(): void {
    if (this.parent.subtitle !== undefined) {
      this.tabSubtitle = this.parent.subtitle;
    } else if (!this.parent.isLastStep && this.parent.optional) {
      this.tabSubtitle = this.translateService.translate('optional') as string;
    }

    if (this.parent.isLastStep) {
      if (this.allTabsFinished()) {
        this.tabSubtitle = this.translateService.translate('completed') as string;
        this.stepNoSrc = `assets/images/tabs/tick.svg`;
        this.parent.completed = true;
      } else {
        this.stepNoSrc = `assets/images/tabs/${this.parent.stepNo || 1}.svg`;
        this.parent.completed = false;
      }
    } else if (this.parent.formInputValid) {
      this.stepNoSrc = `assets/images/tabs/${this.parent.completed ? 'tick' : this.parent.stepNo || 1}.svg`;
    } else {
      this.stepNoSrc = `assets/images/tabs/important.svg`;
    }
    this.cdRef.markForCheck();
  }

  allTabsFinished(): boolean {
    if (this.invalidTabs.length === 0) {
      return false;
    }

    for (const tab of this.invalidTabs) {
      if (!tab.completed) {
        return false;
      }
    }

    return true;
  }
}
