<mt-custom-modal class="reset-password-modal">
  <div modal-header>
    <div class="flex-center-space-between">
      <h4 class="modal-title">{{ 'forgot_password' | transloco }}</h4>
    </div>
  </div>

  <div modal-body>
    <div class="form-group">
      <label for="inputEmail" transloco="Emailaddress"></label>
      <input
        id="inputEmail"
        type="email"
        class="form-control"
        placeholder="{{ 'Emailaddress' | transloco }}"
        [formControl]="emailAddress"
        required
      />

      <div *ngIf="emailAddress.touched || emailAddress.dirty">
        <!-- Validate required email -->
        <div *ngIf="emailAddress.errors?.required" class="martop10 text-danger">
          <span
            >{{ 'E-Mail-Adresse' | transloco }} {{ 'required_field' | transloco
            }}</span
          >
        </div>

        <!-- Validate correct email format -->
        <div *ngIf="emailAddress.errors?.email" class="martop10 text-danger">
          <span transloco="Pleasevalidemailaddress"></span>
        </div>
      </div>
    </div>
  </div>

  <div modal-footer>
    <div>
      <div class="flex-center-space-between">
        <div class="flex"></div>

        <div class="flex">
          <mt-button
            ngbAutofocus
            text="abort"
            color="default"
            class="mt_left"
            (click)="modal.dismiss()"
          ></mt-button>
          <ng-container
            *ngIf="resetNowBtnDisabled$ | async as resetNowBtnDisabled"
          >
            <mt-button
              [disabled]="resetNowBtnDisabled.isDisabled"
              text="Resetnow"
              class="roboto_light"
              color="primary"
              type="submit"
              ngbAutofocus
              (click)="!resetNowBtnDisabled.isDisabled && resetPassword()"
            >
            </mt-button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</mt-custom-modal>
