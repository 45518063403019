<!-- Marketing submenu section -->
<ul
  class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
  [ngClass]="{ 'cognism-signatures': isCognismSignatures }"
>
  <ng-container *ngFor="let menu of menus">
    <ng-container *ngIf="!menu.submenu; else optionWithSubMenu">
      <li
        (click)="navigationSidebarService.closeOtherDropDown(menus, menu.name)"
      >
        <a
          [routerLink]="menu.url"
          routerLinkActive="active"
          class="show"
          [ngClass]="{
            link__inactive:
              (menu.name
              | submenuInactive
                : authorizationScope.isUserOnBasicPlan
                : authorizationScope.isUserOnAdvancedPlan)
          }"
        >
          <ng-container
            [ngTemplateOutlet]="renderSideMenuData"
            [ngTemplateOutletContext]="{ menu }"
          ></ng-container>
        </a>
      </li>
    </ng-container>
    <ng-template #optionWithSubMenu>
      <ng-container *ngIf="menu.name === 'Leads'; else otherMenus">
        <ng-container
          *ngIf="
            navigationSidebarService.isRecipientsLeads$
              | async as recipientsLeads
          "
        >
          <li
            *ngIf="
              recipientsLeads.recordForeignContacts ||
              recipientsLeads.recordForeignDomains
            "
            [ngClass]="{
              link__inactive:
                authorizationScope.isUserOnBasicPlan ||
                authorizationScope.isUserOnAdvancedPlan
            }"
            (click)="
              navigationSidebarService.closeOtherDropDown(menus, menu.name);
              menu.isDropdownOpen = !menu.isDropdownOpen
            "
          >
            <a
              [ngClass]="{
                link__inactive:
                  (menu.name
                  | submenuInactive
                    : authorizationScope.isUserOnBasicPlan
                    : authorizationScope.isUserOnAdvancedPlan)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
          <ng-container *ngIf="menu.isDropdownOpen">
            <ng-container *ngFor="let subMenu of menu.submenu; let i = index">
              <li
                *ngIf="
                  subMenu.name === 'accounts'
                    ? recipientsLeads.recordForeignDomains
                    : recipientsLeads.recordForeignContacts
                "
                [ngClass]="{
                  link__inactive:
                    authorizationScope.isUserOnBasicPlan ||
                    authorizationScope.isUserOnAdvancedPlan
                }"
                class="mt-submenu-title_subtitle-wrapper"
              >
                <a
                  [routerLink]="subMenu.url"
                  routerLinkActive="active"
                  class="show"
                >
                  <div class="mt-submenu-icon marleft20">
                    <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                    </mt-svg>
                  </div>
                  <span
                    class="mt-submenu-title_subtitle marleft5"
                    [transloco]="subMenu.name"
                  ></span>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #otherMenus>
        <li
          [ngClass]="{
            link__inactive:
              authorizationScope.isUserOnBasicPlan ||
              authorizationScope.isUserOnAdvancedPlan
          }"
          (click)="
            navigationSidebarService.closeOtherDropDown(menus, menu.name);
            menu.isDropdownOpen = !menu.isDropdownOpen
          "
        >
          <a
            [ngClass]="{
              link__inactive:
                (menu.name
                | submenuInactive
                  : authorizationScope.isUserOnBasicPlan
                  : authorizationScope.isUserOnAdvancedPlan)
            }"
          >
            <ng-container
              [ngTemplateOutlet]="renderSideMenuData"
              [ngTemplateOutletContext]="{ menu }"
            ></ng-container>
          </a>
        </li>
        <ng-container *ngIf="menu.isDropdownOpen">
          <li
            [ngClass]="{
              link__inactive:
                authorizationScope.isUserOnBasicPlan ||
                authorizationScope.isUserOnAdvancedPlan
            }"
            *ngFor="let subMenu of menu.submenu; let i = index"
            class="mt-submenu-title_subtitle-wrapper"
          >
            <a
              [routerLink]="subMenu.url"
              routerLinkActive="active"
              class="show"
            >
              <div class="mt-submenu-icon marleft20">
                <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                </mt-svg>
              </div>
              <span
                class="mt-submenu-title_subtitle marleft5"
                [transloco]="subMenu.name"
              ></span>
            </a>
          </li>
        </ng-container>
      </ng-template>
    </ng-template>
  </ng-container>

  <!-- Displays sidemenu data -->
  <ng-template let-menu="menu" #renderSideMenuData>
    <div class="mt-submenu-icon">
      <mt-svg
        class="tiny-svg"
        [ngClass]="
          (menu.name
          | submenuInactive
            : authorizationScope.isUserOnBasicPlan
            : authorizationScope.isUserOnAdvancedPlan)
            ? 'mt-grau'
            : 'mt-white'
        "
        src="{{ menu.image }}"
      >
      </mt-svg>
    </div>
    <span class="mt-submenu-title inline-block" [transloco]="menu.name"></span>
    <span *ngIf="menu.hasCounter" class="mt-submenu-counter">
      {{ getCount(menu.name) }}
    </span>
    <mt-svg
      *ngIf="!menu.hasCounter && menu.submenu && menu.submenu.length"
      class="mt-submenu-dropdown-arrow martop4 mt-white"
      src="assets/images/sidebar/down-triangle.svg"
    >
    </mt-svg>
  </ng-template>
</ul>
