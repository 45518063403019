import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mt-welcome-modal',
  templateUrl: './mt-welcome-modal.component.html',
  styleUrls: ['./mt-welcome-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MtWelcomeModalComponent {
  /**
   * Display prompt while refreshing page for confirmation
   * @returns Boolean
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    return false;
  }

  constructor(public modal: NgbActiveModal) {}
}
