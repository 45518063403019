import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem
} from '@ng-bootstrap/ng-bootstrap';

import { catchError, map, switchMap, tap, zip } from 'rxjs';

import { AlertService } from 'src/app/services/alert/alert.service';
import { GroupService } from 'src/app/services/group/group.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';

import { IGroup, IGroupObject, GroupSmall } from 'src/app/model/interfaces/group.interface';
import { SELECT_TYPE } from 'src/app/services/list-helper/list-helper-service.interface';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { MtSearchComponent } from '@shared/components/atoms/inputs/search/search.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { DefaultBoxComponent } from '@shared/components/molecules/boxes/default/default.component';
import { SelectAllButtonComponent } from '@shared/components/molecules/buttons/select-all-button/select-all-button.component';
import { CampaignStatusColComponent } from '@shared/components/organisms/cols/campaign-status-col/campaign-status-col.component';
import { GroupColComponent } from '@shared/components/organisms/cols/group-col/group-col.component';
import { SelectColComponent } from '@shared/components/organisms/cols/select-col/select-col.component';
import { EmptyStateComponent } from '@shared/components/organisms/empty-states/empty-state.component';

/**
 * FIXME for some reason the `this.queryHelperService.getObject().var.page = 2`
 * */
@Component({
  selector: 'mt-assign-group-modal',
  templateUrl: './assign-group-modal.component.html',
  styleUrls: ['./assign-group-modal.component.scss'],
  providers: [ListHelperService, QueryHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CampaignStatusColComponent,
    CustomModalComponent,
    DefaultBoxComponent,
    EmptyStateComponent,
    GroupColComponent,
    InfiniteScrollModule,
    MtSearchComponent,
    MtSvgComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass,
    NgFor,
    NgIf,
    SelectAllButtonComponent,
    SelectColComponent,
    TranslocoModule
  ]
})
export class AssignGroupModalComponent {
  sortDir = false;

  private _assignedGroups: (IGroup | IGroupObject)[] = [];

  /**
   * Groups that have already been assigned will not be displayed in the list
   */
  @Input()
  set assignedGroups(groups: (IGroup | IGroupObject)[]) {
    this._assignedGroups = groups;
  }

  /**
   * List selection mode
   * @defaultValue 'multi'
   */
  @Input() selectionType: SELECT_TYPE = 'multi';

  isLoading = false; // Loading spinner shown when loading more data or filtering

  /**
   * Handles infinite scroll state
   */
  infinitescrolloptions = {
    disable: true,
    scrollAction: (): void => {
      this.infinitescrolloptions.disable = true;

      // Load more list until all are loaded
      if (this.list.all.length < this.list.denominator - this._assignedGroups.length) {
        this.queryHelperService.loadNextPage();
      }
    }
  };

  // Loads data
  loadData$ = this.queryHelperService.queryObject$.pipe(
    tap(() => (this.isLoading = true)),
    switchMap(queryObj => zip(this.groupService.getSmallDetails(queryObj), this.groupService.count(queryObj))),
    map(([groups, count]) => {
      const filteredGroups = groups.filter(group => !this._assignedGroups.some(assigned => assigned.id === group.id));

      return { groups: filteredGroups, count };
    }),
    tap(({ groups, count }) => {
      this.list.all = this.list.all.concat(groups);
      this.list.denominator = count;
      this.list.setFiltered();
      this.list.createFraction(this.list.all.length);
      this.infinitescrolloptions.disable = false;
      this.isLoading = false;
    }),
    catchError((err: HttpErrorResponse) => {
      void this.alert.defaultErrorMessage(err.message);
      throw err;
    })
  );

  // Pipes the result from the search bar
  // and sets the search accordingly
  searchData$ = this.list.filterText$.pipe(
    tap(text => {
      this.list.resetData();

      this.queryHelperService.resetPagination();
      this.queryHelperService.setSearch(text);
      this.queryHelperService.setQueryObservable(this.queryHelperService.getObject());
    })
  );

  constructor(
    private alert: AlertService,
    private groupService: GroupService,
    private queryHelperService: QueryHelperService,
    public list: ListHelperService<GroupSmall>,
    public modal: NgbActiveModal
  ) {}

  /**
   * Closes the modal with the selected groups
   */
  accept(): void {
    this.modal.close(this.list.selected);
  }
}
