<div class="container-fluid">
  <div class="row">
    <div class="col-6 img-testimonial onboardimg-left bg-img">
      <app-signature-preview [signatureHtmlOnly]="true"></app-signature-preview>
    </div>
    <div class="col-6">
      <div class="container mt-0">
        <div class="header onboardright-info">
          <mt-welcome-form
            (welcomeFormValue)="modal.close(true)"
          ></mt-welcome-form>
        </div>
      </div>
    </div>
  </div>
</div>
