import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, map } from 'rxjs';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';

// Interfaces
import {
  ClickCustomActivityType,
  CrmImportTargetGroup,
  CrmLandingPages,
  CrmManualSync,
  HubSpotGetAccount,
  HubSpotGetToken,
  HubspotTargetGroups,
  IntegrationTestConnectionConfig,
  IntegrationTestConnectionResponse,
  MarketoProgram,
  MarketoTargetGroups,
  MarketoWorkspace,
  PardotGetAccount,
  PardotGetToken,
  PardotTargetGroups
} from './crm-service.interface';

// Interfaces
import { GetLeadFields, LeadFields } from './crm-service.interface';
import { ICompanyInfo } from 'src/app/model/interfaces/company-info.interface';
import { IQueryObj } from 'src/app/services/query-helper/query-helper-service.interface';
import { Response, ResponseData } from 'src/app/model/interfaces/response.interface';

// Services
import { AlertService } from 'src/app/services/alert/alert.service';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class CrmService {
  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators,
    private queryHelperService: QueryHelperService
  ) {}

  /**
   * Create custom activity type
   * @param crmProvider - Provider name
   * @returns Observable API response
   */
  createCustomActivityType(crmProvider: string): Observable<ResponseData<ClickCustomActivityType>> {
    return this.http
      .post<ResponseData<ClickCustomActivityType>>(`/crm/${crmProvider}/createCustomActivityType`, {})
      .pipe(map(value => value));
  }

  /**
   * Approve custom activity type
   * @param crmProvider - Provider name
   * @returns Observable API response
   */
  approveCustomActivityType(crmProvider: string): Observable<ClickCustomActivityType> {
    return this.http
      .post<ResponseData<ClickCustomActivityType>>(`/crm/${crmProvider}/approveCustomActivityType`, {})
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets connected providers
   * @returns Connected providers array
   */
  connectedProviders(): Observable<string[]> {
    return this.http.get<ResponseData<string[]>>('/crm/connectedProviders').pipe(this.operator.extractResponseData());
  }

  /**
   * Import target groups from hubspot
   * @param crmProvider - Provider name
   * @param ids - Selected accounts ids
   * @returns Observable of Import accounts status
   */
  importLists(crmProvider: string, ids: number[]): Observable<CrmImportTargetGroup> {
    return this.http
      .post<ResponseData<CrmImportTargetGroup>>(`/crm/importlists`, {
        provider: crmProvider,
        ids: ids
      })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Retrieves the URL that starts login process
   * @param crmProvider - Provider name
   * @returns Observable of redirect url
   */
  getRedirectUri(crmProvider: string): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(`/crmauth/${crmProvider}/getRedirectUri/true`, { headers, responseType: 'text' }).pipe(
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Retrieves token from Hubspot using oAuth code
   * @param code - Code from hubspot connection redirections
   * @param provider - Provider for this function must be 'hubspot'
   * @returns Tokens of hubspot connection
   */
  getHubspotToken(code: string, provider: string): Observable<HubSpotGetToken> {
    return this.http
      .get<HubSpotGetToken>(`/crmauth/getToken?code=${code}&isFromNewAngularTs=true&provider=${provider}`)
      .pipe(
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets account data
   * @param token - Access token of hubspot
   * @param accountId - Account id
   * @param provider - Provider for this function must be 'hubspot'
   * @returns Observable of account of hubspot
   */
  getHubspotAccount(token: string, provider: string, accountId?: string): Observable<HubSpotGetAccount> {
    return this.http
      .get<ResponseData<HubSpotGetAccount>>(
        `/crm/account/${accountId as string}?crmToken=${token}&provider=${provider}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Retrieves token from Pardot using oAuth code
   * @param code - Code from hubspot connection redirections
   * @param provider - Provider for this function must be 'pardot'
   * @returns Tokens of pardot connection
   */
  getPardotToken(code: string, provider: string): Observable<PardotGetToken> {
    return this.http
      .get<PardotGetToken>(`/crmauth/getToken?code=${code}&isFromNewAngularTs=true&provider=${provider}`)
      .pipe(
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets account data
   * @param token - Access token of pardot
   * @param accountId - Account id
   * @param provider - Provider for this function must be 'pardot'
   * @returns Observable of account of pardot
   */
  getPardotAccount(token: string, provider: string, accountId: string): Observable<PardotGetAccount> {
    return this.http
      .get<ResponseData<PardotGetAccount>>(`/crm/account/${accountId}?crmToken=${token}&provider=${provider}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Test CRM connection
   * @param crmProvider - Provider name
   * @param configParams - Object of integration test connection config
   * @returns Observable of company info
   */
  testConnection(
    crmProvider: string,
    configParams: IntegrationTestConnectionConfig
  ): Observable<IntegrationTestConnectionResponse> {
    return this.http
      .post<ResponseData<IntegrationTestConnectionResponse>>(`/crm/${crmProvider}/test`, { configParams: configParams })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a new crm sync
   * @param crmProvider - Provider name
   * @param companyInfo - Company info
   * @returns Observable of company info
   */
  add(crmProvider: string, companyInfo: Partial<ICompanyInfo>): Observable<ICompanyInfo> {
    return this.http
      .post<ResponseData<ICompanyInfo>>(`/crm/${crmProvider}/companyInfo`, companyInfo)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the crm sync
   * @param crmProvider - Provider name
   * @param companyInfo - Company info
   * @returns Observable of company info
   */
  update(crmProvider: string, companyInfo: Partial<ICompanyInfo>): Observable<ICompanyInfo> {
    return this.http
      .put<ResponseData<ICompanyInfo>>(`/crm/${crmProvider}/companyInfo`, companyInfo)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets company info
   * @param crmProvider - Provider name
   * @returns Observable of company info
   */
  get(crmProvider: string): Observable<ICompanyInfo> {
    return this.http
      .get<ResponseData<ICompanyInfo>>(`/crm/${crmProvider}/companyinfo`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets lead field list
   * @param crmProvider - Provider name
   * @returns Observable of lead field list
   */
  getLeadFieldList(crmProvider: string): Observable<LeadFields[]> {
    return this.http.get<ResponseData<GetLeadFields>>(`/crm/${crmProvider}/getLeadFieldList`).pipe(
      map(value => {
        // Error popup with error message if exist
        if (value.data.errors) void this.alert.defaultErrorMessage(value.data.errors[0].message);
        return value.data.result;
      })
    );
  }

  /**
   * Gets workspace list
   * @param crmProvider - Provider name
   * @returns Observable of workspace list
   */
  getWorkspaces(crmProvider: string): Observable<MarketoWorkspace[]> {
    return this.http
      .get<ResponseData<MarketoWorkspace[]>>(`/crm/${crmProvider}/workspaces`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets program list
   * @param crmProvider - Provider name
   * @returns Observable of program list
   */
  getPrograms(crmProvider: string): Observable<MarketoProgram[]> {
    return this.http
      .get<ResponseData<MarketoProgram[]>>(`/crm/${crmProvider}/programs`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Delete CRM provider
   * @param crmProvider - Provider name
   * @returns Observable of void
   */
  delete(crmProvider: string): Observable<void> {
    return this.http
      .delete<ResponseData<void>>(`/crm/${crmProvider}/connection`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Sync crm data
   * @param crmProvider - Provider name
   * @returns Observable of synced data
   */
  sync(crmProvider: string): Observable<CrmManualSync> {
    return this.http
      .get<ResponseData<CrmManualSync>>(`/crm/${crmProvider}/sync`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Disconnect crm provider
   * @param crmProvider - Provider name
   * @returns Observable of true
   */
  disconnect(crmProvider: string): Observable<true> {
    return this.http.delete<Response>(`/crm/${crmProvider}/connection`).pipe(
      this.operator.extractResponse(),
      tap(() => this.navigationSidebarService.loadMyIntegrations())
    );
  }

  /**
   * Disconnect from hubspot
   * @param crmProvider - Provider name
   * @returns Observable of true
   */
  disconnectHubspot(crmProvider: string): Observable<true> {
    return this.http.delete<Response>(`/crm/${crmProvider}/hubspotConnection`).pipe(this.operator.extractResponse());
  }

  /**
   * Disconnect from hubspot
   * @param crmProvider - Provider name
   * @returns Observable of true
   */
  disconnectCrm(crmProvider: string): Observable<true> {
    return this.http.delete<Response>(`/crm/${crmProvider}/crmConnection`).pipe(
      this.operator.extractResponse(),
      tap(() => this.navigationSidebarService.loadMyIntegrations())
    );
  }

  /**
   * Disconnect from Kloudless
   * @param crmProvider -
   * @param accountId -
   * @param token -
   * @returns
   */
  disconnectKloudless(crmProvider: string, accountId: number, token: string): unknown {
    return this.http
      .delete(`/crm/${crmProvider}/kloudlessConnection?crmToken=${token}&accountId=${accountId}`)
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets all groups based off the `queryObj`
   * @param crmProvider -
   * @param token -
   * @param queryObj -
   * @returns
   */
  countLists(crmProvider: string, token: string, queryObj: IQueryObj): unknown {
    return this.http
      .get(`/crm/${crmProvider}/lists${this.queryHelperService.createQuery(queryObj)}&crmToken=${token}`)
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Import CRM lists as target groups
   * @param crmProvider - Provider name
   * @param queryObj - IQueryObj
   * @returns Observable of hubspot target groups object with list
   */
  getHubspotLists(crmProvider: string, queryObj: IQueryObj): Observable<HubspotTargetGroups> {
    return this.http
      .get<ResponseData<HubspotTargetGroups>>(
        `/crm/${crmProvider}/lists${this.queryHelperService.createQuery(queryObj)}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Import Marketo lists as target groups
   * @param crmProvider - Provider name
   * @param queryObj - Query object holding the keys or empty
   * @returns Observable of hubspot target groups object with list
   */
  getMarketoLists(crmProvider: string, queryObj: IQueryObj): Observable<MarketoTargetGroups> {
    return this.http
      .get<ResponseData<MarketoTargetGroups>>(
        `/crm/${crmProvider}/lists${this.queryHelperService.createQuery(queryObj)}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Import CRM lists as target groups
   * @param crmProvider - Provider name
   * @param queryObj - IQueryObj
   * @returns Observable of pardot target groups object with list
   */
  getPardotLists(crmProvider: string, queryObj: IQueryObj): Observable<PardotTargetGroups> {
    return this.http
      .get<ResponseData<PardotTargetGroups>>(
        `/crm/${crmProvider}/lists${this.queryHelperService.createQuery(queryObj)}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get connected crms landing pages
   * @param crmProvider - Provider name
   * @returns Observable of connected crms landing pages
   */
  getPages(crmProvider: string): Observable<CrmLandingPages[]> {
    return this.http
      .get<ResponseData<CrmLandingPages[]>>(`/crm/${crmProvider}/pages`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get Business Unit Ids for Pardot
   * @param crmProvider - Provider name
   * @returns Observable of business unit ids
   */
  getBusinessUnitIds(crmProvider: string): Observable<string[]> {
    return this.http
      .get<ResponseData<string[]>>(`/crm/${crmProvider}/getBusinessUnitIds`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Update business unit id
   * @param crmProvider - Provider name
   * @param newBusinessUnitId - New business unit id
   * @returns Observable of company info
   */
  changeBusinessUnitIds(crmProvider: string, newBusinessUnitId: string): Observable<ICompanyInfo> {
    return this.http
      .put<ResponseData<ICompanyInfo>>(`/crm/${crmProvider}/updateUnitId`, { newUnitId: newBusinessUnitId })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Create custom activity type
   * @param crmProvider - Provider name
   * @returns unknown
   */
  createCustomActivity(crmProvider: string): Observable<ClickCustomActivityType> {
    return this.http
      .post<ResponseData<ClickCustomActivityType>>(`/crm/${crmProvider}/createCustomActivityType`, {})
      .pipe(this.operator.extractResponseData());
  }
}
