<mt-custom-modal headline="{{ 'selecht_targetgroup' | transloco }}">
  <div
    *ngIf="loadTargetGroups$ | async as targetGroups; else loading"
    class="baseonecolumn"
    modal-body
  >
    <mt-list-actions>
      <ng-container left-side>
        <mt-select-all-button
          class="leftbutton"
          [checked]="!!targetGroups.length && list.isSelectedAll()"
          (click)="list.updateSelectionAll()"
        ></mt-select-all-button>
      </ng-container>

      <ng-container right-side>
        <mt-search
          *ngIf="(searchData$ | async) || targetGroups.length"
          class="leftbutton"
          [disableEnter]="true"
          (search)="searchService.setFilterText($event)"
        ></mt-search>

        <mt-sort-dropdown *ngIf="targetGroups.length">
          <li
            (click)="list.sortList('title')"
            transloco="sort_titel"
            ngbDropdownItem
          ></li>
        </mt-sort-dropdown>
      </ng-container>
    </mt-list-actions>

    <div class="scroll-block" id="scrollContainer">
      <div
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [infiniteScrollDisabled]="infinitescrolloptions.disable"
        [infiniteScrollContainer]="'#scrollContainer'"
        [fromRoot]="true"
        (scrolled)="infinitescrolloptions.scrollAction()"
      >
        <mt-list>
          <mt-row
            *ngFor="
              let targetGroup of targetGroups;
              odd as isOdd;
              even as isEven
            "
            [isEven]="isEven"
            [isOdd]="isOdd"
            [isSelected]="list.isSelected(targetGroup)"
            (click)="list.updateSelection(targetGroup)"
          >
            <div class="tcell first select">
              <selectcol [list]="list" [item]="targetGroup"></selectcol>
            </div>

            <div class="tcell w-auto">
              <targetgroupentrytitlecol
                iconColor="mt-blau"
                [item]="targetGroup"
              ></targetgroupentrytitlecol>
            </div>

            <div class="tcell w-auto">
              <targetgroupentrynumbercol
                [item]="targetGroup"
                [disableLinks]="true"
              ></targetgroupentrynumbercol>
            </div>

            <div class="tcell w-auto">
              <activecampaigncol
                [activeCampaign]="
                  targetGroup.Campaign ?? targetGroup.Event?.TriggerCampaign
                "
                [disableLinks]="true"
              >
              </activecampaigncol>
            </div> </mt-row
        ></mt-list>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="empty-state card">
      <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
  ></ng-template>

  <div class="d-flex justify-content-between" modal-footer>
    <div class="d-flex">
      <span
        *ngIf="list.selected.length"
        [innerHTML]="
          'param.target_groups_selected'
            | transloco: { amount: list.selected.length }
        "
      ></span>
    </div>
    <div class="d-flex">
      <mt-button
        (click)="modal.close()"
        class="mt_left"
        color="default"
        ngbAutoFocus
        text="abort"
      ></mt-button>
      <mt-button
        (click)="modal.close(list.selected)"
        [disabled]="!list.selected.length"
        class="roboto_light"
        color="primary"
        icon="plus"
        text="add_target_group"
      >
      </mt-button>
    </div>
  </div>
</mt-custom-modal>
